/*!
 * @dineug/erd-editor
 * @version 3.2.4 | Sat Sep 28 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
/*!
 * @dineug/erd-editor-schema
 * @version 0.1.0 | Sat Sep 28 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
var _g = typeof global == "object" && global && global.Object === Object && global, Pg = typeof self == "object" && self && self.Object === Object && self, rc = _g || Pg || Function("return this")(), rn = rc.Symbol, Ud = Object.prototype, Bg = Ud.hasOwnProperty, jg = Ud.toString, ar = rn ? rn.toStringTag : void 0;
function Ug(e) {
  var t = Bg.call(e, ar), n = e[ar];
  try {
    e[ar] = void 0;
    var o = !0;
  } catch {
  }
  var r = jg.call(e);
  return o && (t ? e[ar] = n : delete e[ar]), r;
}
var Wg = Object.prototype, Fg = Wg.toString;
function qg(e) {
  return Fg.call(e);
}
var Hg = "[object Null]", Vg = "[object Undefined]", _u = rn ? rn.toStringTag : void 0;
function ic(e) {
  return e == null ? e === void 0 ? Vg : Hg : _u && _u in Object(e) ? Ug(e) : qg(e);
}
function ma(e) {
  return e != null && typeof e == "object";
}
var Zg = "[object Symbol]";
function sc(e) {
  return typeof e == "symbol" || ma(e) && ic(e) == Zg;
}
function Wd(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = Array(o); ++n < o; )
    r[n] = t(e[n], n, e);
  return r;
}
var li = Array.isArray, zg = 1 / 0, Pu = rn ? rn.prototype : void 0, Bu = Pu ? Pu.toString : void 0;
function Fd(e) {
  if (typeof e == "string")
    return e;
  if (li(e))
    return Wd(e, Fd) + "";
  if (sc(e))
    return Bu ? Bu.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -zg ? "-0" : t;
}
function Us(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
function qd(e) {
  return e;
}
var Gg = "[object AsyncFunction]", Yg = "[object Function]", Kg = "[object GeneratorFunction]", Qg = "[object Proxy]";
function Hd(e) {
  if (!Us(e))
    return !1;
  var t = ic(e);
  return t == Yg || t == Kg || t == Gg || t == Qg;
}
var Ja = rc["__core-js_shared__"], ju = function() {
  var e = /[^.]+$/.exec(Ja && Ja.keys && Ja.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Jg(e) {
  return !!ju && ju in e;
}
var Xg = Function.prototype, ev = Xg.toString;
function tv(e) {
  if (e != null) {
    try {
      return ev.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var nv = /[\\^$.*+?()[\]{}|]/g, ov = /^\[object .+?Constructor\]$/, rv = Function.prototype, iv = Object.prototype, sv = rv.toString, av = iv.hasOwnProperty, lv = RegExp(
  "^" + sv.call(av).replace(nv, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function cv(e) {
  if (!Us(e) || Jg(e))
    return !1;
  var t = Hd(e) ? lv : ov;
  return t.test(tv(e));
}
function uv(e, t) {
  return e == null ? void 0 : e[t];
}
function ac(e, t) {
  var n = uv(e, t);
  return cv(n) ? n : void 0;
}
function fv(e, t, n) {
  switch (n.length) {
    case 0:
      return e.call(t);
    case 1:
      return e.call(t, n[0]);
    case 2:
      return e.call(t, n[0], n[1]);
    case 3:
      return e.call(t, n[0], n[1], n[2]);
  }
  return e.apply(t, n);
}
var dv = 800, hv = 16, mv = Date.now;
function pv(e) {
  var t = 0, n = 0;
  return function() {
    var o = mv(), r = hv - (o - n);
    if (n = o, r > 0) {
      if (++t >= dv)
        return arguments[0];
    } else
      t = 0;
    return e.apply(void 0, arguments);
  };
}
function yv(e) {
  return function() {
    return e;
  };
}
var Ws = function() {
  try {
    var e = ac(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), gv = Ws ? function(e, t) {
  return Ws(e, "toString", {
    configurable: !0,
    enumerable: !1,
    value: yv(t),
    writable: !0
  });
} : qd;
const vv = gv;
var Vd = pv(vv);
function bv(e, t, n, o) {
  for (var r = e.length, i = n + (o ? 1 : -1); o ? i-- : ++i < r; )
    if (t(e[i], i, e))
      return i;
  return -1;
}
function Iv(e) {
  return e !== e;
}
function Tv(e, t, n) {
  for (var o = n - 1, r = e.length; ++o < r; )
    if (e[o] === t)
      return o;
  return -1;
}
function wv(e, t, n) {
  return t === t ? Tv(e, t, n) : bv(e, Iv, n);
}
function Cv(e, t) {
  var n = e == null ? 0 : e.length;
  return !!n && wv(e, t, 0) > -1;
}
var Ov = 9007199254740991, Ev = /^(?:0|[1-9]\d*)$/;
function Zd(e, t) {
  var n = typeof e;
  return t = t ?? Ov, !!t && (n == "number" || n != "symbol" && Ev.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function Sv(e, t, n) {
  t == "__proto__" && Ws ? Ws(e, t, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : e[t] = n;
}
function zd(e, t) {
  return e === t || e !== e && t !== t;
}
var xv = Object.prototype, Av = xv.hasOwnProperty;
function $v(e, t, n) {
  var o = e[t];
  (!(Av.call(e, t) && zd(o, n)) || n === void 0 && !(t in e)) && Sv(e, t, n);
}
var Uu = Math.max;
function Gd(e, t, n) {
  return t = Uu(t === void 0 ? e.length - 1 : t, 0), function() {
    for (var o = arguments, r = -1, i = Uu(o.length - t, 0), s = Array(i); ++r < i; )
      s[r] = o[t + r];
    r = -1;
    for (var a = Array(t + 1); ++r < t; )
      a[r] = o[r];
    return a[t] = n(s), fv(e, this, a);
  };
}
function Nv(e, t) {
  return Vd(Gd(e, t, qd), e + "");
}
var Mv = 9007199254740991;
function Yd(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= Mv;
}
function kv(e) {
  return e != null && Yd(e.length) && !Hd(e);
}
var Dv = "[object Arguments]";
function Wu(e) {
  return ma(e) && ic(e) == Dv;
}
var Kd = Object.prototype, Lv = Kd.hasOwnProperty, Rv = Kd.propertyIsEnumerable, Qd = Wu(/* @__PURE__ */ function() {
  return arguments;
}()) ? Wu : function(e) {
  return ma(e) && Lv.call(e, "callee") && !Rv.call(e, "callee");
};
function _v(e) {
  return function(t) {
    return e(t);
  };
}
var Pv = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, Bv = /^\w*$/;
function jv(e, t) {
  if (li(e))
    return !1;
  var n = typeof e;
  return n == "number" || n == "symbol" || n == "boolean" || e == null || sc(e) ? !0 : Bv.test(e) || !Pv.test(e) || t != null && e in Object(t);
}
var Kr = ac(Object, "create");
function Uv() {
  this.__data__ = Kr ? Kr(null) : {}, this.size = 0;
}
function Wv(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Fv = "__lodash_hash_undefined__", qv = Object.prototype, Hv = qv.hasOwnProperty;
function Vv(e) {
  var t = this.__data__;
  if (Kr) {
    var n = t[e];
    return n === Fv ? void 0 : n;
  }
  return Hv.call(t, e) ? t[e] : void 0;
}
var Zv = Object.prototype, zv = Zv.hasOwnProperty;
function Gv(e) {
  var t = this.__data__;
  return Kr ? t[e] !== void 0 : zv.call(t, e);
}
var Yv = "__lodash_hash_undefined__";
function Kv(e, t) {
  var n = this.__data__;
  return this.size += this.has(e) ? 0 : 1, n[e] = Kr && t === void 0 ? Yv : t, this;
}
function Pn(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Pn.prototype.clear = Uv;
Pn.prototype.delete = Wv;
Pn.prototype.get = Vv;
Pn.prototype.has = Gv;
Pn.prototype.set = Kv;
function Qv() {
  this.__data__ = [], this.size = 0;
}
function pa(e, t) {
  for (var n = e.length; n--; )
    if (zd(e[n][0], t))
      return n;
  return -1;
}
var Jv = Array.prototype, Xv = Jv.splice;
function eb(e) {
  var t = this.__data__, n = pa(t, e);
  if (n < 0)
    return !1;
  var o = t.length - 1;
  return n == o ? t.pop() : Xv.call(t, n, 1), --this.size, !0;
}
function tb(e) {
  var t = this.__data__, n = pa(t, e);
  return n < 0 ? void 0 : t[n][1];
}
function nb(e) {
  return pa(this.__data__, e) > -1;
}
function ob(e, t) {
  var n = this.__data__, o = pa(n, e);
  return o < 0 ? (++this.size, n.push([e, t])) : n[o][1] = t, this;
}
function Wo(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Wo.prototype.clear = Qv;
Wo.prototype.delete = eb;
Wo.prototype.get = tb;
Wo.prototype.has = nb;
Wo.prototype.set = ob;
var rb = ac(rc, "Map");
function ib() {
  this.size = 0, this.__data__ = {
    hash: new Pn(),
    map: new (rb || Wo)(),
    string: new Pn()
  };
}
function sb(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function ya(e, t) {
  var n = e.__data__;
  return sb(t) ? n[typeof t == "string" ? "string" : "hash"] : n.map;
}
function ab(e) {
  var t = ya(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function lb(e) {
  return ya(this, e).get(e);
}
function cb(e) {
  return ya(this, e).has(e);
}
function ub(e, t) {
  var n = ya(this, e), o = n.size;
  return n.set(e, t), this.size += n.size == o ? 0 : 1, this;
}
function cn(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
cn.prototype.clear = ib;
cn.prototype.delete = ab;
cn.prototype.get = lb;
cn.prototype.has = cb;
cn.prototype.set = ub;
var fb = "Expected a function";
function lc(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(fb);
  var n = function() {
    var o = arguments, r = t ? t.apply(this, o) : o[0], i = n.cache;
    if (i.has(r))
      return i.get(r);
    var s = e.apply(this, o);
    return n.cache = i.set(r, s) || i, s;
  };
  return n.cache = new (lc.Cache || cn)(), n;
}
lc.Cache = cn;
var db = 500;
function hb(e) {
  var t = lc(e, function(o) {
    return n.size === db && n.clear(), o;
  }), n = t.cache;
  return t;
}
var mb = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, pb = /\\(\\)?/g, yb = hb(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(mb, function(n, o, r, i) {
    t.push(r ? i.replace(pb, "$1") : o || n);
  }), t;
});
function gb(e) {
  return e == null ? "" : Fd(e);
}
function ga(e, t) {
  return li(e) ? e : jv(e, t) ? [e] : yb(gb(e));
}
var vb = 1 / 0;
function cc(e) {
  if (typeof e == "string" || sc(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -vb ? "-0" : t;
}
function bb(e, t) {
  t = ga(t, e);
  for (var n = 0, o = t.length; e != null && n < o; )
    e = e[cc(t[n++])];
  return n && n == o ? e : void 0;
}
function Ib(e, t) {
  for (var n = -1, o = t.length, r = e.length; ++n < o; )
    e[r + n] = t[n];
  return e;
}
var Fu = rn ? rn.isConcatSpreadable : void 0;
function Tb(e) {
  return li(e) || Qd(e) || !!(Fu && e && e[Fu]);
}
function uc(e, t, n, o, r) {
  var i = -1, s = e.length;
  for (n || (n = Tb), r || (r = []); ++i < s; ) {
    var a = e[i];
    t > 0 && n(a) ? t > 1 ? uc(a, t - 1, n, o, r) : Ib(r, a) : o || (r[r.length] = a);
  }
  return r;
}
function wb(e) {
  var t = e == null ? 0 : e.length;
  return t ? uc(e, 1) : [];
}
function Cb(e) {
  return Vd(Gd(e, void 0, wb), e + "");
}
var Ob = "__lodash_hash_undefined__";
function Eb(e) {
  return this.__data__.set(e, Ob), this;
}
function Sb(e) {
  return this.__data__.has(e);
}
function Fs(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.__data__ = new cn(); ++t < n; )
    this.add(e[t]);
}
Fs.prototype.add = Fs.prototype.push = Eb;
Fs.prototype.has = Sb;
function xb(e, t) {
  return e.has(t);
}
function Ab(e, t) {
  return e != null && t in Object(e);
}
function $b(e, t, n) {
  t = ga(t, e);
  for (var o = -1, r = t.length, i = !1; ++o < r; ) {
    var s = cc(t[o]);
    if (!(i = e != null && n(e, s)))
      break;
    e = e[s];
  }
  return i || ++o != r ? i : (r = e == null ? 0 : e.length, !!r && Yd(r) && Zd(s, r) && (li(e) || Qd(e)));
}
function Nb(e, t) {
  return e != null && $b(e, t, Ab);
}
function qu(e) {
  return ma(e) && kv(e);
}
function Mb(e, t, n) {
  for (var o = -1, r = e == null ? 0 : e.length; ++o < r; )
    if (n(t, e[o]))
      return !0;
  return !1;
}
var kb = 200;
function Db(e, t, n, o) {
  var r = -1, i = Cv, s = !0, a = e.length, l = [], c = t.length;
  if (!a)
    return l;
  n && (t = Wd(t, _v(n))), o ? (i = Mb, s = !1) : t.length >= kb && (i = xb, s = !1, t = new Fs(t));
  e:
    for (; ++r < a; ) {
      var u = e[r], f = n == null ? u : n(u);
      if (u = o || u !== 0 ? u : 0, s && f === f) {
        for (var d = c; d--; )
          if (t[d] === f)
            continue e;
        l.push(u);
      } else
        i(t, f, o) || l.push(u);
    }
  return l;
}
var fc = Nv(function(e, t) {
  return qu(e) ? Db(e, uc(t, 1, qu, !0)) : [];
});
function Lb(e, t, n, o) {
  if (!Us(e))
    return e;
  t = ga(t, e);
  for (var r = -1, i = t.length, s = i - 1, a = e; a != null && ++r < i; ) {
    var l = cc(t[r]), c = n;
    if (l === "__proto__" || l === "constructor" || l === "prototype")
      return e;
    if (r != s) {
      var u = a[l];
      c = o ? o(u, l, a) : void 0, c === void 0 && (c = Us(u) ? u : Zd(t[r + 1]) ? [] : {});
    }
    $v(a, l, c), a = a[l];
  }
  return e;
}
function Rb(e, t, n) {
  for (var o = -1, r = t.length, i = {}; ++o < r; ) {
    var s = t[o], a = bb(e, s);
    n(a, s) && Lb(i, ga(s, e), a);
  }
  return i;
}
function _b(e, t) {
  return Rb(e, t, function(n, o) {
    return Nb(e, o);
  });
}
var Pb = Cb(function(e, t) {
  return e == null ? {} : _b(e, t);
});
const ci = (e) => (t) => typeof t === e, Bb = ci("object"), He = ci("boolean"), ne = ci("number"), G = ci("string"), jb = ci("undefined"), Jd = (e) => e === null, Be = (e) => Jd(e) || jb(e), { isArray: Te } = Array, ut = (e) => Bb(e) && !Jd(e) && !Te(e), Ub = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let Wb = (e, t, n) => {
  let o = (2 << Math.log(e.length - 1) / Math.LN2) - 1, r = -~(1.6 * o * t / e.length);
  return (i = t) => {
    let s = "";
    for (; ; ) {
      let a = n(r), l = r;
      for (; l--; )
        if (s += e[a[l] & o] || "", s.length === i)
          return s;
    }
  };
};
const Fb = Wb(Ub, 21, (e) => {
  let t = globalThis.crypto;
  return t === void 0 && (t = require("node:crypto").webcrypto), t.getRandomValues(new Uint8Array(e));
}), ui = (e, t) => (n) => Math.min(Math.max(n, e), t);
function w(e, t, n) {
  return (o) => {
    if (!n)
      return;
    const r = n[o];
    e(r) && (t[o] = r);
  };
}
function We(e) {
  return (t) => G(t) && e.includes(t);
}
function It(e) {
  return (t) => ne(t) && e.includes(t);
}
function Le(e, t, n) {
  return Reflect.get(e, t) ?? n;
}
function Fo() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function qo(e, t) {
  const n = w(ne, e, t);
  n("updateAt"), n("createAt");
}
const qb = () => ({
  tableIds: [],
  relationshipIds: [],
  indexIds: [],
  memoIds: []
});
function Hb(e) {
  const t = qb();
  if (!ut(e) || Be(e))
    return t;
  const n = w(Te, t, e);
  return n("tableIds"), n("relationshipIds"), n("indexIds"), n("memoIds"), t;
}
const Xd = () => ({
  id: "",
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Fo()
});
function Vb(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = Xd(), r = w(G, o, n), i = w(He, o, n), s = w(Te, o, n);
    r("id"), r("name"), r("tableId"), i("unique"), s("indexColumnIds"), s("seqIndexColumnIds"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const dc = {
  ASC: (
    /*  */
    1
  ),
  DESC: (
    /* */
    2
  )
}, eh = Object.values(dc), th = () => ({
  id: "",
  indexId: "",
  columnId: "",
  orderType: dc.ASC,
  meta: Fo()
});
function Zb(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = th(), r = w(G, o, n);
    r("id"), r("indexId"), r("columnId"), w(It(eh), o, n)("orderType"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const zb = () => ({});
function Gb(e) {
  const t = zb();
  if (!ut(e) || Be(e))
    return t;
  for (const [n, o] of Object.entries(e)) {
    if (!Te(o) || o.length !== 4)
      continue;
    const [r, i, s, a] = o;
    if (G(r) && ne(i) && ne(s) && ut(a)) {
      const l = {}, c = w(ne, l, a);
      Object.keys(a).forEach(c), t[n] = [r, i, s, l];
    }
  }
  return t;
}
const nh = () => ({
  id: "",
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: 116,
    height: 100,
    color: ""
  },
  meta: Fo()
});
function Yb(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = nh(), r = w(G, o, n), i = w(ne, o.ui, n.ui), s = w(G, o.ui, n.ui);
    r("id"), r("value"), s("color"), i("x"), i("y"), i("zIndex"), i("width"), i("height"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const hc = {
  // ZeroOneN: /* */ 0b0000000000000000000000000000001,
  ZeroOne: (
    /*  */
    2
  ),
  ZeroN: (
    /*    */
    4
  ),
  OneOnly: (
    /*  */
    8
  ),
  OneN: (
    /*     */
    16
  )
  // One: /*      */ 0b0000000000000000000000000100000,
  // N: /*        */ 0b0000000000000000000000001000000,
}, oh = Object.values(hc), mc = {
  ring: (
    /* */
    1
  ),
  dash: (
    /* */
    2
  )
}, rh = Object.values(mc), qs = {
  left: (
    /*   */
    1
  ),
  right: (
    /*  */
    2
  ),
  top: (
    /*    */
    4
  ),
  bottom: (
    /* */
    8
  )
}, wl = Object.values(qs), ih = () => ({
  id: "",
  identification: !1,
  relationshipType: hc.ZeroN,
  startRelationshipType: mc.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: qs.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: qs.bottom
  },
  meta: Fo()
});
function Kb(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = ih(), r = w(G, o, n), i = w(He, o, n), s = w(ne, o.start, n.start), a = w(G, o.start, n.start), l = w(ne, o.end, n.end), c = w(G, o.end, n.end);
    r("id"), i("identification"), w(It(oh), o, n)("relationshipType"), w(It(rh), o, n)("startRelationshipType"), a("tableId"), s("x"), s("y"), w(It(wl), o.start, n.start)("direction"), w(Te, o.start, n.start)("columnIds"), c("tableId"), l("x"), l("y"), w(It(wl), o.end, n.end)("direction"), w(Te, o.end, n.end)("columnIds"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const pc = {
  ERD: "ERD",
  visualization: "@dineug/erd-editor/builtin-visualization",
  schemaSQL: "@dineug/erd-editor/builtin-schema-sql",
  generatorCode: "@dineug/erd-editor/builtin-generator-code",
  settings: "settings"
}, Qb = Object.values(pc), Yt = {
  tableComment: (
    /*        */
    1
  ),
  columnComment: (
    /*       */
    2
  ),
  columnDataType: (
    /*      */
    4
  ),
  columnDefault: (
    /*       */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnPrimaryKey: (
    /*    */
    32
  ),
  columnUnique: (
    /*        */
    64
  ),
  columnNotNull: (
    /*       */
    128
  ),
  relationship: (
    /*        */
    256
  )
}, $t = {
  columnName: (
    /*          */
    1
  ),
  columnDataType: (
    /*      */
    2
  ),
  columnNotNull: (
    /*       */
    4
  ),
  columnUnique: (
    /*        */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnDefault: (
    /*       */
    32
  ),
  columnComment: (
    /*       */
    64
  )
}, Cl = Object.values($t), yc = {
  MariaDB: (
    /*    */
    1
  ),
  MSSQL: (
    /*      */
    2
  ),
  MySQL: (
    /*      */
    4
  ),
  Oracle: (
    /*     */
    8
  ),
  PostgreSQL: (
    /* */
    16
  ),
  SQLite: (
    /*     */
    32
  )
}, sh = Object.values(yc), gc = {
  GraphQL: (
    /*    */
    1
  ),
  csharp: (
    /*     */
    2
  ),
  Java: (
    /*       */
    4
  ),
  Kotlin: (
    /*     */
    8
  ),
  TypeScript: (
    /* */
    16
  ),
  JPA: (
    /*        */
    32
  ),
  Scala: (
    /*      */
    64
  )
}, ah = Object.values(gc), Hs = {
  none: (
    /*       */
    1
  ),
  camelCase: (
    /*  */
    2
  ),
  pascalCase: (
    /* */
    4
  ),
  snakeCase: (
    /*  */
    8
  )
}, Ol = Object.values(Hs), vc = {
  none: (
    /*        */
    1
  ),
  doubleQuote: (
    /* */
    2
  ),
  singleQuote: (
    /* */
    4
  ),
  backtick: (
    /*    */
    8
  )
}, lh = Object.values(vc), Jb = {
  scroll: (
    /*    */
    1
  ),
  zoomLevel: (
    /* */
    2
  )
}, ch = 0.1, uh = 1, fh = 2e3, dh = 2e4, Xb = Yt.tableComment | Yt.columnComment | Yt.columnDataType | Yt.columnDefault | Yt.columnPrimaryKey | Yt.columnNotNull | Yt.relationship, e0 = () => ({
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: Xb,
  database: yc.MySQL,
  databaseName: "",
  canvasType: pc.ERD,
  language: gc.GraphQL,
  tableNameCase: Hs.pascalCase,
  columnNameCase: Hs.camelCase,
  bracketType: vc.none,
  relationshipDataTypeSync: !0,
  relationshipOptimization: !1,
  columnOrder: [
    $t.columnName,
    $t.columnDataType,
    $t.columnNotNull,
    $t.columnUnique,
    $t.columnAutoIncrement,
    $t.columnDefault,
    $t.columnComment
  ],
  maxWidthComment: -1,
  ignoreSaveSettings: 0
}), Hu = ui(fh, dh), t0 = ui(ch, uh), n0 = ui(60, 200);
function o0(e) {
  const t = e0();
  if (!ut(e) || Be(e))
    return t;
  const n = w(ne, t, e), o = w(G, t, e), r = w(He, t, e);
  return ne(e.width) && (t.width = Hu(e.width)), ne(e.height) && (t.height = Hu(e.height)), ne(e.zoomLevel) && (t.zoomLevel = t0(e.zoomLevel)), ne(e.maxWidthComment) && e.maxWidthComment !== -1 && (t.maxWidthComment = n0(e.maxWidthComment)), n("scrollTop"), n("scrollLeft"), n("show"), n("ignoreSaveSettings"), o("databaseName"), o("canvasType"), r("relationshipDataTypeSync"), r("relationshipOptimization"), w(It(sh), t, e)("database"), w(It(ah), t, e)("language"), w(It(Ol), t, e)("tableNameCase"), w(It(Ol), t, e)("columnNameCase"), w(It(lh), t, e)("bracketType"), Te(e.columnOrder) && Cl.length === e.columnOrder.length && fc(Cl, e.columnOrder).length === 0 && (t.columnOrder = e.columnOrder), t;
}
const hh = () => ({
  id: "",
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60,
    color: ""
  },
  meta: Fo()
});
function r0(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = hh(), r = w(G, o, n), i = w(Te, o, n), s = w(ne, o.ui, n.ui), a = w(G, o.ui, n.ui);
    r("id"), r("name"), r("comment"), i("columnIds"), i("seqColumnIds"), a("color"), s("x"), s("y"), s("zIndex"), s("widthName"), s("widthComment"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const mh = () => ({
  id: "",
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  },
  meta: Fo()
});
function i0(e) {
  const t = {};
  if (!ut(e) || Be(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = mh(), r = w(G, o, n), i = w(ne, o, n), s = w(ne, o.ui, n.ui);
    r("id"), r("tableId"), r("name"), r("comment"), r("dataType"), r("default"), i("options"), s("keys"), s("widthName"), s("widthComment"), s("widthDataType"), s("widthDefault"), qo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
function qn(e) {
  var t, n, o, r, i, s;
  const a = e, l = o0(a.settings), c = Hb(a.doc), u = Gb(a.lww), f = r0((t = a.collections) == null ? void 0 : t.tableEntities), d = i0((n = a.collections) == null ? void 0 : n.tableColumnEntities), h = Kb((o = a.collections) == null ? void 0 : o.relationshipEntities), p = Vb((r = a.collections) == null ? void 0 : r.indexEntities), g = Zb((i = a.collections) == null ? void 0 : i.indexColumnEntities), v = Yb((s = a.collections) == null ? void 0 : s.memoEntities);
  return {
    $schema: "https://raw.githubusercontent.com/dineug/erd-editor/main/json-schema/schema.json",
    version: "3.0.0",
    settings: l,
    doc: c,
    collections: {
      tableEntities: f,
      tableColumnEntities: d,
      relationshipEntities: h,
      indexEntities: p,
      indexColumnEntities: g,
      memoEntities: v
    },
    lww: u
  };
}
const s0 = {
  autoIncrement: (
    /* */
    1
  ),
  primaryKey: (
    /*    */
    2
  ),
  unique: (
    /*        */
    4
  ),
  notNull: (
    /*       */
    8
  )
}, a0 = {
  primaryKey: (
    /* */
    1
  ),
  foreignKey: (
    /* */
    2
  )
}, E = {
  CanvasType: pc,
  CanvasTypeList: Qb,
  Show: Yt,
  ColumnType: $t,
  ColumnTypeList: Cl,
  Database: yc,
  DatabaseList: sh,
  Language: gc,
  LanguageList: ah,
  NameCase: Hs,
  NameCaseList: Ol,
  BracketType: vc,
  BracketTypeList: lh,
  RelationshipType: hc,
  RelationshipTypeList: oh,
  StartRelationshipType: mc,
  StartRelationshipTypeList: rh,
  Direction: qs,
  DirectionList: wl,
  ColumnOption: s0,
  ColumnUIKey: a0,
  OrderType: dc,
  OrderTypeList: eh,
  SaveSettingType: Jb,
  CANVAS_SIZE_MAX: dh,
  CANVAS_SIZE_MIN: fh,
  CANVAS_ZOOM_MAX: uh,
  CANVAS_ZOOM_MIN: ch
};
function l0(e) {
  const t = qn({});
  return c0(t.settings, e.canvas), u0(t, e.table), f0(t, e.memo), d0(t, e.relationship), t;
}
function c0(e, t) {
  e.width = t.width, e.height = t.height, e.scrollTop = t.scrollTop, e.scrollLeft = t.scrollLeft, e.zoomLevel = t.zoomLevel, e.databaseName = t.databaseName, e.canvasType = E.CanvasType.ERD, e.show = Object.keys(t.show).reduce((o, r) => {
    if (Le(t.show, r, !1)) {
      const i = Le(E.Show, r, 0);
      return o | i;
    }
    return o;
  }, 0), e.database = Reflect.get(E.Database, t.database) ?? E.Database.MySQL, e.language = t.language === "C#" ? E.Language.csharp : Le(E.Language, t.language, E.Language.GraphQL), e.tableNameCase = Le(E.NameCase, t.tableCase, E.NameCase.pascalCase), e.columnNameCase = Le(E.NameCase, t.columnCase, E.NameCase.camelCase), e.bracketType = Le(E.BracketType, t.bracketType, E.BracketType.none), e.relationshipDataTypeSync = t.setting.relationshipDataTypeSync, e.relationshipOptimization = t.setting.relationshipOptimization;
  const n = t.setting.columnOrder.map((o) => E.ColumnType[o]);
  fc(E.ColumnTypeList, n).length === 0 && (e.columnOrder = n);
}
function u0(e, t) {
  const n = t.tables.filter(({ id: r }) => !!r), o = t.indexes.filter(({ id: r }) => !!r);
  e.doc.tableIds = n.map(({ id: r }) => r), e.doc.indexIds = o.map(({ id: r }) => r);
  for (const r of n) {
    const i = hh();
    i.id = r.id, i.name = r.name, i.comment = r.comment, i.columnIds = r.columns.map(({ id: s }) => s), i.seqColumnIds = [...i.columnIds], i.ui.y = r.ui.top, i.ui.x = r.ui.left, i.ui.zIndex = r.ui.zIndex, i.ui.widthName = r.ui.widthName, i.ui.widthComment = r.ui.widthComment, i.ui.color = r.ui.color ?? "";
    for (const s of r.columns) {
      const a = mh();
      a.id = s.id, a.tableId = r.id, a.name = s.name, a.comment = s.comment, a.dataType = s.dataType, a.default = s.default, a.options = Object.keys(s.option).reduce((l, c) => {
        if (Le(s.option, c, !1)) {
          const u = Le(E.ColumnOption, c, 0);
          return l | u;
        }
        return l;
      }, 0), a.ui.widthName = s.ui.widthName, a.ui.widthComment = s.ui.widthComment, a.ui.widthDataType = s.ui.widthDataType, a.ui.widthDefault = s.ui.widthDefault, s.ui.pfk ? a.ui.keys = E.ColumnUIKey.primaryKey | E.ColumnUIKey.foreignKey : s.ui.pk ? a.ui.keys = E.ColumnUIKey.primaryKey : s.ui.fk && (a.ui.keys = E.ColumnUIKey.foreignKey), e.collections.tableColumnEntities[a.id] = a;
    }
    e.collections.tableEntities[i.id] = i;
  }
  for (const r of o) {
    const i = Xd();
    i.id = r.id, i.name = r.name, i.tableId = r.tableId, i.unique = r.unique;
    for (const s of r.columns) {
      const a = Fb(), l = th();
      i.indexColumnIds.push(a), i.seqIndexColumnIds.push(a), l.id = a, l.indexId = r.id, l.columnId = s.id, l.orderType = Le(E.OrderType, s.orderType, E.OrderType.ASC), e.collections.indexColumnEntities[l.id] = l;
    }
    e.collections.indexEntities[i.id] = i;
  }
}
function f0(e, t) {
  const n = t.memos.filter(({ id: o }) => !!o);
  e.doc.memoIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = nh();
    r.id = o.id, r.value = o.value, r.ui.y = o.ui.top, r.ui.x = o.ui.left, r.ui.width = o.ui.width, r.ui.height = o.ui.height, r.ui.zIndex = o.ui.zIndex, r.ui.color = o.ui.color ?? "", e.collections.memoEntities[r.id] = r;
  }
}
function d0(e, t) {
  const n = t.relationships.filter(({ id: o }) => !!o);
  e.doc.relationshipIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = ih();
    r.id = o.id, r.identification = o.identification, r.relationshipType = Le(E.RelationshipType, o.relationshipType, E.RelationshipType.ZeroN), r.startRelationshipType = Le(E.StartRelationshipType, o.startRelationshipType === "Ring" ? "ring" : "dash", E.StartRelationshipType.dash), r.start.tableId = o.start.tableId, r.start.columnIds = o.start.columnIds, r.start.x = o.start.x, r.start.y = o.start.y, r.start.direction = Le(E.Direction, o.start.direction, E.Direction.bottom), r.end.tableId = o.end.tableId, r.end.columnIds = o.end.columnIds, r.end.x = o.end.x, r.end.y = o.end.y, r.end.direction = Le(E.Direction, o.end.direction, E.Direction.bottom), e.collections.relationshipEntities[r.id] = r;
  }
}
function h0(e) {
  return [e, -1, -1, {}];
}
function bc(e, t, n) {
  return e[t] || (e[t] = h0(n), e[t]);
}
function m0(e, t, n, o, r) {
  const i = bc(e, n, o), s = i[1], a = i[2];
  s < t && (i[1] = t), a < t && r();
}
function p0(e, t, n, o, r) {
  const i = bc(e, n, o), s = i[2], a = i[1];
  s < t && (i[2] = t), a <= t && r();
}
function ph(e, t, n, o, r, i) {
  const s = bc(e, n, o);
  (s[3][r] ?? -1) <= t && (s[3][r] = t, i());
}
let y0 = class {
  constructor(t) {
    Object.defineProperty(this, "collections", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    });
  }
  collection(t) {
    return new g0(this.collections[t], t);
  }
}, g0 = class {
  constructor(t, n) {
    Object.defineProperty(this, "collection", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "collectionKey", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    });
  }
  selectById(t) {
    return this.collection[t];
  }
  selectByIds(t) {
    return t.length, t.map((n) => this.selectById(n)).filter(Boolean);
  }
  selectEntities() {
    return this.collection;
  }
  selectAll() {
    return Object.values(this.collection);
  }
  setOne(t) {
    return this.collection[t.id] = t, this;
  }
  setMany(t) {
    return t.forEach((n) => this.setOne(n)), this;
  }
  setAll(t) {
    return this.removeAll(), this.setMany(t), this;
  }
  addOne(t) {
    return this.selectById(t.id) ? this : (this.setOne(t), this);
  }
  addMany(t) {
    return t.forEach((n) => this.addOne(n)), this;
  }
  removeOne(t) {
    return this.selectById(t) && Reflect.deleteProperty(this.collection, t), this;
  }
  removeMany(t) {
    return t.forEach((n) => this.removeOne(n)), this;
  }
  removeAll() {
    return this.collection = {}, this;
  }
  updateOne(t, n) {
    const o = this.selectById(t);
    return o && (n(o), o.meta.updateAt = Date.now()), this;
  }
  updateMany(t, n) {
    return t.forEach((o) => this.updateOne(o, n)), this;
  }
  getOrCreate(t, n) {
    const o = this.selectById(t);
    if (o)
      return o;
    const r = n(t);
    return this.setOne(r), this.selectById(t);
  }
  addOperator(t, n, o, r) {
    return m0(t, n, o, this.collectionKey, r), this;
  }
  removeOperator(t, n, o, r) {
    return p0(t, n, o, this.collectionKey, r), this;
  }
  replaceOperator(t, n, o, r, i) {
    return ph(t, n, o, this.collectionKey, r, i), this;
  }
};
const y = (e) => new y0(e);
function Vu(e, t) {
  return (e & t) === t;
}
const v0 = {
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, Zu = Object.values(v0), b0 = {
  ERD: "ERD",
  "@vuerd/builtin-sql-ddl": "@vuerd/builtin-sql-ddl",
  "@vuerd/builtin-grid": "@vuerd/builtin-grid",
  "@vuerd/builtin-generator-code": "@vuerd/builtin-generator-code",
  "@vuerd/builtin-visualization": "@vuerd/builtin-visualization"
}, I0 = Object.values(b0), T0 = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, w0 = Object.values(T0), C0 = {
  GraphQL: "GraphQL",
  "C#": "C#",
  Java: "Java",
  Kotlin: "Kotlin",
  TypeScript: "TypeScript",
  JPA: "JPA",
  Scala: "Scala"
}, O0 = Object.values(C0), E0 = {
  none: "none",
  camelCase: "camelCase",
  pascalCase: "pascalCase",
  snakeCase: "snakeCase"
}, zu = Object.values(E0), S0 = {
  AtomOneDark: "AtomOneDark",
  AtomOneLight: "AtomOneLight",
  MonokaiSublime: "MonokaiSublime",
  GithubGist: "GithubGist",
  VS2015: "VS2015"
}, x0 = Object.values(S0), A0 = {
  none: "none",
  doubleQuote: "doubleQuote",
  singleQuote: "singleQuote",
  backtick: "backtick"
}, $0 = Object.values(A0), N0 = 0.1, M0 = 1, k0 = 2e3, D0 = 2e4, L0 = () => ({
  version: "2.2.11",
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: {
    tableComment: !0,
    columnComment: !0,
    columnDataType: !0,
    columnDefault: !0,
    columnAutoIncrement: !1,
    columnPrimaryKey: !0,
    columnUnique: !1,
    columnNotNull: !0,
    relationship: !0
  },
  database: "MySQL",
  databaseName: "",
  canvasType: "ERD",
  language: "GraphQL",
  tableCase: "pascalCase",
  columnCase: "camelCase",
  highlightTheme: "VS2015",
  bracketType: "none",
  setting: {
    relationshipDataTypeSync: !0,
    relationshipOptimization: !1,
    columnOrder: [
      "columnName",
      "columnDataType",
      "columnNotNull",
      "columnUnique",
      "columnAutoIncrement",
      "columnDefault",
      "columnComment"
    ]
  },
  pluginSerializationMap: {}
}), Gu = ui(k0, D0), R0 = ui(N0, M0);
function _0(e) {
  var t;
  const n = L0();
  if (Be(e))
    return n;
  const o = w(ne, n, e), r = w(G, n, e), i = w(He, n.show, e.show), s = w(He, n.setting, e.setting);
  if (ne(e.width) && (n.width = Gu(e.width)), ne(e.height) && (n.height = Gu(e.height)), ne(e.zoomLevel) && (n.zoomLevel = R0(e.zoomLevel)), r("version"), r("databaseName"), o("scrollTop"), o("scrollLeft"), w(We(w0), n, e)("database"), w(We(I0), n, e)("canvasType"), w(We(O0), n, e)("language"), w(We(zu), n, e)("tableCase"), w(We(zu), n, e)("columnCase"), w(We(x0), n, e)("highlightTheme"), w(We($0), n, e)("bracketType"), i("tableComment"), i("columnComment"), i("columnDataType"), i("columnDefault"), i("columnAutoIncrement"), i("columnPrimaryKey"), i("columnUnique"), i("columnNotNull"), i("relationship"), s("relationshipDataTypeSync"), s("relationshipOptimization"), (t = e.setting) != null && t.columnOrder && Zu.length === e.setting.columnOrder.length && fc(Zu, e.setting.columnOrder).length === 0 && (n.setting.columnOrder = e.setting.columnOrder), ut(e.pluginSerializationMap)) {
    const a = e.pluginSerializationMap;
    for (const l of Object.keys(a)) {
      const c = a[l];
      G(c) && (n.pluginSerializationMap[l] = c);
    }
  }
  return n;
}
const P0 = () => ({
  memos: []
}), B0 = () => ({
  id: "",
  value: "",
  ui: {
    active: !1,
    left: 200,
    top: 200,
    zIndex: 2,
    width: 127,
    height: 127
  }
});
function j0(e) {
  const t = P0();
  if (Be(e) || !Te(e.memos))
    return t;
  for (const n of e.memos) {
    const o = B0(), r = w(G, o, n), i = w(ne, o.ui, n.ui), s = w(He, o.ui, n.ui), a = w(G, o.ui, n.ui);
    r("id"), r("value"), s("active"), a("color"), i("left"), i("top"), i("zIndex"), i("width"), i("height"), t.memos.push(o);
  }
  return t;
}
const U0 = ["ZeroOneN", "One", "N"], W0 = {
  ZeroOneN: "ZeroN",
  One: "OneOnly",
  N: "OneN"
}, F0 = (e) => U0.includes(e) ? W0[e] : e, gr = {
  ZeroOneN: "ZeroOneN",
  ZeroOne: "ZeroOne",
  ZeroN: "ZeroN",
  OneOnly: "OneOnly",
  OneN: "OneN",
  One: "One",
  N: "N"
}, q0 = Object.values(gr), H0 = {
  Ring: "Ring",
  Dash: "Dash"
}, V0 = Object.values(H0), vr = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, Yu = Object.values(vr), Z0 = () => ({
  relationships: []
}), z0 = () => ({
  id: "",
  identification: !1,
  relationshipType: "ZeroN",
  startRelationshipType: "Dash",
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  constraintName: "",
  visible: !0
});
function G0(e) {
  var t, n, o, r;
  const i = Z0();
  if (Be(e) || !Te(e.relationships))
    return i;
  for (const s of e.relationships) {
    const a = z0(), l = w(G, a, s), c = w(He, a, s), u = w(ne, a.start, s.start), f = w(G, a.start, s.start), d = w(ne, a.end, s.end), h = w(G, a.end, s.end);
    l("id"), l("constraintName"), c("identification"), c("visible"), w(We(q0), a, s)("relationshipType"), a.relationshipType = F0(a.relationshipType), w(We(V0), a, s)("startRelationshipType"), f("tableId"), u("x"), u("y"), w(We(Yu), a.start, s.start)("direction"), Te((t = s.start) == null ? void 0 : t.columnIds) && (a.start.columnIds = (n = s.start) == null ? void 0 : n.columnIds.filter(G)), h("tableId"), d("x"), d("y"), w(We(Yu), a.end, s.end)("direction"), Te((o = s.end) == null ? void 0 : o.columnIds) && (a.end.columnIds = (r = s.end) == null ? void 0 : r.columnIds.filter(G)), i.relationships.push(a);
  }
  return i;
}
const yh = {
  ASC: "ASC",
  DESC: "DESC"
}, Y0 = Object.values(yh), K0 = () => ({
  tables: [],
  indexes: []
}), Q0 = () => ({
  id: "",
  name: "",
  comment: "",
  columns: [],
  ui: {
    active: !1,
    left: 200,
    top: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60
  },
  visible: !0
}), J0 = () => ({
  id: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  option: {
    autoIncrement: !1,
    primaryKey: !1,
    unique: !1,
    notNull: !1
  },
  ui: {
    active: !1,
    pk: !1,
    fk: !1,
    pfk: !1,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  }
}), X0 = () => ({
  id: "",
  name: "",
  tableId: "",
  columns: [],
  unique: !1
}), eI = () => ({
  id: "",
  orderType: yh.ASC
});
function tI(e) {
  const t = K0();
  if (Be(e))
    return t;
  if (Te(e.tables))
    for (const n of e.tables) {
      const o = Q0(), r = w(G, o, n), i = w(He, o, n), s = w(ne, o.ui, n.ui), a = w(He, o.ui, n.ui), l = w(G, o.ui, n.ui);
      if (r("id"), r("name"), r("comment"), i("visible"), a("active"), l("color"), s("left"), s("top"), s("zIndex"), s("widthName"), s("widthComment"), Te(n.columns))
        for (const c of n.columns) {
          const u = J0(), f = w(G, u, c), d = w(ne, u.ui, c.ui), h = w(He, u.ui, c.ui), p = w(He, u.option, c.option);
          f("id"), f("name"), f("comment"), f("dataType"), f("default"), p("autoIncrement"), p("primaryKey"), p("unique"), p("notNull"), h("active"), h("pk"), h("fk"), h("pfk"), d("widthName"), d("widthComment"), d("widthDataType"), d("widthDefault"), o.columns.push(u);
        }
      t.tables.push(o);
    }
  if (Te(e.indexes))
    for (const n of e.indexes) {
      const o = X0(), r = w(G, o, n), i = w(He, o, n);
      if (r("id"), r("name"), r("tableId"), i("unique"), Te(n.columns))
        for (const s of n.columns) {
          const a = eI();
          w(G, a, s)("id"), w(We(Y0), a, s)("orderType"), o.columns.push(a);
        }
      t.indexes.push(o);
    }
  return t;
}
function nI(e) {
  const t = e, n = _0(t.canvas), o = tI(t.table), r = G0(t.relationship), i = j0(t.memo);
  return { canvas: n, table: o, relationship: r, memo: i };
}
E.RelationshipType.ZeroOne + "", gr.ZeroOne, E.RelationshipType.ZeroN + "", gr.ZeroN, E.RelationshipType.OneOnly + "", gr.OneOnly, E.RelationshipType.OneN + "", gr.OneN;
E.Direction.left + "", vr.left, E.Direction.right + "", vr.right, E.Direction.top + "", vr.top, E.Direction.bottom + "", vr.bottom;
function gh(e) {
  const t = JSON.parse(e);
  return Reflect.get(t, "version") === "3.0.0" ? qn(t) : l0(nI(t));
}
function Ic(e) {
  const t = Pb(e, [
    "$schema",
    "version",
    "settings",
    "doc",
    "collections",
    "lww"
  ]);
  return Vu(t.settings.ignoreSaveSettings, E.SaveSettingType.scroll) && (t.settings.scrollTop = 0, t.settings.scrollLeft = 0), Vu(t.settings.ignoreSaveSettings, E.SaveSettingType.zoomLevel) && (t.settings.zoomLevel = 1), JSON.stringify(t, null, 2);
}
/*!
 * @dineug/r-html
 * @version 0.1.3 | Sat Sep 28 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function BP(e, t) {
  return Object.freeze({ key: t ?? Symbol(), value: e });
}
const vh = {
  subscribe: "@@r-html/context-subscribe",
  unsubscribe: "@@r-html/context-unsubscribe"
};
function bh(e, t) {
  function n(o, r) {
    return new CustomEvent(e, {
      detail: o,
      ...t,
      ...r
    });
  }
  return n.toString = () => `${e}`, n.type = e, n;
}
const Io = bh(vh.subscribe, { bubbles: !0, composed: !0 }), To = bh(vh.unsubscribe, { bubbles: !0, composed: !0 });
function oI(e, t) {
  const n = (r) => {
    const i = r;
    t.dispatchEvent(Io(i.detail));
  }, o = (r) => {
    const i = r;
    t.dispatchEvent(To(i.detail));
  };
  return e.addEventListener(Io.type, n), e.addEventListener(To.type, o), () => {
    e.removeEventListener(Io.type, n), e.removeEventListener(To.type, o);
  };
}
function va(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const rI = (e) => {
  Promise.resolve().then(e);
}, Ih = queueMicrotask ?? rI, un = (e) => (t) => typeof t === e, Th = un("object"), iI = un("bigint"), wh = un("boolean"), on = un("function"), sI = un("number"), Ch = un("string"), aI = un("symbol"), ft = un("undefined"), jt = (e) => e === null, { isArray: he } = Array, Ce = (e) => Th(e) && !jt(e) && !he(e), fi = (e) => iI(e) || wh(e) || sI(e) || Ch(e) || aI(e) || ft(e) || jt(e);
function Qr() {
  const e = /* @__PURE__ */ new Set(), t = (n) => (e.has(n) || e.add(n), () => {
    e.delete(n);
  });
  return {
    subscribe: t,
    next: (n) => {
      e.forEach((o) => o(n));
    },
    asReadonly: () => ({ subscribe: t })
  };
}
const lI = 1, ba = [], El = /* @__PURE__ */ new Map(), Ui = /* @__PURE__ */ new Map();
let Sl = !0, Tc = 0;
function cI(e, t, n) {
  const o = Jr.get(n);
  if (!o)
    return !1;
  const r = o.get(e);
  return r ? r.has(t) : !1;
}
const Oh = (e) => (t) => {
  const n = El.get(t);
  let o = () => {
  };
  const r = n != null && n.promise ? n.promise : new Promise((i) => {
    o = i;
  });
  if (!n) {
    const i = {
      type: e,
      tickCount: Tc,
      promise: r,
      fn: t,
      resolve: () => {
        o();
      }
    };
    ba.push(i), El.set(t, i);
  }
  return Sl && (Ih(mI), Sl = !1), r;
}, uI = Oh("observer"), fI = Oh("nextTick"), Ku = (e, t) => {
  var n;
  return (n = Zs.get(e)) == null ? void 0 : n.forEach((o) => cI(e, t, o) && uI(o));
};
function Eh() {
  const e = ba.shift();
  if (e) {
    if (El.delete(e.fn), e.type === "observer")
      Sh(e.fn), Ec(e.fn), e.resolve();
    else if (e.type === "nextTick") {
      const t = va(e.fn);
      t instanceof Promise ? t.finally(e.resolve) : e.resolve();
    }
    dI() && Eh();
  }
}
function dI() {
  const e = ba[0];
  return e ? lI <= Tc - e.tickCount : !1;
}
function hI() {
  for (; ba.length; )
    Eh();
  Sl = !0, Tc = 0;
}
function mI() {
  hI();
}
function Qu(e, t) {
  const n = ts.get(e);
  if (!n)
    return;
  const o = Al.get(n);
  if (!o)
    return;
  const r = Ui.get(n);
  r ? r.has(t) || r.add(t) : (Ui.set(n, /* @__PURE__ */ new Set([t])), fI(() => {
    const i = Ui.get(n);
    i && (Ui.delete(n), i.forEach((s) => o.next(s)));
  }));
}
function m(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function b(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
const pI = "on", yI = "@", gI = ".", vI = "?", bI = "...", II = "@@r-html", TI = Math.random().toString().substring(2, 8), sn = `${II}-${TI}`, wI = `${bI}${sn}`, xl = new RegExp(`${sn}_(\\d+)_`, "g"), CI = new RegExp(`^${sn}_\\d+_$`), OI = /^\n/;
var J;
(function(e) {
  e.attribute = "attribute", e.boolean = "boolean", e.event = "event", e.property = "property", e.spread = "spread", e.directive = "directive";
})(J || (J = {}));
const Ia = Symbol.for("https://github.com/dineug/r-html#beforeMount"), Ta = Symbol.for("https://github.com/dineug/r-html#mounted"), wa = Symbol.for("https://github.com/dineug/r-html#unmounted"), wc = Symbol.for("https://github.com/dineug/r-html#beforeFirstUpdate"), Ca = Symbol.for("https://github.com/dineug/r-html#beforeUpdate"), Cc = Symbol.for("https://github.com/dineug/r-html#firstUpdated"), Oa = Symbol.for("https://github.com/dineug/r-html#updated"), EI = [
  Ia,
  Ta,
  wa,
  wc,
  Ca,
  Cc,
  Oa
], Oc = Symbol.for("https://github.com/dineug/r-html#Directive"), Mn = Symbol.for("https://github.com/dineug/r-html#TemplateLiterals");
let es = null;
function Vs(e) {
  es = e;
}
const di = (e) => (t) => {
  es && (es[e] ?? (es[e] = [])).push(t);
}, SI = di(Ia), jP = di(Ta), xI = di(wa), UP = di(Ca), WP = di(Oa);
function Fe(e, t) {
  const n = Reflect.get(e, t, e);
  he(n) && n.forEach(va);
}
function AI(e) {
  EI.forEach((t) => Reflect.set(e, t, null, e));
}
const $I = Qr(), NI = (e) => e, MI = (e) => {
  var t, n, o;
  return o = class extends e {
    constructor(r, i, s) {
      super(r, i, s), t.set(this, []), n.set(this, null), this.hmr();
    }
    commit(r) {
      const i = NI(r);
      super.commit(i), b(this, t, r, "f");
    }
    hmr() {
      b(this, n, $I.subscribe((r) => m(this, t, "f").includes(r) && this.commit(m(this, t, "f"))), "f");
    }
    destroy() {
      var r, i;
      (r = m(this, n, "f")) == null || r.call(this), (i = super.destroy) == null || i.call(this);
    }
  }, t = /* @__PURE__ */ new WeakMap(), n = /* @__PURE__ */ new WeakMap(), o;
}, ts = /* @__PURE__ */ new WeakMap(), Zs = /* @__PURE__ */ new WeakMap(), Ju = /* @__PURE__ */ new WeakMap(), Al = /* @__PURE__ */ new WeakMap(), Jr = /* @__PURE__ */ new WeakMap(), kI = { shallow: !1 };
let Mt = null;
function Ec(e) {
  return Mt = e, va(e), Mt = null, () => Sh(e);
}
function Sh(e) {
  const t = Jr.get(e);
  if (t)
    for (const [n] of t.entries()) {
      const o = Zs.get(n);
      o == null || o.delete(e);
    }
  t && Jr.delete(e);
}
function DI(e) {
  if (!Mt)
    return;
  const t = Zs.get(e);
  t ? t.has(Mt) || t.add(Mt) : Zs.set(e, /* @__PURE__ */ new Set([Mt]));
}
function LI(e, t) {
  if (!Mt)
    return;
  const n = Jr.get(Mt);
  if (n) {
    const o = n.get(e);
    o ? o.has(t) || o.add(t) : n.set(e, /* @__PURE__ */ new Set([t]));
  } else
    Jr.set(Mt, /* @__PURE__ */ new Map([[e, /* @__PURE__ */ new Set([t])]]));
}
const RI = (e) => e instanceof Node || e instanceof Map || e instanceof Set || e instanceof WeakMap || e instanceof WeakSet || e instanceof RegExp || e instanceof Date || e instanceof Promise || (he(e) || Ce(e)) && Object.isFrozen(e);
function hi(e, t = {}) {
  const { shallow: n } = Object.assign({}, kI, t), o = new Proxy(e, {
    get(r, i, s) {
      const a = Reflect.get(r, i, s);
      return RI(a) ? a : (DI(e), LI(e, i), !n && (Ce(a) || he(a)) && !Ju.has(a) ? ts.has(a) ? ts.get(a) : hi(a, t) : a);
    },
    set(r, i, s, a) {
      const l = Reflect.get(r, i, a), c = Reflect.set(r, i, s, a);
      return (!he(r) && l !== s || i === "length") && (Ku(r, i), Qu(r, i)), c;
    },
    deleteProperty(r, i) {
      const s = Reflect.deleteProperty(r, i);
      return Ku(r, i), Qu(r, i), s;
    }
  });
  return ts.set(e, o), Ju.set(o, e), o;
}
function FP(e) {
  return (Al.get(e) ?? Al.set(e, Qr()).get(e)).asReadonly();
}
function qP(e, t) {
  const n = hi({ value: t.value }, { shallow: !0 }), o = (s) => {
    n.value = s;
  }, r = () => e instanceof HTMLElement ? e : e.parentElement ?? e.host, i = () => {
    r().dispatchEvent(Io({
      context: t,
      observer: o
    }));
  };
  return i(), SI(i), xI(() => {
    r().dispatchEvent(To({
      context: t,
      observer: o
    }));
  }), n;
}
function HP(e, t, n) {
  const o = e instanceof HTMLElement ? e : e.parentElement ?? e.host, r = Qr(), i = /* @__PURE__ */ new Map(), s = (l) => {
    var c, u;
    const f = l;
    ((u = (c = f.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key && (f.stopPropagation(), f.detail.observer(n), i.set(f.detail.observer, r.subscribe(f.detail.observer)));
  }, a = (l) => {
    var c, u;
    const f = l;
    if (((u = (c = f.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key) {
      f.stopPropagation();
      const d = i.get(f.detail.observer);
      d == null || d(), i.delete(f.detail.observer);
    }
  };
  return o.addEventListener(Io.type, s), o.addEventListener(To.type, a), {
    set: (l) => {
      r.next(l);
    },
    destroy: () => {
      o.removeEventListener(Io.type, s), o.removeEventListener(To.type, a);
      for (const l of i.values())
        l();
      i.clear();
    }
  };
}
var we;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(we || (we = {}));
class xn {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: we.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
const _I = (e, t = !1) => t ? document.createElementNS("http://www.w3.org/2000/svg", e) : document.createElement(e), PI = ({ type: e, value: t }, n = !1) => e === we.element ? _I(t, n) : e === we.text ? document.createTextNode(t) : document.createComment(t), Sc = (e) => !!e && e !== "false";
function BI(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
      e.setAttribute(n, o ?? "");
      break;
    case J.boolean:
      Sc(o) && e.setAttribute(n, "");
      break;
    case J.property:
      Reflect.set(e, n, o, e);
      break;
  }
}
function jI(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
    case J.property:
      Reflect.set(e, n, o);
      break;
    case J.boolean:
      Reflect.set(e, n, Sc(o));
      break;
  }
}
const Bn = (e, t) => e.length === t.length && e.every((n, o) => n === t[o]);
function $l(e, t) {
  if (e === t)
    return !0;
  const n = Ce(e) ? e : {}, o = Ce(t) ? t : {}, r = Object.keys(n), i = Object.keys(o);
  return r.length === i.length && i.every((s) => Reflect.get(n, s) === Reflect.get(o, s));
}
const UI = (e) => he(e) && on(e[0]) && (ft(e[1]) || wh(e[1]) || Ce(e[1]));
function Qe(e, t) {
  const n = t.parentNode;
  n && n.insertBefore(e, t);
}
function Je(e, t) {
  const n = t.parentNode;
  n && (t.nextSibling ? n.insertBefore(e, t.nextSibling) : n.appendChild(e));
}
const Ne = (e) => e.parentNode && e.parentNode.removeChild(e), WI = (e) => e instanceof Node;
function Me(e, t) {
  const n = [];
  let o = e.nextSibling;
  for (; o && o !== t; )
    n.push(o), o = o.nextSibling;
  return n;
}
const Nl = () => {
}, Xu = (e) => e instanceof HTMLElement, ef = (e) => e instanceof SVGElement, FI = (e) => e instanceof Promise, qI = (e) => {
  var t;
  return ((t = e.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)) == null ? void 0 : t.join("-").toLowerCase()) ?? "";
}, lr = (e) => e.replace(/^([A-Z])|[\s-_](\w)/g, (t, n, o) => o ? o.toUpperCase() : n.toLowerCase());
var Ot;
(function(e) {
  e.html = "html", e.svg = "svg", e.css = "css";
})(Ot || (Ot = {}));
const HI = new Set(Object.values(Ot)), Xa = /* @__PURE__ */ new WeakMap(), el = /* @__PURE__ */ new WeakMap(), VI = /* @__PURE__ */ new Set([Ot.svg]), xh = (e) => `${sn}_${e}_`, ZI = (e) => he(e) && he(e.raw), Ea = (e) => Ce(e) && ZI(e.strings) && he(e.values) && HI.has(Reflect.get(e, Mn) ?? ""), Sa = (e) => Ea(e) && e[Mn] === Ot.css, Ho = (e, t = !0, n = !1) => (o) => t ? !!(o != null && o.trimStart().startsWith(e)) : n ? !!(o != null && o.trimEnd().endsWith(e)) : new RegExp(e).test(o ?? ""), Ah = Ho(wI), $h = Ho(gI), Nh = Ho(vI), Mh = Ho(yI), kh = Ho(pI), ko = Ho(sn, !1), mi = (e) => ko(e) && CI.test((e == null ? void 0 : e.trim()) ?? ""), tf = ({ type: e, value: t }) => e === J.spread || e === J.directive || ko(t), zI = (e) => VI.has(e), nf = (e) => mi(e) ? J.directive : Ah(e) ? J.spread : $h(e) ? J.property : Mh(e) || kh(e) ? J.event : Nh(e) ? J.boolean : J.attribute, Wi = (e) => Ah(e) ? e.substring(3) : mi(e) ? e : $h(e) || Mh(e) || Nh(e) ? e.substring(1) : kh(e) ? e.substring(2) : e;
function be(e) {
  const t = [];
  let n = xl.exec(e);
  for (; n; ) {
    const o = Number(n[1]);
    t.push([n[0], Number.isInteger(o) ? o : -1]), n = xl.exec(e);
  }
  return t;
}
const of = "0123456789abcdefghijklmnopqrstuvwxyz-_";
function GI(e, t) {
  return e = Math.ceil(e), t = Math.floor(t), Math.floor(Math.random() * (t - e)) + e;
}
function YI(e = 21) {
  let t = "_";
  for (let n = 0; n < e; n++)
    t += of.charAt(GI(0, of.length));
  return t;
}
var Se, ro, ns, br, Kt, Ir, Tr;
class KI {
  constructor(t, { name: n, value: o }) {
    Se.set(this, void 0), ro.set(this, void 0), ns.set(this, void 0), br.set(this, []), Kt.set(this, []), Ir.set(this, null), Tr.set(this, null), b(this, Se, t, "f"), b(this, ro, n, "f"), b(this, ns, o, "f"), b(this, br, be(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, br, "f").map(([r, i]) => t[i]);
    if (Bn(m(this, Kt, "f"), n))
      return;
    const o = n[n.length - 1];
    if (m(this, ro, "f") === "class")
      this.classCommit(o);
    else if (m(this, ro, "f") === "style")
      this.styleCommit(o);
    else {
      const r = n.reduce((i, s, a) => i.replace(new RegExp(m(this, br, "f")[a][0]), os(s)), m(this, ns, "f") ?? "");
      m(this, Se, "f").setAttribute(m(this, ro, "f"), r.trim());
    }
    b(this, Kt, n, "f");
  }
  classCommit(t) {
    if (!Xu(m(this, Se, "f")) && !ef(m(this, Se, "f")) || !Ce(t) && !he(t))
      return;
    const n = m(this, Kt, "f")[m(this, Kt, "f").length - 1];
    if (n === t || he(n) && he(t) && Bn(n, t) || Ce(n) && Ce(t) && $l(n, t))
      return;
    const o = [...m(this, Se, "f").classList], r = Dh(t);
    if (jt(m(this, Tr, "f")))
      b(this, Tr, o, "f");
    else {
      const i = m(this, Tr, "f"), s = o.filter((a) => !i.includes(a) && !r.includes(a));
      m(this, Se, "f").classList.remove(...s);
    }
    m(this, Se, "f").classList.add(...r);
  }
  styleCommit(t) {
    if (!Xu(m(this, Se, "f")) && !ef(m(this, Se, "f")))
      return;
    const n = m(this, Kt, "f")[m(this, Kt, "f").length - 1];
    if ($l(n, t))
      return;
    const o = QI(m(this, Se, "f")), r = Ce(t) ? t : {};
    if (jt(m(this, Ir, "f")))
      b(this, Ir, o, "f");
    else {
      const i = m(this, Ir, "f");
      Object.keys(o).filter((s) => !i[s] && !r[s]).forEach((s) => m(this, Se, "f").style.removeProperty(s));
    }
    for (const i of Object.keys(r))
      m(this, Se, "f").style.setProperty(i, r[i]);
  }
}
Se = /* @__PURE__ */ new WeakMap(), ro = /* @__PURE__ */ new WeakMap(), ns = /* @__PURE__ */ new WeakMap(), br = /* @__PURE__ */ new WeakMap(), Kt = /* @__PURE__ */ new WeakMap(), Ir = /* @__PURE__ */ new WeakMap(), Tr = /* @__PURE__ */ new WeakMap();
function os(e) {
  return fi(e) && !jt(e) && !ft(e) || Sa(e) ? String(e) : "";
}
function QI(e) {
  const t = {};
  for (let n = 0; n < e.style.length; n++) {
    const o = e.style.item(n);
    t[o] = e.style.getPropertyValue(o);
  }
  return t;
}
function Dh(e, t = []) {
  if (Sa(e))
    return t.push(os(e)), t;
  if (fi(e))
    t.push(os(e));
  else if (Th(e))
    if (he(e))
      for (let n = 0; n < e.length; n++)
        e[n] && Dh(e[n], t);
    else
      for (let n in e)
        e[n] && t.push(os(n));
  return t;
}
var wr, Cr, rs, is;
class JI {
  constructor(t, { name: n, value: o }) {
    wr.set(this, void 0), Cr.set(this, void 0), rs.set(this, []), is.set(this, []), b(this, wr, t, "f"), b(this, Cr, n, "f"), b(this, rs, be(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, rs, "f").map(([r, i]) => t[i]);
    if (Bn(m(this, is, "f"), n))
      return;
    const o = n[n.length - 1];
    Sc(o) ? m(this, wr, "f").setAttribute(m(this, Cr, "f"), "") : m(this, wr, "f").removeAttribute(m(this, Cr, "f")), b(this, is, n, "f");
  }
}
wr = /* @__PURE__ */ new WeakMap(), Cr = /* @__PURE__ */ new WeakMap(), rs = /* @__PURE__ */ new WeakMap(), is = /* @__PURE__ */ new WeakMap();
var Do;
(function(e) {
  e.node = "node", e.attribute = "attribute";
})(Do || (Do = {}));
function Lh(e, t) {
  return Reflect.set(t, Oc, e), t;
}
var ss, as, ls, Or, Qt;
const XI = (e) => he(e) && Reflect.get(e, Oc) === Do.attribute;
let Rh = class {
  constructor(e, { name: t }) {
    ss.set(this, void 0), as.set(this, void 0), ls.set(this, null), Or.set(this, null), Qt.set(this, null), b(this, ss, e, "f"), b(this, as, be(t)[0], "f");
  }
  commit(e) {
    var t, n, o, r;
    const [, i] = m(this, as, "f"), s = e[i];
    if (!XI(s))
      return;
    const [a, l] = s;
    if (m(this, ls, "f") !== l)
      (t = m(this, Qt, "f")) == null || t.call(this), b(this, Or, l({ node: m(this, ss, "f") }), "f"), b(this, ls, l, "f"), b(this, Qt, (n = m(this, Or, "f")) == null ? void 0 : n.call(this, a), "f");
    else {
      const c = (o = m(this, Or, "f")) == null ? void 0 : o.call(this, a);
      m(this, Qt, "f") !== c && ((r = m(this, Qt, "f")) == null || r.call(this), b(this, Qt, c, "f"));
    }
  }
  destroy() {
    var e;
    (e = m(this, Qt, "f")) == null || e.call(this);
  }
};
ss = /* @__PURE__ */ new WeakMap(), as = /* @__PURE__ */ new WeakMap(), ls = /* @__PURE__ */ new WeakMap(), Or = /* @__PURE__ */ new WeakMap(), Qt = /* @__PURE__ */ new WeakMap();
var bn, In, cs, Er;
class _h {
  constructor(t, { name: n, value: o }) {
    bn.set(this, void 0), In.set(this, void 0), cs.set(this, []), Er.set(this, []), b(this, bn, t, "f"), b(this, In, n, "f"), b(this, cs, be(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, cs, "f").map(([o, r]) => t[r]).filter((o) => on(o) || UI(o));
    Bn(m(this, Er, "f"), n) || (this.clear(), n.forEach((o) => on(o) ? m(this, bn, "f").addEventListener(m(this, In, "f"), o) : m(this, bn, "f").addEventListener(m(this, In, "f"), o[0], o[1])), b(this, Er, n, "f"));
  }
  clear() {
    m(this, Er, "f").forEach((t) => on(t) ? m(this, bn, "f").removeEventListener(m(this, In, "f"), t) : m(this, bn, "f").removeEventListener(m(this, In, "f"), t[0], t[1]));
  }
  destroy() {
    this.clear();
  }
}
bn = /* @__PURE__ */ new WeakMap(), In = /* @__PURE__ */ new WeakMap(), cs = /* @__PURE__ */ new WeakMap(), Er = /* @__PURE__ */ new WeakMap();
var Sr, us, fs, ds;
class eT {
  constructor(t, { name: n, value: o }) {
    Sr.set(this, void 0), us.set(this, void 0), fs.set(this, []), ds.set(this, []), b(this, Sr, t, "f"), b(this, us, n, "f"), b(this, fs, be(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, fs, "f").map(([r, i]) => t[i]);
    if (Bn(m(this, ds, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(m(this, Sr, "f"), m(this, us, "f"), o, m(this, Sr, "f")), b(this, ds, n, "f");
  }
}
Sr = /* @__PURE__ */ new WeakMap(), us = /* @__PURE__ */ new WeakMap(), fs = /* @__PURE__ */ new WeakMap(), ds = /* @__PURE__ */ new WeakMap();
var xr, hs, ms;
class Ph {
  constructor(t, { name: n }) {
    xr.set(this, void 0), hs.set(this, void 0), ms.set(this, null), b(this, xr, t, "f"), b(this, hs, be(n)[0], "f");
  }
  commit(t) {
    const [, n] = m(this, hs, "f"), o = t[n];
    !Ce(o) || $l(m(this, ms, "f"), o) || (Object.keys(o).forEach((r) => Reflect.set(m(this, xr, "f"), r, o[r], m(this, xr, "f"))), b(this, ms, o, "f"));
  }
}
xr = /* @__PURE__ */ new WeakMap(), hs = /* @__PURE__ */ new WeakMap(), ms = /* @__PURE__ */ new WeakMap();
const tT = (e, t) => t.type === J.attribute ? new KI(e, t) : t.type === J.boolean ? new JI(e, t) : t.type === J.event ? new _h(e, t) : t.type === J.property ? new eT(e, t) : t.type === J.spread ? new Ph(e, t) : new Rh(e, t);
var ps, ys, Ar, $r;
class nT {
  constructor(t, { value: n }) {
    ps.set(this, void 0), ys.set(this, void 0), Ar.set(this, []), $r.set(this, []), b(this, ps, t, "f"), b(this, ys, n, "f"), Ch(n) && b(this, Ar, be(n), "f");
  }
  commit(t) {
    const n = m(this, Ar, "f").map(([r, i]) => t[i]);
    if (Bn(m(this, $r, "f"), n))
      return;
    b(this, $r, n, "f");
    const o = m(this, $r, "f").reduce((r, i, s) => r.replace(new RegExp(m(this, Ar, "f")[s][0]), fi(i) && !jt(i) && !ft(i) ? String(i) : ""), m(this, ys, "f") ?? "");
    m(this, ps, "f").data = o;
  }
}
ps = /* @__PURE__ */ new WeakMap(), ys = /* @__PURE__ */ new WeakMap(), Ar = /* @__PURE__ */ new WeakMap(), $r = /* @__PURE__ */ new WeakMap();
const Ml = Symbol("https://github.com/dineug/r-html.git#hostBridge");
function oT(e, t) {
  if (t instanceof ShadowRoot) {
    const n = t.host;
    Reflect.set(e, Ml, n);
  }
  return () => {
    Reflect.deleteProperty(e, Ml);
  };
}
function rT(e) {
  return Reflect.get(e, Ml) ?? null;
}
var gs, vs, bs, Is;
class iT {
  constructor(t, { name: n, value: o }) {
    gs.set(this, void 0), vs.set(this, void 0), bs.set(this, []), Is.set(this, []), b(this, gs, t, "f"), b(this, vs, n, "f"), b(this, bs, be(o ?? ""), "f");
  }
  commit(t) {
    const n = m(this, bs, "f").map(([r, i]) => t[i]);
    if (Bn(m(this, Is, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(m(this, gs, "f"), m(this, vs, "f"), o), b(this, Is, n, "f");
  }
}
gs = /* @__PURE__ */ new WeakMap(), vs = /* @__PURE__ */ new WeakMap(), bs = /* @__PURE__ */ new WeakMap(), Is = /* @__PURE__ */ new WeakMap();
var kt;
(function(e) {
  e.create = "create", e.move = "move";
})(kt || (kt = {}));
function sT(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, value: r }, i) => {
    const s = {
      type: o,
      key: o === te.templateLiterals ? r.strings : r
    };
    t.push(s), n.set(s, i);
  }), {
    items: t,
    itemToIndex: n
  };
}
function aT(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach((o, r) => {
    const i = fn(o), s = {
      type: i,
      key: i === te.templateLiterals ? o.strings : o
    };
    t.push(s), n.set(s, r);
  }), {
    items: t,
    itemToIndex: n
  };
}
function Bh(e, t, n) {
  const o = !!(n != null && n.strict), r = {
    update: [],
    delete: []
  }, i = /* @__PURE__ */ new Set(), s = [], a = e.items, l = t.items;
  return a.forEach((c, u) => {
    const f = l.findIndex((d, h) => c.type === d.type && c.key === d.key && !i.has(h));
    f === -1 ? o ? r.delete.push({ from: u }) : s.push(c) : (i.add(f), r.update.push({ action: kt.move, from: u, to: f }));
  }), s.forEach((c) => {
    const u = e.itemToIndex.get(c), f = l.find((d, h) => c.type === d.type && !i.has(h));
    if (f) {
      const d = t.itemToIndex.get(f);
      i.add(d), r.update.push({ action: kt.move, from: u, to: d });
    } else
      r.delete.push({ from: u });
  }), l.forEach((c, u) => {
    i.has(u) || r.update.push({
      action: kt.create,
      from: -1,
      to: t.itemToIndex.get(c)
    });
  }), r.update.sort((c, u) => c.to - u.to), r;
}
var Nr, Ts, De, Mr;
class jh {
  constructor(t, n) {
    Nr.set(this, void 0), Ts.set(this, void 0), De.set(this, []), b(this, Nr, t, "f"), b(this, Ts, n, "f");
  }
  commit(t) {
    const n = Bh(sT(m(this, De, "f")), aT(t)), o = { length: t.length };
    n.update.forEach(({ action: r, from: i, to: s }) => {
      switch (r) {
        case kt.create:
          const a = document.createComment("");
          s === 0 ? Je(a, m(this, Nr, "f")) : m(this, De, "f").length ? Je(a, o[s - 1] ? o[s - 1].endNode : m(this, De, "f")[s - 1].endNode) : Qe(a, m(this, Ts, "f")), o[s] = new lT(a, t[s]);
          break;
        case kt.move:
          if (o[s] = m(this, De, "f")[i], s === i)
            return;
          s === 0 ? m(this, De, "f")[i].insert("after", m(this, Nr, "f")) : m(this, De, "f")[i].insert("after", o[s - 1] ? o[s - 1].endNode : m(this, De, "f")[s - 1].endNode);
          break;
      }
    }), n.delete.forEach(({ from: r }) => m(this, De, "f")[r].destroy()), b(this, De, Array.from(o), "f"), m(this, De, "f").forEach((r, i) => r.commit(t[i]));
  }
  destroy() {
    m(this, De, "f").forEach((t) => t.destroy());
  }
}
Nr = /* @__PURE__ */ new WeakMap(), Ts = /* @__PURE__ */ new WeakMap(), De = /* @__PURE__ */ new WeakMap();
let lT = class {
  constructor(e, t) {
    Mr.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Qe(this.startNode, e), Je(this.endNode, e), Ne(e), this.value = t, this.type = fn(t), b(this, Mr, Vo(this.type, this.startNode, this.endNode), "f");
  }
  commit(e) {
    m(this, Mr, "f").commit(e), this.value = e;
  }
  insert(e, t) {
    const n = [
      this.startNode,
      ...Me(this.startNode, this.endNode),
      this.endNode
    ];
    e === "before" ? n.forEach((o) => Qe(o, t)) : n.reverse().forEach((o) => Je(o, t));
  }
  destroy() {
    var e, t;
    (t = (e = m(this, Mr, "f")).destroy) == null || t.call(e), Me(this.startNode, this.endNode).forEach(Ne), this.startNode.remove(), this.endNode.remove();
  }
};
Mr = /* @__PURE__ */ new WeakMap();
var kr, Dr, ws, Lr, io;
class Uh {
  constructor(t, n) {
    kr.set(this, void 0), Dr.set(this, void 0), ws.set(this, null), Lr.set(this, null), io.set(this, void 0), b(this, kr, t, "f"), b(this, Dr, n, "f");
  }
  commit(t) {
    var n, o;
    if (!Zh(t))
      return;
    const [r, i] = t;
    if (m(this, ws, "f") !== i)
      this.clear(), b(this, Lr, i({
        startNode: m(this, kr, "f"),
        endNode: m(this, Dr, "f")
      }), "f"), b(this, ws, i, "f"), b(this, io, (n = m(this, Lr, "f")) == null ? void 0 : n.call(this, r), "f");
    else {
      const s = (o = m(this, Lr, "f")) == null ? void 0 : o.call(this, r);
      m(this, io, "f") !== s && (this.clear(), b(this, io, s, "f"));
    }
  }
  clear() {
    var t;
    (t = m(this, io, "f")) == null || t.call(this), Me(m(this, kr, "f"), m(this, Dr, "f")).forEach(Ne);
  }
  destroy() {
    this.clear();
  }
}
kr = /* @__PURE__ */ new WeakMap(), Dr = /* @__PURE__ */ new WeakMap(), ws = /* @__PURE__ */ new WeakMap(), Lr = /* @__PURE__ */ new WeakMap(), io = /* @__PURE__ */ new WeakMap();
class Wh {
  constructor(t, n) {
  }
  commit(t) {
  }
}
var Cs, so;
class Fh {
  constructor(t, n) {
    Cs.set(this, void 0), so.set(this, null), b(this, Cs, n, "f");
  }
  commit(t) {
    m(this, so, "f") !== t && (m(this, so, "f") && Ne(m(this, so, "f")), Qe(t, m(this, Cs, "f")), b(this, so, t, "f"));
  }
}
Cs = /* @__PURE__ */ new WeakMap(), so = /* @__PURE__ */ new WeakMap();
var ao, lo, Os, co, Rr;
class qh {
  constructor(t, n) {
    ao.set(this, void 0), lo.set(this, void 0), Os.set(this, null), co.set(this, null), Rr.set(this, Nl), b(this, ao, t, "f"), b(this, lo, n, "f");
  }
  commit(t) {
    m(this, Os, "f") !== t && (this.clear(), FI(t) && this.promiseCommit(t));
  }
  promiseCommit(t) {
    const [n, o] = cT(t);
    b(this, Rr, o, "f"), n.then((r) => {
      var i;
      const s = fn(r);
      b(this, co, Vo(s, m(this, ao, "f"), m(this, lo, "f")), "f"), (i = m(this, co, "f")) == null || i.commit(r);
    }), b(this, Os, t, "f");
  }
  partClear() {
    var t, n;
    (n = (t = m(this, co, "f")) == null ? void 0 : t.destroy) == null || n.call(t), Me(m(this, ao, "f"), m(this, lo, "f")).forEach(Ne);
  }
  clear() {
    var t, n;
    m(this, Rr, "f").call(this), (n = (t = m(this, co, "f")) == null ? void 0 : t.destroy) == null || n.call(t), Me(m(this, ao, "f"), m(this, lo, "f")).forEach(Ne), b(this, Rr, Nl, "f");
  }
  destroy() {
    this.clear();
  }
}
ao = /* @__PURE__ */ new WeakMap(), lo = /* @__PURE__ */ new WeakMap(), Os = /* @__PURE__ */ new WeakMap(), co = /* @__PURE__ */ new WeakMap(), Rr = /* @__PURE__ */ new WeakMap();
function cT(e) {
  let t = Nl;
  const n = new Promise((r, i) => t = i), o = () => t();
  return [Promise.race([n, e]), o];
}
var Es, Ss;
class Hh {
  constructor(t, n) {
    Es.set(this, document.createTextNode("")), Ss.set(this, null), Je(m(this, Es, "f"), t);
  }
  commit(t) {
    m(this, Ss, "f") !== t && (m(this, Es, "f").data = jt(t) || ft(t) ? "" : String(t), b(this, Ss, t, "f"));
  }
}
Es = /* @__PURE__ */ new WeakMap(), Ss = /* @__PURE__ */ new WeakMap();
var xs, _r, at;
class Vh {
  constructor(t, n) {
    xs.set(this, void 0), _r.set(this, void 0), at.set(this, null), b(this, xs, t, "f"), b(this, _r, n, "f");
  }
  commit(t) {
    const { strings: n, values: o } = t;
    m(this, at, "f") && !m(this, at, "f").equalStrings(n) && (m(this, at, "f").destroy(), b(this, at, null, "f")), m(this, at, "f") || (b(this, at, new Yh(t, m(this, xs, "f"), m(this, _r, "f")), "f"), m(this, at, "f").insert("before", m(this, _r, "f"))), m(this, at, "f").commit(o);
  }
  destroy() {
    var t;
    (t = m(this, at, "f")) == null || t.destroy();
  }
}
xs = /* @__PURE__ */ new WeakMap(), _r = /* @__PURE__ */ new WeakMap(), at = /* @__PURE__ */ new WeakMap();
var te;
(function(e) {
  e.primitive = "primitive", e.templateLiterals = "templateLiterals", e.array = "array", e.node = "node", e.object = "object", e.function = "function", e.directive = "directive";
})(te || (te = {}));
const Zh = (e) => he(e) && Reflect.get(e, Oc) === Do.node, Hn = (e) => (t) => t instanceof e, uT = Hn(Hh), fT = Hn(Vh), dT = Hn(jh), hT = Hn(Fh), mT = Hn(qh), pT = Hn(Wh), yT = Hn(Uh), fn = (e) => fi(e) ? te.primitive : Ea(e) ? te.templateLiterals : Zh(e) ? te.directive : he(e) ? te.array : WI(e) ? te.node : on(e) ? te.function : te.object, gT = {
  [te.primitive]: uT,
  [te.templateLiterals]: fT,
  [te.array]: dT,
  [te.node]: hT,
  [te.function]: pT,
  [te.object]: mT,
  [te.directive]: yT
}, vT = {
  [te.primitive]: Hh,
  [te.templateLiterals]: Vh,
  [te.array]: jh,
  [te.node]: Fh,
  [te.function]: Wh,
  [te.object]: qh,
  [te.directive]: Uh
}, zh = (e, t) => gT[e](t), Vo = (e, t, n) => new vT[e](t, n);
var Jt, uo, As, kl, Pr, Tn, fo, ho, $s, Br, Ns;
class bT {
  constructor(t, n, o, r) {
    var i;
    Jt.set(this, document.createComment("")), uo.set(this, document.createComment("")), As.set(this, void 0), kl.set(this, void 0), Pr.set(this, []), Tn.set(this, []), fo.set(this, null), ho.set(this, hi({}, { shallow: !0 })), $s.set(this, null), Br.set(this, null), Ns.set(this, document.createElement("div")), b(this, Jt, t, "f"), b(this, uo, n, "f"), b(this, kl, o, "f"), b(this, As, be(o.value)[0], "f"), o.staticAttrs && o.staticAttrs.forEach((s) => jI(m(this, ho, "f"), s)), (i = o.attrs) == null || i.forEach((s) => {
      s.type === J.directive ? m(this, Pr, "f").push(s) : s.type === J.spread ? r.push(new Ph(m(this, ho, "f"), s)) : s.type === J.event ? r.push(new _h(m(this, Ns, "f"), s)) : r.push(new iT(m(this, ho, "f"), s));
    });
  }
  createContext() {
    const t = m(this, Jt, "f"), n = {
      host: document.body,
      get parentElement() {
        return t.parentElement;
      },
      dispatchEvent: (r) => m(this, Ns, "f").dispatchEvent(r)
    }, o = m(this, Jt, "f").getRootNode();
    if (o instanceof ShadowRoot) {
      const r = o.host;
      n.host = r;
    } else if (o instanceof DocumentFragment) {
      const r = rT(o);
      r && (n.host = r);
    }
    return n;
  }
  commit(t) {
    const [, n] = m(this, As, "f"), o = t[n];
    if (!on(o) || m(this, $s, "f") === o) {
      m(this, Tn, "f").forEach((a) => a.commit(t));
      return;
    }
    const r = this.createContext();
    this.clear(), Vs(this);
    const i = o.call(r, m(this, ho, "f"), r);
    Vs(null), m(this, Pr, "f").length && m(this, Tn, "f").push(...m(this, Pr, "f").map((a) => new Rh(r, a))), Fe(this, Ia);
    let s = !1;
    b(this, Br, Ec(() => {
      var a;
      const l = i(), c = fn(l);
      zh(c, m(this, fo, "f")) || (this.partClear(), b(this, fo, Vo(c, m(this, Jt, "f"), m(this, uo, "f")), "f")), Fe(this, s ? Ca : wc), (a = m(this, fo, "f")) == null || a.commit(l), s ? Fe(this, Oa) : (Fe(this, Cc), s = !0);
    }), "f"), m(this, Tn, "f").forEach((a) => a.commit(t)), Fe(this, Ta), b(this, $s, o, "f");
  }
  partClear() {
    var t, n;
    (n = (t = m(this, fo, "f")) == null ? void 0 : t.destroy) == null || n.call(t), Me(m(this, Jt, "f"), m(this, uo, "f")).forEach(Ne);
  }
  clear() {
    var t;
    Me(m(this, Jt, "f"), m(this, uo, "f")).forEach(Ne), Fe(this, wa), m(this, Tn, "f").forEach((n) => {
      var o;
      return (o = n.destroy) == null ? void 0 : o.call(n);
    }), (t = m(this, Br, "f")) == null || t.call(this), b(this, Tn, [], "f"), b(this, Br, null, "f"), AI(this);
  }
  destroy() {
    this.clear(), this.partClear();
  }
}
Jt = /* @__PURE__ */ new WeakMap(), uo = /* @__PURE__ */ new WeakMap(), As = /* @__PURE__ */ new WeakMap(), kl = /* @__PURE__ */ new WeakMap(), Pr = /* @__PURE__ */ new WeakMap(), Tn = /* @__PURE__ */ new WeakMap(), fo = /* @__PURE__ */ new WeakMap(), ho = /* @__PURE__ */ new WeakMap(), $s = /* @__PURE__ */ new WeakMap(), Br = /* @__PURE__ */ new WeakMap(), Ns = /* @__PURE__ */ new WeakMap();
var cr, ur, fr, rf;
const IT = (rf = class {
  constructor(e, t, n) {
    cr.set(this, document.createComment("")), ur.set(this, document.createComment("")), fr.set(this, void 0), b(this, fr, new bT(m(this, cr, "f"), m(this, ur, "f"), t, n), "f"), Qe(m(this, cr, "f"), e), Je(m(this, ur, "f"), e), e.remove();
  }
  commit(e) {
    m(this, fr, "f").commit(e);
  }
  destroy() {
    var e, t;
    (t = (e = m(this, fr, "f")).destroy) == null || t.call(e), m(this, cr, "f").remove(), m(this, ur, "f").remove();
  }
}, cr = /* @__PURE__ */ new WeakMap(), ur = /* @__PURE__ */ new WeakMap(), fr = /* @__PURE__ */ new WeakMap(), rf), TT = MI(IT);
var mo, po, Ms, ks, wn;
class wT {
  constructor(t, { value: n }) {
    mo.set(this, document.createComment("")), po.set(this, document.createComment("")), Ms.set(this, void 0), ks.set(this, null), wn.set(this, null), b(this, Ms, be(n)[0], "f"), Qe(m(this, mo, "f"), t), Je(m(this, po, "f"), t), t.remove();
  }
  commit(t) {
    var n;
    const [, o] = m(this, Ms, "f"), r = t[o];
    if (m(this, ks, "f") === r)
      return;
    const i = fn(r);
    zh(i, m(this, wn, "f")) || (jt(m(this, wn, "f")) || this.clear(), b(this, wn, Vo(i, m(this, mo, "f"), m(this, po, "f")), "f")), (n = m(this, wn, "f")) == null || n.commit(r), b(this, ks, r, "f");
  }
  clear() {
    var t, n;
    (n = (t = m(this, wn, "f")) == null ? void 0 : t.destroy) == null || n.call(t), Me(m(this, mo, "f"), m(this, po, "f")).forEach(Ne);
  }
  destroy() {
    this.clear(), m(this, mo, "f").remove(), m(this, po, "f").remove();
  }
}
mo = /* @__PURE__ */ new WeakMap(), po = /* @__PURE__ */ new WeakMap(), Ms = /* @__PURE__ */ new WeakMap(), ks = /* @__PURE__ */ new WeakMap(), wn = /* @__PURE__ */ new WeakMap();
function Gh(e = [], t, n = !1, o = []) {
  return e.forEach((r) => {
    if (r.isComponent) {
      const s = document.createComment("");
      t.appendChild(s), o.push(new TT(s, r, o));
      return;
    }
    const i = PI(r, r.isSvg || n);
    t.appendChild(i), i instanceof Comment && r.isMarker && o.push(new nT(i, r)), i instanceof Text && r.isMarkerOnly && o.push(new wT(i, r)), i instanceof Element && (r.staticAttrs && r.staticAttrs.forEach((s) => BI(i, s)), r.attrs && o.push(...r.attrs.map((s) => tT(i, s))), r.children && Gh(r.children, i, r.isSvg || n, o));
  }), o;
}
function CT(e, t = !1) {
  const n = document.createDocumentFragment(), o = Gh(e.children, n, t);
  return [n, o];
}
var yo, go, Xt, jr, Ds, Ls;
class Yh {
  constructor(t, n, o) {
    if (yo.set(this, document.createComment("")), go.set(this, document.createComment("")), Xt.set(this, null), jr.set(this, []), Ds.set(this, void 0), Ls.set(this, !1), b(this, Ds, t.strings, "f"), t[Mn] !== Ot.html && t[Mn] !== Ot.svg)
      return;
    const [r, i] = CT(t.template.node, zI(t[Mn]));
    b(this, Xt, r, "f"), b(this, jr, i, "f"), n && o ? (b(this, yo, n, "f"), b(this, go, o, "f"), b(this, Ls, !0, "f")) : (r.prepend(m(this, yo, "f")), r.append(m(this, go, "f")));
  }
  equalStrings(t) {
    return m(this, Ds, "f") === t;
  }
  commit(t) {
    m(this, jr, "f").forEach((n) => n.commit(t));
  }
  insert(t, n) {
    m(this, Xt, "f") && (t === "before" ? Qe(m(this, Xt, "f"), n) : t === "after" ? Je(m(this, Xt, "f"), n) : n.appendChild(m(this, Xt, "f")), b(this, Xt, null, "f"));
  }
  destroy() {
    m(this, jr, "f").forEach((t) => {
      var n;
      return (n = t.destroy) == null ? void 0 : n.call(t);
    }), Me(m(this, yo, "f"), m(this, go, "f")).forEach(Ne), m(this, Ls, "f") || (m(this, yo, "f").remove(), m(this, go, "f").remove());
  }
}
yo = /* @__PURE__ */ new WeakMap(), go = /* @__PURE__ */ new WeakMap(), Xt = /* @__PURE__ */ new WeakMap(), jr = /* @__PURE__ */ new WeakMap(), Ds = /* @__PURE__ */ new WeakMap(), Ls = /* @__PURE__ */ new WeakMap();
const dr = /* @__PURE__ */ new WeakMap();
function OT(e, t) {
  var n;
  if (!Ea(t)) {
    dr.has(e) && ((n = dr.get(e)) == null || n.destroy(), dr.delete(e));
    return;
  }
  const { strings: o, values: r } = t, i = dr.get(e);
  if (i != null && i.equalStrings(o))
    i == null || i.commit(r);
  else {
    const s = new Yh(t);
    i == null || i.destroy(), dr.set(e, s), s.insert("children", e), s.commit(r);
  }
}
function ET(e, t) {
  return (...n) => Lh(Do.attribute, [e(...n), t]);
}
const VP = (e) => ({ value: e }), ZP = ET((e) => e, ({ node: e }) => {
  let t = null;
  const n = () => {
    t && (t.value = null);
  };
  return (o) => (t === o || (o.value = e, t = o), n);
});
function xc(e, t) {
  return (...n) => Lh(Do.node, [
    e(...n),
    t
  ]);
}
const zP = xc((e) => e, ({ startNode: e, endNode: t }) => {
  const n = e.getRootNode();
  let o = /* @__PURE__ */ new Map(), r = null;
  const i = (u) => Ea(u) ? u.strings : u, s = (u) => {
    const f = i(u);
    return o.has(f) ? o.get(f) : null;
  }, a = (u, f) => {
    o.set(i(u), f);
  }, l = (u) => {
    const f = fn(u), d = Vo(f, e, t), h = document.createDocumentFragment(), p = oI(h, n), g = oT(h, n);
    return {
      part: d,
      fragment: h,
      destroy: () => {
        var v;
        p(), g(), (v = d.destroy) == null || v.call(d);
      }
    };
  }, c = () => {
    o.forEach(({ destroy: u }) => u()), o = /* @__PURE__ */ new Map(), Me(e, t).forEach(Ne);
  };
  return (u) => {
    const f = s(r), d = s(u);
    if (f && i(r) !== i(u) && Me(e, t).forEach((h) => f.fragment.appendChild(h)), d)
      i(r) !== i(u) && Qe(d.fragment, t), d.part.commit(u);
    else {
      const h = l(u);
      a(u, h), h.part.commit(u);
    }
    return r = u, c;
  };
}), GP = xc((e) => e, ({ startNode: e, endNode: t }) => {
  let n = null;
  const o = () => {
    Me(e, t).forEach(Ne);
  };
  return (r) => {
    if (n === r)
      return o;
    o();
    const i = document.createElement("template");
    return i.innerHTML = r, Qe(i.content, t), n = r, o;
  };
});
var Ur;
const YP = xc((e, t, n) => (e.length, [e, t, n]), ({ startNode: e, endNode: t }) => {
  let n = [];
  const o = () => {
    n.forEach((r) => r.destroy());
  };
  return ([r, i, s]) => {
    const a = AT(r, i, s), l = a.values, c = Bh(xT(n), a, {
      strict: !0
    }), u = { length: l.length };
    return c.update.forEach(({ action: f, from: d, to: h }) => {
      switch (f) {
        case kt.create:
          const p = document.createComment("");
          h === 0 ? Je(p, e) : n.length ? Je(p, u[h - 1] ? u[h - 1].endNode : n[h - 1].endNode) : Qe(p, t), u[h] = new ST(p, l[h].value, l[h].key);
          break;
        case kt.move:
          if (u[h] = n[d], h === d)
            return;
          h === 0 ? n[d].insert("after", e) : n[d].insert("after", u[h - 1] ? u[h - 1].endNode : n[h - 1].endNode);
          break;
      }
    }), c.delete.forEach(({ from: f }) => n[f].destroy()), n = Array.from(u), n.forEach((f, d) => f.commit(l[d].value)), o;
  };
});
class ST {
  constructor(t, n, o) {
    Ur.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "key", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Qe(this.startNode, t), Je(this.endNode, t), Ne(t), this.key = o, this.type = fn(n), b(this, Ur, Vo(this.type, this.startNode, this.endNode), "f");
  }
  commit(t) {
    m(this, Ur, "f").commit(t);
  }
  insert(t, n) {
    const o = [
      this.startNode,
      ...Me(this.startNode, this.endNode),
      this.endNode
    ];
    t === "before" ? o.forEach((r) => Qe(r, n)) : o.reverse().forEach((r) => Je(r, n));
  }
  destroy() {
    var t, n;
    (n = (t = m(this, Ur, "f")).destroy) == null || n.call(t), Me(this.startNode, this.endNode).forEach(Ne), this.startNode.remove(), this.endNode.remove();
  }
}
Ur = /* @__PURE__ */ new WeakMap();
function xT(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, key: r }, i) => {
    const s = { type: o, key: r };
    t.push(s), n.set(s, i);
  }), {
    items: t,
    itemToIndex: n
  };
}
function AT(e, t, n) {
  const o = [], r = /* @__PURE__ */ new Map(), i = [];
  return e.forEach((s, a, l) => {
    const c = {
      key: t(s),
      value: n(s, a, l)
    }, u = { type: fn(c.value), key: c.key };
    i.push(c), o.push(u), r.set(u, a);
  }), {
    items: o,
    itemToIndex: r,
    values: i
  };
}
const $T = (e) => he(e) ? e : ft(e) ? [] : Object.keys(e), NT = (e) => ft(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return n === Number || n === String || n === Boolean || Ce(n) && !ft(n.default);
}).map((t) => {
  const n = e[t];
  return Ce(n) ? [t, Reflect.get(n, "default")] : n === Number ? [t, 0] : n === Boolean ? [t, !1] : [t, ""];
}), MT = (e) => ft(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return on(n) || Ce(n) && !ft(n.type);
}).map((t) => {
  const n = e[t];
  return Ce(n) ? [t, Reflect.get(n, "type")] : [t, n];
});
var A;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.nextLine = "nextLine", e.equal = "equal", e.tilde = "tilde", e.plus = "plus", e.sharp = "sharp", e.asterisk = "asterisk", e.commercialAt = "commercialAt", e.ampersand = "ampersand", e.period = "period", e.comma = "comma", e.colon = "colon", e.semicolon = "semicolon", e.gt = "gt", e.leftBrace = "leftBrace", e.rightBrace = "rightBrace", e.leftBracket = "leftBracket", e.rightBracket = "rightBracket", e.leftParent = "leftParent", e.rightParent = "rightParent";
})(A || (A = {}));
var Nt;
(function(e) {
  e.style = "style", e.multiComment = "multiComment", e.singleComment = "singleComment";
})(Nt || (Nt = {}));
const X = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /:|;|{|&|#|@|,|>|~|\(|\)|\[|\.|\+/,
  nextLine: `
`,
  slash: "/",
  equal: "=",
  tilde: "~",
  plus: "+",
  sharp: "#",
  asterisk: "*",
  commercialAt: "@",
  ampersand: "&",
  period: ".",
  comma: ",",
  colon: ":",
  semicolon: ";",
  gt: ">",
  leftBrace: "{",
  rightBrace: "}",
  leftBracket: "[",
  rightBracket: "]",
  leftParent: "(",
  rightParent: ")"
}, ie = (e) => (t) => e === t, tl = (e) => (t) => e.test(t), B = {
  doubleQuote: ie(X.doubleQuote),
  singleQuote: ie(X.singleQuote),
  whiteSpace: tl(X.whiteSpace),
  string: tl(X.string),
  breakString: tl(X.breakString),
  nextLine: ie(X.nextLine),
  slash: ie(X.slash),
  equal: ie(X.equal),
  tilde: ie(X.tilde),
  plus: ie(X.plus),
  sharp: ie(X.sharp),
  asterisk: ie(X.asterisk),
  commercialAt: ie(X.commercialAt),
  ampersand: ie(X.ampersand),
  period: ie(X.period),
  comma: ie(X.comma),
  colon: ie(X.colon),
  semicolon: ie(X.semicolon),
  gt: ie(X.gt),
  leftBrace: ie(X.leftBrace),
  rightBrace: ie(X.rightBrace),
  leftBracket: ie(X.leftBracket),
  rightBracket: ie(X.rightBracket),
  leftParent: ie(X.leftParent),
  rightParent: ie(X.rightParent)
}, kT = (e) => (t) => B.slash(e[t]) && B.asterisk(e[t + 1]), DT = (e) => (t) => B.asterisk(e[t]) && B.slash(e[t + 1]), LT = (e) => (t) => B.slash(e[t]) && B.slash(e[t + 1]);
function RT(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = kT(e), i = DT(e), s = LT(e), a = (l = Nt.style) => {
    for (; o(); ) {
      let c = e[n];
      if (B.nextLine(c)) {
        if (t.push({ type: A.nextLine, value: c }), n++, l === Nt.singleComment)
          break;
        continue;
      }
      if (B.whiteSpace(c)) {
        let u = "";
        for (; o() && B.whiteSpace(c) && !B.nextLine(c); )
          u += c, c = e[++n];
        t.push({ type: A.whiteSpace, value: u });
        continue;
      }
      if (l === Nt.style) {
        if (r(n)) {
          a(Nt.multiComment);
          continue;
        }
        if (s(n)) {
          a(Nt.singleComment);
          continue;
        }
        if (B.colon(c)) {
          t.push({ type: A.colon, value: c });
          let u = "";
          if (c = e[++n], B.whiteSpace(c)) {
            for (u = ""; o() && B.whiteSpace(c); )
              u += c, c = e[++n];
            t.push({ type: A.whiteSpace, value: u }), u = "";
          } else
            continue;
          for (; o() && !B.semicolon(c); )
            u += c, c = e[++n];
          t.push({ type: A.string, value: u });
          continue;
        }
        if (B.leftBracket(c)) {
          t.push({ type: A.leftBracket, value: c });
          let u = "";
          for (c = e[++n]; o() && !B.equal(c) && !B.rightBracket(c); )
            u += c, c = e[++n];
          if (t.push({ type: A.string, value: u }), B.equal(c) && (t.push({ type: A.equal, value: c }), c = e[++n]), B.doubleQuote(c)) {
            let f = "";
            for (c = e[++n]; o() && !B.doubleQuote(c); )
              f += c, c = e[++n];
            t.push({ type: A.string, value: f }), c = e[++n];
          }
          if (B.singleQuote(c)) {
            let f = "";
            for (c = e[++n]; o() && !B.singleQuote(c); )
              f += c, c = e[++n];
            t.push({ type: A.string, value: f }), c = e[++n];
          }
          B.rightBracket(c) && (t.push({ type: A.rightBracket, value: c }), n++);
          continue;
        }
        if (B.commercialAt(c)) {
          t.push({ type: A.commercialAt, value: c }), n++;
          continue;
        }
        if (B.gt(c)) {
          t.push({ type: A.gt, value: c }), n++;
          continue;
        }
        if (B.tilde(c)) {
          t.push({ type: A.tilde, value: c }), n++;
          continue;
        }
        if (B.plus(c)) {
          t.push({ type: A.plus, value: c }), n++;
          continue;
        }
        if (B.sharp(c)) {
          t.push({ type: A.sharp, value: c }), n++;
          continue;
        }
        if (B.asterisk(c)) {
          t.push({ type: A.asterisk, value: c }), n++;
          continue;
        }
        if (B.ampersand(c)) {
          t.push({ type: A.ampersand, value: c }), n++;
          continue;
        }
        if (B.period(c)) {
          t.push({ type: A.period, value: c }), n++;
          continue;
        }
        if (B.comma(c)) {
          t.push({ type: A.comma, value: c }), n++;
          continue;
        }
        if (B.semicolon(c)) {
          t.push({ type: A.semicolon, value: c }), n++;
          continue;
        }
        if (B.leftBrace(c)) {
          t.push({ type: A.leftBrace, value: c }), n++;
          continue;
        }
        if (B.rightBrace(c)) {
          t.push({ type: A.rightBrace, value: c }), n++;
          continue;
        }
        if (B.leftParent(c)) {
          let u = "";
          for (; o() && !B.rightParent(c); )
            u += c, c = e[++n];
          u += c, t.push({ type: A.string, value: u }), n++;
          continue;
        }
      } else if (l === Nt.multiComment) {
        if (i(n)) {
          t.push({ type: A.string, value: "*/" }), n += 2;
          break;
        }
      } else if (l === Nt.singleComment && B.nextLine(c)) {
        t.push({ type: A.nextLine, value: c }), n++;
        break;
      }
      if (B.string(c)) {
        let u = "";
        for (; o() && B.string(c) && !B.breakString(c); )
          u += c, c = e[++n];
        t.push({ type: A.string, value: u });
        continue;
      }
      n++;
    }
  };
  return a(), t;
}
const Kh = new RegExp(`^${sn.replace("@@", "")}_\\d+_$`), Qh = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, ce = Qh("type"), Ac = Qh("value"), dn = ce(A.string), _T = ce(A.whiteSpace), Jh = ce(A.nextLine);
ce(A.equal);
ce(A.tilde);
ce(A.plus);
const PT = ce(A.sharp), BT = ce(A.asterisk), $c = ce(A.commercialAt), jT = ce(A.ampersand), UT = ce(A.period);
ce(A.comma);
const Xh = ce(A.colon), Nc = ce(A.semicolon);
ce(A.gt);
const WT = ce(A.leftBrace), FT = ce(A.rightBrace);
ce(A.leftBracket);
ce(A.rightBracket);
ce(A.leftParent);
ce(A.rightParent);
const qT = Ac("/*"), HT = Ac("*/"), VT = Ac("//"), ZT = Jh, zT = (e) => {
  const t = dn(e), n = Xh(e), o = _T(e), r = Nc(e);
  return (i) => t(i) && n(i + 1) && o(i + 2) && t(i + 3) && r(i + 4);
}, GT = (e) => {
  const t = $c(e), n = dn(e), o = Nc(e), r = Jh(e);
  return (i) => t(i) && t(i + 1) && n(i + 2) && Kh.test(e[i + 2].value) && (r(i + 3) || o(i + 3));
}, em = (e) => {
  const t = $c(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, YT = (e) => {
  const t = Xh(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, KT = dn, QT = BT, JT = (e) => {
  const t = $c(e), n = dn(e);
  return (o) => t(o) && t(o + 1) && n(o + 2) && Kh.test(e[o + 2].value);
}, XT = (e) => {
  const t = KT(e), n = QT(e), o = jT(e), r = JT(e);
  return (i) => t(i) || n(i) || o(i) || r(i);
}, e1 = (e) => {
  const t = UT(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, t1 = (e) => {
  const t = PT(e), n = dn(e);
  return (o) => t(o) && n(o + 1);
}, n1 = (e) => {
  const t = XT(e), n = e1(e), o = t1(e), r = em(e), i = YT(e);
  return (s) => t(s) || n(s) || o(s) || r(s) || i(s);
}, tm = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, sf = tm("properties"), hr = tm("children");
var $e;
(function(e) {
  e.style = "style", e.comment = "comment", e.atRule = "atRule";
})($e || ($e = {}));
let Fi = class {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: $e.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
};
function o1(e) {
  let t = 0;
  const n = () => t < e.length, o = zT(e), r = GT(e), i = n1(e), s = WT(e), a = FT(e), l = qT(e), c = HT(e), u = VT(e), f = ZT(e), d = em(e), h = Nc(e), p = (g, v, T = $e.style) => {
    const O = new Fi({
      type: T,
      parent: g,
      value: v
    });
    for (; n(); ) {
      let k = e[t];
      if (a(t)) {
        t++;
        break;
      }
      if (o(t)) {
        const L = e[t + 3];
        sf(O)({
          name: k.value,
          value: L.value
        }), t += 5;
        continue;
      } else if (r(t)) {
        const L = `@@${e[t + 2].value}`;
        sf(O)({
          name: L,
          value: L
        }), t += 4;
        continue;
      } else if (l(t)) {
        let L = k.value;
        for (k = e[++t]; n() && !c(t); )
          L += k.value, k = e[++t];
        L += k.value, hr(O)(new Fi({
          type: $e.comment,
          parent: O,
          value: L
        })), t++;
        continue;
      } else if (u(t)) {
        let L = k.value;
        for (k = e[++t]; n() && !f(t); )
          L += k.value, k = e[++t];
        hr(O)(new Fi({
          type: $e.comment,
          parent: O,
          value: L
        })), t++;
        continue;
      } else if (d(t)) {
        let L = k.value;
        for (k = e[++t]; n() && !s(t) && !h(t); )
          L += k.value, k = e[++t];
        if (h(t)) {
          L += ";", hr(O)(new Fi({
            type: $e.atRule,
            parent: O,
            value: L
          })), t++;
          continue;
        }
        hr(O)(p(O, L.trim(), $e.atRule));
        continue;
      } else if (i(t)) {
        let L = k.value;
        for (k = e[++t]; n() && !s(t); )
          L += k.value, k = e[++t];
        hr(O)(p(O, L.trim()));
        continue;
      }
      t++;
    }
    return O;
  };
  return p(null);
}
const r1 = (e) => o1(RT(e));
var pe;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.lt = "lt", e.gt = "gt", e.slash = "slash", e.equal = "equal";
})(pe || (pe = {}));
var en;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(en || (en = {}));
const ot = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /<|>|=/,
  lt: "<",
  gt: ">",
  slash: "/",
  equal: "=",
  hyphen: "-",
  exclamationPoint: "!"
}, Vt = (e) => (t) => e === t, nl = (e) => (t) => e.test(t), ue = {
  doubleQuote: Vt(ot.doubleQuote),
  singleQuote: Vt(ot.singleQuote),
  whiteSpace: nl(ot.whiteSpace),
  string: nl(ot.string),
  breakString: nl(ot.breakString),
  lt: Vt(ot.lt),
  gt: Vt(ot.gt),
  slash: Vt(ot.slash),
  equal: Vt(ot.equal),
  hyphen: Vt(ot.hyphen),
  exclamationPoint: Vt(ot.exclamationPoint)
}, i1 = (e) => (t) => ue.lt(e[t]) && ue.exclamationPoint(e[t + 1]) && ue.hyphen(e[t + 2]) && ue.hyphen(e[t + 3]);
function s1(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = i1(e), i = (s = en.text) => {
    for (; o(); ) {
      let a = e[n];
      if (ue.whiteSpace(a)) {
        let l = "";
        for (; o() && ue.whiteSpace(a); )
          l += a, a = e[++n];
        s !== en.element && t.push({ type: pe.whiteSpace, value: l });
        continue;
      }
      if (s === en.element) {
        if (ue.lt(a)) {
          t.push({ type: pe.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: pe.gt, value: a }), n++;
          break;
        }
        if (ue.slash(a)) {
          t.push({ type: pe.slash, value: a }), n++;
          continue;
        }
        if (ue.equal(a)) {
          t.push({ type: pe.equal, value: a }), n++;
          continue;
        }
        if (ue.doubleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.doubleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: pe.string, value: l }), n++;
          continue;
        }
        if (ue.singleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.singleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: pe.string, value: l }), n++;
          continue;
        }
      } else if (s === en.comment) {
        if (ue.lt(a)) {
          t.push({ type: pe.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: pe.gt, value: a }), n++;
          break;
        }
      } else if (ue.lt(a)) {
        i(r(n) ? en.comment : en.element);
        continue;
      }
      if (ue.string(a)) {
        let l = "";
        for (; o() && ue.string(a) && !ue.breakString(a); )
          l += a, a = e[++n];
        t.push({ type: pe.string, value: l });
        continue;
      }
      n++;
    }
  };
  return i(), t;
}
const a1 = /^(area|base|br|col|embed|hr|img|input|keygen|link|meta|param|source|track|wbr)$/i, nm = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, Zo = nm("type"), om = nm("value"), pi = Zo(pe.lt), yi = Zo(pe.gt), Mc = Zo(pe.slash), l1 = Zo(pe.equal), gi = Zo(pe.string), c1 = Zo(pe.whiteSpace), u1 = om("!--"), f1 = om("--"), d1 = (e) => {
  const t = pi(e), n = gi(e);
  return (o) => t(o) && n(o + 1);
}, h1 = (e) => {
  const t = pi(e), n = Mc(e), o = gi(e), r = yi(e);
  return (i) => t(i) && n(i + 1) && o(i + 2) && r(i + 3);
}, m1 = (e) => {
  const t = pi(e), n = Mc(e), o = yi(e);
  return (r) => t(r) && n(r + 1) && n(r + 2) && o(r + 3);
}, p1 = (e) => {
  const t = Mc(e), n = yi(e);
  return (o) => t(o) && n(o + 1);
}, y1 = (e) => a1.test(e), g1 = (e) => {
  const t = pi(e), n = gi(e), o = u1(e);
  return (r) => t(r) && n(r + 1) && o(r + 1);
}, v1 = (e) => {
  const t = gi(e), n = f1(e), o = yi(e);
  return (r) => t(r) && n(r) && o(r + 1);
}, rm = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, b1 = rm("attrs"), af = rm("children");
function I1(e) {
  const t = new xn({
    type: we.element,
    value: "template",
    children: []
  });
  let n = 0;
  const o = () => n < e.length, r = d1(e), i = h1(e), s = m1(e), a = p1(e), l = g1(e), c = v1(e), u = pi(e), f = yi(e), d = l1(e), h = c1(e), p = gi(e), g = () => {
    let T = e[n];
    const O = { name: T.value };
    return T = e[++n], d(n) && (T = e[++n], p(n) && (O.value = T.value, n++)), O;
  }, v = (T) => {
    let O = e[n];
    if (h(n) || p(n)) {
      let k = O.value;
      for (O = e[++n]; o() && !u(n); )
        k += O.value, O = e[++n];
      return k = k.trim(), k.length ? new xn({ parent: T, type: we.text, value: k }) : null;
    }
    if (l(n)) {
      let k = "";
      for (n += 2, O = e[n]; o() && !c(n); )
        k += O.value, O = e[++n];
      return c(n) && (n += 2), new xn({ parent: T, type: we.comment, value: k });
    }
    if (r(n)) {
      O = e[++n];
      const k = y1(O.value), L = new xn({
        parent: T,
        type: we.element,
        value: O.value.toLowerCase()
      });
      for (n++; o() && !f(n) && !a(n); ) {
        if (p(n)) {
          b1(L)(g());
          continue;
        }
        n++;
      }
      if (a(n))
        return n += 2, L;
      for (O = e[++n]; o() && !i(n) && !s(n) && !k; )
        af(L)(v(L));
      return (i(n) || s(n)) && (n += 4), L;
    }
    return n++, null;
  };
  for (; o(); )
    af(t)(v(t));
  return t;
}
const T1 = (e) => I1(s1(e)), w1 = (e, t) => e.reduce((n, o) => {
  const r = n[t(o)];
  return r ? r.push(o) : n[t(o)] = [o], n;
}, {});
class wo {
  get isMarker() {
    return ko(this.value);
  }
  get isMarkerOnly() {
    return mi(this.value);
  }
  get isSvg() {
    return this.type === we.element && /^svg$/i.test(this.value);
  }
  get isComponent() {
    return this.type === we.element && this.isMarkerOnly;
  }
  constructor(t, n = null) {
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: we.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "staticAttrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.attrs) {
      const [o, r] = E1(t.attrs);
      o.length && (this.staticAttrs = o), r.length && (this.attrs = r);
    }
    t.children && (this.children = t.children.map((o) => new wo(o, this)));
  }
  insert(t, n, o) {
    if (this.children) {
      const r = t === "before" ? 0 : 1;
      this.children.includes(o) && this.children.splice(this.children.indexOf(o) + r, 0, n);
    } else
      this.children = [n];
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function C1(e) {
  const t = new wo(e);
  for (const n of t)
    n.type === we.text && !mi(n.value) && O1(n);
  return t;
}
function O1(e) {
  const t = be(e.value);
  e.value.replace(xl, sn).split(sn).reduce((n, o, r) => (r < t.length ? n.push(new wo(new xn({ type: we.text, value: o }), e.parent), new wo(new xn({ type: we.text, value: t[r][0] }), e.parent)) : n.push(new wo(new xn({ type: we.text, value: o }), e.parent)), n), []).filter((n) => n.value !== "" && !(!n.value.trim() && OI.test(n.value))).reverse().forEach((n, o, { length: r }) => o === r - 1 ? e.value = n.value : e.parent && e.parent.insert("after", n, e));
}
function E1(e = []) {
  const t = w1(e, (n) => Wi(n.name));
  return Object.keys(t).map((n) => t[n]).reduce((n, o) => {
    const [r, i] = n, s = o[o.length - 1], a = nf(s.name);
    if (a === J.event)
      i.push(...o.filter((l) => !!l.value).map((l) => ({
        type: nf(l.name),
        name: Wi(l.name),
        value: l.value
      })));
    else if (a === J.attribute) {
      const l = o.filter((u) => !!u.value).map((u) => u.value).join(" "), c = { type: a, name: Wi(s.name) };
      l && (c.value = l), tf(c) ? i.push(c) : r.push(c);
    } else {
      const l = { type: a, name: Wi(s.name) };
      s.value && (l.value = s.value), tf(l) ? i.push(l) : r.push(l);
    }
    return n;
  }, [[], []]);
}
const im = (e) => (t, ...n) => {
  const o = {
    strings: t,
    values: n,
    [Mn]: e
  };
  if (Xa.has(t)) {
    const s = Xa.get(t);
    return o.template = s, o;
  }
  const r = t.reduce((s, a, l) => (l < n.length ? s.push(a, xh(l)) : s.push(a), s), []).join(""), i = C1(T1(r));
  return o.template = Object.freeze({ node: i }), Xa.set(t, o.template), o;
}, S1 = im(Ot.html), QP = im(Ot.svg), sm = (e) => fi(e) && !jt(e) && !ft(e) ? String(e) : Sa(e) ? `.${String(e)}` : "", am = (e) => (t, [n, o]) => t.replace(new RegExp(n), sm(e[o]));
class kc {
  get selector() {
    return [...this.iterParent()].reverse().map((t) => t.toString(!0)).join(" ");
  }
  get isAtRule() {
    return this.type === $e.atRule;
  }
  constructor(t, n = null, o) {
    var r;
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: $e.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "style", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "skipParent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), this.type = t.type, this.value = t.value, this.parent = n, t.value && t.isMarker) {
      const a = am(o), l = be(t.value).reduce(a, t.value);
      this.value = l;
    }
    this.value && t.isThis && this.parent && (this.value = this.value.replace(/\&/, (r = this.parent) == null ? void 0 : r.toString(!0)), this.skipParent = !0), this.value && t.isAtRule && this.parent && (this.skipParent = !0);
    const [i, s] = lm(t, o);
    if (t.children && s.push(...t.children.map((a) => [a, o])), this.style = i, this.children = s.filter(([a]) => a.type === $e.style || a.type === $e.atRule).map(([a, l]) => new kc(a, this, l)), this.value && t.isAtRule && this.parent && t.children) {
      let a = "";
      for (const l of this.children)
        l.value && (a += `${l.value} {
${l.style}}
`);
      this.style = a;
    }
  }
  toString(t = !1) {
    return this.value ? this.value : t ? `.${lf(this.style)}` : lf(this.style);
  }
  *iterParent() {
    if (yield this, !!this.parent)
      if (this.skipParent) {
        if (!this.parent.parent)
          return;
        yield* this.parent.parent.iterParent();
      } else
        yield* this.parent.iterParent();
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        t.isAtRule ? yield t : yield* t;
  }
}
const ol = /* @__PURE__ */ new Map();
function lf(e) {
  if (ol.has(e))
    return ol.get(e);
  const t = YI();
  return ol.set(e, t), t;
}
const qi = (e, t) => `${e}: ${t};
`;
function lm(e, t, n = ["", []]) {
  var o;
  const r = am(t);
  return (o = e.properties) == null || o.forEach((i) => {
    if (i.isDynamic) {
      const s = be(i.name), a = t[s[0][1]];
      Sa(a) ? (a.template.node.children && n[1].push(...a.template.node.children.map((l) => [
        l,
        a.values
      ])), lm(a.template.node, a.values, n)) : n[0] += sm(a);
    } else if (i.isMarkerName && i.isMarkerValue) {
      const s = be(i.name), a = be(i.value), l = s.reduce(r, i.name), c = a.reduce(r, i.value);
      n[0] += qi(l, c);
    } else if (i.isMarkerName) {
      const s = be(i.name).reduce(r, i.name);
      n[0] += qi(s, i.value);
    } else if (i.isMarkerValue) {
      const s = be(i.value).reduce(r, i.value);
      n[0] += qi(i.name, s);
    } else
      n[0] += qi(i.name, i.value);
  }), n;
}
const x1 = {
  vCSSStyleSheetMap: /* @__PURE__ */ new Map(),
  hostContextMap: /* @__PURE__ */ new Map()
};
let zs = globalThis.ShadowRoot && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype;
function vi() {
  return x1;
}
function cf(e, t) {
  const n = vi(), o = new kc(e, null, t);
  return [...o].forEach((r) => {
    const i = r.selector;
    if (n.vCSSStyleSheetMap.has(i))
      return;
    const s = zs ? new CSSStyleSheet() : null, a = zs ? null : document.createElement("style"), l = r.isAtRule && !r.style ? `${i}` : `${i} {
${r.style}}`;
    s ? s.replaceSync(l) : a && (a.textContent = l), n.vCSSStyleSheetMap.set(i, {
      selector: i,
      cssText: l,
      style: r.style,
      sheet: s,
      styleElement: a
    }), cm();
  }), String(o);
}
function cm() {
  zs ? A1() : $1();
}
function A1() {
  const e = vi(), t = Array.from(e.vCSSStyleSheetMap).map(([, { sheet: n }]) => n).filter(Boolean);
  Array.from(e.hostContextMap).forEach(([n]) => {
    n.adoptedStyleSheets = t;
  });
}
function $1() {
  const e = vi();
  Array.from(e.hostContextMap).forEach(([t, { vSheets: n, styleElements: o }]) => {
    Array.from(e.vCSSStyleSheetMap).filter(([, r]) => !n.has(r)).map(([, r]) => (n.add(r), r.styleElement ? document.importNode(r.styleElement, !0) : null)).filter(Boolean).forEach((r) => {
      t.appendChild(r), o.add(r);
    });
  });
}
function N1(e) {
  const t = vi();
  t.hostContextMap.has(e) || (t.hostContextMap.set(e, {
    vSheets: /* @__PURE__ */ new Set(),
    styleElements: /* @__PURE__ */ new Set()
  }), cm());
}
function M1(e) {
  const t = vi(), n = t.hostContextMap.get(e);
  n && (zs ? e.adoptedStyleSheets = [] : n.styleElements.forEach((o) => e.removeChild(o)), t.hostContextMap.delete(e));
}
const yn = Symbol.for("https://github.com/dineug/r-html#props");
function JP(e, t) {
  var n, o, r, i, s;
  t.shadow ?? (t.shadow = "open");
  const a = $T(t.observedProps), l = MT(t.observedProps), c = NT(t.observedProps), u = (s = class extends HTMLElement {
    static get observedAttributes() {
      return Array.from(/* @__PURE__ */ new Set([
        ...a,
        ...a.map((f) => qI(f))
      ]));
    }
    constructor() {
      super(), Object.defineProperty(this, i, {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: hi({}, { shallow: !0 })
      }), n.set(this, null), o.set(this, this), r.set(this, void 0), Object.defineProperty(this, "host", {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: this
      }), c.forEach(([f, d]) => Reflect.set(this[yn], lr(f), d)), t.shadow && b(this, o, this.attachShadow({ mode: t.shadow }), "f"), Vs(this), b(this, r, t.render.call(this, this[yn], this), "f"), Vs(null);
    }
    connectedCallback() {
      const f = this.getRootNode();
      f instanceof ShadowRoot && (this.host = f.host), m(this, o, "f") instanceof ShadowRoot && N1(m(this, o, "f")), Fe(this, Ia);
      let d = !1;
      b(this, n, Ec(() => {
        Fe(this, d ? Ca : wc), OT(m(this, o, "f"), S1`${m(this, r, "f").call(this)}`), d ? Fe(this, Oa) : (Fe(this, Cc), d = !0);
      }), "f"), Fe(this, Ta);
    }
    disconnectedCallback() {
      var f;
      (f = m(this, n, "f")) == null || f.call(this), b(this, n, null, "f"), Fe(this, wa), m(this, o, "f") instanceof ShadowRoot && M1(m(this, o, "f"));
    }
    attributeChangedCallback(f, d, h) {
      const p = l.find(([g]) => lr(g) === lr(f));
      p ? Reflect.set(this[yn], lr(f), p[1] === Boolean ? h === "true" || h === "" : p[1](h)) : Reflect.set(this[yn], lr(f), h);
    }
  }, n = /* @__PURE__ */ new WeakMap(), o = /* @__PURE__ */ new WeakMap(), r = /* @__PURE__ */ new WeakMap(), i = yn, s);
  a.forEach((f) => {
    Object.defineProperty(u.prototype, f, {
      get() {
        return Reflect.get(this[yn], f);
      },
      set(d) {
        Reflect.set(this[yn], f, d);
      }
    });
  }), customElements.define(e, u);
}
function I(e) {
  function t(n) {
    return {
      type: e,
      payload: n,
      timestamp: Date.now()
    };
  }
  return t.toString = () => `${e}`, t.type = e, t;
}
function* Dl(e, t, n) {
  for (const o of n)
    o != null && o[Symbol.iterator] ? yield* Dl(e, t, o) : on(o) ? yield* Dl(e, t, o(e, t)) : yield o;
}
const um = (e, t, n) => [...Dl(e, t, n)], rl = (...e) => (t) => e.reduce((n, o) => o(n), t);
function* k1(e) {
  yield e;
}
const il = function* (e) {
  for (const t of e)
    t.length && (yield t);
};
function D1({ context: e, state: t, reducers: n, enableObservable: o = !0 }) {
  const r = o ? hi(t) : t, i = Qr(), s = Qr();
  let a = rl(il);
  const l = (g) => {
    const v = Reflect.get(n, g.type, n);
    va(v, r, g, e);
  }, c = (...g) => {
    const v = um(r, e, g);
    i.next(v);
  }, u = (...g) => {
    Ih(() => c(...g));
  }, f = i.subscribe((g) => {
    const v = a(k1(g));
    for (const T of v)
      s.next(T);
  }), d = s.subscribe((g) => g.forEach(l)), h = (...g) => (a = rl(...g, il), () => {
    a = rl(il);
  }), p = () => {
    f(), d();
  };
  return {
    context: e,
    state: r,
    dispatch: u,
    dispatchSync: c,
    subscribe: s.subscribe,
    pipe: h,
    destroy: p
  };
}
class L1 {
  get isMarkerName() {
    return ko(this.name);
  }
  get isMarkerValue() {
    return ko(this.value);
  }
  get isDynamic() {
    return mi(this.name) && this.name === this.value;
  }
  constructor(t) {
    Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.name = t.name, this.value = t.value;
  }
}
class Dc {
  get isMarker() {
    return ko(this.value);
  }
  get isThis() {
    var t;
    return !!((t = this.value) != null && t.startsWith("&"));
  }
  get isAtRule() {
    return this.type === $e.atRule;
  }
  constructor(t, n = null) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: $e.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.properties && (this.properties = t.properties.map((o) => new L1(o))), t.children && (this.children = t.children.map((o) => new Dc(o, this)));
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function R1(e) {
  return new Dc(e);
}
const XP = (e, ...t) => {
  const n = {
    strings: e,
    values: t,
    [Mn]: Ot.css
  };
  if (el.has(e)) {
    const s = el.get(e), a = cf(s.node, t);
    return n.template = s, n.toString = () => a, n;
  }
  const o = e.raw.reduce((s, a, l) => (l < t.length ? s.push(a, xh(l)) : s.push(a), s), []).join(""), r = R1(r1(o)), i = cf(r, t);
  return n.template = Object.freeze({ node: r }), n.toString = () => i, el.set(e, n.template), n;
};
var Ll = function(e, t) {
  return Ll = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(n, o) {
    n.__proto__ = o;
  } || function(n, o) {
    for (var r in o)
      Object.prototype.hasOwnProperty.call(o, r) && (n[r] = o[r]);
  }, Ll(e, t);
};
function hn(e, t) {
  if (typeof t != "function" && t !== null)
    throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
  Ll(e, t);
  function n() {
    this.constructor = e;
  }
  e.prototype = t === null ? Object.create(t) : (n.prototype = t.prototype, new n());
}
function _1(e, t, n, o) {
  function r(i) {
    return i instanceof n ? i : new n(function(s) {
      s(i);
    });
  }
  return new (n || (n = Promise))(function(i, s) {
    function a(u) {
      try {
        c(o.next(u));
      } catch (f) {
        s(f);
      }
    }
    function l(u) {
      try {
        c(o.throw(u));
      } catch (f) {
        s(f);
      }
    }
    function c(u) {
      u.done ? i(u.value) : r(u.value).then(a, l);
    }
    c((o = o.apply(e, t || [])).next());
  });
}
function fm(e, t) {
  var n = { label: 0, sent: function() {
    if (i[0] & 1)
      throw i[1];
    return i[1];
  }, trys: [], ops: [] }, o, r, i, s;
  return s = { next: a(0), throw: a(1), return: a(2) }, typeof Symbol == "function" && (s[Symbol.iterator] = function() {
    return this;
  }), s;
  function a(c) {
    return function(u) {
      return l([c, u]);
    };
  }
  function l(c) {
    if (o)
      throw new TypeError("Generator is already executing.");
    for (; s && (s = 0, c[0] && (n = 0)), n; )
      try {
        if (o = 1, r && (i = c[0] & 2 ? r.return : c[0] ? r.throw || ((i = r.return) && i.call(r), 0) : r.next) && !(i = i.call(r, c[1])).done)
          return i;
        switch (r = 0, i && (c = [c[0] & 2, i.value]), c[0]) {
          case 0:
          case 1:
            i = c;
            break;
          case 4:
            return n.label++, { value: c[1], done: !1 };
          case 5:
            n.label++, r = c[1], c = [0];
            continue;
          case 7:
            c = n.ops.pop(), n.trys.pop();
            continue;
          default:
            if (i = n.trys, !(i = i.length > 0 && i[i.length - 1]) && (c[0] === 6 || c[0] === 2)) {
              n = 0;
              continue;
            }
            if (c[0] === 3 && (!i || c[1] > i[0] && c[1] < i[3])) {
              n.label = c[1];
              break;
            }
            if (c[0] === 6 && n.label < i[1]) {
              n.label = i[1], i = c;
              break;
            }
            if (i && n.label < i[2]) {
              n.label = i[2], n.ops.push(c);
              break;
            }
            i[2] && n.ops.pop(), n.trys.pop();
            continue;
        }
        c = t.call(e, n);
      } catch (u) {
        c = [6, u], r = 0;
      } finally {
        o = i = 0;
      }
    if (c[0] & 5)
      throw c[1];
    return { value: c[0] ? c[1] : void 0, done: !0 };
  }
}
function Lo(e) {
  var t = typeof Symbol == "function" && Symbol.iterator, n = t && e[t], o = 0;
  if (n)
    return n.call(e);
  if (e && typeof e.length == "number")
    return {
      next: function() {
        return e && o >= e.length && (e = void 0), { value: e && e[o++], done: !e };
      }
    };
  throw new TypeError(t ? "Object is not iterable." : "Symbol.iterator is not defined.");
}
function Gs(e, t) {
  var n = typeof Symbol == "function" && e[Symbol.iterator];
  if (!n)
    return e;
  var o = n.call(e), r, i = [], s;
  try {
    for (; (t === void 0 || t-- > 0) && !(r = o.next()).done; )
      i.push(r.value);
  } catch (a) {
    s = { error: a };
  } finally {
    try {
      r && !r.done && (n = o.return) && n.call(o);
    } finally {
      if (s)
        throw s.error;
    }
  }
  return i;
}
function Ys(e, t, n) {
  if (n || arguments.length === 2)
    for (var o = 0, r = t.length, i; o < r; o++)
      (i || !(o in t)) && (i || (i = Array.prototype.slice.call(t, 0, o)), i[o] = t[o]);
  return e.concat(i || Array.prototype.slice.call(t));
}
function Co(e) {
  return this instanceof Co ? (this.v = e, this) : new Co(e);
}
function P1(e, t, n) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var o = n.apply(e, t || []), r, i = [];
  return r = {}, s("next"), s("throw"), s("return"), r[Symbol.asyncIterator] = function() {
    return this;
  }, r;
  function s(d) {
    o[d] && (r[d] = function(h) {
      return new Promise(function(p, g) {
        i.push([d, h, p, g]) > 1 || a(d, h);
      });
    });
  }
  function a(d, h) {
    try {
      l(o[d](h));
    } catch (p) {
      f(i[0][3], p);
    }
  }
  function l(d) {
    d.value instanceof Co ? Promise.resolve(d.value.v).then(c, u) : f(i[0][2], d);
  }
  function c(d) {
    a("next", d);
  }
  function u(d) {
    a("throw", d);
  }
  function f(d, h) {
    d(h), i.shift(), i.length && a(i[0][0], i[0][1]);
  }
}
function B1(e) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var t = e[Symbol.asyncIterator], n;
  return t ? t.call(e) : (e = typeof Lo == "function" ? Lo(e) : e[Symbol.iterator](), n = {}, o("next"), o("throw"), o("return"), n[Symbol.asyncIterator] = function() {
    return this;
  }, n);
  function o(i) {
    n[i] = e[i] && function(s) {
      return new Promise(function(a, l) {
        s = e[i](s), r(a, l, s.done, s.value);
      });
    };
  }
  function r(i, s, a, l) {
    Promise.resolve(l).then(function(c) {
      i({ value: c, done: a });
    }, s);
  }
}
function eB(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function ge(e) {
  return typeof e == "function";
}
function dm(e) {
  var t = function(o) {
    Error.call(o), o.stack = new Error().stack;
  }, n = e(t);
  return n.prototype = Object.create(Error.prototype), n.prototype.constructor = n, n;
}
var sl = dm(function(e) {
  return function(n) {
    e(this), this.message = n ? n.length + ` errors occurred during unsubscription:
` + n.map(function(o, r) {
      return r + 1 + ") " + o.toString();
    }).join(`
  `) : "", this.name = "UnsubscriptionError", this.errors = n;
  };
});
function Ks(e, t) {
  if (e) {
    var n = e.indexOf(t);
    0 <= n && e.splice(n, 1);
  }
}
var bi = function() {
  function e(t) {
    this.initialTeardown = t, this.closed = !1, this._parentage = null, this._finalizers = null;
  }
  return e.prototype.unsubscribe = function() {
    var t, n, o, r, i;
    if (!this.closed) {
      this.closed = !0;
      var s = this._parentage;
      if (s)
        if (this._parentage = null, Array.isArray(s))
          try {
            for (var a = Lo(s), l = a.next(); !l.done; l = a.next()) {
              var c = l.value;
              c.remove(this);
            }
          } catch (g) {
            t = { error: g };
          } finally {
            try {
              l && !l.done && (n = a.return) && n.call(a);
            } finally {
              if (t)
                throw t.error;
            }
          }
        else
          s.remove(this);
      var u = this.initialTeardown;
      if (ge(u))
        try {
          u();
        } catch (g) {
          i = g instanceof sl ? g.errors : [g];
        }
      var f = this._finalizers;
      if (f) {
        this._finalizers = null;
        try {
          for (var d = Lo(f), h = d.next(); !h.done; h = d.next()) {
            var p = h.value;
            try {
              uf(p);
            } catch (g) {
              i = i ?? [], g instanceof sl ? i = Ys(Ys([], Gs(i)), Gs(g.errors)) : i.push(g);
            }
          }
        } catch (g) {
          o = { error: g };
        } finally {
          try {
            h && !h.done && (r = d.return) && r.call(d);
          } finally {
            if (o)
              throw o.error;
          }
        }
      }
      if (i)
        throw new sl(i);
    }
  }, e.prototype.add = function(t) {
    var n;
    if (t && t !== this)
      if (this.closed)
        uf(t);
      else {
        if (t instanceof e) {
          if (t.closed || t._hasParent(this))
            return;
          t._addParent(this);
        }
        (this._finalizers = (n = this._finalizers) !== null && n !== void 0 ? n : []).push(t);
      }
  }, e.prototype._hasParent = function(t) {
    var n = this._parentage;
    return n === t || Array.isArray(n) && n.includes(t);
  }, e.prototype._addParent = function(t) {
    var n = this._parentage;
    this._parentage = Array.isArray(n) ? (n.push(t), n) : n ? [n, t] : t;
  }, e.prototype._removeParent = function(t) {
    var n = this._parentage;
    n === t ? this._parentage = null : Array.isArray(n) && Ks(n, t);
  }, e.prototype.remove = function(t) {
    var n = this._finalizers;
    n && Ks(n, t), t instanceof e && t._removeParent(this);
  }, e.EMPTY = function() {
    var t = new e();
    return t.closed = !0, t;
  }(), e;
}(), hm = bi.EMPTY;
function mm(e) {
  return e instanceof bi || e && "closed" in e && ge(e.remove) && ge(e.add) && ge(e.unsubscribe);
}
function uf(e) {
  ge(e) ? e() : e.unsubscribe();
}
var pm = {
  onUnhandledError: null,
  onStoppedNotification: null,
  Promise: void 0,
  useDeprecatedSynchronousErrorHandling: !1,
  useDeprecatedNextContext: !1
}, ym = {
  setTimeout: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setTimeout.apply(void 0, Ys([e, t], Gs(n)));
  },
  clearTimeout: function(e) {
    var t = ym.delegate;
    return ((t == null ? void 0 : t.clearTimeout) || clearTimeout)(e);
  },
  delegate: void 0
};
function gm(e) {
  ym.setTimeout(function() {
    throw e;
  });
}
function Rl() {
}
function Rs(e) {
  e();
}
var Lc = function(e) {
  hn(t, e);
  function t(n) {
    var o = e.call(this) || this;
    return o.isStopped = !1, n ? (o.destination = n, mm(n) && n.add(o)) : o.destination = F1, o;
  }
  return t.create = function(n, o, r) {
    return new _l(n, o, r);
  }, t.prototype.next = function(n) {
    this.isStopped || this._next(n);
  }, t.prototype.error = function(n) {
    this.isStopped || (this.isStopped = !0, this._error(n));
  }, t.prototype.complete = function() {
    this.isStopped || (this.isStopped = !0, this._complete());
  }, t.prototype.unsubscribe = function() {
    this.closed || (this.isStopped = !0, e.prototype.unsubscribe.call(this), this.destination = null);
  }, t.prototype._next = function(n) {
    this.destination.next(n);
  }, t.prototype._error = function(n) {
    try {
      this.destination.error(n);
    } finally {
      this.unsubscribe();
    }
  }, t.prototype._complete = function() {
    try {
      this.destination.complete();
    } finally {
      this.unsubscribe();
    }
  }, t;
}(bi), j1 = Function.prototype.bind;
function al(e, t) {
  return j1.call(e, t);
}
var U1 = function() {
  function e(t) {
    this.partialObserver = t;
  }
  return e.prototype.next = function(t) {
    var n = this.partialObserver;
    if (n.next)
      try {
        n.next(t);
      } catch (o) {
        Hi(o);
      }
  }, e.prototype.error = function(t) {
    var n = this.partialObserver;
    if (n.error)
      try {
        n.error(t);
      } catch (o) {
        Hi(o);
      }
    else
      Hi(t);
  }, e.prototype.complete = function() {
    var t = this.partialObserver;
    if (t.complete)
      try {
        t.complete();
      } catch (n) {
        Hi(n);
      }
  }, e;
}(), _l = function(e) {
  hn(t, e);
  function t(n, o, r) {
    var i = e.call(this) || this, s;
    if (ge(n) || !n)
      s = {
        next: n ?? void 0,
        error: o ?? void 0,
        complete: r ?? void 0
      };
    else {
      var a;
      i && pm.useDeprecatedNextContext ? (a = Object.create(n), a.unsubscribe = function() {
        return i.unsubscribe();
      }, s = {
        next: n.next && al(n.next, a),
        error: n.error && al(n.error, a),
        complete: n.complete && al(n.complete, a)
      }) : s = n;
    }
    return i.destination = new U1(s), i;
  }
  return t;
}(Lc);
function Hi(e) {
  gm(e);
}
function W1(e) {
  throw e;
}
var F1 = {
  closed: !0,
  next: Rl,
  error: W1,
  complete: Rl
}, Rc = function() {
  return typeof Symbol == "function" && Symbol.observable || "@@observable";
}();
function q1(e) {
  return e;
}
function H1(e) {
  return e.length === 0 ? q1 : e.length === 1 ? e[0] : function(n) {
    return e.reduce(function(o, r) {
      return r(o);
    }, n);
  };
}
var Xe = function() {
  function e(t) {
    t && (this._subscribe = t);
  }
  return e.prototype.lift = function(t) {
    var n = new e();
    return n.source = this, n.operator = t, n;
  }, e.prototype.subscribe = function(t, n, o) {
    var r = this, i = Z1(t) ? t : new _l(t, n, o);
    return Rs(function() {
      var s = r, a = s.operator, l = s.source;
      i.add(a ? a.call(i, l) : l ? r._subscribe(i) : r._trySubscribe(i));
    }), i;
  }, e.prototype._trySubscribe = function(t) {
    try {
      return this._subscribe(t);
    } catch (n) {
      t.error(n);
    }
  }, e.prototype.forEach = function(t, n) {
    var o = this;
    return n = ff(n), new n(function(r, i) {
      var s = new _l({
        next: function(a) {
          try {
            t(a);
          } catch (l) {
            i(l), s.unsubscribe();
          }
        },
        error: i,
        complete: r
      });
      o.subscribe(s);
    });
  }, e.prototype._subscribe = function(t) {
    var n;
    return (n = this.source) === null || n === void 0 ? void 0 : n.subscribe(t);
  }, e.prototype[Rc] = function() {
    return this;
  }, e.prototype.pipe = function() {
    for (var t = [], n = 0; n < arguments.length; n++)
      t[n] = arguments[n];
    return H1(t)(this);
  }, e.prototype.toPromise = function(t) {
    var n = this;
    return t = ff(t), new t(function(o, r) {
      var i;
      n.subscribe(function(s) {
        return i = s;
      }, function(s) {
        return r(s);
      }, function() {
        return o(i);
      });
    });
  }, e.create = function(t) {
    return new e(t);
  }, e;
}();
function ff(e) {
  var t;
  return (t = e ?? pm.Promise) !== null && t !== void 0 ? t : Promise;
}
function V1(e) {
  return e && ge(e.next) && ge(e.error) && ge(e.complete);
}
function Z1(e) {
  return e && e instanceof Lc || V1(e) && mm(e);
}
function z1(e) {
  return ge(e == null ? void 0 : e.lift);
}
function Vn(e) {
  return function(t) {
    if (z1(t))
      return t.lift(function(n) {
        try {
          return e(n, this);
        } catch (o) {
          this.error(o);
        }
      });
    throw new TypeError("Unable to lift unknown Observable type");
  };
}
function Et(e, t, n, o, r) {
  return new vm(e, t, n, o, r);
}
var vm = function(e) {
  hn(t, e);
  function t(n, o, r, i, s, a) {
    var l = e.call(this, n) || this;
    return l.onFinalize = s, l.shouldUnsubscribe = a, l._next = o ? function(c) {
      try {
        o(c);
      } catch (u) {
        n.error(u);
      }
    } : e.prototype._next, l._error = i ? function(c) {
      try {
        i(c);
      } catch (u) {
        n.error(u);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._error, l._complete = r ? function() {
      try {
        r();
      } catch (c) {
        n.error(c);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._complete, l;
  }
  return t.prototype.unsubscribe = function() {
    var n;
    if (!this.shouldUnsubscribe || this.shouldUnsubscribe()) {
      var o = this.closed;
      e.prototype.unsubscribe.call(this), !o && ((n = this.onFinalize) === null || n === void 0 || n.call(this));
    }
  }, t;
}(Lc), G1 = dm(function(e) {
  return function() {
    e(this), this.name = "ObjectUnsubscribedError", this.message = "object unsubscribed";
  };
}), bm = function(e) {
  hn(t, e);
  function t() {
    var n = e.call(this) || this;
    return n.closed = !1, n.currentObservers = null, n.observers = [], n.isStopped = !1, n.hasError = !1, n.thrownError = null, n;
  }
  return t.prototype.lift = function(n) {
    var o = new df(this, this);
    return o.operator = n, o;
  }, t.prototype._throwIfClosed = function() {
    if (this.closed)
      throw new G1();
  }, t.prototype.next = function(n) {
    var o = this;
    Rs(function() {
      var r, i;
      if (o._throwIfClosed(), !o.isStopped) {
        o.currentObservers || (o.currentObservers = Array.from(o.observers));
        try {
          for (var s = Lo(o.currentObservers), a = s.next(); !a.done; a = s.next()) {
            var l = a.value;
            l.next(n);
          }
        } catch (c) {
          r = { error: c };
        } finally {
          try {
            a && !a.done && (i = s.return) && i.call(s);
          } finally {
            if (r)
              throw r.error;
          }
        }
      }
    });
  }, t.prototype.error = function(n) {
    var o = this;
    Rs(function() {
      if (o._throwIfClosed(), !o.isStopped) {
        o.hasError = o.isStopped = !0, o.thrownError = n;
        for (var r = o.observers; r.length; )
          r.shift().error(n);
      }
    });
  }, t.prototype.complete = function() {
    var n = this;
    Rs(function() {
      if (n._throwIfClosed(), !n.isStopped) {
        n.isStopped = !0;
        for (var o = n.observers; o.length; )
          o.shift().complete();
      }
    });
  }, t.prototype.unsubscribe = function() {
    this.isStopped = this.closed = !0, this.observers = this.currentObservers = null;
  }, Object.defineProperty(t.prototype, "observed", {
    get: function() {
      var n;
      return ((n = this.observers) === null || n === void 0 ? void 0 : n.length) > 0;
    },
    enumerable: !1,
    configurable: !0
  }), t.prototype._trySubscribe = function(n) {
    return this._throwIfClosed(), e.prototype._trySubscribe.call(this, n);
  }, t.prototype._subscribe = function(n) {
    return this._throwIfClosed(), this._checkFinalizedStatuses(n), this._innerSubscribe(n);
  }, t.prototype._innerSubscribe = function(n) {
    var o = this, r = this, i = r.hasError, s = r.isStopped, a = r.observers;
    return i || s ? hm : (this.currentObservers = null, a.push(n), new bi(function() {
      o.currentObservers = null, Ks(a, n);
    }));
  }, t.prototype._checkFinalizedStatuses = function(n) {
    var o = this, r = o.hasError, i = o.thrownError, s = o.isStopped;
    r ? n.error(i) : s && n.complete();
  }, t.prototype.asObservable = function() {
    var n = new Xe();
    return n.source = this, n;
  }, t.create = function(n, o) {
    return new df(n, o);
  }, t;
}(Xe), df = function(e) {
  hn(t, e);
  function t(n, o) {
    var r = e.call(this) || this;
    return r.destination = n, r.source = o, r;
  }
  return t.prototype.next = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.next) === null || r === void 0 || r.call(o, n);
  }, t.prototype.error = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.error) === null || r === void 0 || r.call(o, n);
  }, t.prototype.complete = function() {
    var n, o;
    (o = (n = this.destination) === null || n === void 0 ? void 0 : n.complete) === null || o === void 0 || o.call(n);
  }, t.prototype._subscribe = function(n) {
    var o, r;
    return (r = (o = this.source) === null || o === void 0 ? void 0 : o.subscribe(n)) !== null && r !== void 0 ? r : hm;
  }, t;
}(bm), Y1 = {
  now: function() {
    return Date.now();
  },
  delegate: void 0
}, K1 = function(e) {
  hn(t, e);
  function t(n, o) {
    return e.call(this) || this;
  }
  return t.prototype.schedule = function(n, o) {
    return this;
  }, t;
}(bi), Pl = {
  setInterval: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setInterval.apply(void 0, Ys([e, t], Gs(n)));
  },
  clearInterval: function(e) {
    var t = Pl.delegate;
    return ((t == null ? void 0 : t.clearInterval) || clearInterval)(e);
  },
  delegate: void 0
}, Q1 = function(e) {
  hn(t, e);
  function t(n, o) {
    var r = e.call(this, n, o) || this;
    return r.scheduler = n, r.work = o, r.pending = !1, r;
  }
  return t.prototype.schedule = function(n, o) {
    var r;
    if (o === void 0 && (o = 0), this.closed)
      return this;
    this.state = n;
    var i = this.id, s = this.scheduler;
    return i != null && (this.id = this.recycleAsyncId(s, i, o)), this.pending = !0, this.delay = o, this.id = (r = this.id) !== null && r !== void 0 ? r : this.requestAsyncId(s, this.id, o), this;
  }, t.prototype.requestAsyncId = function(n, o, r) {
    return r === void 0 && (r = 0), Pl.setInterval(n.flush.bind(n, this), r);
  }, t.prototype.recycleAsyncId = function(n, o, r) {
    if (r === void 0 && (r = 0), r != null && this.delay === r && this.pending === !1)
      return o;
    o != null && Pl.clearInterval(o);
  }, t.prototype.execute = function(n, o) {
    if (this.closed)
      return new Error("executing a cancelled action");
    this.pending = !1;
    var r = this._execute(n, o);
    if (r)
      return r;
    this.pending === !1 && this.id != null && (this.id = this.recycleAsyncId(this.scheduler, this.id, null));
  }, t.prototype._execute = function(n, o) {
    var r = !1, i;
    try {
      this.work(n);
    } catch (s) {
      r = !0, i = s || new Error("Scheduled action threw falsy error");
    }
    if (r)
      return this.unsubscribe(), i;
  }, t.prototype.unsubscribe = function() {
    if (!this.closed) {
      var n = this, o = n.id, r = n.scheduler, i = r.actions;
      this.work = this.state = this.scheduler = null, this.pending = !1, Ks(i, this), o != null && (this.id = this.recycleAsyncId(r, o, null)), this.delay = null, e.prototype.unsubscribe.call(this);
    }
  }, t;
}(K1), hf = function() {
  function e(t, n) {
    n === void 0 && (n = e.now), this.schedulerActionCtor = t, this.now = n;
  }
  return e.prototype.schedule = function(t, n, o) {
    return n === void 0 && (n = 0), new this.schedulerActionCtor(this, t).schedule(o, n);
  }, e.now = Y1.now, e;
}(), J1 = function(e) {
  hn(t, e);
  function t(n, o) {
    o === void 0 && (o = hf.now);
    var r = e.call(this, n, o) || this;
    return r.actions = [], r._active = !1, r;
  }
  return t.prototype.flush = function(n) {
    var o = this.actions;
    if (this._active) {
      o.push(n);
      return;
    }
    var r;
    this._active = !0;
    do
      if (r = n.execute(n.state, n.delay))
        break;
    while (n = o.shift());
    if (this._active = !1, r) {
      for (; n = o.shift(); )
        n.unsubscribe();
      throw r;
    }
  }, t;
}(hf), _c = new J1(Q1), X1 = _c;
function ew(e) {
  return e && ge(e.schedule);
}
var tw = function(e) {
  return e && typeof e.length == "number" && typeof e != "function";
};
function nw(e) {
  return ge(e == null ? void 0 : e.then);
}
function ow(e) {
  return ge(e[Rc]);
}
function rw(e) {
  return Symbol.asyncIterator && ge(e == null ? void 0 : e[Symbol.asyncIterator]);
}
function iw(e) {
  return new TypeError("You provided " + (e !== null && typeof e == "object" ? "an invalid object" : "'" + e + "'") + " where a stream was expected. You can provide an Observable, Promise, ReadableStream, Array, AsyncIterable, or Iterable.");
}
function sw() {
  return typeof Symbol != "function" || !Symbol.iterator ? "@@iterator" : Symbol.iterator;
}
var aw = sw();
function lw(e) {
  return ge(e == null ? void 0 : e[aw]);
}
function cw(e) {
  return P1(this, arguments, function() {
    var n, o, r, i;
    return fm(this, function(s) {
      switch (s.label) {
        case 0:
          n = e.getReader(), s.label = 1;
        case 1:
          s.trys.push([1, , 9, 10]), s.label = 2;
        case 2:
          return [4, Co(n.read())];
        case 3:
          return o = s.sent(), r = o.value, i = o.done, i ? [4, Co(void 0)] : [3, 5];
        case 4:
          return [2, s.sent()];
        case 5:
          return [4, Co(r)];
        case 6:
          return [4, s.sent()];
        case 7:
          return s.sent(), [3, 2];
        case 8:
          return [3, 10];
        case 9:
          return n.releaseLock(), [7];
        case 10:
          return [2];
      }
    });
  });
}
function uw(e) {
  return ge(e == null ? void 0 : e.getReader);
}
function Ii(e) {
  if (e instanceof Xe)
    return e;
  if (e != null) {
    if (ow(e))
      return fw(e);
    if (tw(e))
      return dw(e);
    if (nw(e))
      return hw(e);
    if (rw(e))
      return Im(e);
    if (lw(e))
      return mw(e);
    if (uw(e))
      return pw(e);
  }
  throw iw(e);
}
function fw(e) {
  return new Xe(function(t) {
    var n = e[Rc]();
    if (ge(n.subscribe))
      return n.subscribe(t);
    throw new TypeError("Provided object does not correctly implement Symbol.observable");
  });
}
function dw(e) {
  return new Xe(function(t) {
    for (var n = 0; n < e.length && !t.closed; n++)
      t.next(e[n]);
    t.complete();
  });
}
function hw(e) {
  return new Xe(function(t) {
    e.then(function(n) {
      t.closed || (t.next(n), t.complete());
    }, function(n) {
      return t.error(n);
    }).then(null, gm);
  });
}
function mw(e) {
  return new Xe(function(t) {
    var n, o;
    try {
      for (var r = Lo(e), i = r.next(); !i.done; i = r.next()) {
        var s = i.value;
        if (t.next(s), t.closed)
          return;
      }
    } catch (a) {
      n = { error: a };
    } finally {
      try {
        i && !i.done && (o = r.return) && o.call(r);
      } finally {
        if (n)
          throw n.error;
      }
    }
    t.complete();
  });
}
function Im(e) {
  return new Xe(function(t) {
    yw(e, t).catch(function(n) {
      return t.error(n);
    });
  });
}
function pw(e) {
  return Im(cw(e));
}
function yw(e, t) {
  var n, o, r, i;
  return _1(this, void 0, void 0, function() {
    var s, a;
    return fm(this, function(l) {
      switch (l.label) {
        case 0:
          l.trys.push([0, 5, 6, 11]), n = B1(e), l.label = 1;
        case 1:
          return [4, n.next()];
        case 2:
          if (o = l.sent(), !!o.done)
            return [3, 4];
          if (s = o.value, t.next(s), t.closed)
            return [2];
          l.label = 3;
        case 3:
          return [3, 1];
        case 4:
          return [3, 11];
        case 5:
          return a = l.sent(), r = { error: a }, [3, 11];
        case 6:
          return l.trys.push([6, , 9, 10]), o && !o.done && (i = n.return) ? [4, i.call(n)] : [3, 8];
        case 7:
          l.sent(), l.label = 8;
        case 8:
          return [3, 10];
        case 9:
          if (r)
            throw r.error;
          return [7];
        case 10:
          return [7];
        case 11:
          return t.complete(), [2];
      }
    });
  });
}
function gw(e, t, n, o, r) {
  o === void 0 && (o = 0), r === void 0 && (r = !1);
  var i = t.schedule(function() {
    n(), r ? e.add(this.schedule(null, o)) : this.unsubscribe();
  }, o);
  if (e.add(i), !r)
    return i;
}
function vw(e) {
  return e instanceof Date && !isNaN(e);
}
function Qs(e, t) {
  return Vn(function(n, o) {
    var r = 0;
    n.subscribe(Et(o, function(i) {
      o.next(e.call(t, i, r++));
    }));
  });
}
function bw(e, t, n, o, r, i, s, a) {
  var l = [], c = 0, u = 0, f = !1, d = function() {
    f && !l.length && !c && t.complete();
  }, h = function(g) {
    return c < o ? p(g) : l.push(g);
  }, p = function(g) {
    i && t.next(g), c++;
    var v = !1;
    Ii(n(g, u++)).subscribe(Et(t, function(T) {
      r == null || r(T), i ? h(T) : t.next(T);
    }, function() {
      v = !0;
    }, void 0, function() {
      if (v)
        try {
          c--;
          for (var T = function() {
            var O = l.shift();
            s ? gw(t, s, function() {
              return p(O);
            }) : p(O);
          }; l.length && c < o; )
            T();
          d();
        } catch (O) {
          t.error(O);
        }
    }));
  };
  return e.subscribe(Et(t, h, function() {
    f = !0, d();
  })), function() {
    a == null || a();
  };
}
function Tm(e, t, n) {
  return n === void 0 && (n = 1 / 0), ge(t) ? Tm(function(o, r) {
    return Qs(function(i, s) {
      return t(o, i, r, s);
    })(Ii(e(o, r)));
  }, n) : (typeof t == "number" && (n = t), Vn(function(o, r) {
    return bw(o, r, e, n);
  }));
}
function Iw(e, t, n) {
  e === void 0 && (e = 0), n === void 0 && (n = X1);
  var o = -1;
  return t != null && (ew(t) ? n = t : o = t), new Xe(function(r) {
    var i = vw(e) ? +e - n.now() : e;
    i < 0 && (i = 0);
    var s = 0;
    return n.schedule(function() {
      r.closed || (r.next(s++), 0 <= o ? this.schedule(void 0, o) : r.complete());
    }, i);
  });
}
function Tw(e, t) {
  return Vn(function(n, o) {
    var r = 0;
    n.subscribe(Et(o, function(i) {
      return e.call(t, i, r++) && o.next(i);
    }));
  });
}
function ww(e) {
  return Vn(function(t, n) {
    var o = [];
    return t.subscribe(Et(n, function(r) {
      return o.push(r);
    }, function() {
      n.next(o), n.complete();
    })), Ii(e).subscribe(Et(n, function() {
      var r = o;
      o = [], n.next(r);
    }, Rl)), function() {
      o = null;
    };
  });
}
function Cw(e, t) {
  return t === void 0 && (t = _c), Vn(function(n, o) {
    var r = null, i = null, s = null, a = function() {
      if (r) {
        r.unsubscribe(), r = null;
        var c = i;
        i = null, o.next(c);
      }
    };
    function l() {
      var c = s + e, u = t.now();
      if (u < c) {
        r = this.schedule(void 0, c - u), o.add(r);
        return;
      }
      a();
    }
    n.subscribe(Et(o, function(c) {
      i = c, s = t.now(), r || (r = t.schedule(l, e), o.add(r));
    }, function() {
      a(), o.complete();
    }, void 0, function() {
      i = r = null;
    }));
  });
}
function Ow(e, t, n, o) {
  return Vn(function(r, i) {
    var s;
    !t || typeof t == "function" ? s = t : (n = t.duration, s = t.element, o = t.connector);
    var a = /* @__PURE__ */ new Map(), l = function(p) {
      a.forEach(p), p(i);
    }, c = function(p) {
      return l(function(g) {
        return g.error(p);
      });
    }, u = 0, f = !1, d = new vm(i, function(p) {
      try {
        var g = e(p), v = a.get(g);
        if (!v) {
          a.set(g, v = o ? o() : new bm());
          var T = h(g, v);
          if (i.next(T), n) {
            var O = Et(v, function() {
              v.complete(), O == null || O.unsubscribe();
            }, void 0, void 0, function() {
              return a.delete(g);
            });
            d.add(Ii(n(T)).subscribe(O));
          }
        }
        v.next(s ? s(p) : p);
      } catch (k) {
        c(k);
      }
    }, function() {
      return l(function(p) {
        return p.complete();
      });
    }, c, function() {
      return a.clear();
    }, function() {
      return f = !0, u === 0;
    });
    r.subscribe(d);
    function h(p, g) {
      var v = new Xe(function(T) {
        u++;
        var O = g.subscribe(T);
        return function() {
          O.unsubscribe(), --u === 0 && f && d.unsubscribe();
        };
      });
      return v.key = p, v;
    }
  });
}
function Ew(e, t) {
  return Vn(function(n, o) {
    var r = t ?? {}, i = r.leading, s = i === void 0 ? !0 : i, a = r.trailing, l = a === void 0 ? !1 : a, c = !1, u = null, f = null, d = !1, h = function() {
      f == null || f.unsubscribe(), f = null, l && (v(), d && o.complete());
    }, p = function() {
      f = null, d && o.complete();
    }, g = function(T) {
      return f = Ii(e(T)).subscribe(Et(o, h, p));
    }, v = function() {
      if (c) {
        c = !1;
        var T = u;
        u = null, o.next(T), !d && g(T);
      }
    };
    n.subscribe(Et(o, function(T) {
      c = !0, u = T, !(f && !f.closed) && (s ? v() : g(T));
    }, function() {
      d = !0, !(l && c && f && !f.closed) && o.complete();
    }));
  });
}
function Sw(e, t, n) {
  t === void 0 && (t = _c);
  var o = Iw(e, t);
  return Ew(function() {
    return o;
  }, n);
}
function M(e) {
  const t = new Set(e);
  return (n) => t.has(n);
}
function xw(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const Aw = (e) => {
  Promise.resolve().then(e);
}, tB = queueMicrotask ?? Aw, Pc = (e) => (t) => typeof t === e, nB = Pc("function"), Xr = Pc("string"), $w = Pc("undefined"), Nw = (e) => e === null, zo = (e) => Nw(e) || $w(e), { isArray: oB } = Array, Mw = (e) => Number.isInteger(e), kw = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let Dw = (e, t, n) => {
  let o = (2 << Math.log(e.length - 1) / Math.LN2) - 1, r = -~(1.6 * o * t / e.length);
  return (i = t) => {
    let s = "";
    for (; ; ) {
      let a = n(r), l = r;
      for (; l--; )
        if (s += e[a[l] & o] || "", s.length === i)
          return s;
    }
  };
};
const me = Dw(kw, 21, (e) => {
  let t = globalThis.crypto;
  return t === void 0 && (t = require("node:crypto").webcrypto), t.getRandomValues(new Uint8Array(e));
}), jn = (e, t) => (n) => Math.min(Math.max(n, e), t);
var wm = typeof global == "object" && global && global.Object === Object && global, Lw = typeof self == "object" && self && self.Object === Object && self, pt = wm || Lw || Function("return this")(), et = pt.Symbol, Cm = Object.prototype, Rw = Cm.hasOwnProperty, _w = Cm.toString, mr = et ? et.toStringTag : void 0;
function Pw(e) {
  var t = Rw.call(e, mr), n = e[mr];
  try {
    e[mr] = void 0;
    var o = !0;
  } catch {
  }
  var r = _w.call(e);
  return o && (t ? e[mr] = n : delete e[mr]), r;
}
var Bw = Object.prototype, jw = Bw.toString;
function Uw(e) {
  return jw.call(e);
}
var Ww = "[object Null]", Fw = "[object Undefined]", mf = et ? et.toStringTag : void 0;
function Zn(e) {
  return e == null ? e === void 0 ? Fw : Ww : mf && mf in Object(e) ? Pw(e) : Uw(e);
}
function Ut(e) {
  return e != null && typeof e == "object";
}
var qw = "[object Symbol]";
function xa(e) {
  return typeof e == "symbol" || Ut(e) && Zn(e) == qw;
}
function Om(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = Array(o); ++n < o; )
    r[n] = t(e[n], n, e);
  return r;
}
var Pe = Array.isArray, Hw = 1 / 0, pf = et ? et.prototype : void 0, yf = pf ? pf.toString : void 0;
function Em(e) {
  if (typeof e == "string")
    return e;
  if (Pe(e))
    return Om(e, Em) + "";
  if (xa(e))
    return yf ? yf.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -Hw ? "-0" : t;
}
var Vw = /\s/;
function Zw(e) {
  for (var t = e.length; t-- && Vw.test(e.charAt(t)); )
    ;
  return t;
}
var zw = /^\s+/;
function Gw(e) {
  return e && e.slice(0, Zw(e) + 1).replace(zw, "");
}
function dt(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var gf = NaN, Yw = /^[-+]0x[0-9a-f]+$/i, Kw = /^0b[01]+$/i, Qw = /^0o[0-7]+$/i, Jw = parseInt;
function Sm(e) {
  if (typeof e == "number")
    return e;
  if (xa(e))
    return gf;
  if (dt(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = dt(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = Gw(e);
  var n = Kw.test(e);
  return n || Qw.test(e) ? Jw(e.slice(2), n ? 2 : 8) : Yw.test(e) ? gf : +e;
}
var vf = 1 / 0, Xw = 17976931348623157e292;
function _s(e) {
  if (!e)
    return e === 0 ? e : 0;
  if (e = Sm(e), e === vf || e === -vf) {
    var t = e < 0 ? -1 : 1;
    return t * Xw;
  }
  return e === e ? e : 0;
}
function eC(e) {
  var t = _s(e), n = t % 1;
  return t === t ? n ? t - n : t : 0;
}
function xm(e) {
  return e;
}
var tC = "[object AsyncFunction]", nC = "[object Function]", oC = "[object GeneratorFunction]", rC = "[object Proxy]";
function Am(e) {
  if (!dt(e))
    return !1;
  var t = Zn(e);
  return t == nC || t == oC || t == tC || t == rC;
}
var ll = pt["__core-js_shared__"], bf = function() {
  var e = /[^.]+$/.exec(ll && ll.keys && ll.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function iC(e) {
  return !!bf && bf in e;
}
var sC = Function.prototype, aC = sC.toString;
function zn(e) {
  if (e != null) {
    try {
      return aC.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var lC = /[\\^$.*+?()[\]{}|]/g, cC = /^\[object .+?Constructor\]$/, uC = Function.prototype, fC = Object.prototype, dC = uC.toString, hC = fC.hasOwnProperty, mC = RegExp(
  "^" + dC.call(hC).replace(lC, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function pC(e) {
  if (!dt(e) || iC(e))
    return !1;
  var t = Am(e) ? mC : cC;
  return t.test(zn(e));
}
function yC(e, t) {
  return e == null ? void 0 : e[t];
}
function Gn(e, t) {
  var n = yC(e, t);
  return pC(n) ? n : void 0;
}
var Bl = Gn(pt, "WeakMap"), If = Object.create, gC = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!dt(t))
      return {};
    if (If)
      return If(t);
    e.prototype = t;
    var n = new e();
    return e.prototype = void 0, n;
  };
}();
function vC(e, t, n) {
  switch (n.length) {
    case 0:
      return e.call(t);
    case 1:
      return e.call(t, n[0]);
    case 2:
      return e.call(t, n[0], n[1]);
    case 3:
      return e.call(t, n[0], n[1], n[2]);
  }
  return e.apply(t, n);
}
function bC() {
}
function IC(e, t) {
  var n = -1, o = e.length;
  for (t || (t = Array(o)); ++n < o; )
    t[n] = e[n];
  return t;
}
var TC = 800, wC = 16, CC = Date.now;
function OC(e) {
  var t = 0, n = 0;
  return function() {
    var o = CC(), r = wC - (o - n);
    if (n = o, r > 0) {
      if (++t >= TC)
        return arguments[0];
    } else
      t = 0;
    return e.apply(void 0, arguments);
  };
}
function EC(e) {
  return function() {
    return e;
  };
}
var Js = function() {
  try {
    var e = Gn(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), SC = Js ? function(e, t) {
  return Js(e, "toString", {
    configurable: !0,
    enumerable: !1,
    value: EC(t),
    writable: !0
  });
} : xm;
const xC = SC;
var AC = OC(xC);
function $C(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o && t(e[n], n, e) !== !1; )
    ;
  return e;
}
function NC(e, t, n, o) {
  for (var r = e.length, i = n + (o ? 1 : -1); o ? i-- : ++i < r; )
    if (t(e[i], i, e))
      return i;
  return -1;
}
function MC(e) {
  return e !== e;
}
function kC(e, t, n) {
  for (var o = n - 1, r = e.length; ++o < r; )
    if (e[o] === t)
      return o;
  return -1;
}
function DC(e, t, n) {
  return t === t ? kC(e, t, n) : NC(e, MC, n);
}
function LC(e, t) {
  var n = e == null ? 0 : e.length;
  return !!n && DC(e, t, 0) > -1;
}
var RC = 9007199254740991, _C = /^(?:0|[1-9]\d*)$/;
function Aa(e, t) {
  var n = typeof e;
  return t = t ?? RC, !!t && (n == "number" || n != "symbol" && _C.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function Bc(e, t, n) {
  t == "__proto__" && Js ? Js(e, t, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : e[t] = n;
}
function $a(e, t) {
  return e === t || e !== e && t !== t;
}
var PC = Object.prototype, BC = PC.hasOwnProperty;
function jc(e, t, n) {
  var o = e[t];
  (!(BC.call(e, t) && $a(o, n)) || n === void 0 && !(t in e)) && Bc(e, t, n);
}
function Ti(e, t, n, o) {
  var r = !n;
  n || (n = {});
  for (var i = -1, s = t.length; ++i < s; ) {
    var a = t[i], l = o ? o(n[a], e[a], a, n, e) : void 0;
    l === void 0 && (l = e[a]), r ? Bc(n, a, l) : jc(n, a, l);
  }
  return n;
}
var Tf = Math.max;
function jC(e, t, n) {
  return t = Tf(t === void 0 ? e.length - 1 : t, 0), function() {
    for (var o = arguments, r = -1, i = Tf(o.length - t, 0), s = Array(i); ++r < i; )
      s[r] = o[t + r];
    r = -1;
    for (var a = Array(t + 1); ++r < t; )
      a[r] = o[r];
    return a[t] = n(s), vC(e, this, a);
  };
}
var UC = 9007199254740991;
function Uc(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= UC;
}
function wi(e) {
  return e != null && Uc(e.length) && !Am(e);
}
function WC(e, t, n) {
  if (!dt(n))
    return !1;
  var o = typeof t;
  return (o == "number" ? wi(n) && Aa(t, n.length) : o == "string" && t in n) ? $a(n[t], e) : !1;
}
var FC = Object.prototype;
function Na(e) {
  var t = e && e.constructor, n = typeof t == "function" && t.prototype || FC;
  return e === n;
}
function qC(e, t) {
  for (var n = -1, o = Array(e); ++n < e; )
    o[n] = t(n);
  return o;
}
var HC = "[object Arguments]";
function wf(e) {
  return Ut(e) && Zn(e) == HC;
}
var $m = Object.prototype, VC = $m.hasOwnProperty, ZC = $m.propertyIsEnumerable, zC = wf(/* @__PURE__ */ function() {
  return arguments;
}()) ? wf : function(e) {
  return Ut(e) && VC.call(e, "callee") && !ZC.call(e, "callee");
};
const Ma = zC;
function GC() {
  return !1;
}
var Nm = typeof exports == "object" && exports && !exports.nodeType && exports, Cf = Nm && typeof module == "object" && module && !module.nodeType && module, YC = Cf && Cf.exports === Nm, Of = YC ? pt.Buffer : void 0, KC = Of ? Of.isBuffer : void 0, QC = KC || GC;
const ei = QC;
var JC = "[object Arguments]", XC = "[object Array]", eO = "[object Boolean]", tO = "[object Date]", nO = "[object Error]", oO = "[object Function]", rO = "[object Map]", iO = "[object Number]", sO = "[object Object]", aO = "[object RegExp]", lO = "[object Set]", cO = "[object String]", uO = "[object WeakMap]", fO = "[object ArrayBuffer]", dO = "[object DataView]", hO = "[object Float32Array]", mO = "[object Float64Array]", pO = "[object Int8Array]", yO = "[object Int16Array]", gO = "[object Int32Array]", vO = "[object Uint8Array]", bO = "[object Uint8ClampedArray]", IO = "[object Uint16Array]", TO = "[object Uint32Array]", ee = {};
ee[hO] = ee[mO] = ee[pO] = ee[yO] = ee[gO] = ee[vO] = ee[bO] = ee[IO] = ee[TO] = !0;
ee[JC] = ee[XC] = ee[fO] = ee[eO] = ee[dO] = ee[tO] = ee[nO] = ee[oO] = ee[rO] = ee[iO] = ee[sO] = ee[aO] = ee[lO] = ee[cO] = ee[uO] = !1;
function wO(e) {
  return Ut(e) && Uc(e.length) && !!ee[Zn(e)];
}
function Wc(e) {
  return function(t) {
    return e(t);
  };
}
var Mm = typeof exports == "object" && exports && !exports.nodeType && exports, Fr = Mm && typeof module == "object" && module && !module.nodeType && module, CO = Fr && Fr.exports === Mm, cl = CO && wm.process, Ro = function() {
  try {
    var e = Fr && Fr.require && Fr.require("util").types;
    return e || cl && cl.binding && cl.binding("util");
  } catch {
  }
}(), Ef = Ro && Ro.isTypedArray, OO = Ef ? Wc(Ef) : wO;
const Fc = OO;
var EO = Object.prototype, SO = EO.hasOwnProperty;
function km(e, t) {
  var n = Pe(e), o = !n && Ma(e), r = !n && !o && ei(e), i = !n && !o && !r && Fc(e), s = n || o || r || i, a = s ? qC(e.length, String) : [], l = a.length;
  for (var c in e)
    (t || SO.call(e, c)) && !(s && // Safari 9 has enumerable `arguments.length` in strict mode.
    (c == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    r && (c == "offset" || c == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    i && (c == "buffer" || c == "byteLength" || c == "byteOffset") || // Skip index properties.
    Aa(c, l))) && a.push(c);
  return a;
}
function Dm(e, t) {
  return function(n) {
    return e(t(n));
  };
}
var xO = Dm(Object.keys, Object), AO = Object.prototype, $O = AO.hasOwnProperty;
function Lm(e) {
  if (!Na(e))
    return xO(e);
  var t = [];
  for (var n in Object(e))
    $O.call(e, n) && n != "constructor" && t.push(n);
  return t;
}
function Ci(e) {
  return wi(e) ? km(e) : Lm(e);
}
function NO(e) {
  var t = [];
  if (e != null)
    for (var n in Object(e))
      t.push(n);
  return t;
}
var MO = Object.prototype, kO = MO.hasOwnProperty;
function DO(e) {
  if (!dt(e))
    return NO(e);
  var t = Na(e), n = [];
  for (var o in e)
    o == "constructor" && (t || !kO.call(e, o)) || n.push(o);
  return n;
}
function qc(e) {
  return wi(e) ? km(e, !0) : DO(e);
}
var LO = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, RO = /^\w*$/;
function Hc(e, t) {
  if (Pe(e))
    return !1;
  var n = typeof e;
  return n == "number" || n == "symbol" || n == "boolean" || e == null || xa(e) ? !0 : RO.test(e) || !LO.test(e) || t != null && e in Object(t);
}
var ti = Gn(Object, "create");
function _O() {
  this.__data__ = ti ? ti(null) : {}, this.size = 0;
}
function PO(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var BO = "__lodash_hash_undefined__", jO = Object.prototype, UO = jO.hasOwnProperty;
function WO(e) {
  var t = this.__data__;
  if (ti) {
    var n = t[e];
    return n === BO ? void 0 : n;
  }
  return UO.call(t, e) ? t[e] : void 0;
}
var FO = Object.prototype, qO = FO.hasOwnProperty;
function HO(e) {
  var t = this.__data__;
  return ti ? t[e] !== void 0 : qO.call(t, e);
}
var VO = "__lodash_hash_undefined__";
function ZO(e, t) {
  var n = this.__data__;
  return this.size += this.has(e) ? 0 : 1, n[e] = ti && t === void 0 ? VO : t, this;
}
function Un(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Un.prototype.clear = _O;
Un.prototype.delete = PO;
Un.prototype.get = WO;
Un.prototype.has = HO;
Un.prototype.set = ZO;
function zO() {
  this.__data__ = [], this.size = 0;
}
function ka(e, t) {
  for (var n = e.length; n--; )
    if ($a(e[n][0], t))
      return n;
  return -1;
}
var GO = Array.prototype, YO = GO.splice;
function KO(e) {
  var t = this.__data__, n = ka(t, e);
  if (n < 0)
    return !1;
  var o = t.length - 1;
  return n == o ? t.pop() : YO.call(t, n, 1), --this.size, !0;
}
function QO(e) {
  var t = this.__data__, n = ka(t, e);
  return n < 0 ? void 0 : t[n][1];
}
function JO(e) {
  return ka(this.__data__, e) > -1;
}
function XO(e, t) {
  var n = this.__data__, o = ka(n, e);
  return o < 0 ? (++this.size, n.push([e, t])) : n[o][1] = t, this;
}
function Ft(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Ft.prototype.clear = zO;
Ft.prototype.delete = KO;
Ft.prototype.get = QO;
Ft.prototype.has = JO;
Ft.prototype.set = XO;
var ni = Gn(pt, "Map");
function eE() {
  this.size = 0, this.__data__ = {
    hash: new Un(),
    map: new (ni || Ft)(),
    string: new Un()
  };
}
function tE(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function Da(e, t) {
  var n = e.__data__;
  return tE(t) ? n[typeof t == "string" ? "string" : "hash"] : n.map;
}
function nE(e) {
  var t = Da(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function oE(e) {
  return Da(this, e).get(e);
}
function rE(e) {
  return Da(this, e).has(e);
}
function iE(e, t) {
  var n = Da(this, e), o = n.size;
  return n.set(e, t), this.size += n.size == o ? 0 : 1, this;
}
function qt(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
qt.prototype.clear = eE;
qt.prototype.delete = nE;
qt.prototype.get = oE;
qt.prototype.has = rE;
qt.prototype.set = iE;
var sE = "Expected a function";
function Vc(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(sE);
  var n = function() {
    var o = arguments, r = t ? t.apply(this, o) : o[0], i = n.cache;
    if (i.has(r))
      return i.get(r);
    var s = e.apply(this, o);
    return n.cache = i.set(r, s) || i, s;
  };
  return n.cache = new (Vc.Cache || qt)(), n;
}
Vc.Cache = qt;
var aE = 500;
function lE(e) {
  var t = Vc(e, function(o) {
    return n.size === aE && n.clear(), o;
  }), n = t.cache;
  return t;
}
var cE = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, uE = /\\(\\)?/g, fE = lE(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(cE, function(n, o, r, i) {
    t.push(r ? i.replace(uE, "$1") : o || n);
  }), t;
});
function Wn(e) {
  return e == null ? "" : Em(e);
}
function Go(e, t) {
  return Pe(e) ? e : Hc(e, t) ? [e] : fE(Wn(e));
}
var dE = 1 / 0;
function Yo(e) {
  if (typeof e == "string" || xa(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -dE ? "-0" : t;
}
function La(e, t) {
  t = Go(t, e);
  for (var n = 0, o = t.length; e != null && n < o; )
    e = e[Yo(t[n++])];
  return n && n == o ? e : void 0;
}
function hE(e, t, n) {
  var o = e == null ? void 0 : La(e, t);
  return o === void 0 ? n : o;
}
function Zc(e, t) {
  for (var n = -1, o = t.length, r = e.length; ++n < o; )
    e[r + n] = t[n];
  return e;
}
var Sf = et ? et.isConcatSpreadable : void 0;
function mE(e) {
  return Pe(e) || Ma(e) || !!(Sf && e && e[Sf]);
}
function Rm(e, t, n, o, r) {
  var i = -1, s = e.length;
  for (n || (n = mE), r || (r = []); ++i < s; ) {
    var a = e[i];
    t > 0 && n(a) ? t > 1 ? Rm(a, t - 1, n, o, r) : Zc(r, a) : o || (r[r.length] = a);
  }
  return r;
}
function pE(e) {
  var t = e == null ? 0 : e.length;
  return t ? Rm(e, 1) : [];
}
function _m(e) {
  return AC(jC(e, void 0, pE), e + "");
}
var yE = Dm(Object.getPrototypeOf, Object);
const zc = yE;
var gE = "[object Object]", vE = Function.prototype, bE = Object.prototype, Pm = vE.toString, IE = bE.hasOwnProperty, TE = Pm.call(Object);
function wE(e) {
  if (!Ut(e) || Zn(e) != gE)
    return !1;
  var t = zc(e);
  if (t === null)
    return !0;
  var n = IE.call(t, "constructor") && t.constructor;
  return typeof n == "function" && n instanceof n && Pm.call(n) == TE;
}
function Bm(e, t, n) {
  var o = -1, r = e.length;
  t < 0 && (t = -t > r ? 0 : r + t), n = n > r ? r : n, n < 0 && (n += r), r = t > n ? 0 : n - t >>> 0, t >>>= 0;
  for (var i = Array(r); ++o < r; )
    i[o] = e[o + t];
  return i;
}
function CE(e, t, n) {
  var o = e.length;
  return n = n === void 0 ? o : n, !t && n >= o ? e : Bm(e, t, n);
}
var OE = "\\ud800-\\udfff", EE = "\\u0300-\\u036f", SE = "\\ufe20-\\ufe2f", xE = "\\u20d0-\\u20ff", AE = EE + SE + xE, $E = "\\ufe0e\\ufe0f", NE = "\\u200d", ME = RegExp("[" + NE + OE + AE + $E + "]");
function jm(e) {
  return ME.test(e);
}
function kE(e) {
  return e.split("");
}
var Um = "\\ud800-\\udfff", DE = "\\u0300-\\u036f", LE = "\\ufe20-\\ufe2f", RE = "\\u20d0-\\u20ff", _E = DE + LE + RE, PE = "\\ufe0e\\ufe0f", BE = "[" + Um + "]", jl = "[" + _E + "]", Ul = "\\ud83c[\\udffb-\\udfff]", jE = "(?:" + jl + "|" + Ul + ")", Wm = "[^" + Um + "]", Fm = "(?:\\ud83c[\\udde6-\\uddff]){2}", qm = "[\\ud800-\\udbff][\\udc00-\\udfff]", UE = "\\u200d", Hm = jE + "?", Vm = "[" + PE + "]?", WE = "(?:" + UE + "(?:" + [Wm, Fm, qm].join("|") + ")" + Vm + Hm + ")*", FE = Vm + Hm + WE, qE = "(?:" + [Wm + jl + "?", jl, Fm, qm, BE].join("|") + ")", HE = RegExp(Ul + "(?=" + Ul + ")|" + qE + FE, "g");
function VE(e) {
  return e.match(HE) || [];
}
function ZE(e) {
  return jm(e) ? VE(e) : kE(e);
}
function zE(e) {
  return function(t) {
    t = Wn(t);
    var n = jm(t) ? ZE(t) : void 0, o = n ? n[0] : t.charAt(0), r = n ? CE(n, 1).join("") : t.slice(1);
    return o[e]() + r;
  };
}
var Zm = zE("toUpperCase");
function GE(e) {
  return Zm(Wn(e).toLowerCase());
}
function YE(e, t, n, o) {
  var r = -1, i = e == null ? 0 : e.length;
  for (o && i && (n = e[++r]); ++r < i; )
    n = t(n, e[r], r, e);
  return n;
}
function KE(e) {
  return function(t) {
    return e == null ? void 0 : e[t];
  };
}
var QE = {
  // Latin-1 Supplement block.
  À: "A",
  Á: "A",
  Â: "A",
  Ã: "A",
  Ä: "A",
  Å: "A",
  à: "a",
  á: "a",
  â: "a",
  ã: "a",
  ä: "a",
  å: "a",
  Ç: "C",
  ç: "c",
  Ð: "D",
  ð: "d",
  È: "E",
  É: "E",
  Ê: "E",
  Ë: "E",
  è: "e",
  é: "e",
  ê: "e",
  ë: "e",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ï: "I",
  ì: "i",
  í: "i",
  î: "i",
  ï: "i",
  Ñ: "N",
  ñ: "n",
  Ò: "O",
  Ó: "O",
  Ô: "O",
  Õ: "O",
  Ö: "O",
  Ø: "O",
  ò: "o",
  ó: "o",
  ô: "o",
  õ: "o",
  ö: "o",
  ø: "o",
  Ù: "U",
  Ú: "U",
  Û: "U",
  Ü: "U",
  ù: "u",
  ú: "u",
  û: "u",
  ü: "u",
  Ý: "Y",
  ý: "y",
  ÿ: "y",
  Æ: "Ae",
  æ: "ae",
  Þ: "Th",
  þ: "th",
  ß: "ss",
  // Latin Extended-A block.
  Ā: "A",
  Ă: "A",
  Ą: "A",
  ā: "a",
  ă: "a",
  ą: "a",
  Ć: "C",
  Ĉ: "C",
  Ċ: "C",
  Č: "C",
  ć: "c",
  ĉ: "c",
  ċ: "c",
  č: "c",
  Ď: "D",
  Đ: "D",
  ď: "d",
  đ: "d",
  Ē: "E",
  Ĕ: "E",
  Ė: "E",
  Ę: "E",
  Ě: "E",
  ē: "e",
  ĕ: "e",
  ė: "e",
  ę: "e",
  ě: "e",
  Ĝ: "G",
  Ğ: "G",
  Ġ: "G",
  Ģ: "G",
  ĝ: "g",
  ğ: "g",
  ġ: "g",
  ģ: "g",
  Ĥ: "H",
  Ħ: "H",
  ĥ: "h",
  ħ: "h",
  Ĩ: "I",
  Ī: "I",
  Ĭ: "I",
  Į: "I",
  İ: "I",
  ĩ: "i",
  ī: "i",
  ĭ: "i",
  į: "i",
  ı: "i",
  Ĵ: "J",
  ĵ: "j",
  Ķ: "K",
  ķ: "k",
  ĸ: "k",
  Ĺ: "L",
  Ļ: "L",
  Ľ: "L",
  Ŀ: "L",
  Ł: "L",
  ĺ: "l",
  ļ: "l",
  ľ: "l",
  ŀ: "l",
  ł: "l",
  Ń: "N",
  Ņ: "N",
  Ň: "N",
  Ŋ: "N",
  ń: "n",
  ņ: "n",
  ň: "n",
  ŋ: "n",
  Ō: "O",
  Ŏ: "O",
  Ő: "O",
  ō: "o",
  ŏ: "o",
  ő: "o",
  Ŕ: "R",
  Ŗ: "R",
  Ř: "R",
  ŕ: "r",
  ŗ: "r",
  ř: "r",
  Ś: "S",
  Ŝ: "S",
  Ş: "S",
  Š: "S",
  ś: "s",
  ŝ: "s",
  ş: "s",
  š: "s",
  Ţ: "T",
  Ť: "T",
  Ŧ: "T",
  ţ: "t",
  ť: "t",
  ŧ: "t",
  Ũ: "U",
  Ū: "U",
  Ŭ: "U",
  Ů: "U",
  Ű: "U",
  Ų: "U",
  ũ: "u",
  ū: "u",
  ŭ: "u",
  ů: "u",
  ű: "u",
  ų: "u",
  Ŵ: "W",
  ŵ: "w",
  Ŷ: "Y",
  ŷ: "y",
  Ÿ: "Y",
  Ź: "Z",
  Ż: "Z",
  Ž: "Z",
  ź: "z",
  ż: "z",
  ž: "z",
  Ĳ: "IJ",
  ĳ: "ij",
  Œ: "Oe",
  œ: "oe",
  ŉ: "'n",
  ſ: "s"
}, JE = KE(QE);
const XE = JE;
var eS = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g, tS = "\\u0300-\\u036f", nS = "\\ufe20-\\ufe2f", oS = "\\u20d0-\\u20ff", rS = tS + nS + oS, iS = "[" + rS + "]", sS = RegExp(iS, "g");
function aS(e) {
  return e = Wn(e), e && e.replace(eS, XE).replace(sS, "");
}
var lS = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
function cS(e) {
  return e.match(lS) || [];
}
var uS = /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/;
function fS(e) {
  return uS.test(e);
}
var zm = "\\ud800-\\udfff", dS = "\\u0300-\\u036f", hS = "\\ufe20-\\ufe2f", mS = "\\u20d0-\\u20ff", pS = dS + hS + mS, Gm = "\\u2700-\\u27bf", Ym = "a-z\\xdf-\\xf6\\xf8-\\xff", yS = "\\xac\\xb1\\xd7\\xf7", gS = "\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf", vS = "\\u2000-\\u206f", bS = " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000", Km = "A-Z\\xc0-\\xd6\\xd8-\\xde", IS = "\\ufe0e\\ufe0f", Qm = yS + gS + vS + bS, Jm = "['’]", xf = "[" + Qm + "]", TS = "[" + pS + "]", Xm = "\\d+", wS = "[" + Gm + "]", ep = "[" + Ym + "]", tp = "[^" + zm + Qm + Xm + Gm + Ym + Km + "]", CS = "\\ud83c[\\udffb-\\udfff]", OS = "(?:" + TS + "|" + CS + ")", ES = "[^" + zm + "]", np = "(?:\\ud83c[\\udde6-\\uddff]){2}", op = "[\\ud800-\\udbff][\\udc00-\\udfff]", vo = "[" + Km + "]", SS = "\\u200d", Af = "(?:" + ep + "|" + tp + ")", xS = "(?:" + vo + "|" + tp + ")", $f = "(?:" + Jm + "(?:d|ll|m|re|s|t|ve))?", Nf = "(?:" + Jm + "(?:D|LL|M|RE|S|T|VE))?", rp = OS + "?", ip = "[" + IS + "]?", AS = "(?:" + SS + "(?:" + [ES, np, op].join("|") + ")" + ip + rp + ")*", $S = "\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])", NS = "\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])", MS = ip + rp + AS, kS = "(?:" + [wS, np, op].join("|") + ")" + MS, DS = RegExp([
  vo + "?" + ep + "+" + $f + "(?=" + [xf, vo, "$"].join("|") + ")",
  xS + "+" + Nf + "(?=" + [xf, vo + Af, "$"].join("|") + ")",
  vo + "?" + Af + "+" + $f,
  vo + "+" + Nf,
  NS,
  $S,
  Xm,
  kS
].join("|"), "g");
function LS(e) {
  return e.match(DS) || [];
}
function RS(e, t, n) {
  return e = Wn(e), t = n ? void 0 : t, t === void 0 ? fS(e) ? LS(e) : cS(e) : e.match(t) || [];
}
var _S = "['’]", PS = RegExp(_S, "g");
function BS(e) {
  return function(t) {
    return YE(RS(aS(t).replace(PS, "")), e, "");
  };
}
var jS = BS(function(e, t, n) {
  return t = t.toLowerCase(), e + (n ? GE(t) : t);
}), US = pt.isFinite, WS = Math.min;
function FS(e) {
  var t = Math[e];
  return function(n, o) {
    if (n = Sm(n), o = o == null ? 0 : WS(eC(o), 292), o && US(n)) {
      var r = (Wn(n) + "e").split("e"), i = t(r[0] + "e" + (+r[1] + o));
      return r = (Wn(i) + "e").split("e"), +(r[0] + "e" + (+r[1] - o));
    }
    return t(n);
  };
}
function qS() {
  this.__data__ = new Ft(), this.size = 0;
}
function HS(e) {
  var t = this.__data__, n = t.delete(e);
  return this.size = t.size, n;
}
function VS(e) {
  return this.__data__.get(e);
}
function ZS(e) {
  return this.__data__.has(e);
}
var zS = 200;
function GS(e, t) {
  var n = this.__data__;
  if (n instanceof Ft) {
    var o = n.__data__;
    if (!ni || o.length < zS - 1)
      return o.push([e, t]), this.size = ++n.size, this;
    n = this.__data__ = new qt(o);
  }
  return n.set(e, t), this.size = n.size, this;
}
function Ct(e) {
  var t = this.__data__ = new Ft(e);
  this.size = t.size;
}
Ct.prototype.clear = qS;
Ct.prototype.delete = HS;
Ct.prototype.get = VS;
Ct.prototype.has = ZS;
Ct.prototype.set = GS;
function YS(e, t) {
  return e && Ti(t, Ci(t), e);
}
function KS(e, t) {
  return e && Ti(t, qc(t), e);
}
var sp = typeof exports == "object" && exports && !exports.nodeType && exports, Mf = sp && typeof module == "object" && module && !module.nodeType && module, QS = Mf && Mf.exports === sp, kf = QS ? pt.Buffer : void 0, Df = kf ? kf.allocUnsafe : void 0;
function JS(e, t) {
  if (t)
    return e.slice();
  var n = e.length, o = Df ? Df(n) : new e.constructor(n);
  return e.copy(o), o;
}
function XS(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = 0, i = []; ++n < o; ) {
    var s = e[n];
    t(s, n, e) && (i[r++] = s);
  }
  return i;
}
function ap() {
  return [];
}
var ex = Object.prototype, tx = ex.propertyIsEnumerable, Lf = Object.getOwnPropertySymbols, Gc = Lf ? function(e) {
  return e == null ? [] : (e = Object(e), XS(Lf(e), function(t) {
    return tx.call(e, t);
  }));
} : ap;
function nx(e, t) {
  return Ti(e, Gc(e), t);
}
var ox = Object.getOwnPropertySymbols, lp = ox ? function(e) {
  for (var t = []; e; )
    Zc(t, Gc(e)), e = zc(e);
  return t;
} : ap;
function rx(e, t) {
  return Ti(e, lp(e), t);
}
function cp(e, t, n) {
  var o = t(e);
  return Pe(e) ? o : Zc(o, n(e));
}
function Wl(e) {
  return cp(e, Ci, Gc);
}
function up(e) {
  return cp(e, qc, lp);
}
var Fl = Gn(pt, "DataView"), ql = Gn(pt, "Promise"), Oo = Gn(pt, "Set"), Rf = "[object Map]", ix = "[object Object]", _f = "[object Promise]", Pf = "[object Set]", Bf = "[object WeakMap]", jf = "[object DataView]", sx = zn(Fl), ax = zn(ni), lx = zn(ql), cx = zn(Oo), ux = zn(Bl), Cn = Zn;
(Fl && Cn(new Fl(new ArrayBuffer(1))) != jf || ni && Cn(new ni()) != Rf || ql && Cn(ql.resolve()) != _f || Oo && Cn(new Oo()) != Pf || Bl && Cn(new Bl()) != Bf) && (Cn = function(e) {
  var t = Zn(e), n = t == ix ? e.constructor : void 0, o = n ? zn(n) : "";
  if (o)
    switch (o) {
      case sx:
        return jf;
      case ax:
        return Rf;
      case lx:
        return _f;
      case cx:
        return Pf;
      case ux:
        return Bf;
    }
  return t;
});
const _o = Cn;
var fx = Object.prototype, dx = fx.hasOwnProperty;
function hx(e) {
  var t = e.length, n = new e.constructor(t);
  return t && typeof e[0] == "string" && dx.call(e, "index") && (n.index = e.index, n.input = e.input), n;
}
var Xs = pt.Uint8Array;
function Yc(e) {
  var t = new e.constructor(e.byteLength);
  return new Xs(t).set(new Xs(e)), t;
}
function mx(e, t) {
  var n = t ? Yc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.byteLength);
}
var px = /\w*$/;
function yx(e) {
  var t = new e.constructor(e.source, px.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Uf = et ? et.prototype : void 0, Wf = Uf ? Uf.valueOf : void 0;
function gx(e) {
  return Wf ? Object(Wf.call(e)) : {};
}
function vx(e, t) {
  var n = t ? Yc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.length);
}
var bx = "[object Boolean]", Ix = "[object Date]", Tx = "[object Map]", wx = "[object Number]", Cx = "[object RegExp]", Ox = "[object Set]", Ex = "[object String]", Sx = "[object Symbol]", xx = "[object ArrayBuffer]", Ax = "[object DataView]", $x = "[object Float32Array]", Nx = "[object Float64Array]", Mx = "[object Int8Array]", kx = "[object Int16Array]", Dx = "[object Int32Array]", Lx = "[object Uint8Array]", Rx = "[object Uint8ClampedArray]", _x = "[object Uint16Array]", Px = "[object Uint32Array]";
function Bx(e, t, n) {
  var o = e.constructor;
  switch (t) {
    case xx:
      return Yc(e);
    case bx:
    case Ix:
      return new o(+e);
    case Ax:
      return mx(e, n);
    case $x:
    case Nx:
    case Mx:
    case kx:
    case Dx:
    case Lx:
    case Rx:
    case _x:
    case Px:
      return vx(e, n);
    case Tx:
      return new o();
    case wx:
    case Ex:
      return new o(e);
    case Cx:
      return yx(e);
    case Ox:
      return new o();
    case Sx:
      return gx(e);
  }
}
function jx(e) {
  return typeof e.constructor == "function" && !Na(e) ? gC(zc(e)) : {};
}
var Ux = "[object Map]";
function Wx(e) {
  return Ut(e) && _o(e) == Ux;
}
var Ff = Ro && Ro.isMap, Fx = Ff ? Wc(Ff) : Wx, qx = "[object Set]";
function Hx(e) {
  return Ut(e) && _o(e) == qx;
}
var qf = Ro && Ro.isSet, Vx = qf ? Wc(qf) : Hx, Zx = 1, zx = 2, Gx = 4, fp = "[object Arguments]", Yx = "[object Array]", Kx = "[object Boolean]", Qx = "[object Date]", Jx = "[object Error]", dp = "[object Function]", Xx = "[object GeneratorFunction]", eA = "[object Map]", tA = "[object Number]", hp = "[object Object]", nA = "[object RegExp]", oA = "[object Set]", rA = "[object String]", iA = "[object Symbol]", sA = "[object WeakMap]", aA = "[object ArrayBuffer]", lA = "[object DataView]", cA = "[object Float32Array]", uA = "[object Float64Array]", fA = "[object Int8Array]", dA = "[object Int16Array]", hA = "[object Int32Array]", mA = "[object Uint8Array]", pA = "[object Uint8ClampedArray]", yA = "[object Uint16Array]", gA = "[object Uint32Array]", K = {};
K[fp] = K[Yx] = K[aA] = K[lA] = K[Kx] = K[Qx] = K[cA] = K[uA] = K[fA] = K[dA] = K[hA] = K[eA] = K[tA] = K[hp] = K[nA] = K[oA] = K[rA] = K[iA] = K[mA] = K[pA] = K[yA] = K[gA] = !0;
K[Jx] = K[dp] = K[sA] = !1;
function qr(e, t, n, o, r, i) {
  var s, a = t & Zx, l = t & zx, c = t & Gx;
  if (n && (s = r ? n(e, o, r, i) : n(e)), s !== void 0)
    return s;
  if (!dt(e))
    return e;
  var u = Pe(e);
  if (u) {
    if (s = hx(e), !a)
      return IC(e, s);
  } else {
    var f = _o(e), d = f == dp || f == Xx;
    if (ei(e))
      return JS(e, a);
    if (f == hp || f == fp || d && !r) {
      if (s = l || d ? {} : jx(e), !a)
        return l ? rx(e, KS(s, e)) : nx(e, YS(s, e));
    } else {
      if (!K[f])
        return r ? e : {};
      s = Bx(e, f, a);
    }
  }
  i || (i = new Ct());
  var h = i.get(e);
  if (h)
    return h;
  i.set(e, s), Vx(e) ? e.forEach(function(v) {
    s.add(qr(v, t, n, v, e, i));
  }) : Fx(e) && e.forEach(function(v, T) {
    s.set(T, qr(v, t, n, T, e, i));
  });
  var p = c ? l ? up : Wl : l ? qc : Ci, g = u ? void 0 : p(e);
  return $C(g || e, function(v, T) {
    g && (T = v, v = e[T]), jc(s, T, qr(v, t, n, T, e, i));
  }), s;
}
var vA = 1, bA = 4;
function Ra(e) {
  return qr(e, vA | bA);
}
var IA = "__lodash_hash_undefined__";
function TA(e) {
  return this.__data__.set(e, IA), this;
}
function wA(e) {
  return this.__data__.has(e);
}
function oi(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.__data__ = new qt(); ++t < n; )
    this.add(e[t]);
}
oi.prototype.add = oi.prototype.push = TA;
oi.prototype.has = wA;
function CA(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o; )
    if (t(e[n], n, e))
      return !0;
  return !1;
}
function mp(e, t) {
  return e.has(t);
}
var OA = 1, EA = 2;
function pp(e, t, n, o, r, i) {
  var s = n & OA, a = e.length, l = t.length;
  if (a != l && !(s && l > a))
    return !1;
  var c = i.get(e), u = i.get(t);
  if (c && u)
    return c == t && u == e;
  var f = -1, d = !0, h = n & EA ? new oi() : void 0;
  for (i.set(e, t), i.set(t, e); ++f < a; ) {
    var p = e[f], g = t[f];
    if (o)
      var v = s ? o(g, p, f, t, e, i) : o(p, g, f, e, t, i);
    if (v !== void 0) {
      if (v)
        continue;
      d = !1;
      break;
    }
    if (h) {
      if (!CA(t, function(T, O) {
        if (!mp(h, O) && (p === T || r(p, T, n, o, i)))
          return h.push(O);
      })) {
        d = !1;
        break;
      }
    } else if (!(p === g || r(p, g, n, o, i))) {
      d = !1;
      break;
    }
  }
  return i.delete(e), i.delete(t), d;
}
function SA(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o, r) {
    n[++t] = [r, o];
  }), n;
}
function Kc(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o) {
    n[++t] = o;
  }), n;
}
var xA = 1, AA = 2, $A = "[object Boolean]", NA = "[object Date]", MA = "[object Error]", kA = "[object Map]", DA = "[object Number]", LA = "[object RegExp]", RA = "[object Set]", _A = "[object String]", PA = "[object Symbol]", BA = "[object ArrayBuffer]", jA = "[object DataView]", Hf = et ? et.prototype : void 0, ul = Hf ? Hf.valueOf : void 0;
function UA(e, t, n, o, r, i, s) {
  switch (n) {
    case jA:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case BA:
      return !(e.byteLength != t.byteLength || !i(new Xs(e), new Xs(t)));
    case $A:
    case NA:
    case DA:
      return $a(+e, +t);
    case MA:
      return e.name == t.name && e.message == t.message;
    case LA:
    case _A:
      return e == t + "";
    case kA:
      var a = SA;
    case RA:
      var l = o & xA;
      if (a || (a = Kc), e.size != t.size && !l)
        return !1;
      var c = s.get(e);
      if (c)
        return c == t;
      o |= AA, s.set(e, t);
      var u = pp(a(e), a(t), o, r, i, s);
      return s.delete(e), u;
    case PA:
      if (ul)
        return ul.call(e) == ul.call(t);
  }
  return !1;
}
var WA = 1, FA = Object.prototype, qA = FA.hasOwnProperty;
function HA(e, t, n, o, r, i) {
  var s = n & WA, a = Wl(e), l = a.length, c = Wl(t), u = c.length;
  if (l != u && !s)
    return !1;
  for (var f = l; f--; ) {
    var d = a[f];
    if (!(s ? d in t : qA.call(t, d)))
      return !1;
  }
  var h = i.get(e), p = i.get(t);
  if (h && p)
    return h == t && p == e;
  var g = !0;
  i.set(e, t), i.set(t, e);
  for (var v = s; ++f < l; ) {
    d = a[f];
    var T = e[d], O = t[d];
    if (o)
      var k = s ? o(O, T, d, t, e, i) : o(T, O, d, e, t, i);
    if (!(k === void 0 ? T === O || r(T, O, n, o, i) : k)) {
      g = !1;
      break;
    }
    v || (v = d == "constructor");
  }
  if (g && !v) {
    var L = e.constructor, ae = t.constructor;
    L != ae && "constructor" in e && "constructor" in t && !(typeof L == "function" && L instanceof L && typeof ae == "function" && ae instanceof ae) && (g = !1);
  }
  return i.delete(e), i.delete(t), g;
}
var VA = 1, Vf = "[object Arguments]", Zf = "[object Array]", Vi = "[object Object]", ZA = Object.prototype, zf = ZA.hasOwnProperty;
function zA(e, t, n, o, r, i) {
  var s = Pe(e), a = Pe(t), l = s ? Zf : _o(e), c = a ? Zf : _o(t);
  l = l == Vf ? Vi : l, c = c == Vf ? Vi : c;
  var u = l == Vi, f = c == Vi, d = l == c;
  if (d && ei(e)) {
    if (!ei(t))
      return !1;
    s = !0, u = !1;
  }
  if (d && !u)
    return i || (i = new Ct()), s || Fc(e) ? pp(e, t, n, o, r, i) : UA(e, t, l, n, o, r, i);
  if (!(n & VA)) {
    var h = u && zf.call(e, "__wrapped__"), p = f && zf.call(t, "__wrapped__");
    if (h || p) {
      var g = h ? e.value() : e, v = p ? t.value() : t;
      return i || (i = new Ct()), r(g, v, n, o, i);
    }
  }
  return d ? (i || (i = new Ct()), HA(e, t, n, o, r, i)) : !1;
}
function Qc(e, t, n, o, r) {
  return e === t ? !0 : e == null || t == null || !Ut(e) && !Ut(t) ? e !== e && t !== t : zA(e, t, n, o, Qc, r);
}
var GA = 1, YA = 2;
function KA(e, t, n, o) {
  var r = n.length, i = r, s = !o;
  if (e == null)
    return !i;
  for (e = Object(e); r--; ) {
    var a = n[r];
    if (s && a[2] ? a[1] !== e[a[0]] : !(a[0] in e))
      return !1;
  }
  for (; ++r < i; ) {
    a = n[r];
    var l = a[0], c = e[l], u = a[1];
    if (s && a[2]) {
      if (c === void 0 && !(l in e))
        return !1;
    } else {
      var f = new Ct();
      if (o)
        var d = o(c, u, l, e, t, f);
      if (!(d === void 0 ? Qc(u, c, GA | YA, o, f) : d))
        return !1;
    }
  }
  return !0;
}
function yp(e) {
  return e === e && !dt(e);
}
function QA(e) {
  for (var t = Ci(e), n = t.length; n--; ) {
    var o = t[n], r = e[o];
    t[n] = [o, r, yp(r)];
  }
  return t;
}
function gp(e, t) {
  return function(n) {
    return n == null ? !1 : n[e] === t && (t !== void 0 || e in Object(n));
  };
}
function JA(e) {
  var t = QA(e);
  return t.length == 1 && t[0][2] ? gp(t[0][0], t[0][1]) : function(n) {
    return n === e || KA(n, e, t);
  };
}
function XA(e, t) {
  return e != null && t in Object(e);
}
function e$(e, t, n) {
  t = Go(t, e);
  for (var o = -1, r = t.length, i = !1; ++o < r; ) {
    var s = Yo(t[o]);
    if (!(i = e != null && n(e, s)))
      break;
    e = e[s];
  }
  return i || ++o != r ? i : (r = e == null ? 0 : e.length, !!r && Uc(r) && Aa(s, r) && (Pe(e) || Ma(e)));
}
function vp(e, t) {
  return e != null && e$(e, t, XA);
}
var t$ = 1, n$ = 2;
function o$(e, t) {
  return Hc(e) && yp(t) ? gp(Yo(e), t) : function(n) {
    var o = hE(n, e);
    return o === void 0 && o === t ? vp(n, e) : Qc(t, o, t$ | n$);
  };
}
function r$(e) {
  return function(t) {
    return t == null ? void 0 : t[e];
  };
}
function i$(e) {
  return function(t) {
    return La(t, e);
  };
}
function s$(e) {
  return Hc(e) ? r$(Yo(e)) : i$(e);
}
function a$(e) {
  return typeof e == "function" ? e : e == null ? xm : typeof e == "object" ? Pe(e) ? o$(e[0], e[1]) : JA(e) : s$(e);
}
function l$(e, t, n, o) {
  for (var r = -1, i = e == null ? 0 : e.length; ++r < i; ) {
    var s = e[r];
    t(o, s, n(s), e);
  }
  return o;
}
function c$(e) {
  return function(t, n, o) {
    for (var r = -1, i = Object(t), s = o(t), a = s.length; a--; ) {
      var l = s[e ? a : ++r];
      if (n(i[l], l, i) === !1)
        break;
    }
    return t;
  };
}
var u$ = c$();
const f$ = u$;
function d$(e, t) {
  return e && f$(e, t, Ci);
}
function h$(e, t) {
  return function(n, o) {
    if (n == null)
      return n;
    if (!wi(n))
      return e(n, o);
    for (var r = n.length, i = t ? r : -1, s = Object(n); (t ? i-- : ++i < r) && o(s[i], i, s) !== !1; )
      ;
    return n;
  };
}
var m$ = h$(d$);
function p$(e, t, n, o) {
  return m$(e, function(r, i, s) {
    t(o, r, n(r), s);
  }), o;
}
function y$(e, t) {
  return function(n, o) {
    var r = Pe(n) ? l$ : p$, i = t ? t() : {};
    return r(n, e, a$(o), i);
  };
}
function g$(e, t, n) {
  for (var o = -1, r = e == null ? 0 : e.length; ++o < r; )
    if (n(t, e[o]))
      return !0;
  return !1;
}
function Ko(e) {
  var t = e == null ? 0 : e.length;
  return t ? e[t - 1] : void 0;
}
function Oi(e) {
  return e && e.length ? e[0] : void 0;
}
var v$ = Object.prototype, b$ = v$.hasOwnProperty, Ei = y$(function(e, t, n) {
  b$.call(e, n) ? e[n].push(t) : Bc(e, n, [t]);
});
function I$(e, t) {
  return t.length < 2 ? e : La(e, Bm(t, 0, -1));
}
var T$ = "[object Map]", w$ = "[object Set]", C$ = Object.prototype, O$ = C$.hasOwnProperty;
function bp(e) {
  if (e == null)
    return !0;
  if (wi(e) && (Pe(e) || typeof e == "string" || typeof e.splice == "function" || ei(e) || Fc(e) || Ma(e)))
    return !e.length;
  var t = _o(e);
  if (t == T$ || t == w$)
    return !e.size;
  if (Na(e))
    return !Lm(e).length;
  for (var n in e)
    if (O$.call(e, n))
      return !1;
  return !0;
}
function E$(e, t) {
  return t = Go(t, e), e = I$(e, t), e == null || delete e[Yo(Ko(t))];
}
function S$(e) {
  return wE(e) ? void 0 : e;
}
var x$ = 1, A$ = 2, $$ = 4, N$ = _m(function(e, t) {
  var n = {};
  if (e == null)
    return n;
  var o = !1;
  t = Om(t, function(i) {
    return i = Go(i, e), o || (o = i.length > 1), i;
  }), Ti(e, up(e), n), o && (n = qr(n, x$ | A$ | $$, S$));
  for (var r = t.length; r--; )
    E$(n, t[r]);
  return n;
});
function M$(e, t, n, o) {
  if (!dt(e))
    return e;
  t = Go(t, e);
  for (var r = -1, i = t.length, s = i - 1, a = e; a != null && ++r < i; ) {
    var l = Yo(t[r]), c = n;
    if (l === "__proto__" || l === "constructor" || l === "prototype")
      return e;
    if (r != s) {
      var u = a[l];
      c = o ? o(u, l, a) : void 0, c === void 0 && (c = dt(u) ? u : Aa(t[r + 1]) ? [] : {});
    }
    jc(a, l, c), a = a[l];
  }
  return e;
}
function k$(e, t, n) {
  for (var o = -1, r = t.length, i = {}; ++o < r; ) {
    var s = t[o], a = La(e, s);
    n(a, s) && M$(i, Go(s, e), a);
  }
  return i;
}
function D$(e, t) {
  return k$(e, t, function(n, o) {
    return vp(e, o);
  });
}
var ea = _m(function(e, t) {
  return e == null ? {} : D$(e, t);
}), L$ = Math.ceil, R$ = Math.max;
function _$(e, t, n, o) {
  for (var r = -1, i = R$(L$((t - e) / (n || 1)), 0), s = Array(i); i--; )
    s[o ? i : ++r] = e, e += n;
  return s;
}
function P$(e) {
  return function(t, n, o) {
    return o && typeof o != "number" && WC(t, n, o) && (n = o = void 0), t = _s(t), n === void 0 ? (n = t, t = 0) : n = _s(n), o = o === void 0 ? t < n ? 1 : -1 : _s(o), _$(t, n, o, e);
  };
}
var Gf = P$(), ht = FS("round"), B$ = 1 / 0, j$ = Oo && 1 / Kc(new Oo([, -0]))[1] == B$ ? function(e) {
  return new Oo(e);
} : bC, U$ = 200;
function W$(e, t, n) {
  var o = -1, r = LC, i = e.length, s = !0, a = [], l = a;
  if (n)
    s = !1, r = g$;
  else if (i >= U$) {
    var c = t ? null : j$(e);
    if (c)
      return Kc(c);
    s = !1, r = mp, l = new oi();
  } else
    l = t ? [] : a;
  e:
    for (; ++o < i; ) {
      var u = e[o], f = t ? t(u) : u;
      if (u = n || u !== 0 ? u : 0, s && f === f) {
        for (var d = l.length; d--; )
          if (l[d] === f)
            continue e;
        t && l.push(f), a.push(u);
      } else
        r(l, f, n) || (l !== a && l.push(f), a.push(u));
    }
  return a;
}
function Ip(e) {
  return e && e.length ? W$(e) : [];
}
const x = {
  changeHasHistory: "editor.changeHasHistory",
  selectAll: "editor.selectAll",
  unselectAll: "editor.unselectAll",
  select: "editor.select",
  changeViewport: "editor.changeViewport",
  clear: "editor.clear",
  loadJson: "editor.loadJson",
  initialClear: "editor.initialClear",
  initialLoadJson: "editor.initialLoadJson",
  focusTable: "editor.focusTable",
  focusColumn: "editor.focusColumn",
  focusTableEnd: "editor.focusTableEnd",
  focusMoveTable: "editor.focusMoveTable",
  editTable: "editor.editTable",
  editTableEnd: "editor.editTableEnd",
  selectAllColumn: "editor.selectAllColumn",
  drawStartRelationship: "editor.drawStartRelationship",
  drawStartAddRelationship: "editor.drawStartAddRelationship",
  drawEndRelationship: "editor.drawEndRelationship",
  drawRelationship: "editor.drawRelationship",
  hoverColumnMap: "editor.hoverColumnMap",
  changeOpenMap: "editor.changeOpenMap",
  dragstartColumn: "editor.dragstartColumn",
  dragendColumn: "editor.dragendColumn",
  sharedMouseTracker: "editor.sharedMouseTracker",
  validationIds: "editor.validationIds"
}, Tp = E.CanvasType, F$ = E.CanvasTypeList, z = E.Show, _ = E.ColumnType, q$ = E.ColumnTypeList, to = E.Database, H$ = E.DatabaseList, rB = E.Language, V$ = E.LanguageList, iB = E.NameCase, Z$ = E.NameCaseList, Zi = E.BracketType, z$ = E.BracketTypeList, wp = E.RelationshipType, Hl = E.StartRelationshipType, wt = E.Direction, U = E.ColumnOption, Re = E.ColumnUIKey, Eo = E.OrderType, sB = E.SaveSettingType, G$ = E.CANVAS_SIZE_MAX, Y$ = E.CANVAS_SIZE_MIN, K$ = E.CANVAS_ZOOM_MAX, Q$ = E.CANVAS_ZOOM_MIN, aB = {
  [Zi.none]: "",
  [Zi.backtick]: "`",
  [Zi.doubleQuote]: '"',
  [Zi.singleQuote]: "'"
}, lB = {
  [_.columnName]: "Name",
  [_.columnDataType]: "DataType",
  [_.columnDefault]: "Default",
  [_.columnComment]: "Comment",
  [_.columnAutoIncrement]: "Auto Increment",
  [_.columnUnique]: "Unique",
  [_.columnNotNull]: "Not Null"
}, Yn = {
  shared: (
    /*     */
    1
  ),
  changeOnly: (
    /* */
    2
  ),
  following: (
    /*  */
    4
  )
};
function J$(e, t) {
  return {
    ...Ra(t),
    tags: Mw(t.tags) ? t.tags | e : e
  };
}
function X$(e, t) {
  return t.map((n) => J$(e, n));
}
const eN = (e) => (...t) => function* (n, o) {
  const r = um(n, o, t);
  yield X$(e, r);
}, cB = eN(Yn.changeOnly);
function $(e, t) {
  return (e & t) === t;
}
function Yf(e, t) {
  return !(e.x > t.x + t.w || e.x + e.w < t.x || e.y > t.y + t.h || e.y + e.h < t.y);
}
function Vl(e, t, n) {
  const o = { x: 0, y: 0, w: 0, h: 0 };
  return o.w = e * n, o.h = t * n, o.x = (e - o.w) / 2, o.y = (t - o.h) / 2, o;
}
function tN({ x: e, y: t }, n, o, r) {
  const i = Vl(n, o, r), s = e * r, a = t * r, l = i.x + s, c = i.y + a;
  return { x: l, y: c };
}
function Cp(e, t, n, o) {
  const { x: r, y: i } = e, { x: s, y: a } = tN(e, t, n, o), l = (r - s) / o, c = (i - a) / o, u = r + l, f = i + c;
  return { x: u, y: f };
}
const nN = 200, oN = 100, Kf = 50, rN = 16, iN = 9, Op = 1200, sN = Op / rN * iN, Ep = 20, kn = 8, Sp = 12, aN = 4, ta = 1, na = 8, lN = 2, cN = 2, uN = Ep + lN * 2, fN = Sp + cN + uN, uB = 4, xp = 12, Ap = 12, Ve = 60, $p = 35, Np = 22, Mp = 15, dN = 2, hN = Ep + dN * 2, oa = 1, ra = 8, kp = Sp + aN, mN = 100 + kp, pN = 100, fB = 150, dB = 20, hB = 30, mB = 200, On = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, yN = Object.values(On), pB = {
  [On.MariaDB]: to.MariaDB,
  [On.MSSQL]: to.MSSQL,
  [On.MySQL]: to.MySQL,
  [On.Oracle]: to.Oracle,
  [On.PostgreSQL]: to.PostgreSQL,
  [On.SQLite]: to.SQLite
}, gN = /[^0-9]/g, Dp = (e) => e.replace(gN, ""), vN = jn(Y$, G$), bN = jn(Q$, K$), Lp = jn(Ve, 200);
function Zl(e) {
  const t = Xr(e) ? Number(Dp(e)) : e;
  return vN(t);
}
function _a(e) {
  return ht(bN(e), 2);
}
function yB(e) {
  const t = Xr(e) ? Number(Dp(e)) : e;
  return `${Lp(t)}px`;
}
function gB(e) {
  return `${(e * 100).toFixed()}%`;
}
const IN = M(H$), Rp = M(Z$), TN = M(z$), wN = M(V$), Qf = M(q$), vB = M(yN), _p = M(F$);
function ye(e) {
  return Math.max(e, Ve);
}
function bB(e) {
  return Xr(e) ? e.trim() : "";
}
function IB(e) {
  return e <= 0.7;
}
const ke = {
  table: "table",
  memo: "memo"
}, j = {
  tableName: "tableName",
  tableComment: "tableComment",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, lt = {
  ArrowUp: "ArrowUp",
  ArrowRight: "ArrowRight",
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  Tab: "Tab"
}, TB = M(Object.values(lt)), CN = () => ({
  id: me(),
  selectedMap: {},
  hasUndo: !1,
  hasRedo: !1,
  viewport: {
    width: Op,
    height: sN
  },
  focusTable: null,
  drawRelationship: null,
  hoverColumnMap: {},
  openMap: {},
  draggableColumn: null,
  draggingColumnMap: {},
  sharedMouseTrackerMap: {}
}), Jf = (e) => e.ui.zIndex, Pa = (e, t) => Math.max(1, ...e.map(Jf), ...t.map(Jf)) + 1, ON = (e) => (t) => e.y === t.y && e.x === t.x;
function Pp({ width: e, height: t, zoomLevel: n, scrollLeft: o, scrollTop: r }, i, s) {
  const a = Cp({
    x: nN - o,
    y: oN - r
  }, e, t, n), l = [...i, ...s].map(({ ui: c }) => c);
  for (; l.some(ON(a)); )
    a.x += Kf, a.y += Kf;
  return a;
}
function Qo() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function EN(e, t) {
  return e < t ? Gf(e, t + 1) : Gf(t, e + 1);
}
function wB(e) {
  return Zm(jS(e));
}
function Si(e, t) {
  return Ip([...e, t]);
}
function Bp(e, t, n) {
  if (!t || t === n)
    return [n];
  const o = e.indexOf(t), r = e.indexOf(n);
  return o === -1 ? [n] : EN(o, r).map((i) => e[i]);
}
function SN(e, t, n, o) {
  return Ip([
    ...t,
    ...Bp(e, n, o)
  ]);
}
const xN = {
  [_.columnName]: !0,
  [_.columnDataType]: z.columnDataType,
  [_.columnNotNull]: z.columnNotNull,
  [_.columnUnique]: z.columnUnique,
  [_.columnAutoIncrement]: z.columnAutoIncrement,
  [_.columnDefault]: z.columnDefault,
  [_.columnComment]: z.columnComment
}, AN = {
  [_.columnName]: j.columnName,
  [_.columnDataType]: j.columnDataType,
  [_.columnNotNull]: j.columnNotNull,
  [_.columnUnique]: j.columnUnique,
  [_.columnAutoIncrement]: j.columnAutoIncrement,
  [_.columnDefault]: j.columnDefault,
  [_.columnComment]: j.columnComment
}, jp = [
  j.tableName,
  j.tableComment
];
function Jo({ settings: { show: e, columnOrder: t } }) {
  return t.filter((n) => {
    const o = xN[n];
    return o === !0 ? !0 : $(e, o);
  }).map((n) => AN[n]);
}
function xi({ collections: e, editor: { focusTable: t } }) {
  if (!t)
    return !1;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return !!(n != null && n.columnIds.length);
}
function Jc(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Jo(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function Up(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Jo(e).indexOf(t.focusType) === 0 : !0;
}
function Xc({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === n.columnIds.length - 1 : !0;
}
function Wp({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === 0 : !0;
}
function zl(e) {
  const t = Jo(e);
  return t[t.length - 1];
}
function Gl(e) {
  return Jo(e)[0];
}
function $N(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return j.columnName;
  const n = Jo(e), o = n.indexOf(t.focusType);
  return Jc(e) ? n[0] : n[o + 1];
}
function NN(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return j.columnName;
  const n = Jo(e), o = n.indexOf(t.focusType);
  return Up(e) ? n[n.length - 1] : n[o - 1];
}
function MN(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!(o != null && o.columnId))
    return null;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (!r)
    return null;
  const i = r.columnIds.indexOf(o.columnId);
  if (i <= 0)
    return null;
  let s = null;
  for (let a = i; a >= 0; a--) {
    const l = r.columnIds[a];
    if (!t.includes(l)) {
      s = l;
      break;
    }
  }
  return s;
}
function Ba({ settings: { show: e } }) {
  return $(e, z.tableComment) ? jp : [j.tableName];
}
function eu(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Ba(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function Fp(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Ba(e).indexOf(t.focusType) === 0 : !0;
}
const Fn = M(jp);
function Xf(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return j.tableName;
  const n = Ba(e), o = n.indexOf(t.focusType);
  return eu(e) ? n[0] : n[o + 1];
}
function ed(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return j.tableName;
  const n = Ba(e), o = n.indexOf(t.focusType);
  return Fp(e) ? n[n.length - 1] : n[o - 1];
}
function kN(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Fn(o.focusType)) {
      if (xi(e)) {
        const i = r.columnIds[r.columnIds.length - 1];
        o.focusType = zl(e), o.columnId = i, o.prevSelectColumnId = i, o.selectColumnIds = [i];
      }
    } else if (Wp(e))
      o.focusType = j.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const i = r.columnIds.indexOf(o.columnId), s = r.columnIds[i - 1];
      o.columnId = s, o.prevSelectColumnId = s, t.shiftKey && t.moveKey !== lt.Tab ? o.selectColumnIds = Si(o.selectColumnIds, s) : o.selectColumnIds = [s];
    }
  }
}
function DN(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Fn(o.focusType)) {
      if (xi(e)) {
        const i = r.columnIds[0];
        o.focusType = Gl(e), o.columnId = i, o.prevSelectColumnId = i, o.selectColumnIds = [i];
      }
    } else if (Xc(e))
      o.focusType = j.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const i = r.columnIds.indexOf(o.columnId), s = r.columnIds[i + 1];
      o.columnId = s, o.prevSelectColumnId = s, t.shiftKey && t.moveKey !== lt.Tab ? o.selectColumnIds = Si(o.selectColumnIds, s) : o.selectColumnIds = [s];
    }
  }
}
function td(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Fn(o.focusType))
      if (Fp(e))
        if (xi(e)) {
          const i = r.columnIds[r.columnIds.length - 1];
          o.focusType = zl(e), o.columnId = i, o.prevSelectColumnId = i, o.selectColumnIds = [i];
        } else
          o.focusType = ed(e);
      else
        o.focusType = ed(e);
    else if (Up(e)) {
      if (Wp(e))
        o.focusType = $(e.settings.show, z.tableComment) ? j.tableComment : j.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const i = r.columnIds.indexOf(o.columnId), s = r.columnIds[i - 1];
        o.focusType = zl(e), o.columnId = s, o.prevSelectColumnId = s, t.shiftKey && t.moveKey !== lt.Tab ? o.selectColumnIds = Si(o.selectColumnIds, s) : o.selectColumnIds = [s];
      }
    } else
      o.focusType = NN(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
function nd(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Fn(o.focusType))
      if (eu(e))
        if (xi(e)) {
          const i = r.columnIds[0];
          o.focusType = Gl(e), o.columnId = i, o.prevSelectColumnId = i, o.selectColumnIds = [i];
        } else
          o.focusType = Xf(e);
      else
        o.focusType = Xf(e);
    else if (Jc(e)) {
      if (Xc(e))
        o.focusType = j.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const i = r.columnIds.indexOf(o.columnId), s = r.columnIds[i + 1];
        o.focusType = Gl(e), o.columnId = s, o.prevSelectColumnId = s, t.shiftKey && t.moveKey !== lt.Tab ? o.selectColumnIds = Si(o.selectColumnIds, s) : o.selectColumnIds = [s];
      }
    } else
      o.focusType = $N(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
const od = 1e3 * 30, LN = I(x.changeHasHistory), RN = ({ editor: e }, { payload: { hasRedo: t, hasUndo: n } }) => {
  e.hasRedo = t, e.hasUndo = n;
}, _N = I(x.selectAll), PN = ({ editor: e, doc: t }) => {
  const n = t.tableIds.reduce((r, i) => (r[i] = ke.table, r), {}), o = t.memoIds.reduce((r, i) => (r[i] = ke.memo, r), {});
  e.selectedMap = {
    ...o,
    ...n
  };
}, ja = I(x.unselectAll), BN = ({ editor: e }) => {
  Object.keys(e.selectedMap).forEach((t) => {
    Reflect.deleteProperty(e.selectedMap, t);
  });
}, Xo = I(x.select), jN = ({ editor: e }, { payload: t }) => {
  Object.assign(e.selectedMap, t);
}, UN = I(x.changeViewport), WN = ({ editor: e }, { payload: { width: t, height: n } }) => {
  e.viewport.width = t, e.viewport.height = n;
}, tu = I(x.clear), FN = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, an = I(x.loadJson), qN = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: i, lww: s } = gh(t);
  _p(o.canvasType) || (o.canvasType = Tp.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = i, e.lww = s;
}, qp = I(x.initialClear), HN = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, Po = I(x.initialLoadJson), VN = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: i, lww: s } = gh(t);
  _p(o.canvasType) || (o.canvasType = Tp.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = i, e.lww = s;
}, Ua = I(x.focusTable), ZN = ({ editor: e, collections: t }, { payload: n }) => {
  var r, i;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId && n.focusType)
    e.focusTable.focusType = n.focusType, e.focusTable.columnId = null, e.focusTable.prevSelectColumnId = null, e.focusTable.selectColumnIds = [];
  else if (n.focusType) {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    e.focusTable = {
      tableId: s.id,
      focusType: n.focusType,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  } else if (((i = e.focusTable) == null ? void 0 : i.tableId) !== n.tableId) {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    e.focusTable = {
      tableId: s.id,
      focusType: j.tableName,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  }
}, ln = I(x.focusColumn), zN = ({ editor: e, collections: t }, { payload: n }) => {
  var r;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId) {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    const s = e.focusTable;
    s.columnId = n.columnId, s.focusType = n.focusType, n.$mod && n.shiftKey ? s.selectColumnIds = SN(i.columnIds, s.selectColumnIds, s.prevSelectColumnId, s.columnId) : n.shiftKey ? s.selectColumnIds = Bp(i.columnIds, s.prevSelectColumnId, s.columnId) : n.$mod ? s.selectColumnIds = Si(s.selectColumnIds, n.columnId) : s.selectColumnIds = [n.columnId], s.prevSelectColumnId = n.columnId;
  } else {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    e.focusTable = {
      tableId: i.id,
      focusType: n.focusType,
      columnId: n.columnId,
      prevSelectColumnId: n.columnId,
      selectColumnIds: [n.columnId],
      edit: !1
    };
  }
}, nu = I(x.focusTableEnd), GN = ({ editor: e }) => {
  e.focusTable = null;
}, Hp = I(x.focusMoveTable), YN = (e, { payload: t }) => {
  const { editor: { focusTable: n } } = e;
  if (n)
    switch (n.edit = !1, t.moveKey) {
      case lt.ArrowUp:
        kN(e, t);
        break;
      case lt.ArrowDown:
        DN(e, t);
        break;
      case lt.ArrowLeft:
        td(e, t);
        break;
      case lt.ArrowRight:
        nd(e, t);
        break;
      case lt.Tab:
        t.shiftKey ? td(e, t) : nd(e, t);
        break;
    }
}, KN = I(x.editTable), QN = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !0);
}, JN = I(x.editTableEnd), XN = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !1);
}, eM = I(x.selectAllColumn), tM = ({ collections: e, editor: { focusTable: t } }) => {
  if (!t)
    return;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  n && (t.selectColumnIds = [...n.columnIds]);
}, Vp = I(x.drawStartRelationship), nM = ({ editor: e }, { payload: { relationshipType: t } }) => {
  e.drawRelationship = {
    relationshipType: t,
    start: null,
    end: { x: 0, y: 0 }
  };
}, Zp = I(x.drawStartAddRelationship), oM = ({ editor: { drawRelationship: e }, collections: t }, { payload: { tableId: n } }) => {
  if (!e)
    return;
  const o = y(t).collection("tableEntities").selectById(n);
  o && (e.start = {
    tableId: n,
    x: o.ui.x,
    y: o.ui.y
  });
}, ou = I(x.drawEndRelationship), rM = ({ editor: e }) => {
  e.drawRelationship = null;
}, iM = I(x.drawRelationship), sM = ({ editor: { drawRelationship: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: i } }, { payload: { x: s, y: a } }) => {
  if (!(e != null && e.start))
    return;
  const l = Cp({ x: s - t, y: a - n }, r, i, o);
  e.end.x = l.x, e.end.y = l.y;
}, aM = I(x.hoverColumnMap), lM = ({ editor: e }, { payload: { columnIds: t } }) => {
  Object.keys(e.hoverColumnMap).forEach((n) => {
    Reflect.deleteProperty(e.hoverColumnMap, n);
  });
  for (const n of t)
    e.hoverColumnMap[n] = !0;
}, cM = I(x.changeOpenMap), uM = ({ editor: e }, { payload: t }) => {
  Object.assign(e.openMap, t);
}, ru = I(x.dragstartColumn), fM = ({ editor: e }, { payload: t }) => {
  e.draggableColumn = t, t.columnIds.forEach((n) => {
    e.draggingColumnMap[n] = !0;
  });
}, dM = I(x.dragendColumn), hM = ({ editor: e }) => {
  e.draggableColumn = null, Object.keys(e.draggingColumnMap).forEach((t) => {
    Reflect.deleteProperty(e.draggingColumnMap, t);
  });
}, mM = I(x.sharedMouseTracker), pM = ({ editor: e }, { payload: t, tags: n, meta: o }) => {
  if (zo(n) || !$(n, Yn.shared) || !Xr(o == null ? void 0 : o.editorId) || e.id === o.editorId)
    return;
  const r = e.sharedMouseTrackerMap[o.editorId], i = !Xr(o.nickname) || bp(o.nickname.trim()) ? "user" : o.nickname.trim();
  r ? (r.x = t.x, r.y = t.y, r.nickname = i, clearTimeout(r.timeoutId), r.timeoutId = setTimeout(() => {
    Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
  }, od)) : e.sharedMouseTrackerMap[o.editorId] = {
    ...t,
    id: o.editorId,
    nickname: i,
    timeoutId: setTimeout(() => {
      Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
    }, od)
  };
}, yM = I(x.validationIds), gM = ({ doc: e, collections: t }) => {
  const n = y(t).collection("tableEntities"), o = y(t).collection("tableColumnEntities"), r = y(t).collection("indexEntities"), i = y(t).collection("indexColumnEntities"), s = y(t).collection("relationshipEntities"), a = y(t).collection("memoEntities"), l = e.tableIds.filter((d) => !n.selectById(d)), c = e.relationshipIds.filter((d) => !s.selectById(d)), u = e.indexIds.filter((d) => !r.selectById(d)), f = e.memoIds.filter((d) => !a.selectById(d));
  e.tableIds = e.tableIds.filter((d) => !l.includes(d)), e.relationshipIds = e.relationshipIds.filter((d) => !c.includes(d)), e.indexIds = e.indexIds.filter((d) => !u.includes(d)), e.memoIds = e.memoIds.filter((d) => !f.includes(d)), n.selectAll().forEach((d) => {
    const h = d.columnIds.filter((g) => !o.selectById(g)), p = d.seqColumnIds.filter((g) => !o.selectById(g));
    d.columnIds = d.columnIds.filter((g) => !h.includes(g)), d.seqColumnIds = d.seqColumnIds.filter((g) => !p.includes(g));
  }), r.selectAll().forEach((d) => {
    const h = d.indexColumnIds.filter((g) => !i.selectById(g)), p = d.seqIndexColumnIds.filter((g) => !i.selectById(g));
    d.indexColumnIds = d.indexColumnIds.filter((g) => !h.includes(g)), d.seqIndexColumnIds = d.seqIndexColumnIds.filter((g) => !p.includes(g));
  });
}, vM = {
  [x.changeHasHistory]: RN,
  [x.selectAll]: PN,
  [x.unselectAll]: BN,
  [x.select]: jN,
  [x.changeViewport]: WN,
  [x.clear]: FN,
  [x.loadJson]: qN,
  [x.initialClear]: HN,
  [x.initialLoadJson]: VN,
  [x.focusTable]: ZN,
  [x.focusColumn]: zN,
  [x.focusTableEnd]: GN,
  [x.focusMoveTable]: YN,
  [x.editTable]: QN,
  [x.editTableEnd]: XN,
  [x.selectAllColumn]: tM,
  [x.drawStartRelationship]: nM,
  [x.drawStartAddRelationship]: oM,
  [x.drawEndRelationship]: rM,
  [x.drawRelationship]: sM,
  [x.hoverColumnMap]: lM,
  [x.changeOpenMap]: uM,
  [x.dragstartColumn]: fM,
  [x.dragendColumn]: hM,
  [x.sharedMouseTracker]: pM,
  [x.validationIds]: gM
}, bM = {
  changeHasHistoryAction: LN,
  selectAllAction: _N,
  unselectAllAction: ja,
  selectAction: Xo,
  changeViewportAction: UN,
  clearAction: tu,
  loadJsonAction: an,
  initialClearAction: qp,
  initialLoadJsonAction: Po,
  focusTableAction: Ua,
  focusColumnAction: ln,
  focusTableEndAction: nu,
  focusMoveTableAction: Hp,
  editTableAction: KN,
  editTableEndAction: JN,
  selectAllColumnAction: eM,
  drawStartRelationshipAction: Vp,
  drawStartAddRelationshipAction: Zp,
  drawEndRelationshipAction: ou,
  drawRelationshipAction: iM,
  hoverColumnMapAction: aM,
  changeOpenMapAction: cM,
  dragstartColumnAction: ru,
  dragendColumnAction: dM,
  sharedMouseTrackerAction: mM,
  validationIdsAction: yM
}, IM = (e, t, n) => {
  e.push(tu(), an({ value: Ic(n) }));
}, TM = (e, t, n) => {
  e.push(an({ value: Ic(n) }));
}, wM = {
  [x.loadJson]: IM,
  [x.clear]: TM
}, Ze = {
  addIndex: "index.add",
  removeIndex: "index.remove",
  changeIndexName: "index.changeName",
  changeIndexUnique: "index.changeUnique"
};
var CB = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function CM(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var OM = function(t) {
  return EM(t) && !SM(t);
};
function EM(e) {
  return !!e && typeof e == "object";
}
function SM(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object RegExp]" || t === "[object Date]" || $M(e);
}
var xM = typeof Symbol == "function" && Symbol.for, AM = xM ? Symbol.for("react.element") : 60103;
function $M(e) {
  return e.$$typeof === AM;
}
function NM(e) {
  return Array.isArray(e) ? [] : {};
}
function ri(e, t) {
  return t.clone !== !1 && t.isMergeableObject(e) ? Bo(NM(e), e, t) : e;
}
function MM(e, t, n) {
  return e.concat(t).map(function(o) {
    return ri(o, n);
  });
}
function kM(e, t) {
  if (!t.customMerge)
    return Bo;
  var n = t.customMerge(e);
  return typeof n == "function" ? n : Bo;
}
function DM(e) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(e).filter(function(t) {
    return Object.propertyIsEnumerable.call(e, t);
  }) : [];
}
function rd(e) {
  return Object.keys(e).concat(DM(e));
}
function zp(e, t) {
  try {
    return t in e;
  } catch {
    return !1;
  }
}
function LM(e, t) {
  return zp(e, t) && !(Object.hasOwnProperty.call(e, t) && Object.propertyIsEnumerable.call(e, t));
}
function RM(e, t, n) {
  var o = {};
  return n.isMergeableObject(e) && rd(e).forEach(function(r) {
    o[r] = ri(e[r], n);
  }), rd(t).forEach(function(r) {
    LM(e, r) || (zp(e, r) && n.isMergeableObject(t[r]) ? o[r] = kM(r, n)(e[r], t[r], n) : o[r] = ri(t[r], n));
  }), o;
}
function Bo(e, t, n) {
  n = n || {}, n.arrayMerge = n.arrayMerge || MM, n.isMergeableObject = n.isMergeableObject || OM, n.cloneUnlessOtherwiseSpecified = ri;
  var o = Array.isArray(t), r = Array.isArray(e), i = o === r;
  return i ? o ? n.arrayMerge(e, t, n) : RM(e, t, n) : ri(t, n);
}
Bo.all = function(t, n) {
  if (!Array.isArray(t))
    throw new Error("first argument should be an array");
  return t.reduce(function(o, r) {
    return Bo(o, r, n);
  }, {});
};
var _M = Bo, PM = _M;
const er = /* @__PURE__ */ CM(PM), Kn = (e) => er({
  id: me(),
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Qo()
}, e ?? {}), iu = I(Ze.addIndex), BM = ({ doc: e, collections: t, lww: n }, { payload: { id: o, tableId: r }, timestamp: i }) => {
  y(t).collection("indexEntities").addOne(Kn({ id: o, tableId: r })).addOperator(n, i, o, () => {
    M(e.indexIds)(o) || e.indexIds.push(o);
  });
}, ii = I(Ze.removeIndex), jM = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("indexEntities").removeOperator(n, r, o, () => {
    const i = e.indexIds.indexOf(o);
    i !== -1 && e.indexIds.splice(i, 1);
  });
}, Gp = I(Ze.changeIndexName), UM = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: i }) => {
  const s = y(e).collection("indexEntities");
  s.getOrCreate(n, (a) => Kn({ id: a, tableId: o })), s.replaceOperator(t, i, n, "name", () => {
    s.updateOne(n, (a) => {
      a.name = r;
    });
  });
}, su = I(Ze.changeIndexUnique), WM = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: i }) => {
  const s = y(e).collection("indexEntities");
  s.getOrCreate(n, (a) => Kn({ id: a, tableId: o })), s.replaceOperator(t, i, n, "unique", () => {
    s.updateOne(n, (a) => {
      a.unique = r;
    });
  });
}, FM = {
  [Ze.addIndex]: BM,
  [Ze.removeIndex]: jM,
  [Ze.changeIndexName]: UM,
  [Ze.changeIndexUnique]: WM
}, qM = {
  addIndexAction: iu,
  removeIndexAction: ii,
  changeIndexNameAction: Gp,
  changeIndexUniqueAction: su
}, HM = (e, { payload: { id: t } }) => {
  e.push(ii({ id: t }));
}, VM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("indexEntities").selectById(t);
  o && e.push(iu({
    id: t,
    tableId: o.tableId
  }));
}, ZM = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("indexEntities").selectById(t);
  r && e.push(Gp({
    id: t,
    tableId: n,
    value: r.name
  }));
}, zM = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("indexEntities").selectById(t) && e.push(su({
    id: t,
    tableId: n,
    value: !o
  }));
}, GM = {
  [Ze.addIndex]: HM,
  [Ze.removeIndex]: VM,
  [Ze.changeIndexName]: ZM,
  [Ze.changeIndexUnique]: zM
}, ze = {
  addIndexColumn: "indexColumn.add",
  removeIndexColumn: "indexColumn.remove",
  moveIndexColumn: "indexColumn.move",
  changeIndexColumnOrderType: "indexColumn.changeOrderType"
}, au = (e) => er({
  id: me(),
  indexId: "",
  columnId: "",
  orderType: Eo.ASC,
  meta: Qo()
}, e ?? {});
function Yp(e, t, n) {
  if (M(t)(n)) {
    const r = t.reduce((i, s, a) => (i[s] = a, i), {});
    e.push(n), e.sort((i, s) => {
      const a = r[i], l = r[s];
      return a === void 0 ? 1 : l === void 0 ? -1 : a - l;
    });
  } else
    e.push(n), t.push(n);
}
const ia = I(ze.addIndexColumn), YM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r, columnId: i }, timestamp: s }) => {
  const a = y(e).collection("indexEntities"), l = a.getOrCreate(o, (c) => Kn({ id: c, tableId: r }));
  y(e).collection("indexColumnEntities").addOne(au({ id: n, indexId: o, columnId: i })).addOperator(t, s, n, () => {
    M(l.indexColumnIds)(n) || a.updateOne(o, (c) => {
      Yp(c.indexColumnIds, c.seqIndexColumnIds, n);
    });
  });
}, lu = I(ze.removeIndexColumn), KM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r }, timestamp: i }) => {
  const s = y(e).collection("indexEntities"), a = s.getOrCreate(o, (l) => Kn({ id: l, tableId: r }));
  y(e).collection("indexColumnEntities").removeOperator(t, i, n, () => {
    const l = a.indexColumnIds.indexOf(n);
    l !== -1 && s.updateOne(o, (c) => {
      c.indexColumnIds.splice(l, 1);
    });
  });
}, cu = I(ze.moveIndexColumn), QM = ({ collections: e }, { payload: { id: t, indexId: n, tableId: o, targetId: r } }) => {
  if (t === r)
    return;
  const i = y(e).collection("indexEntities"), s = i.getOrCreate(n, (c) => Kn({ id: c, tableId: o })), a = s.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = s.indexColumnIds.indexOf(r);
  l !== -1 && i.updateOne(n, (c) => {
    c.indexColumnIds.splice(a, 1), c.indexColumnIds.splice(l, 0, t);
    const u = c.seqIndexColumnIds.indexOf(t), f = c.seqIndexColumnIds.indexOf(r);
    u !== -1 && f !== -1 && (c.seqIndexColumnIds.splice(u, 1), c.seqIndexColumnIds.splice(f, 0, t));
  });
}, uu = I(ze.changeIndexColumnOrderType), JM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, columnId: r, value: i }, timestamp: s }) => {
  const a = y(e).collection("indexColumnEntities");
  a.getOrCreate(n, (l) => au({ id: l, indexId: o, columnId: r })), a.replaceOperator(t, s, n, "orderType", () => {
    a.updateOne(n, (l) => {
      l.orderType = i;
    });
  });
}, XM = {
  [ze.addIndexColumn]: YM,
  [ze.removeIndexColumn]: KM,
  [ze.moveIndexColumn]: QM,
  [ze.changeIndexColumnOrderType]: JM
}, ek = {
  addIndexColumnAction: ia,
  removeIndexColumnAction: lu,
  moveIndexColumnAction: cu,
  changeIndexColumnOrderTypeAction: uu
}, tk = (e, { payload: { id: t, indexId: n, tableId: o } }) => {
  e.push(lu({ id: t, indexId: n, tableId: o }));
}, nk = (e, { payload: { id: t, indexId: n, tableId: o } }, { collections: r }) => {
  const i = y(r).collection("indexColumnEntities").selectById(t);
  i && e.push(ia({
    id: t,
    indexId: n,
    tableId: o,
    columnId: i.columnId
  }));
}, ok = (e, { payload: { id: t, indexId: n, tableId: o, targetId: r } }, { collections: i }) => {
  const s = y(i).collection("indexEntities").selectById(n);
  if (!s)
    return;
  const a = s.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = s.indexColumnIds.indexOf(r);
  if (l === -1)
    return;
  const c = a < l ? a + 1 : a - 1, u = s.indexColumnIds[c];
  e.push(cu({
    indexId: n,
    tableId: o,
    id: t,
    targetId: u
  }));
}, rk = (e, { payload: { id: t, indexId: n, columnId: o } }, { collections: r }) => {
  const i = y(r).collection("indexColumnEntities").selectById(t);
  i && e.push(uu({
    id: t,
    indexId: n,
    columnId: o,
    value: i.orderType
  }));
}, ik = {
  [ze.addIndexColumn]: tk,
  [ze.removeIndexColumn]: nk,
  [ze.moveIndexColumn]: ok,
  [ze.changeIndexColumnOrderType]: rk
}, oe = {
  addMemo: "memo.add",
  moveMemo: "memo.move",
  moveToMemo: "memo.moveTo",
  removeMemo: "memo.remove",
  changeMemoValue: "memo.changeValue",
  changeMemoColor: "memo.changeColor",
  resizeMemo: "memo.resize",
  changeZIndex: "memo.changeZIndex"
}, Qn = (e) => er({
  id: me(),
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: mN,
    height: pN,
    color: ""
  },
  meta: Qo()
}, e ?? {}), fu = I(oe.addMemo), sk = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: i }) => {
  y(t).collection("memoEntities").addOne(Qn({ id: o, ui: r })).addOperator(n, i, o, () => {
    M(e.memoIds)(o) || e.memoIds.push(o);
  });
}, So = I(oe.moveMemo), ak = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("memoEntities");
  for (const i of t)
    r.getOrCreate(i, (s) => Qn({ id: s }));
  r.updateMany(t, (i) => {
    i.ui.x = ht(i.ui.x + n, 4), i.ui.y = ht(i.ui.y + o, 4);
  });
}, Kp = I(oe.moveToMemo), lk = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("memoEntities");
  r.getOrCreate(t, (i) => Qn({ id: i })), r.updateOne(t, (i) => {
    i.ui.x = n, i.ui.y = o;
  });
}, sa = I(oe.removeMemo), ck = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("memoEntities").removeOperator(n, r, o, () => {
    const i = e.memoIds.indexOf(o);
    i !== -1 && e.memoIds.splice(i, 1);
  });
}, Qp = I(oe.changeMemoValue), uk = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("memoEntities");
  i.getOrCreate(n, (s) => Qn({ id: s })), i.replaceOperator(t, r, n, "value", () => {
    i.updateOne(n, (s) => {
      s.value = o;
    });
  });
}, Hr = I(oe.changeMemoColor), fk = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const i = y(e).collection("memoEntities");
  i.getOrCreate(n, (s) => Qn({ id: s })), i.replaceOperator(t, r, n, "ui.color", () => {
    i.updateOne(n, (s) => {
      s.ui.color = o;
    });
  });
}, Jp = I(oe.resizeMemo), dk = ({ collections: e }, { payload: { id: t, x: n, y: o, width: r, height: i } }) => {
  const s = y(e).collection("memoEntities");
  s.getOrCreate(t, (a) => Qn({ id: a })), s.updateOne(t, (a) => {
    a.ui.x = n, a.ui.y = o, a.ui.width = r, a.ui.height = i;
  });
}, Xp = I(oe.changeZIndex), hk = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("memoEntities");
  o.getOrCreate(t, (r) => Qn({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, mk = {
  [oe.addMemo]: sk,
  [oe.moveMemo]: ak,
  [oe.moveToMemo]: lk,
  [oe.removeMemo]: ck,
  [oe.changeMemoValue]: uk,
  [oe.changeMemoColor]: fk,
  [oe.resizeMemo]: dk,
  [oe.changeZIndex]: hk
}, pk = {
  addMemoAction: fu,
  moveMemoAction: So,
  moveToMemoAction: Kp,
  removeMemoAction: sa,
  changeMemoValueAction: Qp,
  changeMemoColorAction: Hr,
  resizeMemoAction: Jp,
  changeZIndexAction: Xp
}, yk = 20, gk = (e, { payload: { id: t } }) => {
  e.push(sa({ id: t }));
}, vk = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(fu({ id: o.id, ui: ea(o.ui, ["x", "y", "zIndex"]) }));
}, bk = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(Qp({ id: t, value: o.value }));
}, Ik = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(Kp({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, Tk = {
  [oe.addMemo]: gk,
  [oe.removeMemo]: vk,
  [oe.changeMemoValue]: bk,
  [oe.moveToMemo]: Ik
}, wk = (e, t, n) => {
  const o = n.filter((i) => i.type === So.type);
  if (!o.length)
    return;
  const r = Ei(o, (i) => i.payload.ids.join(","));
  for (const [, i] of Object.entries(r)) {
    const { payload: { ids: s } } = Oi(i), { x: a, y: l } = i.reduce((c, { payload: { movementX: u, movementY: f } }) => (c.x += u, c.y += f, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < yk || (e.push(So({
      ids: s,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(So({
      ids: s,
      movementX: a,
      movementY: l
    })));
  }
}, Ck = (e, t, n) => {
  const o = n.filter(({ type: i }) => i === Hr.type);
  if (!o.length)
    return;
  const r = Ei(o, (i) => i.payload.id);
  for (const [i, s] of Object.entries(r)) {
    const a = Oi(s), l = Ko(s);
    e.push(Hr({
      id: i,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(Hr({
      id: i,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, Ok = (e, t, n) => {
  const o = n.filter((i) => i.type === Jp.type);
  if (!o.length)
    return;
  const r = Ei(o, (i) => i.payload.id);
  for (const [, i] of Object.entries(r)) {
    if (i.length < 2)
      continue;
    const s = Oi(i), a = Ko(i);
    e.push(s), t.push(a);
  }
}, Ek = {
  [oe.moveMemo]: wk,
  [oe.changeMemoColor]: Ck,
  [oe.resizeMemo]: Ok
}, Dt = {
  addRelationship: "relationship.add",
  removeRelationship: "relationship.remove",
  changeRelationshipType: "relationship.changeType"
}, ey = (e) => er({
  id: me(),
  identification: !1,
  relationshipType: wp.ZeroN,
  startRelationshipType: Hl.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: wt.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: wt.bottom
  },
  meta: Qo()
}, e ?? {}), Ai = I(Dt.addRelationship), Sk = ({ doc: e, collections: t, lww: n }, { payload: { id: o, relationshipType: r, start: i, end: s }, timestamp: a }) => {
  y(t).collection("relationshipEntities").addOne(ey({
    id: o,
    relationshipType: r,
    start: {
      tableId: i.tableId,
      columnIds: i.columnIds
    },
    end: {
      tableId: s.tableId,
      columnIds: s.columnIds
    }
  })).addOperator(n, a, o, () => {
    M(e.relationshipIds)(o) || e.relationshipIds.push(o);
  });
}, jo = I(Dt.removeRelationship), xk = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("relationshipEntities").removeOperator(n, r, o, () => {
    const i = e.relationshipIds.indexOf(o);
    i !== -1 && e.relationshipIds.splice(i, 1);
  });
}, ty = I(Dt.changeRelationshipType), Ak = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("relationshipEntities");
  i.replaceOperator(t, r, n, "relationshipType", () => {
    i.updateOne(n, (s) => {
      s.relationshipType = o;
    });
  });
}, $k = {
  [Dt.addRelationship]: Sk,
  [Dt.removeRelationship]: xk,
  [Dt.changeRelationshipType]: Ak
}, Nk = {
  addRelationshipAction: Ai,
  removeRelationshipAction: jo,
  changeRelationshipTypeAction: ty
}, Mk = (e, { payload: { id: t } }) => {
  e.push(jo({ id: t }));
}, kk = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(Ai({
    id: o.id,
    relationshipType: o.relationshipType,
    start: ea(o.start, ["tableId", "columnIds"]),
    end: ea(o.end, ["tableId", "columnIds"])
  }));
}, Dk = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(ty({
    id: t,
    value: o.relationshipType
  }));
}, Lk = {
  [Dt.addRelationship]: Mk,
  [Dt.removeRelationship]: kk,
  [Dt.changeRelationshipType]: Dk
}, R = {
  changeDatabaseName: "settings.changeDatabaseName",
  resize: "settings.resize",
  changeZoomLevel: "settings.changeZoomLevel",
  streamZoomLevel: "settings.streamZoomLevel",
  scrollTo: "settings.scrollTo",
  streamScrollTo: "settings.streamScrollTo",
  changeShow: "settings.changeShow",
  changeDatabase: "settings.changeDatabase",
  changeCanvasType: "settings.changeCanvasType",
  changeLanguage: "settings.changeLanguage",
  changeTableNameCase: "settings.changeTableNameCase",
  changeColumnNameCase: "settings.changeColumnNameCase",
  changeBracketType: "settings.changeBracketType",
  changeRelationshipDataTypeSync: "settings.changeRelationshipDataTypeSync",
  changeRelationshipOptimization: "settings.changeRelationshipOptimization",
  changeColumnOrder: "settings.changeColumnOrder",
  changeMaxWidthComment: "settings.changeMaxWidthComment",
  changeIgnoreSaveSettings: "settings.changeIgnoreSaveSettings"
}, Rk = I(R.changeDatabaseName), _k = ({ settings: e, lww: t }, { payload: { value: n }, timestamp: o }) => {
  ph(t, o, "settings.databaseName", "settings", "databaseName", () => {
    e.databaseName = n;
  });
}, ny = I(R.resize), Pk = ({ settings: e }, { payload: { width: t, height: n } }) => {
  e.width = Zl(t), e.height = Zl(n);
}, du = I(R.changeZoomLevel), Bk = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !zo(n) && $(n, Yn.following) || (e.zoomLevel = _a(t));
}, Vr = I(R.streamZoomLevel), jk = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !zo(n) && $(n, Yn.following) || (e.zoomLevel = _a(e.zoomLevel + t));
}, hu = I(R.scrollTo), Uk = ({ settings: e, editor: { viewport: t } }, { payload: { scrollTop: n, scrollLeft: o }, tags: r }) => {
  if (!zo(r) && $(r, Yn.following))
    return;
  const i = jn(t.height - e.height, 0), s = jn(t.width - e.width, 0);
  e.scrollTop = ht(i(n), 4), e.scrollLeft = ht(s(o), 4);
}, Zr = I(R.streamScrollTo), Wk = ({ settings: e, editor: { viewport: t } }, { payload: { movementX: n, movementY: o }, tags: r }) => {
  if (!zo(r) && $(r, Yn.following))
    return;
  const i = jn(t.height - e.height, 0), s = jn(t.width - e.width, 0);
  e.scrollTop = ht(i(e.scrollTop + o), 4), e.scrollLeft = ht(s(e.scrollLeft + n), 4);
}, mu = I(R.changeShow), Fk = ({ settings: e }, { payload: { show: t, value: n } }) => {
  e.show = n ? e.show | t : e.show & ~t;
}, qk = I(R.changeDatabase), Hk = ({ settings: e }, { payload: { value: t } }) => {
  IN(t) && (e.database = t);
}, Vk = I(R.changeCanvasType), Zk = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !zo(n) && $(n, Yn.following) || (e.canvasType = t);
}, zk = I(R.changeLanguage), Gk = ({ settings: e }, { payload: { value: t } }) => {
  wN(t) && (e.language = t);
}, Yk = I(R.changeTableNameCase), Kk = ({ settings: e }, { payload: { value: t } }) => {
  Rp(t) && (e.tableNameCase = t);
}, Qk = I(R.changeColumnNameCase), Jk = ({ settings: e }, { payload: { value: t } }) => {
  Rp(t) && (e.columnNameCase = t);
}, Xk = I(R.changeBracketType), e2 = ({ settings: e }, { payload: { value: t } }) => {
  TN(t) && (e.bracketType = t);
}, t2 = I(R.changeRelationshipDataTypeSync), n2 = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipDataTypeSync = t;
}, o2 = I(R.changeRelationshipOptimization), r2 = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipOptimization = t;
}, i2 = I(R.changeColumnOrder), s2 = ({ settings: e }, { payload: { value: t, target: n } }) => {
  if (t === n || !Qf(t) || !Qf(n))
    return;
  const o = e.columnOrder.indexOf(t), r = e.columnOrder.indexOf(n);
  o === -1 || r === -1 || (e.columnOrder.splice(o, 1), e.columnOrder.splice(r, 0, t));
}, oy = I(R.changeMaxWidthComment), a2 = ({ settings: e }, { payload: { value: t } }) => {
  e.maxWidthComment = t === -1 ? t : Lp(t);
}, l2 = I(R.changeIgnoreSaveSettings), c2 = ({ settings: e }, { payload: { saveSettingType: t, value: n } }) => {
  e.ignoreSaveSettings = n ? e.ignoreSaveSettings | t : e.ignoreSaveSettings & ~t;
}, u2 = {
  [R.changeDatabaseName]: _k,
  [R.resize]: Pk,
  [R.changeZoomLevel]: Bk,
  [R.streamZoomLevel]: jk,
  [R.scrollTo]: Uk,
  [R.streamScrollTo]: Wk,
  [R.changeShow]: Fk,
  [R.changeDatabase]: Hk,
  [R.changeCanvasType]: Zk,
  [R.changeLanguage]: Gk,
  [R.changeTableNameCase]: Kk,
  [R.changeColumnNameCase]: Jk,
  [R.changeBracketType]: e2,
  [R.changeRelationshipDataTypeSync]: n2,
  [R.changeRelationshipOptimization]: r2,
  [R.changeColumnOrder]: s2,
  [R.changeMaxWidthComment]: a2,
  [R.changeIgnoreSaveSettings]: c2
}, f2 = {
  changeDatabaseNameAction: Rk,
  resizeAction: ny,
  changeZoomLevelAction: du,
  streamZoomLevelAction: Vr,
  scrollToAction: hu,
  streamScrollToAction: Zr,
  changeShowAction: mu,
  changeDatabaseAction: qk,
  changeCanvasTypeAction: Vk,
  changeLanguageAction: zk,
  changeTableNameCaseAction: Yk,
  changeColumnNameCaseAction: Qk,
  changeBracketTypeAction: Xk,
  changeRelationshipDataTypeSyncAction: t2,
  changeRelationshipOptimizationAction: o2,
  changeColumnOrderAction: i2,
  changeMaxWidthCommentAction: oy,
  changeIgnoreSaveSettingsAction: l2
}, d2 = 20, h2 = (e, t, { settings: n }) => {
  e.push(ny({ width: n.width, height: n.height }));
}, m2 = (e, t, { settings: n }) => {
  e.push(hu({
    scrollLeft: n.scrollLeft,
    scrollTop: n.scrollTop
  }));
}, p2 = (e, { payload: { show: t, value: n } }) => {
  e.push(mu({ show: t, value: !n }));
}, y2 = (e, t, { settings: n }) => {
  e.push(du({ value: n.zoomLevel }));
}, g2 = {
  [R.resize]: h2,
  [R.scrollTo]: m2,
  [R.changeShow]: p2,
  [R.changeZoomLevel]: y2
}, v2 = (e, t, n) => {
  const o = n.filter((s) => s.type === Zr.type);
  if (!o.length)
    return;
  const { x: r, y: i } = o.reduce((s, { payload: { movementX: a, movementY: l } }) => (s.x += a, s.y += l, s), { x: 0, y: 0 });
  Math.abs(r) + Math.abs(i) < d2 || (e.push(Zr({
    movementX: -1 * r,
    movementY: -1 * i
  })), t.push(Zr({
    movementX: r,
    movementY: i
  })));
}, b2 = (e, t, n) => {
  const o = n.filter((i) => i.type === Vr.type);
  if (!o.length)
    return;
  const r = o.reduce((i, { payload: { value: s } }) => i + s, 0);
  e.push(Vr({ value: -1 * r })), t.push(Vr({ value: r }));
}, I2 = {
  [R.streamScrollTo]: v2,
  [R.streamZoomLevel]: b2
}, Q = {
  addTable: "table.add",
  moveTable: "table.move",
  moveToTable: "table.moveTo",
  removeTable: "table.remove",
  changeTableName: "table.changeName",
  changeTableComment: "table.changeComment",
  changeTableColor: "table.changeColor",
  changeZIndex: "table.changeZIndex",
  sortTable: "table.sort"
};
function pu(e, { settings: { show: t, maxWidthComment: n }, collections: o }) {
  let r = e.ui.widthName + kn;
  if ($(t, z.tableComment)) {
    const l = n === -1 ? e.ui.widthComment : n < e.ui.widthComment ? n : e.ui.widthComment;
    r += l + kn;
  }
  const i = w2(t);
  r < i && (r = i);
  const s = y(o).collection("tableColumnEntities").selectByIds(e.columnIds), a = C2(s, t, n);
  return r < a.width && (r = a.width), {
    ...a,
    width: ta + na + r + na + ta
  };
}
const T2 = [
  {
    key: z.columnComment,
    width: Ve
  },
  {
    key: z.columnDataType,
    width: Ve
  },
  {
    key: z.columnDefault,
    width: Ve
  },
  {
    key: z.columnNotNull,
    width: $p
  },
  {
    key: z.columnAutoIncrement,
    width: Mp
  },
  {
    key: z.columnUnique,
    width: Np
  }
];
function w2(e) {
  return T2.reduce((t, { key: n, width: o }) => $(e, n) ? t + o + kn : t, Ap + kn + Ve + kn + xp);
}
function C2(e, t, n) {
  const o = {
    width: 0,
    name: 0,
    comment: 0,
    dataType: 0,
    default: 0,
    notNull: 0,
    autoIncrement: 0,
    unique: 0
  };
  for (const r of e) {
    if (o.name < r.ui.widthName && (o.name = r.ui.widthName), $(t, z.columnComment) && o.comment < r.ui.widthComment) {
      const i = n === -1 ? r.ui.widthComment : n < r.ui.widthComment ? n : r.ui.widthComment;
      o.comment = i;
    }
    $(t, z.columnDataType) && o.dataType < r.ui.widthDataType && (o.dataType = r.ui.widthDataType), $(t, z.columnDefault) && o.default < r.ui.widthDefault && (o.default = r.ui.widthDefault);
  }
  return $(t, z.columnNotNull) && (o.notNull = $p), $(t, z.columnAutoIncrement) && (o.autoIncrement = Mp), $(t, z.columnUnique) && (o.unique = Np), o.width = Object.entries(o).reduce((r, [i, s]) => i === "width" || s === 0 ? r : r + s + kn, Ap + kn + xp), o;
}
function yu(e) {
  return ta + na + fN + e.columnIds.length * hN + na + ta;
}
function O2({ doc: { tableIds: e }, collections: t }, { toWidth: n }) {
  const o = y(t).collection("tableEntities").selectByIds(e), r = y(t).collection("tableColumnEntities");
  for (const i of o) {
    i.ui.widthName = ye(n(i.name)), i.ui.widthComment = ye(n(i.comment));
    const s = r.selectByIds(i.columnIds);
    for (const a of s)
      a.ui.widthName = ye(n(a.name)), a.ui.widthDataType = ye(n(a.dataType)), a.ui.widthDefault = ye(n(a.default)), a.ui.widthComment = ye(n(a.comment));
  }
}
const yt = (e) => er({
  id: me(),
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: Ve,
    widthComment: Ve,
    color: ""
  },
  meta: Qo()
}, e ?? {}), gu = I(Q.addTable), E2 = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: i }) => {
  y(t).collection("tableEntities").addOne(yt({ id: o, ui: r })).addOperator(n, i, o, () => {
    M(e.tableIds)(o) || e.tableIds.push(o);
  });
}, xo = I(Q.moveTable), S2 = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("tableEntities");
  for (const i of t)
    r.getOrCreate(i, (s) => yt({ id: s }));
  r.updateMany(t, (i) => {
    i.ui.x = ht(i.ui.x + n, 4), i.ui.y = ht(i.ui.y + o, 4);
  });
}, vu = I(Q.moveToTable), x2 = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("tableEntities");
  r.getOrCreate(t, (i) => yt({ id: i })), r.updateOne(t, (i) => {
    i.ui.x = n, i.ui.y = o;
  });
}, aa = I(Q.removeTable), A2 = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("tableEntities").removeOperator(n, r, o, () => {
    const i = e.tableIds.indexOf(o);
    i !== -1 && e.tableIds.splice(i, 1);
  });
}, bu = I(Q.changeTableName), $2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableEntities");
  s.getOrCreate(n, (a) => yt({ id: a })), s.replaceOperator(t, r, n, "name", () => {
    s.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ye(i(o));
    });
  });
}, Iu = I(Q.changeTableComment), N2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableEntities");
  s.getOrCreate(n, (a) => yt({ id: a })), s.replaceOperator(t, r, n, "comment", () => {
    s.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ye(i(o));
    });
  });
}, zr = I(Q.changeTableColor), M2 = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const i = y(e).collection("tableEntities");
  i.getOrCreate(n, (s) => yt({ id: s })), i.replaceOperator(t, r, n, "ui.color", () => {
    i.updateOne(n, (s) => {
      s.ui.color = o;
    });
  });
}, ry = I(Q.changeZIndex), k2 = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("tableEntities");
  o.getOrCreate(t, (r) => yt({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, Tu = I(Q.sortTable), D2 = (e) => {
  const { doc: t, settings: n, collections: o } = e, r = n.width, i = y(o).collection("tableEntities").selectByIds(t.tableIds), s = 80;
  i.sort((u, f) => u.columnIds.length - f.columnIds.length);
  let a = 50, l = 50, c = 50;
  i.forEach((u) => {
    const f = pu(u, e).width + s, d = yu(u) + s;
    a + f > r && (l += c, c = 0, a = 50), c < d && (c = d), u.ui.y = l, u.ui.x = a, a += f;
  });
}, L2 = {
  [Q.addTable]: E2,
  [Q.moveTable]: S2,
  [Q.moveToTable]: x2,
  [Q.removeTable]: A2,
  [Q.changeTableName]: $2,
  [Q.changeTableComment]: N2,
  [Q.changeTableColor]: M2,
  [Q.changeZIndex]: k2,
  [Q.sortTable]: D2
}, R2 = {
  addTableAction: gu,
  moveTableAction: xo,
  moveToTableAction: vu,
  removeTableAction: aa,
  changeTableNameAction: bu,
  changeTableCommentAction: Iu,
  changeTableColorAction: zr,
  changeZIndexAction: ry,
  sortTableAction: Tu
}, _2 = 20, P2 = (e, { payload: { id: t } }) => {
  e.push(aa({ id: t }));
}, B2 = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(gu({ id: o.id, ui: ea(o.ui, ["x", "y", "zIndex"]) }));
}, j2 = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(bu({ id: t, value: o.name }));
}, U2 = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(Iu({ id: t, value: o.comment }));
}, W2 = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(vu({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, F2 = () => {
}, q2 = {
  [Q.addTable]: P2,
  [Q.removeTable]: B2,
  [Q.changeTableName]: j2,
  [Q.changeTableComment]: U2,
  [Q.moveToTable]: W2,
  [Q.sortTable]: F2
}, H2 = (e, t, n) => {
  const o = n.filter((i) => i.type === xo.type);
  if (!o.length)
    return;
  const r = Ei(o, (i) => i.payload.ids.join(","));
  for (const [, i] of Object.entries(r)) {
    const { payload: { ids: s } } = Oi(i), { x: a, y: l } = i.reduce((c, { payload: { movementX: u, movementY: f } }) => (c.x += u, c.y += f, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < _2 || (e.push(xo({
      ids: s,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(xo({
      ids: s,
      movementX: a,
      movementY: l
    })));
  }
}, V2 = (e, t, n) => {
  const o = n.filter(({ type: i }) => i === zr.type);
  if (!o.length)
    return;
  const r = Ei(o, (i) => i.payload.id);
  for (const [i, s] of Object.entries(r)) {
    const a = Oi(s), l = Ko(s);
    e.push(zr({
      id: i,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(zr({
      id: i,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, Z2 = {
  [Q.moveTable]: H2,
  [Q.changeTableColor]: V2
}, F = {
  addColumn: "column.add",
  removeColumn: "column.remove",
  changeColumnName: "column.changeName",
  changeColumnComment: "column.changeComment",
  changeColumnDataType: "column.changeDataType",
  changeColumnDefault: "column.changeDefault",
  changeColumnAutoIncrement: "column.changeAutoIncrement",
  changeColumnPrimaryKey: "column.changePrimaryKey",
  changeColumnUnique: "column.changeUnique",
  changeColumnNotNull: "column.changeNotNull",
  moveColumn: "column.move"
}, xt = (e) => er({
  id: me(),
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: Ve,
    widthComment: Ve,
    widthDataType: Ve,
    widthDefault: Ve
  },
  meta: Qo()
}, e ?? {}), St = I(F.addColumn), z2 = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const i = y(e).collection("tableEntities"), s = i.getOrCreate(o, (a) => yt({ id: a }));
  y(e).collection("tableColumnEntities").addOne(xt({ id: n, tableId: o })).addOperator(t, r, n, () => {
    M(s.columnIds)(n) || i.updateOne(o, (a) => {
      Yp(a.columnIds, a.seqColumnIds, n);
    });
  });
}, Ao = I(F.removeColumn), G2 = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const i = y(e).collection("tableEntities"), s = i.getOrCreate(o, (a) => yt({ id: a }));
  y(e).collection("tableColumnEntities").removeOperator(t, r, n, () => {
    const a = s.columnIds.indexOf(n);
    a !== -1 && i.updateOne(o, (l) => {
      l.columnIds.splice(a, 1);
    });
  });
}, Lt = I(F.changeColumnName), Y2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (a) => xt({ id: a })), s.replaceOperator(t, r, n, "name", () => {
    s.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ye(i(o));
    });
  });
}, Rt = I(F.changeColumnComment), K2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (a) => xt({ id: a })), s.replaceOperator(t, r, n, "comment", () => {
    s.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ye(i(o));
    });
  });
}, _t = I(F.changeColumnDataType), Q2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (a) => xt({ id: a })), s.replaceOperator(t, r, n, "dataType", () => {
    s.updateOne(n, (a) => {
      a.dataType = o, a.ui.widthDataType = ye(i(o));
    });
  });
}, Pt = I(F.changeColumnDefault), J2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: i }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (a) => xt({ id: a })), s.replaceOperator(t, r, n, "default", () => {
    s.updateOne(n, (a) => {
      a.default = o, a.ui.widthDefault = ye(i(o));
    });
  });
}, Dn = I(F.changeColumnAutoIncrement), X2 = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (s) => xt({ id: s })), i.replaceOperator(t, r, n, "options(autoIncrement)", () => {
    i.updateOne(n, (s) => {
      s.options = o ? s.options | U.autoIncrement : s.options & ~U.autoIncrement;
    });
  });
}, Jn = I(F.changeColumnPrimaryKey), eD = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (s) => xt({ id: s })), i.replaceOperator(t, r, n, "options(primaryKey)", () => {
    i.updateOne(n, (s) => {
      s.options = o ? s.options | U.primaryKey : s.options & ~U.primaryKey, s.ui.keys = o ? s.ui.keys | Re.primaryKey : s.ui.keys & ~Re.primaryKey;
    });
  });
}, Ln = I(F.changeColumnUnique), tD = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (s) => xt({ id: s })), i.replaceOperator(t, r, n, "options(unique)", () => {
    i.updateOne(n, (s) => {
      s.options = o ? s.options | U.unique : s.options & ~U.unique;
    });
  });
}, Bt = I(F.changeColumnNotNull), nD = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (s) => xt({ id: s })), i.replaceOperator(t, r, n, "options(notNull)", () => {
    i.updateOne(n, (s) => {
      s.options = o ? s.options | U.notNull : s.options & ~U.notNull;
    });
  });
}, la = I(F.moveColumn), oD = ({ collections: e }, { payload: { id: t, tableId: n, targetId: o } }) => {
  if (t === o)
    return;
  const r = y(e).collection("tableEntities"), i = r.getOrCreate(n, (l) => yt({ id: l })), s = i.columnIds.indexOf(t);
  if (s === -1)
    return;
  const a = i.columnIds.indexOf(o);
  a !== -1 && r.updateOne(n, (l) => {
    l.columnIds.splice(s, 1), l.columnIds.splice(a, 0, t);
    const c = l.seqColumnIds.indexOf(t), u = l.seqColumnIds.indexOf(o);
    c !== -1 && u !== -1 && (l.seqColumnIds.splice(c, 1), l.seqColumnIds.splice(u, 0, t));
  });
}, rD = {
  [F.addColumn]: z2,
  [F.removeColumn]: G2,
  [F.changeColumnName]: Y2,
  [F.changeColumnComment]: K2,
  [F.changeColumnDataType]: Q2,
  [F.changeColumnDefault]: J2,
  [F.changeColumnAutoIncrement]: X2,
  [F.changeColumnPrimaryKey]: eD,
  [F.changeColumnUnique]: tD,
  [F.changeColumnNotNull]: nD,
  [F.moveColumn]: oD
}, iD = {
  addColumnAction: St,
  removeColumnAction: Ao,
  changeColumnNameAction: Lt,
  changeColumnCommentAction: Rt,
  changeColumnDataTypeAction: _t,
  changeColumnDefaultAction: Pt,
  changeColumnAutoIncrementAction: Dn,
  changeColumnPrimaryKeyAction: Jn,
  changeColumnUniqueAction: Ln,
  changeColumnNotNullAction: Bt,
  moveColumnAction: la
}, sD = (e, { payload: { id: t, tableId: n } }) => {
  e.push(Ao({ id: t, tableId: n }));
}, aD = (e, { payload: { id: t, tableId: n } }) => {
  e.push(St({ id: t, tableId: n }));
}, lD = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Lt({ id: t, tableId: n, value: r.name }));
}, cD = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(_t({ id: t, tableId: n, value: r.dataType }));
}, uD = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Pt({ id: t, tableId: n, value: r.default }));
}, fD = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Rt({ id: t, tableId: n, value: r.comment }));
}, dD = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Dn({
    id: t,
    tableId: n,
    value: !o
  }));
}, hD = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Bt({
    id: t,
    tableId: n,
    value: !o
  }));
}, mD = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Jn({
    id: t,
    tableId: n,
    value: !o
  }));
}, pD = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Ln({
    id: t,
    tableId: n,
    value: !o
  }));
}, yD = (e, { payload: { id: t, tableId: n, targetId: o } }, { collections: r }) => {
  const i = y(r).collection("tableEntities").selectById(n);
  if (!i)
    return;
  const s = i.columnIds.indexOf(t);
  if (s === -1)
    return;
  const a = i.columnIds.indexOf(o);
  if (a === -1)
    return;
  const l = s < a ? s + 1 : s - 1, c = i.columnIds[l];
  e.push(la({
    id: t,
    tableId: n,
    targetId: c
  }));
}, gD = {
  [F.addColumn]: sD,
  [F.removeColumn]: aD,
  [F.changeColumnName]: lD,
  [F.changeColumnDataType]: cD,
  [F.changeColumnDefault]: uD,
  [F.changeColumnComment]: fD,
  [F.changeColumnAutoIncrement]: dD,
  [F.changeColumnNotNull]: hD,
  [F.changeColumnPrimaryKey]: mD,
  [F.changeColumnUnique]: pD,
  [F.moveColumn]: yD
}, iy = {
  ...q2,
  ...gD,
  ...Lk,
  ...Tk,
  ...g2,
  ...wM,
  ...GM,
  ...ik
}, si = {
  ...Z2,
  ...Ek,
  ...I2
};
function vD(e, t, n) {
  const o = [], r = [];
  for (const i of n) {
    const s = iy[i.type];
    s && (s(o, i, e.state), r.push(Ra(i)));
  }
  for (const i of Object.keys(si))
    si[i](o, r, n);
  !o.length || !r.length || t.push({
    undo: (i) => {
      const s = Date.now();
      i(o.map(id(s)));
    },
    redo: (i) => {
      const s = Date.now();
      i(r.map(id(s)));
    }
  });
}
function id(e) {
  return (t) => ({
    ...Ra(t),
    timestamp: e
  });
}
const OB = (e, t) => (n) => {
  xw(vD, e, t, n);
}, bD = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), i = y(o).collection("memoEntities").selectByIds(n), s = Pp(e, r, i), a = me();
  yield qa(), yield Xo({ [a]: ke.memo }), yield fu({
    id: a,
    ui: {
      ...s,
      zIndex: Pa(r, i)
    }
  });
}, sy = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    yield sa({ id: e });
    return;
  }
  const n = Object.entries(t).filter(([, o]) => o === ke.memo);
  for (const [o] of n)
    yield sa({ id: o });
}, ID = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r }) {
  const i = y(r).collection("tableEntities").selectByIds(n), s = y(r).collection("memoEntities").selectByIds(o);
  t || (yield qa()), yield Xo({ [e]: ke.memo }), yield Xp({ id: e, zIndex: Pa(i, s) });
}, TD = {
  addMemoAction$: bD,
  removeMemoAction$: sy,
  selectMemoAction$: ID
}, wD = {
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, EB = M(Object.values(wD));
function CD(e, t) {
  return t.filter((n) => {
    switch (n) {
      case _.columnName:
        return !0;
      case _.columnDataType:
        return $(e, z.columnDataType);
      case _.columnDefault:
        return $(e, z.columnDefault);
      case _.columnComment:
        return $(e, z.columnComment);
      case _.columnAutoIncrement:
        return $(e, z.columnAutoIncrement);
      case _.columnUnique:
        return $(e, z.columnUnique);
      case _.columnNotNull:
        return $(e, z.columnNotNull);
      default:
        return !1;
    }
  });
}
const OD = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), i = y(o).collection("memoEntities").selectByIds(n), s = Pp(e, r, i), a = me();
  yield ja(), yield Xo({ [a]: ke.table }), yield gu({
    id: a,
    ui: {
      ...s,
      zIndex: Pa(r, i)
    }
  }), yield Ua({ tableId: a });
}, ay = (e) => function* ({ doc: { relationshipIds: t, indexIds: n }, editor: { selectedMap: o }, collections: r }) {
  const i = y(r).collection("relationshipEntities").selectByIds(t), s = y(r).collection("indexEntities").selectByIds(n);
  if (e) {
    const f = i.filter(({ start: h, end: p }) => h.tableId === e || p.tableId === e), d = s.filter(({ tableId: h }) => h === e);
    for (const { id: h } of d)
      yield ii({ id: h });
    for (const { id: h } of f)
      yield jo({ id: h });
    yield aa({ id: e });
    return;
  }
  const a = Object.entries(o).filter(([, f]) => f === ke.table).map(([f]) => f), l = M(a), c = i.filter(({ start: f, end: d }) => l(f.tableId) || l(d.tableId)), u = s.filter(({ tableId: f }) => l(f));
  for (const { id: f } of u)
    yield ii({ id: f });
  for (const { id: f } of c)
    yield jo({ id: f });
  for (const f of a)
    yield aa({ id: f });
}, ED = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r, editor: { drawRelationship: i } }) {
  const s = y(r).collection("tableEntities"), a = s.selectByIds(n), l = y(r).collection("memoEntities").selectByIds(o);
  if (t || (yield ja()), yield Xo({ [e]: ke.table }), yield ry({ id: e, zIndex: Pa(a, l) }), yield Ua({ tableId: e }), !!i)
    if (i.start) {
      const c = s.selectById(i.start.tableId), u = s.selectById(e);
      if (!c || !u)
        return;
      const f = y(r).collection("tableColumnEntities").selectByIds(c.columnIds).filter(({ options: h }) => $(h, U.primaryKey));
      if (!f.length)
        return;
      const d = f.map(() => me());
      for (let h = 0; h < f.length; h++) {
        const p = f[h], v = {
          id: d[h],
          tableId: u.id
        };
        yield [
          St(v),
          Bt({
            ...v,
            value: !0
          }),
          Lt({
            ...v,
            value: p.name
          }),
          _t({
            ...v,
            value: p.dataType
          }),
          Pt({
            ...v,
            value: p.default
          }),
          Rt({
            ...v,
            value: p.comment
          })
        ];
      }
      yield Ai({
        id: me(),
        relationshipType: i.relationshipType,
        start: {
          tableId: c.id,
          columnIds: f.map(({ id: h }) => h)
        },
        end: {
          tableId: u.id,
          columnIds: d
        }
      }), yield ou();
    } else
      yield Ty(e);
}, SD = (e) => function* ({ editor: { selectedMap: t, focusTable: n }, settings: { show: o, columnOrder: r }, collections: i }) {
  const s = n && !Fn(n.focusType), a = Object.entries(t).filter(([, p]) => p === ke.table).map(([p]) => p).filter((p) => s ? p !== (n == null ? void 0 : n.tableId) : !0);
  for (const p of a)
    for (const g of e) {
      const T = {
        id: me(),
        tableId: p
      };
      yield [
        St(T),
        Lt({
          ...T,
          value: g.name
        }),
        _t({
          ...T,
          value: g.dataType
        }),
        Pt({
          ...T,
          value: g.default
        }),
        Rt({
          ...T,
          value: g.comment
        }),
        Bt({
          ...T,
          value: $(g.options, U.notNull)
        }),
        Ln({
          ...T,
          value: $(g.options, U.unique)
        }),
        Dn({
          ...T,
          value: $(g.options, U.autoIncrement)
        })
      ];
    }
  if (!n || !s)
    return;
  const l = y(i).collection("tableEntities").selectById(n.tableId);
  if (!l)
    return;
  const c = l.columnIds.filter(M(n.selectColumnIds));
  if (c.length === 0)
    return;
  const u = l.columnIds.slice(l.columnIds.indexOf(c[c.length - 1]) + 1), f = [...c, ...u], d = CD(o, r);
  yield nu();
  for (let p = 0; p < f.length; p++) {
    const g = f[p], v = e[p];
    if (!v)
      break;
    const T = {
      id: g,
      tableId: l.id
    };
    yield [
      ...d.map((O) => {
        switch (O) {
          case _.columnName:
            return Lt({
              ...T,
              value: v.name
            });
          case _.columnDataType:
            return _t({
              ...T,
              value: v.dataType
            });
          case _.columnDefault:
            return Pt({
              ...T,
              value: v.default
            });
          case _.columnComment:
            return Rt({
              ...T,
              value: v.comment
            });
          case _.columnAutoIncrement:
            return Dn({
              ...T,
              value: $(v.options, U.autoIncrement)
            });
          case _.columnUnique:
            return Ln({
              ...T,
              value: $(v.options, U.unique)
            });
          case _.columnNotNull:
            return Bt({
              ...T,
              value: $(v.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      ln({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  const h = e.slice(f.length);
  for (const p of h) {
    const g = me(), v = {
      id: g,
      tableId: l.id
    };
    yield [
      St(v),
      ...d.map((T) => {
        switch (T) {
          case _.columnName:
            return Lt({
              ...v,
              value: p.name
            });
          case _.columnDataType:
            return _t({
              ...v,
              value: p.dataType
            });
          case _.columnDefault:
            return Pt({
              ...v,
              value: p.default
            });
          case _.columnComment:
            return Rt({
              ...v,
              value: p.comment
            });
          case _.columnAutoIncrement:
            return Dn({
              ...v,
              value: $(p.options, U.autoIncrement)
            });
          case _.columnUnique:
            return Ln({
              ...v,
              value: $(p.options, U.unique)
            });
          case _.columnNotNull:
            return Bt({
              ...v,
              value: $(p.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      ln({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
}, xD = {
  addTableAction$: OD,
  removeTableAction$: ay,
  selectTableAction$: ED,
  pasteTableAction$: SD
};
function ly(e, t, n, o = []) {
  const { doc: { relationshipIds: r }, collections: i } = t, s = e.pop();
  return s && (o.some(({ id: a }) => a === s.id) || (o.push(s), y(i).collection("relationshipEntities").selectByIds(r).forEach(({ start: a, end: l }) => {
    const c = a.columnIds.indexOf(s.id);
    if (c !== -1) {
      const u = l.columnIds[c];
      e.push({
        id: u,
        tableId: l.tableId,
        value: n.value
      });
    } else {
      const u = l.columnIds.indexOf(s.id);
      if (u !== -1) {
        const f = a.columnIds[u];
        e.push({
          id: f,
          tableId: a.tableId,
          value: n.value
        });
      }
    }
  })), ly(e, t, n, o)), o;
}
const AD = M([
  j.columnNotNull,
  j.columnUnique,
  j.columnAutoIncrement
]), $D = M([
  j.columnName,
  j.columnDataType,
  j.columnDefault,
  j.columnComment
]), cy = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    const r = me();
    yield St({
      id: r,
      tableId: e
    }), yield ln({
      tableId: e,
      columnId: r,
      focusType: j.columnName,
      $mod: !1,
      shiftKey: !1
    });
    return;
  }
  const n = Object.entries(t).filter(([, r]) => r === ke.table).map(([r]) => ({ tableId: r, id: me() }));
  for (const r of n)
    yield St(r);
  const o = Ko(n);
  o && (yield ln({
    tableId: o.tableId,
    columnId: o.id,
    focusType: j.columnName,
    $mod: !1,
    shiftKey: !1
  }));
}, uy = (e, t) => function* (n) {
  const { doc: { relationshipIds: o, indexIds: r }, editor: { focusTable: i }, collections: s } = n;
  if (i != null && i.columnId) {
    const u = MN(n, t);
    u ? yield ln({
      tableId: i.tableId,
      columnId: u,
      focusType: i.focusType,
      $mod: !1,
      shiftKey: !1
    }) : yield Ua({
      tableId: i.tableId,
      focusType: j.tableName
    });
  }
  const a = M(t), l = y(s).collection("relationshipEntities").selectByIds(o).filter(({ start: u, end: f }) => u.tableId === e && u.columnIds.some(a) || f.tableId === e && f.columnIds.some(a)), c = y(s).collection("indexEntities").selectByIds(r).filter((u) => u.tableId === e && y(s).collection("indexColumnEntities").selectByIds(u.indexColumnIds).map(({ columnId: f }) => f).some(a));
  for (const { id: u } of c)
    yield ii({ id: u });
  for (const { id: u } of l)
    yield jo({ id: u });
  for (const u of t)
    yield Ao({
      id: u,
      tableId: e
    });
}, ND = (e, t, n) => function* ({ collections: o }) {
  if (!AD(e))
    return;
  const r = y(o).collection("tableColumnEntities").selectById(n);
  if (r)
    switch (e) {
      case j.columnNotNull:
        yield Bt({
          id: n,
          tableId: t,
          value: !$(r.options, U.notNull)
        });
        break;
      case j.columnUnique:
        yield Ln({
          id: n,
          tableId: t,
          value: !$(r.options, U.unique)
        });
        break;
      case j.columnAutoIncrement:
        yield Dn({
          id: n,
          tableId: t,
          value: !$(r.options, U.autoIncrement)
        });
        break;
    }
}, fy = (e) => function* (t) {
  const { settings: { relationshipDataTypeSync: n } } = t;
  let o = [e];
  n && (o = ly([e], t, e)), yield o.map(_t);
}, MD = (e, t, n, o) => function* ({ collections: r }) {
  if (!$D(e) || !y(r).collection("tableColumnEntities").selectById(n))
    return;
  const s = {
    id: n,
    tableId: t,
    value: o
  };
  switch (e) {
    case j.columnName:
      yield Lt(s);
      break;
    case j.columnDataType:
      yield fy(s);
      break;
    case j.columnDefault:
      yield Pt(s);
      break;
    case j.columnComment:
      yield Rt(s);
      break;
  }
}, kD = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("tableColumnEntities").selectById(t);
  if (!o)
    return;
  const r = $(o.options, U.primaryKey);
  yield Jn({
    tableId: e,
    id: t,
    value: !r
  });
}, DD = {
  addColumnAction$: cy,
  removeColumnAction$: uy,
  toggleColumnValueAction$: ND,
  changeColumnDataTypeAction$: fy,
  changeColumnValueAction$: MD,
  changeColumnPrimaryKeyAction$: kD
};
function LD(e) {
  return oa + ra + e.ui.width + ra + oa;
}
function RD(e) {
  return oa + ra + kp + e.ui.height + ra + oa;
}
const _D = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FIXED",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "REAL",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], PD = [
  "BIGINT",
  "BINARY",
  "BIT",
  "CHAR",
  "DATE",
  "DATETIME",
  "DATETIME2",
  "DATETIMEOFFSET",
  "DECIMAL",
  "FLOAT",
  "GEOGRAPHY",
  "GEOMETRY",
  "IMAGE",
  "INT",
  "MONEY",
  "NCHAR",
  "NTEXT",
  "NUMERIC",
  "NVARCHAR",
  "REAL",
  "SMALLDATETIME",
  "SMALLINT",
  "SMALLMONEY",
  "SQL_VARIANT",
  "TEXT",
  "TIME",
  "TINYINT",
  "UNIQUEIDENTIFIER",
  "VARBINARY",
  "VARCHAR",
  "XML"
], BD = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], jD = [
  "BFILE",
  "BINARY_DOUBLE",
  "BINARY_FLOAT",
  "BLOB",
  "CHAR",
  "CLOB",
  "DATE",
  "DATETIME",
  "LONG RAW",
  "LONG",
  "NCHAR",
  "NCLOB",
  "NUMBER",
  "NVARCHAR2",
  "RAW",
  "TIMESTAMP WITH LOCAL TIME ZONE",
  "TIMESTAMP WITH TIME ZONE",
  "TIMESTAMP",
  "UriType",
  "VARCHAR",
  "VARCHAR2",
  "XMLType"
], UD = [
  "BIGINT",
  "BIGSERIAL",
  "BIT VARYING",
  "BIT",
  "BOOL",
  "BOOLEAN",
  "BOX",
  "BYTEA",
  "CHAR",
  "CHARACTER VARYING",
  "CHARACTER",
  "CIDR",
  "CIRCLE",
  "DATE",
  "DECIMAL",
  "DOUBLE PRECISION",
  "FLOAT4",
  "FLOAT8",
  "INET",
  "INT",
  "INT2",
  "INT4",
  "INT8",
  "INTEGER",
  "INTERVAL",
  "JSON",
  "JSONB",
  "LINE",
  "LSEG",
  "MACADDR",
  "MACADDR8",
  "MONEY",
  "NUMERIC",
  "PATH",
  "PG_LSN",
  "POINT",
  "POLYGON",
  "REAL",
  "SERIAL",
  "SERIAL2",
  "SERIAL4",
  "SERIAL8",
  "SMALLINT",
  "SMALLSERIAL",
  "TEXT",
  "TIME WITH",
  "TIME",
  "TIMESTAMP WITH",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMETZ",
  "TSQUERY",
  "TSVECTOR",
  "TXID_SNAPSHOT",
  "UUID",
  "VARBIT",
  "VARCHAR",
  "XML"
], WD = [
  "BLOB",
  "INTEGER",
  "NUMERIC",
  "REAL",
  "TEXT"
], ve = {
  string: "string",
  leftParent: "leftParent",
  rightParent: "rightParent",
  leftBracket: "leftBracket",
  rightBracket: "rightBracket",
  comma: "comma",
  period: "period",
  equal: "equal",
  semicolon: "semicolon"
}, Ee = {
  doubleQuote: '"',
  singleQuote: "'",
  backtick: "`",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /;|,|\(|\)|\[|\./,
  equal: "=",
  period: ".",
  comma: ",",
  semicolon: ";",
  leftParent: "(",
  rightParent: ")",
  leftBracket: "[",
  rightBracket: "]"
}, rt = (e) => (t) => e === t, fl = (e) => (t) => e.test(t), de = {
  doubleQuote: rt(Ee.doubleQuote),
  singleQuote: rt(Ee.singleQuote),
  backtick: rt(Ee.backtick),
  whiteSpace: fl(Ee.whiteSpace),
  string: fl(Ee.string),
  breakString: fl(Ee.breakString),
  equal: rt(Ee.equal),
  period: rt(Ee.period),
  comma: rt(Ee.comma),
  semicolon: rt(Ee.semicolon),
  leftParent: rt(Ee.leftParent),
  rightParent: rt(Ee.rightParent),
  leftBracket: rt(Ee.leftBracket),
  rightBracket: rt(Ee.rightBracket)
};
function FD(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length;
  for (; o(); ) {
    let r = e[n];
    if (de.whiteSpace(r)) {
      for (; o() && de.whiteSpace(r); )
        r = e[++n];
      continue;
    }
    if (de.leftParent(r)) {
      t.push({ type: ve.leftParent, value: r }), n++;
      continue;
    }
    if (de.rightParent(r)) {
      t.push({ type: ve.rightParent, value: r }), n++;
      continue;
    }
    if (de.leftBracket(r)) {
      t.push({ type: ve.leftBracket, value: r }), n++;
      continue;
    }
    if (de.rightBracket(r)) {
      t.push({ type: ve.rightBracket, value: r }), n++;
      continue;
    }
    if (de.comma(r)) {
      t.push({ type: ve.comma, value: r }), n++;
      continue;
    }
    if (de.period(r)) {
      t.push({ type: ve.period, value: r }), n++;
      continue;
    }
    if (de.equal(r)) {
      t.push({ type: ve.equal, value: r }), n++;
      continue;
    }
    if (de.semicolon(r)) {
      t.push({ type: ve.semicolon, value: r }), n++;
      continue;
    }
    if (de.doubleQuote(r)) {
      let i = "";
      for (r = e[++n]; o() && !de.doubleQuote(r); )
        i += r, r = e[++n];
      t.push({ type: ve.string, value: i }), n++;
      continue;
    }
    if (de.singleQuote(r)) {
      let i = "";
      for (r = e[++n]; o() && !de.singleQuote(r); )
        i += r, r = e[++n];
      t.push({ type: ve.string, value: i }), n++;
      continue;
    }
    if (de.backtick(r)) {
      let i = "";
      for (r = e[++n]; o() && !de.backtick(r); )
        i += r, r = e[++n];
      t.push({ type: ve.string, value: i }), n++;
      continue;
    }
    if (de.string(r)) {
      let i = "";
      for (; o() && de.string(r) && !de.breakString(r); )
        i += r, r = e[++n];
      t.push({ type: ve.string, value: i });
      continue;
    }
    n++;
  }
  return t;
}
const $i = (e) => (t) => (n) => t[n] ? t[n].type === e : !1, Y = (e) => {
  const t = e.toUpperCase();
  return (n) => (o) => n[o] ? n[o].value.toUpperCase() === t : !1;
}, mn = $i(ve.string), Ni = $i(ve.period), dy = $i(ve.comma), tr = $i(ve.leftParent), Mi = $i(ve.rightParent), ki = Y("CREATE"), Wa = Y("ALTER"), qD = Y("DROP"), HD = Y("USE"), VD = Y("RENAME"), ZD = Y("DELETE"), zD = Y("SELECT"), pn = Y("TABLE"), Fa = Y("INDEX"), Di = Y("UNIQUE"), wu = Y("ADD"), Cu = Y("PRIMARY"), Li = Y("KEY"), Xn = Y("CONSTRAINT"), Ou = Y("FOREIGN"), hy = Y("NOT"), GD = Y("NULL"), YD = Y("DEFAULT"), my = Y("COMMENT"), KD = Y("REFERENCES"), py = Y("ASC"), yy = Y("DESC"), QD = Y("ON"), JD = Y("AUTO_INCREMENT"), XD = Y("AUTOINCREMENT"), eL = Y("IF"), tL = Y("EXISTS"), nL = (e) => {
  const t = JD(e), n = XD(e);
  return (o) => t(o) || n(o);
}, Ri = (e) => {
  const t = ki(e), n = Wa(e), o = qD(e), r = HD(e), i = VD(e), s = ZD(e), a = zD(e);
  return (l) => t(l) || n(l) || o(l) || r(l) || i(l) || s(l) || a(l);
}, oL = (e) => {
  const t = ki(e), n = pn(e), o = eL(e), r = hy(e), i = tL(e);
  return (s) => t(s) && n(s + 1) && o(s + 2) && r(s + 3) && i(s + 4);
}, rL = (e) => {
  const t = ki(e), n = pn(e);
  return (o) => t(o) && n(o + 1);
}, gy = (e) => {
  const t = ki(e), n = Fa(e), o = Di(e);
  return (r) => t(r) && o(r + 1) && n(r + 2);
}, iL = (e) => {
  const t = ki(e), n = Fa(e), o = gy(e);
  return (r) => t(r) && n(r + 1) || o(r);
}, sL = (e) => {
  const t = Wa(e), n = pn(e), o = wu(e), r = Cu(e), i = Li(e), s = Xn(e);
  return (a) => t(a) && n(a + 1) && o(a + 3) && r(a + 4) && i(a + 5) || t(a) && n(a + 1) && o(a + 3) && s(a + 4) && r(a + 6) && i(a + 7) || t(a) && n(a + 1) && o(a + 5) && r(a + 6) && i(a + 7) || t(a) && n(a + 1) && o(a + 5) && s(a + 6) && r(a + 8) && i(a + 9);
}, aL = (e) => {
  const t = Wa(e), n = pn(e), o = wu(e), r = Ou(e), i = Li(e), s = Xn(e);
  return (a) => t(a) && n(a + 1) && o(a + 3) && r(a + 4) && i(a + 5) || t(a) && n(a + 1) && o(a + 3) && s(a + 4) && r(a + 6) && i(a + 7) || t(a) && n(a + 1) && o(a + 5) && r(a + 6) && i(a + 7) || t(a) && n(a + 1) && o(a + 5) && s(a + 6) && r(a + 8) && i(a + 9);
}, lL = (e) => {
  const t = Wa(e), n = pn(e), o = wu(e), r = Di(e), i = Xn(e);
  return (s) => t(s) && n(s + 1) && o(s + 3) && r(s + 4) || t(s) && n(s + 1) && o(s + 3) && i(s + 4) && r(s + 6) || t(s) && n(s + 1) && o(s + 5) && r(s + 6) || t(s) && n(s + 1) && o(s + 5) && i(s + 6) && r(s + 8);
}, cL = Array.from(new Set([
  ..._D,
  ...PD,
  ...BD,
  ...jD,
  ...UD,
  ...WD
].map((e) => e.toUpperCase()))), uL = (e) => {
  const t = mn(e);
  return (n) => {
    const o = e[n];
    return o ? t(n) && cL.includes(o.value.toUpperCase()) : !1;
  };
}, Tt = {
  createTable: "create.table",
  createIndex: "create.index",
  alterTableAddUnique: "alter.table.add.unique",
  alterTableAddPrimaryKey: "alter.table.add.primaryKey",
  alterTableAddForeignKey: "alter.table.add.foreignKey"
}, Rn = {
  asc: "ASC",
  desc: "DESC"
};
function fL(e, t) {
  const n = Ri(e), o = mn(e), r = tr(e), i = Ni(e), s = my(e), a = oL(e), l = () => t.value < e.length, c = {
    type: Tt.createTable,
    name: "",
    comment: "",
    columns: [],
    indexes: [],
    foreignKeys: []
  };
  for (t.value += a(t.value) ? 5 : 2; l() && !n(t.value); ) {
    let u = e[t.value];
    if (r(t.value)) {
      t.value++;
      const { columns: f, indexes: d, foreignKeys: h } = dL(e, t);
      c.columns = f, c.indexes = d, c.foreignKeys = h;
      continue;
    }
    if (o(t.value) && !c.name) {
      c.name = u.value, u = e[++t.value], i(t.value) && (u = e[++t.value], o(t.value) && (c.name = u.value, t.value++));
      continue;
    }
    if (s(t.value)) {
      u = e[++t.value], o(t.value) && (c.comment = u.value, t.value++);
      continue;
    }
    t.value++;
  }
  return c;
}
function dL(e, t) {
  const n = mn(e), o = tr(e), r = Mi(e), i = dy(e), s = Xn(e), a = Fa(e), l = Cu(e), c = Ou(e), u = nL(e), f = Di(e), d = GD(e), h = hy(e), p = YD(e), g = my(e), v = yy(e), T = py(e), O = Li(e), k = uL(e), L = () => t.value < e.length, ae = [], gt = [], vt = [], tt = [], je = [];
  let Z = {
    name: "",
    dataType: "",
    default: "",
    comment: "",
    primaryKey: !1,
    autoIncrement: !1,
    unique: !1,
    nullable: !0
  };
  for (; L(); ) {
    let P = e[t.value];
    if (n(t.value) && !Z.name && !s(t.value) && !l(t.value) && !c(t.value) && !f(t.value) && !a(t.value) && !O(t.value)) {
      Z.name = P.value, t.value++;
      continue;
    }
    if (o(t.value)) {
      for (P = e[++t.value]; L() && !r(t.value); )
        P = e[++t.value];
      t.value++;
      continue;
    }
    if (s(t.value)) {
      P = e[++t.value], n(t.value) && t.value++;
      continue;
    }
    if (l(t.value)) {
      if (P = e[++t.value], O(t.value))
        if (P = e[++t.value], o(t.value)) {
          for (P = e[++t.value]; L() && !r(t.value); )
            n(t.value) && tt.push(P.value.toUpperCase()), P = e[++t.value];
          t.value++;
        } else
          Z.primaryKey = !0;
      continue;
    }
    if (c(t.value)) {
      const nt = vy(e, t);
      nt && vt.push(nt);
      continue;
    }
    if (a(t.value) || O(t.value)) {
      if (P = e[++t.value], n(t.value)) {
        const nt = P.value, Ht = [];
        if (P = e[++t.value], o(t.value)) {
          P = e[++t.value];
          let At = {
            name: "",
            sort: Rn.asc
          };
          for (; L() && !r(t.value); )
            n(t.value) && !v(t.value) && !T(t.value) && (At.name = P.value), v(t.value) && (At.sort = Rn.desc), i(t.value) && (Ht.push(At), At = {
              name: "",
              sort: Rn.asc
            }), P = e[++t.value];
          !Ht.includes(At) && At.name !== "" && Ht.push(At), Ht.length && gt.push({
            name: nt,
            unique: !1,
            columns: Ht
          }), t.value++;
        }
      }
      continue;
    }
    if (f(t.value)) {
      if (P = e[++t.value], O(t.value) && (P = e[++t.value]), n(t.value) && (P = e[++t.value]), o(t.value)) {
        for (P = e[++t.value]; L() && !r(t.value); )
          n(t.value) && je.push(P.value.toUpperCase()), P = e[++t.value];
        t.value++;
      } else
        Z.unique = !0;
      continue;
    }
    if (h(t.value)) {
      P = e[++t.value], d(t.value) && (Z.nullable = !1, t.value++);
      continue;
    }
    if (p(t.value)) {
      P = e[++t.value], n(t.value) && (Z.default = P.value, t.value++);
      continue;
    }
    if (g(t.value)) {
      P = e[++t.value], n(t.value) && (Z.comment = P.value, t.value++);
      continue;
    }
    if (u(t.value)) {
      Z.autoIncrement = !0, t.value++;
      continue;
    }
    if (k(t.value)) {
      let nt = P.value;
      if (P = e[++t.value], o(t.value)) {
        for (nt += "(", P = e[++t.value]; L() && !r(t.value); )
          nt += P.value, P = e[++t.value];
        nt += ")", t.value++;
      }
      Z.dataType = nt;
      continue;
    }
    if (i(t.value)) {
      (Z.name || Z.dataType) && ae.push(Z), Z = {
        name: "",
        dataType: "",
        default: "",
        comment: "",
        primaryKey: !1,
        autoIncrement: !1,
        unique: !1,
        nullable: !0
      }, t.value++;
      continue;
    }
    if (r(t.value)) {
      t.value++;
      break;
    }
    t.value++;
  }
  return !ae.includes(Z) && (Z.name || Z.dataType) && ae.push(Z), ae.forEach((P) => {
    tt.includes(P.name.toUpperCase()) && (P.primaryKey = !0), je.includes(P.name.toUpperCase()) && (P.unique = !0);
  }), {
    columns: ae,
    indexes: gt,
    foreignKeys: vt
  };
}
function vy(e, t) {
  const n = mn(e), o = tr(e), r = Mi(e), i = KD(e), s = Ni(e), a = Li(e), l = () => t.value < e.length, c = {
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  let u = e[++t.value];
  if (a(t.value)) {
    if (u = e[++t.value], o(t.value)) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.columnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (i(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value], s(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value])), o(t.value)))) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.refColumnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (c.columnNames.length && c.columnNames.length === c.refColumnNames.length)
      return c;
  }
  return null;
}
function hL(e, t) {
  const n = Ri(e), o = mn(e), r = Xn(e), i = Ni(e), s = pn(e), a = Ou(e), l = () => t.value < e.length, c = {
    type: Tt.alterTableAddForeignKey,
    name: "",
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  for (t.value++; l() && !n(t.value); ) {
    let u = e[t.value];
    if (s(t.value)) {
      u = e[++t.value], o(t.value) && (c.name = u.value, u = e[++t.value], i(t.value) && (u = e[++t.value], o(t.value) && (c.name = u.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      u = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      const f = vy(e, t);
      f && (c.columnNames = f.columnNames, c.refTableName = f.refTableName, c.refColumnNames = f.refColumnNames);
      continue;
    }
    t.value++;
  }
  return c;
}
function mL(e, t) {
  const n = Ri(e), o = mn(e), r = tr(e), i = Mi(e), s = Xn(e), a = Cu(e), l = Ni(e), c = Li(e), u = pn(e), f = () => t.value < e.length, d = {
    type: Tt.alterTableAddPrimaryKey,
    name: "",
    columnNames: []
  };
  for (t.value++; f() && !n(t.value); ) {
    let h = e[t.value];
    if (u(t.value)) {
      h = e[++t.value], o(t.value) && (d.name = h.value, h = e[++t.value], l(t.value) && (h = e[++t.value], o(t.value) && (d.name = h.value, t.value++)));
      continue;
    }
    if (s(t.value)) {
      h = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (h = e[++t.value], c(t.value) && (h = e[++t.value], r(t.value))) {
        for (h = e[++t.value]; f() && !i(t.value); )
          o(t.value) && d.columnNames.push(h.value), h = e[++t.value];
        h = e[++t.value];
      }
      continue;
    }
    t.value++;
  }
  return d;
}
function pL(e, t) {
  const n = Ri(e), o = mn(e), r = Xn(e), i = Ni(e), s = pn(e), a = Di(e), l = tr(e), c = Mi(e), u = () => t.value < e.length, f = {
    type: Tt.alterTableAddUnique,
    name: "",
    columnNames: []
  };
  for (t.value++; u() && !n(t.value); ) {
    let d = e[t.value];
    if (s(t.value)) {
      d = e[++t.value], o(t.value) && (f.name = d.value, d = e[++t.value], i(t.value) && (d = e[++t.value], o(t.value) && (f.name = d.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      d = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (d = e[++t.value], l(t.value)) {
        for (d = e[++t.value]; u() && !c(t.value); )
          o(t.value) && f.columnNames.push(d.value), d = e[++t.value];
        t.value++;
      }
      continue;
    }
    t.value++;
  }
  return f;
}
function yL(e, t) {
  const n = Ri(e), o = Di(e), r = mn(e), i = tr(e), s = Mi(e), a = dy(e), l = Fa(e), c = QD(e), u = yy(e), f = py(e), d = gy(e), h = () => t.value < e.length, p = {
    type: Tt.createIndex,
    name: "",
    unique: o(t.value + 1),
    tableName: "",
    columns: []
  };
  for (t.value += d(t.value) ? 2 : 1; h() && !n(t.value); ) {
    let g = e[t.value];
    if (l(t.value)) {
      g = e[++t.value], r(t.value) && (p.name = g.value);
      continue;
    }
    if (c(t.value)) {
      if (g = e[++t.value], r(t.value) && (p.tableName = g.value, g = e[++t.value], i(t.value))) {
        g = e[++t.value];
        let v = {
          name: "",
          sort: Rn.asc
        };
        for (; h() && !s(t.value); )
          r(t.value) && !u(t.value) && !f(t.value) && (v.name = g.value), u(t.value) && (v.sort = Rn.desc), a(t.value) && (p.columns.push(v), v = {
            name: "",
            sort: Rn.asc
          }), g = e[++t.value];
        !p.columns.includes(v) && v.name !== "" && p.columns.push(v), t.value++;
      }
      continue;
    }
    t.value++;
  }
  return p;
}
function gL(e) {
  const t = [], n = { value: 0 }, o = () => n.value < e.length, r = rL(e), i = iL(e), s = sL(e), a = aL(e), l = lL(e);
  for (; o(); ) {
    if (r(n.value)) {
      t.push(fL(e, n));
      continue;
    }
    if (i(n.value)) {
      t.push(yL(e, n));
      continue;
    }
    if (s(n.value)) {
      t.push(mL(e, n));
      continue;
    }
    if (a(n.value)) {
      t.push(hL(e, n));
      continue;
    }
    if (l(n.value)) {
      t.push(pL(e, n));
      continue;
    }
    n.value++;
  }
  return t;
}
const vL = (e) => gL(FD(e));
function qe(e, t) {
  for (const n of e)
    if (n.name.toUpperCase() === t.toUpperCase())
      return n;
  return null;
}
function bL(e, t, n) {
  const o = qn({}), r = vL(e), i = IL(r), s = TL(i), a = Zl(s.length * 100);
  return o.settings.width = a, o.settings.height = a, s.forEach((l) => wL(o, l, t)), CL(o, s), OL(o, s), Ic(n ? n(o) : o);
}
function IL(e) {
  const t = {
    tables: [],
    indexes: [],
    primaryKeys: [],
    foreignKeys: [],
    uniques: []
  };
  for (const n of e)
    switch (n.type) {
      case Tt.createTable:
        n.name && t.tables.push(n);
        break;
      case Tt.createIndex:
        n.tableName && n.columns.length && t.indexes.push(n);
        break;
      case Tt.alterTableAddPrimaryKey:
        n.name && n.columnNames.length && t.primaryKeys.push(n);
        break;
      case Tt.alterTableAddForeignKey:
        n.name && n.columnNames.length && n.refTableName && n.refColumnNames.length && n.columnNames.length === n.refColumnNames.length && t.foreignKeys.push(n);
        break;
      case Tt.alterTableAddUnique:
        n.name && n.columnNames.length && t.uniques.push(n);
        break;
    }
  return t;
}
function TL({ tables: e, indexes: t, primaryKeys: n, foreignKeys: o, uniques: r }) {
  return t.forEach((i) => {
    const s = qe(e, i.tableName);
    s && s.indexes.push({
      name: i.name,
      unique: i.unique,
      columns: i.columns
    });
  }), n.forEach((i) => {
    const s = qe(e, i.name);
    s && i.columnNames.forEach((a) => {
      const l = qe(s.columns, a);
      l && (l.primaryKey = !0);
    });
  }), r.forEach((i) => {
    const s = qe(e, i.name);
    s && i.columnNames.forEach((a) => {
      const l = qe(s.columns, a);
      l && (l.unique = !0);
    });
  }), o.forEach((i) => {
    const s = qe(e, i.name);
    s && s.foreignKeys.push({
      columnNames: i.columnNames,
      refTableName: i.refTableName,
      refColumnNames: i.refColumnNames
    });
  }), e;
}
function wL({ doc: e, collections: t }, n, { toWidth: o }) {
  const r = yt({
    name: n.name,
    comment: n.comment,
    ui: {
      widthName: ye(o(n.name)),
      widthComment: ye(o(n.comment))
    }
  });
  n.columns.forEach((i) => {
    const s = xt({
      tableId: r.id,
      name: i.name,
      comment: i.comment,
      dataType: i.dataType,
      default: i.default,
      options: (i.autoIncrement ? U.autoIncrement : 0) | (i.primaryKey ? U.primaryKey : 0) | (i.unique ? U.unique : 0) | (i.nullable ? 0 : U.notNull),
      ui: {
        widthName: ye(o(i.name)),
        widthComment: ye(o(i.comment)),
        widthDataType: ye(o(i.dataType)),
        widthDefault: ye(o(i.default)),
        keys: i.primaryKey ? Re.primaryKey : 0
      }
    });
    r.columnIds.push(s.id), r.seqColumnIds.push(s.id), y(t).collection("tableColumnEntities").setOne(s);
  }), e.tableIds.push(r.id), y(t).collection("tableEntities").setOne(r);
}
function CL({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds), r = y(t).collection("tableColumnEntities");
  n.forEach((i) => {
    if (!i.foreignKeys.length)
      return;
    const s = qe(o, i.name);
    if (!s)
      return;
    const a = r.selectByIds(s.columnIds);
    i.foreignKeys.forEach((l) => {
      const c = qe(o, l.refTableName);
      if (!c)
        return;
      const u = r.selectByIds(c.columnIds), f = [], d = [];
      l.refColumnNames.forEach((p) => {
        const g = qe(u, p);
        g && f.push(g);
      }), l.columnNames.forEach((p) => {
        const g = qe(a, p);
        g && (d.push(g), $(g.ui.keys, Re.primaryKey) ? g.ui.keys |= Re.foreignKey : g.ui.keys = Re.foreignKey);
      });
      const h = ey({
        identification: !d.some((p) => !($(p.ui.keys, Re.primaryKey) && $(p.ui.keys, Re.foreignKey))),
        relationshipType: wp.ZeroN,
        start: {
          tableId: c.id,
          columnIds: f.map((p) => p.id)
        },
        end: {
          tableId: s.id,
          columnIds: d.map((p) => p.id)
        }
      });
      e.relationshipIds.push(h.id), y(t).collection("relationshipEntities").setOne(h);
    });
  });
}
function OL({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds);
  n.forEach((r) => {
    r.indexes.forEach((i) => {
      const s = qe(o, r.name);
      if (!s)
        return;
      const a = y(t).collection("tableColumnEntities").selectByIds(s.columnIds), l = [], c = Kn({
        name: i.name,
        tableId: s.id,
        unique: i.unique
      });
      i.columns.forEach((u) => {
        const f = qe(a, u.name);
        if (!f)
          return;
        const d = au({
          indexId: c.id,
          columnId: f.id,
          orderType: u.sort === Rn.asc ? Eo.ASC : Eo.DESC
        });
        l.push(d);
      }), l.length !== 0 && (l.forEach((u) => {
        c.indexColumnIds.push(u.id), c.seqIndexColumnIds.push(u.id), y(t).collection("indexColumnEntities").setOne(u);
      }), e.indexIds.push(c.id), y(t).collection("indexEntities").setOne(c));
    });
  });
}
const Zt = 15;
function by(e) {
  return Object.entries(e).reduce((t, [n, o]) => (o === ke.table ? t.tableIds.push(n) : o === ke.memo && t.memoIds.push(n), t), { tableIds: [], memoIds: [] });
}
const Iy = (e) => function* () {
  yield tu(), yield an({ value: e });
}, EL = (e) => function* () {
  yield qp(), yield Po({ value: e });
}, SL = (e, t) => function* ({ editor: { selectedMap: n }, settings: { zoomLevel: o } }) {
  const { tableIds: r, memoIds: i } = by(n), s = e / o, a = t / o;
  r.length && (yield xo({
    ids: r,
    movementX: s,
    movementY: a
  })), i.length && (yield So({
    ids: i,
    movementX: s,
    movementY: a
  }));
}, xL = () => function* () {
  yield ay(), yield sy();
}, AL = (e) => function* (t) {
  const { doc: { tableIds: n, memoIds: o }, collections: r } = t, i = {
    ...y(r).collection("tableEntities").selectByIds(n).reduce((s, a) => {
      const l = pu(a, t).width, c = yu(a), u = a.ui.x + l / 2 - Zt, f = a.ui.y + c / 2 - Zt;
      return Yf(e, {
        x: u,
        y: f,
        w: Zt,
        h: Zt
      }) && (s[a.id] = ke.table), s;
    }, {}),
    ...y(r).collection("memoEntities").selectByIds(o).reduce((s, a) => {
      const l = LD(a), c = RD(a), u = a.ui.x + l / 2 - Zt, f = a.ui.y + c / 2 - Zt;
      return Yf(e, {
        x: u,
        y: f,
        w: Zt,
        h: Zt
      }) && (s[a.id] = ke.memo), s;
    }, {})
  };
  yield qa(), bp(i) || (yield Xo(i));
}, qa = () => function* () {
  yield ja(), yield nu();
}, $L = (e, t) => function* (n) {
  const { editor: { focusTable: o } } = n;
  o && (e === lt.Tab && !t && (Fn(o.focusType) && eu(n) && !xi(n) || !Fn(o.focusType) && Jc(n) && Xc(n)) ? yield cy(o.tableId) : yield Hp({ moveKey: e, shiftKey: t }));
}, NL = (e) => function* ({ editor: t }) {
  var n;
  ((n = t.drawRelationship) == null ? void 0 : n.relationshipType) === e ? yield ou() : yield Vp({ relationshipType: e });
}, Ty = (e) => function* ({ collections: t }) {
  const n = y(t).collection("tableEntities").selectById(e);
  if (!n)
    return;
  if (!y(t).collection("tableColumnEntities").selectByIds(n.columnIds).some((r) => $(r.options, U.primaryKey))) {
    const r = me();
    yield St({
      tableId: e,
      id: r
    }), yield Jn({
      tableId: e,
      id: r,
      value: !0
    }), yield ln({
      tableId: e,
      columnId: r,
      focusType: j.columnName,
      $mod: !1,
      shiftKey: !1
    });
  }
  yield Zp({ tableId: e });
}, ML = (e) => function* ({ editor: { selectedMap: t }, collections: n }) {
  const { tableIds: o, memoIds: r } = by(t), i = y(n).collection("tableEntities").selectByIds(o), s = y(n).collection("memoEntities").selectByIds(r);
  yield i.map((a) => zr({ id: a.id, color: e, prevColor: a.ui.color })), yield s.map((a) => Hr({ id: a.id, color: e, prevColor: a.ui.color }));
}, kL = (e) => function* ({ settings: t }, n) {
  yield Iy(bL(e, n, (o) => (o.settings = {
    ...o.settings,
    ...N$(Ra(t), [
      "width",
      "height",
      "scrollTop",
      "scrollLeft",
      "zoomLevel"
    ])
  }, o))), yield Tu();
}, DL = (e) => function* ({ editor: { focusTable: t } }) {
  !t || !t.columnId || (yield ru({
    tableId: t.tableId,
    columnIds: e ? [...t.selectColumnIds] : [t.columnId]
  }));
}, LL = (e, t) => function* ({ editor: { draggableColumn: n }, collections: o }) {
  if (!n || n.columnIds.length === 0)
    return;
  const { tableId: r, columnIds: i } = n, s = y(o).collection("tableEntities"), a = s.selectById(r);
  if (!a)
    return;
  if (t === r) {
    const f = a.columnIds.indexOf(i[0]);
    if (f === -1)
      return;
    const d = a.columnIds.indexOf(e);
    if (d === -1)
      return;
    const h = i.map((p) => la({ tableId: r, id: p, targetId: e }));
    f < d && h.reverse(), yield h;
    return;
  }
  if (!s.selectById(t))
    return;
  const c = y(o).collection("tableColumnEntities").selectByIds(i);
  if (c.length === 0)
    return;
  yield uy(r, i);
  const u = c.map(() => me());
  for (let f = 0; f < c.length; f++) {
    const d = c[f], h = u[f], p = {
      id: h,
      tableId: t
    };
    yield [
      St(p),
      Lt({
        ...p,
        value: d.name
      }),
      _t({
        ...p,
        value: d.dataType
      }),
      Pt({
        ...p,
        value: d.default
      }),
      Rt({
        ...p,
        value: d.comment
      }),
      Jn({
        ...p,
        value: $(d.options, U.primaryKey)
      }),
      Bt({
        ...p,
        value: $(d.options, U.notNull)
      }),
      Ln({
        ...p,
        value: $(d.options, U.unique)
      }),
      Dn({
        ...p,
        value: $(d.options, U.autoIncrement)
      }),
      la({
        ...p,
        targetId: e
      }),
      ln({
        tableId: t,
        columnId: h,
        focusType: j.columnName,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  yield ru({
    tableId: t,
    columnIds: u
  });
}, RL = {
  loadJsonAction$: Iy,
  initialLoadJsonAction$: EL,
  moveAllAction$: SL,
  removeSelectedAction$: xL,
  dragSelectAction$: AL,
  unselectAllAction$: qa,
  focusMoveTableAction$: $L,
  drawStartRelationshipAction$: NL,
  drawStartAddRelationshipAction$: Ty,
  changeColorAllAction$: ML,
  loadSchemaSQLAction$: kL,
  dragstartColumnAction$: DL,
  dragoverColumnAction$: LL
}, _L = (e) => function* () {
  yield iu({
    id: me(),
    tableId: e
  });
}, PL = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexEntities").selectById(e);
  n && (yield su({
    id: e,
    tableId: n.tableId,
    value: !n.unique
  }));
}, BL = {
  addIndexAction$: _L,
  changeIndexUniqueAction$: PL
}, jL = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.seqIndexColumnIds).find((i) => i.columnId === t);
  r ? yield ia({
    id: r.id,
    indexId: e,
    tableId: o.tableId,
    columnId: t
  }) : yield ia({
    id: me(),
    indexId: e,
    tableId: o.tableId,
    columnId: t
  });
}, UL = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.indexColumnIds).filter((i) => i.columnId === t);
  for (const i of r)
    yield lu({
      id: i.id,
      indexId: e,
      tableId: o.tableId
    });
}, WL = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexColumnEntities").selectById(e);
  n && (yield uu({
    id: e,
    indexId: n.indexId,
    columnId: n.columnId,
    value: n.orderType === Eo.ASC ? Eo.DESC : Eo.ASC
  }));
}, FL = (e, t) => function* ({ collections: n }) {
  if (e === t)
    return;
  const o = y(n).collection("indexColumnEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexEntities").selectById(o.indexId);
  r && (yield cu({
    id: e,
    indexId: o.indexId,
    tableId: r.tableId,
    targetId: t
  }));
}, qL = {
  addIndexColumnAction$: jL,
  removeIndexColumnAction$: UL,
  changeIndexColumnOrderTypeAction$: WL,
  moveIndexColumnAction$: FL
}, HL = {};
function wy({ editor: { viewport: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: i } }, s) {
  const a = Vl(r, i, o), l = Vl(r, i, s), c = (a.w - l.w) / 2, u = (a.h - l.h) / 2, f = r / 2, d = i / 2, h = t * -1 + e.width / 2, p = n * -1 + e.height / 2, g = (f - h) / f, v = (d - p) / d, T = ht(-1 * c * g, 4), O = ht(-1 * u * v, 4);
  return { movementX: T, movementY: O };
}
const VL = (e) => function* (t) {
  const { settings: { scrollLeft: n, scrollTop: o } } = t, r = _a(e), { movementX: i, movementY: s } = wy(t, r);
  yield du({ value: e }), yield hu({
    scrollLeft: n + i,
    scrollTop: o + s
  });
}, ZL = (e) => function* (t) {
  const { settings: { zoomLevel: n } } = t, o = _a(n + e), { movementX: r, movementY: i } = wy(t, o);
  yield Vr({ value: e }), yield Zr({
    movementX: r,
    movementY: i
  });
}, zL = {
  changeZoomLevelAction$: VL,
  streamZoomLevelAction$: ZL
}, SB = Object.freeze({
  ...bM,
  ...RL,
  ...qM,
  ...BL,
  ...ek,
  ...qL,
  ...pk,
  ...TD,
  ...Nk,
  ...HL,
  ...f2,
  ...zL,
  ...R2,
  ...xD,
  ...iD,
  ...DD
}), Cy = [
  // table
  "table.add",
  "table.move",
  "table.moveTo",
  "table.remove",
  "table.changeName",
  "table.changeComment",
  "table.changeColor",
  "table.sort",
  // column
  "column.add",
  "column.remove",
  "column.changeName",
  "column.changeComment",
  "column.changeDataType",
  "column.changeDefault",
  "column.changeAutoIncrement",
  "column.changePrimaryKey",
  "column.changeUnique",
  "column.changeNotNull",
  "column.move",
  // relationship
  "relationship.add",
  "relationship.remove",
  "relationship.changeType",
  // index
  "index.add",
  "index.remove",
  "index.changeName",
  "index.changeUnique",
  // indexColumn
  "indexColumn.add",
  "indexColumn.remove",
  "indexColumn.move",
  "indexColumn.changeOrderType",
  // memo
  "memo.add",
  "memo.move",
  "memo.moveTo",
  "memo.remove",
  "memo.changeValue",
  "memo.changeColor",
  "memo.resize",
  // settings
  "settings.changeDatabaseName",
  "settings.resize",
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeShow",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType",
  "settings.changeRelationshipDataTypeSync",
  "settings.changeRelationshipOptimization",
  "settings.changeColumnOrder",
  "settings.changeMaxWidthComment",
  "settings.changeIgnoreSaveSettings",
  // editor
  "editor.loadJson",
  "editor.clear"
], GL = M([
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType"
]), xB = [
  ...Cy.filter((e) => !GL(e))
], Eu = [
  "editor.sharedMouseTracker"
], AB = [
  ...Cy,
  ...Eu
], $B = [
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeCanvasType"
], YL = [
  "table.move",
  "memo.move"
], KL = [
  "table.changeColor",
  "memo.changeColor"
], QL = [
  "settings.streamZoomLevel",
  "settings.streamScrollTo"
], Su = [
  ...Object.keys(si)
], NB = [
  ...Object.keys(iy),
  ...Su
], Oy = Tw((e) => !!e.length), MB = (e) => {
  const t = M(e);
  return (n) => new Xe((o) => n.subscribe({
    next: (r) => o.next(r.filter((i) => t(i.type))),
    error: (r) => o.error(r),
    complete: () => o.complete()
  })).pipe(Oy);
}, Ey = "@@none-stream", JL = (e, t) => (n) => {
  const o = t.find(([, r]) => r(n));
  return o ? o[0] : e(n) ? n : Ey;
}, sd = (e, t = [], n = Cw(200)) => {
  const o = M(e), r = t.map(([s, a]) => [
    s,
    M(a)
  ]), i = JL(o, r);
  return (s) => new Xe((a) => s.subscribe({
    next: (l) => {
      const c = l.reduce((u, f) => {
        const d = i(f.type);
        return u[d] || (u[d] = []), u[d].push(f), u;
      }, {});
      Object.values(c).forEach((u) => a.next(u));
    },
    error: (l) => a.error(l),
    complete: () => a.complete()
  })).pipe(Oy, Ow((a) => i(a[0].type)), Tm((a) => a.key === Ey ? a : a.pipe(ww(a.pipe(n)), Qs((l) => l.flat()))));
}, XL = M(Su), eR = M(Eu), kB = (e) => e.pipe(sd(Eu, [], Sw(100)), Qs((t) => {
  var n;
  return eR((n = t[0]) == null ? void 0 : n.type) ? [Ko(t)] : t;
}), sd(Su, [
  ["@@move", YL],
  ["@@scroll", QL],
  ["@@color", KL]
]), Qs((t) => {
  var o;
  if (!XL((o = t[0]) == null ? void 0 : o.type))
    return t;
  const n = [];
  for (const r of Object.keys(si))
    si[r]([], n, t);
  return n.length ? n : t;
}));
function DB(e, t) {
  return D1({
    context: e,
    state: {
      ...qn({}),
      editor: CN()
    },
    reducers: {
      ...vM,
      ...L2,
      ...rD,
      ...mk,
      ...$k,
      ...u2,
      ...FM,
      ...XM
    },
    enableObservable: t ?? !0
  });
}
/*!
 * @dineug/go
 * @version 0.1.8 | Sun Dec 24 2023
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function H(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function Gr(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
var Yl, xe, Sn, Sy;
const tR = {
  limit: -1,
  leading: !1,
  trailing: !1
};
class nR {
  constructor(t) {
    Yl.add(this), xe.set(this, []), Sn.set(this, void 0), Gr(this, Sn, Object.assign({}, tR, {
      ...t
    }), "f");
  }
  isEmpty() {
    return !H(this, xe, "f").length;
  }
  put(t) {
    H(this, xe, "f").push(t), H(this, Yl, "m", Sy).call(this);
  }
  take() {
    return H(this, xe, "f").shift();
  }
  flush() {
    const t = H(this, xe, "f");
    return Gr(this, xe, [], "f"), t;
  }
  drop(t) {
    const n = H(this, xe, "f").findIndex(t);
    if (n === -1)
      return !1;
    const o = H(this, xe, "f").slice();
    return o.splice(n, 1), Gr(this, xe, o, "f"), !0;
  }
}
xe = /* @__PURE__ */ new WeakMap(), Sn = /* @__PURE__ */ new WeakMap(), Yl = /* @__PURE__ */ new WeakSet(), Sy = function() {
  H(this, Sn, "f").limit !== -1 && H(this, Sn, "f").limit < H(this, xe, "f").length && (H(this, Sn, "f").trailing ? H(this, xe, "f").pop() : H(this, Sn, "f").leading ? H(this, xe, "f").shift() : H(this, xe, "f").pop());
};
const oR = (e) => new nR(e), ad = {
  limitBuffer: oR
};
var Ps, An, $n, En, Kl;
const dl = Symbol.for("https://github.com/dineug/go.git#closed");
class rR {
  get closed() {
    return H(this, En, "f");
  }
  constructor(t = ad.limitBuffer()) {
    Ps.add(this), An.set(this, void 0), $n.set(this, ad.limitBuffer()), En.set(this, !1), Gr(this, An, t, "f");
  }
  put(t) {
    H(this, En, "f") || (H(this, An, "f").put(t), H(this, Ps, "m", Kl).call(this));
  }
  take(t, n) {
    return H(this, En, "f") ? (n == null || n(dl), () => !1) : (H(this, $n, "f").put([t, n]), H(this, Ps, "m", Kl).call(this), () => H(this, $n, "f").drop(([o]) => o === t));
  }
  flush(t, n) {
    if (H(this, En, "f")) {
      n == null || n(dl);
      return;
    }
    t(H(this, An, "f").flush());
  }
  close() {
    Gr(this, En, !0, "f"), H(this, $n, "f").flush().forEach(([t, n]) => n == null ? void 0 : n(dl));
  }
}
An = /* @__PURE__ */ new WeakMap(), $n = /* @__PURE__ */ new WeakMap(), En = /* @__PURE__ */ new WeakMap(), Ps = /* @__PURE__ */ new WeakSet(), Kl = function() {
  if (H(this, $n, "f").isEmpty() || H(this, An, "f").isEmpty())
    return;
  const [e] = H(this, $n, "f").take(), t = H(this, An, "f").take();
  e == null || e(t);
};
const iR = (e) => new rR(e), xy = (e) => (t) => typeof t === e, sR = xy("object"), $o = xy("function"), aR = (e) => e === null, { isArray: ca } = Array, Ha = (e) => sR(e) && !aR(e) && !ca(e), Ay = (e) => Ha(e) && $o(e.then), $y = (e) => Ha(e) && $o(e.next), lR = (e) => Ha(e) && $o(e.next) && $o(e.throw) && $o(e.return), xu = Symbol.for("https://github.com/dineug/go.git#cancel"), Ny = Symbol.for("https://github.com/dineug/go.git#attachCancel"), My = (e, t) => (Reflect.set(e, Ny, t), e), cR = (e) => e === xu, ky = (e) => {
  if (Ha(e)) {
    const t = Reflect.get(e, Ny);
    t == null || t();
  }
  return Ke(() => new Promise((t, n) => n(xu)));
}, Dy = (e) => Ke(function* () {
  let t = () => !1;
  const n = new Promise((o, r) => {
    t = e.take(o, r);
  });
  return My(n, () => {
    t();
  }), yield n;
}), LB = (e) => Ke(() => new Promise((t) => setTimeout(t, e))), uR = Symbol.for("https://github.com/dineug/go.git#kill"), fR = (e) => e === uR, dR = (e, t) => {
  e.put(t);
}, Va = (e, t) => Ke(function* () {
  for (; ; ) {
    const n = yield Dy(e);
    Ke(t, n);
  }
}), hR = {
  leading: !0,
  trailing: !1
}, Za = (e, t, n, o) => Ke(function* () {
  const r = Object.assign({}, hR, {
    ...o
  });
  let i = -1, s, a;
  for (; ; ) {
    const l = yield Dy(e);
    a = l, i === -1 && (r.leading && (s = l, Ke(t, l)), i = setTimeout(() => {
      r.trailing && (!r.leading || s !== a) && Ke(t, a), i = -1;
    }, n));
  }
});
function Ke(e, ...t) {
  let n = !1, o = null;
  const r = new Promise(async (i, s) => {
    let a = null;
    o = () => {
      s(xu), a == null || a.forEach(ky), a = null;
    };
    try {
      const l = e(...t);
      if (Ay(l))
        return a = [l], i(await l);
      if (!$y(l))
        return i(l);
      let c = await l.next(), u;
      for (; !n && !c.done; ) {
        try {
          const f = mR(c.value);
          a = ca(f) ? f : [f], u = await (ca(f) ? Promise.all(f) : f), c = await l.next(u);
        } catch (f) {
          if (fR(f))
            throw f;
          lR(l) && (c = await l.throw(f));
        }
        u = void 0, a = null;
      }
      i(c.value);
    } catch (l) {
      cR(l) && (n = !0), s(l);
    }
  });
  return r.catch(() => {
  }), My(r, () => {
    n = !0, o == null || o();
  }), r;
}
function mR(e) {
  return Ay(e) ? e : $y(e) ? Ke(() => e) : $o(e) ? Ke(e) : ca(e) ? e.map((t) => Ke(() => t)) : Promise.resolve();
}
const re = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, Ql = Object.values(re);
M([
  re.top,
  re.bottom,
  re.left,
  re.right
]);
const pR = 30, RB = pR + 20, _B = 35, PB = 10, BB = 16, jB = 26;
function yR(e, t) {
  const n = pu(t, e).width, o = yu(t), { x: r, y: i } = t.ui;
  return {
    width: n,
    height: o,
    top: {
      x: r + n / 2,
      y: i
    },
    bottom: {
      x: r + n / 2,
      y: i + o
    },
    left: {
      x: r,
      y: i + o / 2
    },
    right: {
      x: r + n,
      y: i + o / 2
    },
    lt: {
      x: r,
      y: i
    },
    rt: {
      x: r + n,
      y: i
    },
    lb: {
      x: r,
      y: i + o
    },
    rb: {
      x: r + n,
      y: i + o
    }
  };
}
function ld(e, t) {
  return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
}
const cd = {
  [re.top]: wt.top,
  [re.bottom]: wt.bottom,
  [re.left]: wt.left,
  [re.right]: wt.right
};
function Ly(e) {
  const { doc: { tableIds: t, relationshipIds: n }, collections: o } = e, r = M(t), i = y(o).collection("tableEntities"), s = y(o).collection("relationshipEntities").selectByIds(n).filter(({ start: l, end: c }) => r(l.tableId) && r(c.tableId)), a = /* @__PURE__ */ new Map();
  for (const l of s) {
    const { start: c, end: u } = l, f = i.selectById(c.tableId), d = i.selectById(u.tableId);
    if (!(!f || !d))
      if (c.tableId === u.tableId) {
        c.direction = wt.top, u.direction = wt.right;
        const h = hl(e, a, f);
        c.x = h.objectPoint.rt.x - 20, c.y = h.objectPoint.rt.y, u.x = h.objectPoint.rt.x, u.y = h.objectPoint.rt.y + 20, h.top.set(l.id, l), h.right.set(l.id, l);
      } else {
        const h = hl(e, a, f), p = hl(e, a, d), [g, v] = gR(h.objectPoint, p.objectPoint, l);
        h[g].set(l.id, l), p[v].set(l.id, l);
      }
  }
  for (const l of a.values())
    for (const c of Ql) {
      const u = c;
      l[u].size < 2 || vR(u, l);
    }
}
function hl(e, t, n) {
  let o = t.get(n.id);
  return o || (o = {
    table: n,
    objectPoint: yR(e, n),
    top: /* @__PURE__ */ new Map(),
    bottom: /* @__PURE__ */ new Map(),
    left: /* @__PURE__ */ new Map(),
    right: /* @__PURE__ */ new Map()
  }, t.set(n.id, o)), o;
}
function gR(e, t, n) {
  const o = [
    re.bottom,
    re.bottom
  ];
  let r = ld(e.bottom, t.bottom);
  n.start.x = e.bottom.x, n.start.y = e.bottom.y, n.end.x = t.bottom.x, n.end.y = t.bottom.y, n.start.direction = wt.bottom, n.end.direction = wt.bottom;
  for (const i of Ql)
    for (const s of Ql) {
      const a = i, l = s, c = ld(e[a], t[l]);
      r <= c || (r = c, o[0] = a, o[1] = l, n.start.x = e[a].x, n.start.y = e[a].y, n.start.direction = cd[a], n.end.x = t[l].x, n.end.y = t[l].y, n.end.direction = cd[l]);
    }
  return o;
}
function vR(e, t) {
  const n = bR(e, t), o = IR(e, t);
  e === re.left || e === re.right ? n.yArray.forEach((r, i) => {
    o[i].start.y = r;
  }) : (e === re.top || e === re.bottom) && n.xArray.forEach((r, i) => {
    o[i].start.x = r;
  });
}
function bR(e, t) {
  const n = t[e].size, o = {
    x: t.objectPoint.width / n,
    y: t.objectPoint.height / n
  }, r = {
    x: o.x / 2,
    y: o.y / 2
  }, i = [], s = [];
  if (e === re.left || e === re.right) {
    let a = t.objectPoint.lt.y - r.y;
    for (let l = 0; l < n; l++)
      a += o.y, s.push(a);
  } else if (e === re.top || e === re.bottom) {
    let a = t.objectPoint.lt.x - r.x;
    for (let l = 0; l < n; l++)
      a += o.x, i.push(a);
  }
  return {
    xArray: i,
    yArray: s
  };
}
function IR(e, t) {
  const n = [], o = [], r = [], i = e === re.top || e === re.bottom;
  for (const s of t[e].values()) {
    const { start: a, end: l } = s;
    a.tableId === l.tableId ? e === re.top ? (n.push(s.start), o.push(s.end)) : e === re.right && (n.push(s.end), o.push(s.start)) : s.start.tableId === t.table.id ? (n.push(s.start), o.push(s.end)) : (n.push(s.end), o.push(s.start));
  }
  return o.forEach((s, a) => {
    r.push({
      start: n[a],
      end: o[a],
      distance: i ? s.x : s.y
    });
  }), r.sort((s, a) => s.distance - a.distance);
}
const TR = function* (e, t) {
  yield Za(e, function* () {
    const { doc: n, collections: o } = t, i = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const s of i) {
      const { end: a, identification: l } = s, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = M(c.columnIds), f = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((h) => u(h.id));
      if (!f.length)
        continue;
      const d = f.every((h) => $(h.options, U.primaryKey));
      d !== l && (s.identification = d);
    }
  }, 10, { leading: !1, trailing: !0 });
}, wR = function* (e, t) {
  yield Za(e, function* () {
    const { doc: n, collections: o } = t, i = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const s of i) {
      const { end: a, startRelationshipType: l } = s, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = M(c.columnIds), f = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((h) => u(h.id));
      if (!f.length)
        continue;
      const d = f.every((h) => $(h.options, U.notNull)) ? Hl.dash : Hl.ring;
      d !== l && (s.startRelationshipType = d);
    }
  }, 10, { leading: !1, trailing: !0 });
}, CR = function* (e, t) {
  yield Za(e, function* () {
    Ly(t);
  }, 5, { leading: !1, trailing: !0 });
}, OR = [
  [
    [
      Ao,
      Jn,
      an,
      Po
    ],
    TR
  ],
  [
    [
      Ao,
      Bt,
      an,
      Po
    ],
    wR
  ],
  [
    [
      mu,
      oy,
      Ai,
      So,
      xo,
      vu,
      bu,
      Iu,
      St,
      Ao,
      Lt,
      Rt,
      _t,
      Pt,
      Tu
    ],
    CR
  ]
], ER = function* (e, t, n) {
  yield Za(e, function* () {
    O2(t, n), Ly(t);
  }, 5, { leading: !1, trailing: !0 });
}, SR = [
  [[an, Po], ER]
], xR = function* (e, t) {
  yield Va(e, function* ({ payload: { id: n } }) {
    const { collections: o } = t, i = y(o).collection("tableColumnEntities").selectById(n);
    !i || !$(i.options, U.primaryKey) || $(i.options, U.notNull) || (i.options = i.options | U.notNull);
  });
}, AR = function* (e, t) {
  yield Va(e, function* ({ payload: { id: n, end: o } }) {
    const { doc: { relationshipIds: r }, collections: i } = t;
    if (!r.includes(n))
      return;
    const s = y(i).collection("tableColumnEntities").selectByIds(o.columnIds);
    for (const a of s)
      a.ui.keys = a.ui.keys | Re.foreignKey;
  });
}, $R = function* (e, t) {
  yield Va(e, function* ({ payload: { id: n } }) {
    const { doc: { relationshipIds: o }, collections: r } = t;
    if (o.includes(n))
      return;
    const i = y(r).collection("relationshipEntities").selectById(n);
    if (!i)
      return;
    const s = y(r).collection("tableColumnEntities").selectByIds(i.end.columnIds);
    for (const a of s)
      a.ui.keys = a.ui.keys & ~Re.foreignKey;
  });
}, NR = function* (e, t) {
  yield Va(e, function* () {
    const { doc: n, collections: o } = t, r = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds), i = y(o).collection("tableEntities").selectByIds(n.tableIds), s = /* @__PURE__ */ new Set(), a = y(o).collection("tableColumnEntities");
    for (const { end: l } of r) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        u.ui.keys = u.ui.keys | Re.foreignKey, s.add(u.id);
    }
    for (const l of i) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        $(u.ui.keys, Re.foreignKey) && !s.has(u.id) && (u.ui.keys = u.ui.keys & ~Re.foreignKey);
    }
  });
}, MR = [
  [[Jn], xR],
  [[Ai], AR],
  [[jo], $R],
  [[an, Po], NR]
], kR = [...SR, ...MR, ...OR];
function UB(e) {
  const t = kR.map(([r, i]) => {
    const s = iR();
    return {
      pattern: M(r.map(String)),
      channel: s,
      proc: Ke(i, s, e.state, e.context)
    };
  }), n = e.subscribe((r) => {
    for (const i of r)
      for (const s of t)
        s.pattern(i.type) && dR(s.channel, i);
  });
  return { destroy: () => {
    t.forEach(({ proc: r }) => ky(r)), t.splice(0, t.length), n();
  } };
}
class eo extends Error {
}
class DR extends eo {
  constructor(t) {
    super(`Invalid DateTime: ${t.toMessage()}`);
  }
}
class LR extends eo {
  constructor(t) {
    super(`Invalid Interval: ${t.toMessage()}`);
  }
}
class RR extends eo {
  constructor(t) {
    super(`Invalid Duration: ${t.toMessage()}`);
  }
}
class bo extends eo {
}
class Ry extends eo {
  constructor(t) {
    super(`Invalid unit ${t}`);
  }
}
class Ae extends eo {
}
class zt extends eo {
  constructor() {
    super("Zone is an abstract class");
  }
}
const C = "numeric", mt = "short", _e = "long", ua = {
  year: C,
  month: C,
  day: C
}, _y = {
  year: C,
  month: mt,
  day: C
}, _R = {
  year: C,
  month: mt,
  day: C,
  weekday: mt
}, Py = {
  year: C,
  month: _e,
  day: C
}, By = {
  year: C,
  month: _e,
  day: C,
  weekday: _e
}, jy = {
  hour: C,
  minute: C
}, Uy = {
  hour: C,
  minute: C,
  second: C
}, Wy = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: mt
}, Fy = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
}, qy = {
  hour: C,
  minute: C,
  hourCycle: "h23"
}, Hy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23"
}, Vy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: mt
}, Zy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: _e
}, zy = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C
}, Gy = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C,
  second: C
}, Yy = {
  year: C,
  month: mt,
  day: C,
  hour: C,
  minute: C
}, Ky = {
  year: C,
  month: mt,
  day: C,
  hour: C,
  minute: C,
  second: C
}, PR = {
  year: C,
  month: mt,
  day: C,
  weekday: mt,
  hour: C,
  minute: C
}, Qy = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  timeZoneName: mt
}, Jy = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: mt
}, Xy = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  timeZoneName: _e
}, eg = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
};
class _i {
  /**
   * The type of zone
   * @abstract
   * @type {string}
   */
  get type() {
    throw new zt();
  }
  /**
   * The name of this zone.
   * @abstract
   * @type {string}
   */
  get name() {
    throw new zt();
  }
  get ianaName() {
    return this.name;
  }
  /**
   * Returns whether the offset is known to be fixed for the whole year.
   * @abstract
   * @type {boolean}
   */
  get isUniversal() {
    throw new zt();
  }
  /**
   * Returns the offset's common name (such as EST) at the specified timestamp
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the name
   * @param {Object} opts - Options to affect the format
   * @param {string} opts.format - What style of offset to return. Accepts 'long' or 'short'.
   * @param {string} opts.locale - What locale to return the offset name in.
   * @return {string}
   */
  offsetName(t, n) {
    throw new zt();
  }
  /**
   * Returns the offset's value as a string
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the offset
   * @param {string} format - What style of offset to return.
   *                          Accepts 'narrow', 'short', or 'techie'. Returning '+6', '+06:00', or '+0600' respectively
   * @return {string}
   */
  formatOffset(t, n) {
    throw new zt();
  }
  /**
   * Return the offset in minutes for this zone at the specified timestamp.
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to compute the offset
   * @return {number}
   */
  offset(t) {
    throw new zt();
  }
  /**
   * Return whether this Zone is equal to another zone
   * @abstract
   * @param {Zone} otherZone - the zone to compare
   * @return {boolean}
   */
  equals(t) {
    throw new zt();
  }
  /**
   * Return whether this Zone is valid.
   * @abstract
   * @type {boolean}
   */
  get isValid() {
    throw new zt();
  }
}
let ml = null;
class za extends _i {
  /**
   * Get a singleton instance of the local zone
   * @return {SystemZone}
   */
  static get instance() {
    return ml === null && (ml = new za()), ml;
  }
  /** @override **/
  get type() {
    return "system";
  }
  /** @override **/
  get name() {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return cg(t, n, o);
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    return -new Date(t).getTimezoneOffset();
  }
  /** @override **/
  equals(t) {
    return t.type === "system";
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
let Bs = {};
function BR(e) {
  return Bs[e] || (Bs[e] = new Intl.DateTimeFormat("en-US", {
    hour12: !1,
    timeZone: e,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    era: "short"
  })), Bs[e];
}
const jR = {
  year: 0,
  month: 1,
  day: 2,
  era: 3,
  hour: 4,
  minute: 5,
  second: 6
};
function UR(e, t) {
  const n = e.format(t).replace(/\u200E/g, ""), o = /(\d+)\/(\d+)\/(\d+) (AD|BC),? (\d+):(\d+):(\d+)/.exec(n), [, r, i, s, a, l, c, u] = o;
  return [s, r, i, a, l, c, u];
}
function WR(e, t) {
  const n = e.formatToParts(t), o = [];
  for (let r = 0; r < n.length; r++) {
    const { type: i, value: s } = n[r], a = jR[i];
    i === "era" ? o[a] = s : N(a) || (o[a] = parseInt(s, 10));
  }
  return o;
}
let zi = {};
class Wt extends _i {
  /**
   * @param {string} name - Zone name
   * @return {IANAZone}
   */
  static create(t) {
    return zi[t] || (zi[t] = new Wt(t)), zi[t];
  }
  /**
   * Reset local caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCache() {
    zi = {}, Bs = {};
  }
  /**
   * Returns whether the provided string is a valid specifier. This only checks the string's format, not that the specifier identifies a known zone; see isValidZone for that.
   * @param {string} s - The string to check validity on
   * @example IANAZone.isValidSpecifier("America/New_York") //=> true
   * @example IANAZone.isValidSpecifier("Sport~~blorp") //=> false
   * @deprecated This method returns false for some valid IANA names. Use isValidZone instead.
   * @return {boolean}
   */
  static isValidSpecifier(t) {
    return this.isValidZone(t);
  }
  /**
   * Returns whether the provided string identifies a real zone
   * @param {string} zone - The string to check
   * @example IANAZone.isValidZone("America/New_York") //=> true
   * @example IANAZone.isValidZone("Fantasia/Castle") //=> false
   * @example IANAZone.isValidZone("Sport~~blorp") //=> false
   * @return {boolean}
   */
  static isValidZone(t) {
    if (!t)
      return !1;
    try {
      return new Intl.DateTimeFormat("en-US", { timeZone: t }).format(), !0;
    } catch {
      return !1;
    }
  }
  constructor(t) {
    super(), this.zoneName = t, this.valid = Wt.isValidZone(t);
  }
  /** @override **/
  get type() {
    return "iana";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return cg(t, n, o, this.name);
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    const n = new Date(t);
    if (isNaN(n))
      return NaN;
    const o = BR(this.name);
    let [r, i, s, a, l, c, u] = o.formatToParts ? WR(o, n) : UR(o, n);
    a === "BC" && (r = -Math.abs(r) + 1);
    const d = Ya({
      year: r,
      month: i,
      day: s,
      hour: l === 24 ? 0 : l,
      minute: c,
      second: u,
      millisecond: 0
    });
    let h = +n;
    const p = h % 1e3;
    return h -= p >= 0 ? p : 1e3 + p, (d - h) / (60 * 1e3);
  }
  /** @override **/
  equals(t) {
    return t.type === "iana" && t.name === this.name;
  }
  /** @override **/
  get isValid() {
    return this.valid;
  }
}
let ud = {};
function FR(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = ud[n];
  return o || (o = new Intl.ListFormat(e, t), ud[n] = o), o;
}
let Jl = {};
function Xl(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = Jl[n];
  return o || (o = new Intl.DateTimeFormat(e, t), Jl[n] = o), o;
}
let ec = {};
function qR(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = ec[n];
  return o || (o = new Intl.NumberFormat(e, t), ec[n] = o), o;
}
let tc = {};
function HR(e, t = {}) {
  const { base: n, ...o } = t, r = JSON.stringify([e, o]);
  let i = tc[r];
  return i || (i = new Intl.RelativeTimeFormat(e, t), tc[r] = i), i;
}
let Wr = null;
function VR() {
  return Wr || (Wr = new Intl.DateTimeFormat().resolvedOptions().locale, Wr);
}
let fd = {};
function ZR(e) {
  let t = fd[e];
  if (!t) {
    const n = new Intl.Locale(e);
    t = "getWeekInfo" in n ? n.getWeekInfo() : n.weekInfo, fd[e] = t;
  }
  return t;
}
function zR(e) {
  const t = e.indexOf("-x-");
  t !== -1 && (e = e.substring(0, t));
  const n = e.indexOf("-u-");
  if (n === -1)
    return [e];
  {
    let o, r;
    try {
      o = Xl(e).resolvedOptions(), r = e;
    } catch {
      const l = e.substring(0, n);
      o = Xl(l).resolvedOptions(), r = l;
    }
    const { numberingSystem: i, calendar: s } = o;
    return [r, i, s];
  }
}
function GR(e, t, n) {
  return (n || t) && (e.includes("-u-") || (e += "-u"), n && (e += `-ca-${n}`), t && (e += `-nu-${t}`)), e;
}
function YR(e) {
  const t = [];
  for (let n = 1; n <= 12; n++) {
    const o = D.utc(2009, n, 1);
    t.push(e(o));
  }
  return t;
}
function KR(e) {
  const t = [];
  for (let n = 1; n <= 7; n++) {
    const o = D.utc(2016, 11, 13 + n);
    t.push(e(o));
  }
  return t;
}
function Gi(e, t, n, o) {
  const r = e.listingMode();
  return r === "error" ? null : r === "en" ? n(t) : o(t);
}
function QR(e) {
  return e.numberingSystem && e.numberingSystem !== "latn" ? !1 : e.numberingSystem === "latn" || !e.locale || e.locale.startsWith("en") || new Intl.DateTimeFormat(e.intl).resolvedOptions().numberingSystem === "latn";
}
class JR {
  constructor(t, n, o) {
    this.padTo = o.padTo || 0, this.floor = o.floor || !1;
    const { padTo: r, floor: i, ...s } = o;
    if (!n || Object.keys(s).length > 0) {
      const a = { useGrouping: !1, ...o };
      o.padTo > 0 && (a.minimumIntegerDigits = o.padTo), this.inf = qR(t, a);
    }
  }
  format(t) {
    if (this.inf) {
      const n = this.floor ? Math.floor(t) : t;
      return this.inf.format(n);
    } else {
      const n = this.floor ? Math.floor(t) : Mu(t, 3);
      return fe(n, this.padTo);
    }
  }
}
class XR {
  constructor(t, n, o) {
    this.opts = o, this.originalZone = void 0;
    let r;
    if (this.opts.timeZone)
      this.dt = t;
    else if (t.zone.type === "fixed") {
      const s = -1 * (t.offset / 60), a = s >= 0 ? `Etc/GMT+${s}` : `Etc/GMT${s}`;
      t.offset !== 0 && Wt.create(a).valid ? (r = a, this.dt = t) : (r = "UTC", this.dt = t.offset === 0 ? t : t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    } else
      t.zone.type === "system" ? this.dt = t : t.zone.type === "iana" ? (this.dt = t, r = t.zone.name) : (r = "UTC", this.dt = t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    const i = { ...this.opts };
    i.timeZone = i.timeZone || r, this.dtf = Xl(n, i);
  }
  format() {
    return this.originalZone ? this.formatToParts().map(({ value: t }) => t).join("") : this.dtf.format(this.dt.toJSDate());
  }
  formatToParts() {
    const t = this.dtf.formatToParts(this.dt.toJSDate());
    return this.originalZone ? t.map((n) => {
      if (n.type === "timeZoneName") {
        const o = this.originalZone.offsetName(this.dt.ts, {
          locale: this.dt.locale,
          format: this.opts.timeZoneName
        });
        return {
          ...n,
          value: o
        };
      } else
        return n;
    }) : t;
  }
  resolvedOptions() {
    return this.dtf.resolvedOptions();
  }
}
class e_ {
  constructor(t, n, o) {
    this.opts = { style: "long", ...o }, !n && ag() && (this.rtf = HR(t, o));
  }
  format(t, n) {
    return this.rtf ? this.rtf.format(t, n) : I_(n, t, this.opts.numeric, this.opts.style !== "long");
  }
  formatToParts(t, n) {
    return this.rtf ? this.rtf.formatToParts(t, n) : [];
  }
}
const t_ = {
  firstDay: 1,
  minimalDays: 4,
  weekend: [6, 7]
};
class V {
  static fromOpts(t) {
    return V.create(
      t.locale,
      t.numberingSystem,
      t.outputCalendar,
      t.weekSettings,
      t.defaultToEN
    );
  }
  static create(t, n, o, r, i = !1) {
    const s = t || le.defaultLocale, a = s || (i ? "en-US" : VR()), l = n || le.defaultNumberingSystem, c = o || le.defaultOutputCalendar, u = nc(r) || le.defaultWeekSettings;
    return new V(a, l, c, u, s);
  }
  static resetCache() {
    Wr = null, Jl = {}, ec = {}, tc = {};
  }
  static fromObject({ locale: t, numberingSystem: n, outputCalendar: o, weekSettings: r } = {}) {
    return V.create(t, n, o, r);
  }
  constructor(t, n, o, r, i) {
    const [s, a, l] = zR(t);
    this.locale = s, this.numberingSystem = n || a || null, this.outputCalendar = o || l || null, this.weekSettings = r, this.intl = GR(this.locale, this.numberingSystem, this.outputCalendar), this.weekdaysCache = { format: {}, standalone: {} }, this.monthsCache = { format: {}, standalone: {} }, this.meridiemCache = null, this.eraCache = {}, this.specifiedLocale = i, this.fastNumbersCached = null;
  }
  get fastNumbers() {
    return this.fastNumbersCached == null && (this.fastNumbersCached = QR(this)), this.fastNumbersCached;
  }
  listingMode() {
    const t = this.isEnglish(), n = (this.numberingSystem === null || this.numberingSystem === "latn") && (this.outputCalendar === null || this.outputCalendar === "gregory");
    return t && n ? "en" : "intl";
  }
  clone(t) {
    return !t || Object.getOwnPropertyNames(t).length === 0 ? this : V.create(
      t.locale || this.specifiedLocale,
      t.numberingSystem || this.numberingSystem,
      t.outputCalendar || this.outputCalendar,
      nc(t.weekSettings) || this.weekSettings,
      t.defaultToEN || !1
    );
  }
  redefaultToEN(t = {}) {
    return this.clone({ ...t, defaultToEN: !0 });
  }
  redefaultToSystem(t = {}) {
    return this.clone({ ...t, defaultToEN: !1 });
  }
  months(t, n = !1) {
    return Gi(this, t, dg, () => {
      const o = n ? { month: t, day: "numeric" } : { month: t }, r = n ? "format" : "standalone";
      return this.monthsCache[r][t] || (this.monthsCache[r][t] = YR((i) => this.extract(i, o, "month"))), this.monthsCache[r][t];
    });
  }
  weekdays(t, n = !1) {
    return Gi(this, t, pg, () => {
      const o = n ? { weekday: t, year: "numeric", month: "long", day: "numeric" } : { weekday: t }, r = n ? "format" : "standalone";
      return this.weekdaysCache[r][t] || (this.weekdaysCache[r][t] = KR(
        (i) => this.extract(i, o, "weekday")
      )), this.weekdaysCache[r][t];
    });
  }
  meridiems() {
    return Gi(
      this,
      void 0,
      () => yg,
      () => {
        if (!this.meridiemCache) {
          const t = { hour: "numeric", hourCycle: "h12" };
          this.meridiemCache = [D.utc(2016, 11, 13, 9), D.utc(2016, 11, 13, 19)].map(
            (n) => this.extract(n, t, "dayperiod")
          );
        }
        return this.meridiemCache;
      }
    );
  }
  eras(t) {
    return Gi(this, t, gg, () => {
      const n = { era: t };
      return this.eraCache[t] || (this.eraCache[t] = [D.utc(-40, 1, 1), D.utc(2017, 1, 1)].map(
        (o) => this.extract(o, n, "era")
      )), this.eraCache[t];
    });
  }
  extract(t, n, o) {
    const r = this.dtFormatter(t, n), i = r.formatToParts(), s = i.find((a) => a.type.toLowerCase() === o);
    return s ? s.value : null;
  }
  numberFormatter(t = {}) {
    return new JR(this.intl, t.forceSimple || this.fastNumbers, t);
  }
  dtFormatter(t, n = {}) {
    return new XR(t, this.intl, n);
  }
  relFormatter(t = {}) {
    return new e_(this.intl, this.isEnglish(), t);
  }
  listFormatter(t = {}) {
    return FR(this.intl, t);
  }
  isEnglish() {
    return this.locale === "en" || this.locale.toLowerCase() === "en-us" || new Intl.DateTimeFormat(this.intl).resolvedOptions().locale.startsWith("en-us");
  }
  getWeekSettings() {
    return this.weekSettings ? this.weekSettings : lg() ? ZR(this.locale) : t_;
  }
  getStartOfWeek() {
    return this.getWeekSettings().firstDay;
  }
  getMinDaysInFirstWeek() {
    return this.getWeekSettings().minimalDays;
  }
  getWeekendDays() {
    return this.getWeekSettings().weekend;
  }
  equals(t) {
    return this.locale === t.locale && this.numberingSystem === t.numberingSystem && this.outputCalendar === t.outputCalendar;
  }
}
let pl = null;
class Oe extends _i {
  /**
   * Get a singleton instance of UTC
   * @return {FixedOffsetZone}
   */
  static get utcInstance() {
    return pl === null && (pl = new Oe(0)), pl;
  }
  /**
   * Get an instance with a specified offset
   * @param {number} offset - The offset in minutes
   * @return {FixedOffsetZone}
   */
  static instance(t) {
    return t === 0 ? Oe.utcInstance : new Oe(t);
  }
  /**
   * Get an instance of FixedOffsetZone from a UTC offset string, like "UTC+6"
   * @param {string} s - The offset string to parse
   * @example FixedOffsetZone.parseSpecifier("UTC+6")
   * @example FixedOffsetZone.parseSpecifier("UTC+06")
   * @example FixedOffsetZone.parseSpecifier("UTC-6:00")
   * @return {FixedOffsetZone}
   */
  static parseSpecifier(t) {
    if (t) {
      const n = t.match(/^utc(?:([+-]\d{1,2})(?::(\d{2}))?)?$/i);
      if (n)
        return new Oe(Ka(n[1], n[2]));
    }
    return null;
  }
  constructor(t) {
    super(), this.fixed = t;
  }
  /** @override **/
  get type() {
    return "fixed";
  }
  /** @override **/
  get name() {
    return this.fixed === 0 ? "UTC" : `UTC${Yr(this.fixed, "narrow")}`;
  }
  get ianaName() {
    return this.fixed === 0 ? "Etc/UTC" : `Etc/GMT${Yr(-this.fixed, "narrow")}`;
  }
  /** @override **/
  offsetName() {
    return this.name;
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.fixed, n);
  }
  /** @override **/
  get isUniversal() {
    return !0;
  }
  /** @override **/
  offset() {
    return this.fixed;
  }
  /** @override **/
  equals(t) {
    return t.type === "fixed" && t.fixed === this.fixed;
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
class n_ extends _i {
  constructor(t) {
    super(), this.zoneName = t;
  }
  /** @override **/
  get type() {
    return "invalid";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName() {
    return null;
  }
  /** @override **/
  formatOffset() {
    return "";
  }
  /** @override **/
  offset() {
    return NaN;
  }
  /** @override **/
  equals() {
    return !1;
  }
  /** @override **/
  get isValid() {
    return !1;
  }
}
function nn(e, t) {
  if (N(e) || e === null)
    return t;
  if (e instanceof _i)
    return e;
  if (i_(e)) {
    const n = e.toLowerCase();
    return n === "default" ? t : n === "local" || n === "system" ? za.instance : n === "utc" || n === "gmt" ? Oe.utcInstance : Oe.parseSpecifier(n) || Wt.create(e);
  } else
    return _n(e) ? Oe.instance(e) : typeof e == "object" && "offset" in e && typeof e.offset == "function" ? e : new n_(e);
}
let dd = () => Date.now(), hd = "system", md = null, pd = null, yd = null, gd = 60, vd, bd = null;
class le {
  /**
   * Get the callback for returning the current timestamp.
   * @type {function}
   */
  static get now() {
    return dd;
  }
  /**
   * Set the callback for returning the current timestamp.
   * The function should return a number, which will be interpreted as an Epoch millisecond count
   * @type {function}
   * @example Settings.now = () => Date.now() + 3000 // pretend it is 3 seconds in the future
   * @example Settings.now = () => 0 // always pretend it's Jan 1, 1970 at midnight in UTC time
   */
  static set now(t) {
    dd = t;
  }
  /**
   * Set the default time zone to create DateTimes in. Does not affect existing instances.
   * Use the value "system" to reset this value to the system's time zone.
   * @type {string}
   */
  static set defaultZone(t) {
    hd = t;
  }
  /**
   * Get the default time zone object currently used to create DateTimes. Does not affect existing instances.
   * The default value is the system's time zone (the one set on the machine that runs this code).
   * @type {Zone}
   */
  static get defaultZone() {
    return nn(hd, za.instance);
  }
  /**
   * Get the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultLocale() {
    return md;
  }
  /**
   * Set the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultLocale(t) {
    md = t;
  }
  /**
   * Get the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultNumberingSystem() {
    return pd;
  }
  /**
   * Set the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultNumberingSystem(t) {
    pd = t;
  }
  /**
   * Get the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultOutputCalendar() {
    return yd;
  }
  /**
   * Set the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultOutputCalendar(t) {
    yd = t;
  }
  /**
   * @typedef {Object} WeekSettings
   * @property {number} firstDay
   * @property {number} minimalDays
   * @property {number[]} weekend
   */
  /**
   * @return {WeekSettings|null}
   */
  static get defaultWeekSettings() {
    return bd;
  }
  /**
   * Allows overriding the default locale week settings, i.e. the start of the week, the weekend and
   * how many days are required in the first week of a year.
   * Does not affect existing instances.
   *
   * @param {WeekSettings|null} weekSettings
   */
  static set defaultWeekSettings(t) {
    bd = nc(t);
  }
  /**
   * Get the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   */
  static get twoDigitCutoffYear() {
    return gd;
  }
  /**
   * Set the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   * @example Settings.twoDigitCutoffYear = 0 // cut-off year is 0, so all 'yy' are interpreted as current century
   * @example Settings.twoDigitCutoffYear = 50 // '49' -> 1949; '50' -> 2050
   * @example Settings.twoDigitCutoffYear = 1950 // interpreted as 50
   * @example Settings.twoDigitCutoffYear = 2050 // ALSO interpreted as 50
   */
  static set twoDigitCutoffYear(t) {
    gd = t % 100;
  }
  /**
   * Get whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static get throwOnInvalid() {
    return vd;
  }
  /**
   * Set whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static set throwOnInvalid(t) {
    vd = t;
  }
  /**
   * Reset Luxon's global caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCaches() {
    V.resetCache(), Wt.resetCache();
  }
}
class ct {
  constructor(t, n) {
    this.reason = t, this.explanation = n;
  }
  toMessage() {
    return this.explanation ? `${this.reason}: ${this.explanation}` : this.reason;
  }
}
const tg = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334], ng = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
function Ge(e, t) {
  return new ct(
    "unit out of range",
    `you specified ${t} (of type ${typeof t}) as a ${e}, which is invalid`
  );
}
function Au(e, t, n) {
  const o = new Date(Date.UTC(e, t - 1, n));
  e < 100 && e >= 0 && o.setUTCFullYear(o.getUTCFullYear() - 1900);
  const r = o.getUTCDay();
  return r === 0 ? 7 : r;
}
function og(e, t, n) {
  return n + (Pi(e) ? ng : tg)[t - 1];
}
function rg(e, t) {
  const n = Pi(e) ? ng : tg, o = n.findIndex((i) => i < t), r = t - n[o];
  return { month: o + 1, day: r };
}
function $u(e, t) {
  return (e - t + 7) % 7 + 1;
}
function fa(e, t = 4, n = 1) {
  const { year: o, month: r, day: i } = e, s = og(o, r, i), a = $u(Au(o, r, i), n);
  let l = Math.floor((s - a + 14 - t) / 7), c;
  return l < 1 ? (c = o - 1, l = ai(c, t, n)) : l > ai(o, t, n) ? (c = o + 1, l = 1) : c = o, { weekYear: c, weekNumber: l, weekday: a, ...Qa(e) };
}
function Id(e, t = 4, n = 1) {
  const { weekYear: o, weekNumber: r, weekday: i } = e, s = $u(Au(o, 1, t), n), a = No(o);
  let l = r * 7 + i - s - 7 + t, c;
  l < 1 ? (c = o - 1, l += No(c)) : l > a ? (c = o + 1, l -= No(o)) : c = o;
  const { month: u, day: f } = rg(c, l);
  return { year: c, month: u, day: f, ...Qa(e) };
}
function yl(e) {
  const { year: t, month: n, day: o } = e, r = og(t, n, o);
  return { year: t, ordinal: r, ...Qa(e) };
}
function Td(e) {
  const { year: t, ordinal: n } = e, { month: o, day: r } = rg(t, n);
  return { year: t, month: o, day: r, ...Qa(e) };
}
function wd(e, t) {
  if (!N(e.localWeekday) || !N(e.localWeekNumber) || !N(e.localWeekYear)) {
    if (!N(e.weekday) || !N(e.weekNumber) || !N(e.weekYear))
      throw new bo(
        "Cannot mix locale-based week fields with ISO-based week fields"
      );
    return N(e.localWeekday) || (e.weekday = e.localWeekday), N(e.localWeekNumber) || (e.weekNumber = e.localWeekNumber), N(e.localWeekYear) || (e.weekYear = e.localWeekYear), delete e.localWeekday, delete e.localWeekNumber, delete e.localWeekYear, {
      minDaysInFirstWeek: t.getMinDaysInFirstWeek(),
      startOfWeek: t.getStartOfWeek()
    };
  } else
    return { minDaysInFirstWeek: 4, startOfWeek: 1 };
}
function o_(e, t = 4, n = 1) {
  const o = Ga(e.weekYear), r = Ye(
    e.weekNumber,
    1,
    ai(e.weekYear, t, n)
  ), i = Ye(e.weekday, 1, 7);
  return o ? r ? i ? !1 : Ge("weekday", e.weekday) : Ge("week", e.weekNumber) : Ge("weekYear", e.weekYear);
}
function r_(e) {
  const t = Ga(e.year), n = Ye(e.ordinal, 1, No(e.year));
  return t ? n ? !1 : Ge("ordinal", e.ordinal) : Ge("year", e.year);
}
function ig(e) {
  const t = Ga(e.year), n = Ye(e.month, 1, 12), o = Ye(e.day, 1, da(e.year, e.month));
  return t ? n ? o ? !1 : Ge("day", e.day) : Ge("month", e.month) : Ge("year", e.year);
}
function sg(e) {
  const { hour: t, minute: n, second: o, millisecond: r } = e, i = Ye(t, 0, 23) || t === 24 && n === 0 && o === 0 && r === 0, s = Ye(n, 0, 59), a = Ye(o, 0, 59), l = Ye(r, 0, 999);
  return i ? s ? a ? l ? !1 : Ge("millisecond", r) : Ge("second", o) : Ge("minute", n) : Ge("hour", t);
}
function N(e) {
  return typeof e > "u";
}
function _n(e) {
  return typeof e == "number";
}
function Ga(e) {
  return typeof e == "number" && e % 1 === 0;
}
function i_(e) {
  return typeof e == "string";
}
function s_(e) {
  return Object.prototype.toString.call(e) === "[object Date]";
}
function ag() {
  try {
    return typeof Intl < "u" && !!Intl.RelativeTimeFormat;
  } catch {
    return !1;
  }
}
function lg() {
  try {
    return typeof Intl < "u" && !!Intl.Locale && ("weekInfo" in Intl.Locale.prototype || "getWeekInfo" in Intl.Locale.prototype);
  } catch {
    return !1;
  }
}
function a_(e) {
  return Array.isArray(e) ? e : [e];
}
function Cd(e, t, n) {
  if (e.length !== 0)
    return e.reduce((o, r) => {
      const i = [t(r), r];
      return o && n(o[0], i[0]) === o[0] ? o : i;
    }, null)[1];
}
function l_(e, t) {
  return t.reduce((n, o) => (n[o] = e[o], n), {});
}
function Uo(e, t) {
  return Object.prototype.hasOwnProperty.call(e, t);
}
function nc(e) {
  if (e == null)
    return null;
  if (typeof e != "object")
    throw new Ae("Week settings must be an object");
  if (!Ye(e.firstDay, 1, 7) || !Ye(e.minimalDays, 1, 7) || !Array.isArray(e.weekend) || e.weekend.some((t) => !Ye(t, 1, 7)))
    throw new Ae("Invalid week settings");
  return {
    firstDay: e.firstDay,
    minimalDays: e.minimalDays,
    weekend: Array.from(e.weekend)
  };
}
function Ye(e, t, n) {
  return Ga(e) && e >= t && e <= n;
}
function c_(e, t) {
  return e - t * Math.floor(e / t);
}
function fe(e, t = 2) {
  const n = e < 0;
  let o;
  return n ? o = "-" + ("" + -e).padStart(t, "0") : o = ("" + e).padStart(t, "0"), o;
}
function tn(e) {
  if (!(N(e) || e === null || e === ""))
    return parseInt(e, 10);
}
function gn(e) {
  if (!(N(e) || e === null || e === ""))
    return parseFloat(e);
}
function Nu(e) {
  if (!(N(e) || e === null || e === "")) {
    const t = parseFloat("0." + e) * 1e3;
    return Math.floor(t);
  }
}
function Mu(e, t, n = !1) {
  const o = 10 ** t;
  return (n ? Math.trunc : Math.round)(e * o) / o;
}
function Pi(e) {
  return e % 4 === 0 && (e % 100 !== 0 || e % 400 === 0);
}
function No(e) {
  return Pi(e) ? 366 : 365;
}
function da(e, t) {
  const n = c_(t - 1, 12) + 1, o = e + (t - n) / 12;
  return n === 2 ? Pi(o) ? 29 : 28 : [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][n - 1];
}
function Ya(e) {
  let t = Date.UTC(
    e.year,
    e.month - 1,
    e.day,
    e.hour,
    e.minute,
    e.second,
    e.millisecond
  );
  return e.year < 100 && e.year >= 0 && (t = new Date(t), t.setUTCFullYear(e.year, e.month - 1, e.day)), +t;
}
function Od(e, t, n) {
  return -$u(Au(e, 1, t), n) + t - 1;
}
function ai(e, t = 4, n = 1) {
  const o = Od(e, t, n), r = Od(e + 1, t, n);
  return (No(e) - o + r) / 7;
}
function oc(e) {
  return e > 99 ? e : e > le.twoDigitCutoffYear ? 1900 + e : 2e3 + e;
}
function cg(e, t, n, o = null) {
  const r = new Date(e), i = {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };
  o && (i.timeZone = o);
  const s = { timeZoneName: t, ...i }, a = new Intl.DateTimeFormat(n, s).formatToParts(r).find((l) => l.type.toLowerCase() === "timezonename");
  return a ? a.value : null;
}
function Ka(e, t) {
  let n = parseInt(e, 10);
  Number.isNaN(n) && (n = 0);
  const o = parseInt(t, 10) || 0, r = n < 0 || Object.is(n, -0) ? -o : o;
  return n * 60 + r;
}
function ug(e) {
  const t = Number(e);
  if (typeof e == "boolean" || e === "" || Number.isNaN(t))
    throw new Ae(`Invalid unit value ${e}`);
  return t;
}
function ha(e, t) {
  const n = {};
  for (const o in e)
    if (Uo(e, o)) {
      const r = e[o];
      if (r == null)
        continue;
      n[t(o)] = ug(r);
    }
  return n;
}
function Yr(e, t) {
  const n = Math.trunc(Math.abs(e / 60)), o = Math.trunc(Math.abs(e % 60)), r = e >= 0 ? "+" : "-";
  switch (t) {
    case "short":
      return `${r}${fe(n, 2)}:${fe(o, 2)}`;
    case "narrow":
      return `${r}${n}${o > 0 ? `:${o}` : ""}`;
    case "techie":
      return `${r}${fe(n, 2)}${fe(o, 2)}`;
    default:
      throw new RangeError(`Value format ${t} is out of range for property format`);
  }
}
function Qa(e) {
  return l_(e, ["hour", "minute", "second", "millisecond"]);
}
const u_ = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
], fg = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
], f_ = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
function dg(e) {
  switch (e) {
    case "narrow":
      return [...f_];
    case "short":
      return [...fg];
    case "long":
      return [...u_];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    case "2-digit":
      return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    default:
      return null;
  }
}
const hg = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
], mg = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], d_ = ["M", "T", "W", "T", "F", "S", "S"];
function pg(e) {
  switch (e) {
    case "narrow":
      return [...d_];
    case "short":
      return [...mg];
    case "long":
      return [...hg];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7"];
    default:
      return null;
  }
}
const yg = ["AM", "PM"], h_ = ["Before Christ", "Anno Domini"], m_ = ["BC", "AD"], p_ = ["B", "A"];
function gg(e) {
  switch (e) {
    case "narrow":
      return [...p_];
    case "short":
      return [...m_];
    case "long":
      return [...h_];
    default:
      return null;
  }
}
function y_(e) {
  return yg[e.hour < 12 ? 0 : 1];
}
function g_(e, t) {
  return pg(t)[e.weekday - 1];
}
function v_(e, t) {
  return dg(t)[e.month - 1];
}
function b_(e, t) {
  return gg(t)[e.year < 0 ? 0 : 1];
}
function I_(e, t, n = "always", o = !1) {
  const r = {
    years: ["year", "yr."],
    quarters: ["quarter", "qtr."],
    months: ["month", "mo."],
    weeks: ["week", "wk."],
    days: ["day", "day", "days"],
    hours: ["hour", "hr."],
    minutes: ["minute", "min."],
    seconds: ["second", "sec."]
  }, i = ["hours", "minutes", "seconds"].indexOf(e) === -1;
  if (n === "auto" && i) {
    const f = e === "days";
    switch (t) {
      case 1:
        return f ? "tomorrow" : `next ${r[e][0]}`;
      case -1:
        return f ? "yesterday" : `last ${r[e][0]}`;
      case 0:
        return f ? "today" : `this ${r[e][0]}`;
    }
  }
  const s = Object.is(t, -0) || t < 0, a = Math.abs(t), l = a === 1, c = r[e], u = o ? l ? c[1] : c[2] || c[1] : l ? r[e][0] : e;
  return s ? `${a} ${u} ago` : `in ${a} ${u}`;
}
function Ed(e, t) {
  let n = "";
  for (const o of e)
    o.literal ? n += o.val : n += t(o.val);
  return n;
}
const T_ = {
  D: ua,
  DD: _y,
  DDD: Py,
  DDDD: By,
  t: jy,
  tt: Uy,
  ttt: Wy,
  tttt: Fy,
  T: qy,
  TT: Hy,
  TTT: Vy,
  TTTT: Zy,
  f: zy,
  ff: Yy,
  fff: Qy,
  ffff: Xy,
  F: Gy,
  FF: Ky,
  FFF: Jy,
  FFFF: eg
};
class Ie {
  static create(t, n = {}) {
    return new Ie(t, n);
  }
  static parseFormat(t) {
    let n = null, o = "", r = !1;
    const i = [];
    for (let s = 0; s < t.length; s++) {
      const a = t.charAt(s);
      a === "'" ? (o.length > 0 && i.push({ literal: r || /^\s+$/.test(o), val: o }), n = null, o = "", r = !r) : r || a === n ? o += a : (o.length > 0 && i.push({ literal: /^\s+$/.test(o), val: o }), o = a, n = a);
    }
    return o.length > 0 && i.push({ literal: r || /^\s+$/.test(o), val: o }), i;
  }
  static macroTokenToFormatOpts(t) {
    return T_[t];
  }
  constructor(t, n) {
    this.opts = n, this.loc = t, this.systemLoc = null;
  }
  formatWithSystemDefault(t, n) {
    return this.systemLoc === null && (this.systemLoc = this.loc.redefaultToSystem()), this.systemLoc.dtFormatter(t, { ...this.opts, ...n }).format();
  }
  dtFormatter(t, n = {}) {
    return this.loc.dtFormatter(t, { ...this.opts, ...n });
  }
  formatDateTime(t, n) {
    return this.dtFormatter(t, n).format();
  }
  formatDateTimeParts(t, n) {
    return this.dtFormatter(t, n).formatToParts();
  }
  formatInterval(t, n) {
    return this.dtFormatter(t.start, n).dtf.formatRange(t.start.toJSDate(), t.end.toJSDate());
  }
  resolvedOptions(t, n) {
    return this.dtFormatter(t, n).resolvedOptions();
  }
  num(t, n = 0) {
    if (this.opts.forceSimple)
      return fe(t, n);
    const o = { ...this.opts };
    return n > 0 && (o.padTo = n), this.loc.numberFormatter(o).format(t);
  }
  formatDateTimeFromString(t, n) {
    const o = this.loc.listingMode() === "en", r = this.loc.outputCalendar && this.loc.outputCalendar !== "gregory", i = (h, p) => this.loc.extract(t, h, p), s = (h) => t.isOffsetFixed && t.offset === 0 && h.allowZ ? "Z" : t.isValid ? t.zone.formatOffset(t.ts, h.format) : "", a = () => o ? y_(t) : i({ hour: "numeric", hourCycle: "h12" }, "dayperiod"), l = (h, p) => o ? v_(t, h) : i(p ? { month: h } : { month: h, day: "numeric" }, "month"), c = (h, p) => o ? g_(t, h) : i(
      p ? { weekday: h } : { weekday: h, month: "long", day: "numeric" },
      "weekday"
    ), u = (h) => {
      const p = Ie.macroTokenToFormatOpts(h);
      return p ? this.formatWithSystemDefault(t, p) : h;
    }, f = (h) => o ? b_(t, h) : i({ era: h }, "era"), d = (h) => {
      switch (h) {
        case "S":
          return this.num(t.millisecond);
        case "u":
        case "SSS":
          return this.num(t.millisecond, 3);
        case "s":
          return this.num(t.second);
        case "ss":
          return this.num(t.second, 2);
        case "uu":
          return this.num(Math.floor(t.millisecond / 10), 2);
        case "uuu":
          return this.num(Math.floor(t.millisecond / 100));
        case "m":
          return this.num(t.minute);
        case "mm":
          return this.num(t.minute, 2);
        case "h":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12);
        case "hh":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12, 2);
        case "H":
          return this.num(t.hour);
        case "HH":
          return this.num(t.hour, 2);
        case "Z":
          return s({ format: "narrow", allowZ: this.opts.allowZ });
        case "ZZ":
          return s({ format: "short", allowZ: this.opts.allowZ });
        case "ZZZ":
          return s({ format: "techie", allowZ: this.opts.allowZ });
        case "ZZZZ":
          return t.zone.offsetName(t.ts, { format: "short", locale: this.loc.locale });
        case "ZZZZZ":
          return t.zone.offsetName(t.ts, { format: "long", locale: this.loc.locale });
        case "z":
          return t.zoneName;
        case "a":
          return a();
        case "d":
          return r ? i({ day: "numeric" }, "day") : this.num(t.day);
        case "dd":
          return r ? i({ day: "2-digit" }, "day") : this.num(t.day, 2);
        case "c":
          return this.num(t.weekday);
        case "ccc":
          return c("short", !0);
        case "cccc":
          return c("long", !0);
        case "ccccc":
          return c("narrow", !0);
        case "E":
          return this.num(t.weekday);
        case "EEE":
          return c("short", !1);
        case "EEEE":
          return c("long", !1);
        case "EEEEE":
          return c("narrow", !1);
        case "L":
          return r ? i({ month: "numeric", day: "numeric" }, "month") : this.num(t.month);
        case "LL":
          return r ? i({ month: "2-digit", day: "numeric" }, "month") : this.num(t.month, 2);
        case "LLL":
          return l("short", !0);
        case "LLLL":
          return l("long", !0);
        case "LLLLL":
          return l("narrow", !0);
        case "M":
          return r ? i({ month: "numeric" }, "month") : this.num(t.month);
        case "MM":
          return r ? i({ month: "2-digit" }, "month") : this.num(t.month, 2);
        case "MMM":
          return l("short", !1);
        case "MMMM":
          return l("long", !1);
        case "MMMMM":
          return l("narrow", !1);
        case "y":
          return r ? i({ year: "numeric" }, "year") : this.num(t.year);
        case "yy":
          return r ? i({ year: "2-digit" }, "year") : this.num(t.year.toString().slice(-2), 2);
        case "yyyy":
          return r ? i({ year: "numeric" }, "year") : this.num(t.year, 4);
        case "yyyyyy":
          return r ? i({ year: "numeric" }, "year") : this.num(t.year, 6);
        case "G":
          return f("short");
        case "GG":
          return f("long");
        case "GGGGG":
          return f("narrow");
        case "kk":
          return this.num(t.weekYear.toString().slice(-2), 2);
        case "kkkk":
          return this.num(t.weekYear, 4);
        case "W":
          return this.num(t.weekNumber);
        case "WW":
          return this.num(t.weekNumber, 2);
        case "n":
          return this.num(t.localWeekNumber);
        case "nn":
          return this.num(t.localWeekNumber, 2);
        case "ii":
          return this.num(t.localWeekYear.toString().slice(-2), 2);
        case "iiii":
          return this.num(t.localWeekYear, 4);
        case "o":
          return this.num(t.ordinal);
        case "ooo":
          return this.num(t.ordinal, 3);
        case "q":
          return this.num(t.quarter);
        case "qq":
          return this.num(t.quarter, 2);
        case "X":
          return this.num(Math.floor(t.ts / 1e3));
        case "x":
          return this.num(t.ts);
        default:
          return u(h);
      }
    };
    return Ed(Ie.parseFormat(n), d);
  }
  formatDurationFromString(t, n) {
    const o = (l) => {
      switch (l[0]) {
        case "S":
          return "millisecond";
        case "s":
          return "second";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
        default:
          return null;
      }
    }, r = (l) => (c) => {
      const u = o(c);
      return u ? this.num(l.get(u), c.length) : c;
    }, i = Ie.parseFormat(n), s = i.reduce(
      (l, { literal: c, val: u }) => c ? l : l.concat(u),
      []
    ), a = t.shiftTo(...s.map(o).filter((l) => l));
    return Ed(i, r(a));
  }
}
const vg = /[A-Za-z_+-]{1,256}(?::?\/[A-Za-z0-9_+-]{1,256}(?:\/[A-Za-z0-9_+-]{1,256})?)?/;
function nr(...e) {
  const t = e.reduce((n, o) => n + o.source, "");
  return RegExp(`^${t}$`);
}
function or(...e) {
  return (t) => e.reduce(
    ([n, o, r], i) => {
      const [s, a, l] = i(t, r);
      return [{ ...n, ...s }, a || o, l];
    },
    [{}, null, 1]
  ).slice(0, 2);
}
function rr(e, ...t) {
  if (e == null)
    return [null, null];
  for (const [n, o] of t) {
    const r = n.exec(e);
    if (r)
      return o(r);
  }
  return [null, null];
}
function bg(...e) {
  return (t, n) => {
    const o = {};
    let r;
    for (r = 0; r < e.length; r++)
      o[e[r]] = tn(t[n + r]);
    return [o, null, n + r];
  };
}
const Ig = /(?:(Z)|([+-]\d\d)(?::?(\d\d))?)/, w_ = `(?:${Ig.source}?(?:\\[(${vg.source})\\])?)?`, ku = /(\d\d)(?::?(\d\d)(?::?(\d\d)(?:[.,](\d{1,30}))?)?)?/, Tg = RegExp(`${ku.source}${w_}`), Du = RegExp(`(?:T${Tg.source})?`), C_ = /([+-]\d{6}|\d{4})(?:-?(\d\d)(?:-?(\d\d))?)?/, O_ = /(\d{4})-?W(\d\d)(?:-?(\d))?/, E_ = /(\d{4})-?(\d{3})/, S_ = bg("weekYear", "weekNumber", "weekDay"), x_ = bg("year", "ordinal"), A_ = /(\d{4})-(\d\d)-(\d\d)/, wg = RegExp(
  `${ku.source} ?(?:${Ig.source}|(${vg.source}))?`
), $_ = RegExp(`(?: ${wg.source})?`);
function Mo(e, t, n) {
  const o = e[t];
  return N(o) ? n : tn(o);
}
function N_(e, t) {
  return [{
    year: Mo(e, t),
    month: Mo(e, t + 1, 1),
    day: Mo(e, t + 2, 1)
  }, null, t + 3];
}
function ir(e, t) {
  return [{
    hours: Mo(e, t, 0),
    minutes: Mo(e, t + 1, 0),
    seconds: Mo(e, t + 2, 0),
    milliseconds: Nu(e[t + 3])
  }, null, t + 4];
}
function Bi(e, t) {
  const n = !e[t] && !e[t + 1], o = Ka(e[t + 1], e[t + 2]), r = n ? null : Oe.instance(o);
  return [{}, r, t + 3];
}
function ji(e, t) {
  const n = e[t] ? Wt.create(e[t]) : null;
  return [{}, n, t + 1];
}
const M_ = RegExp(`^T?${ku.source}$`), k_ = /^-?P(?:(?:(-?\d{1,20}(?:\.\d{1,20})?)Y)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20}(?:\.\d{1,20})?)W)?(?:(-?\d{1,20}(?:\.\d{1,20})?)D)?(?:T(?:(-?\d{1,20}(?:\.\d{1,20})?)H)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,20}))?S)?)?)$/;
function D_(e) {
  const [t, n, o, r, i, s, a, l, c] = e, u = t[0] === "-", f = l && l[0] === "-", d = (h, p = !1) => h !== void 0 && (p || h && u) ? -h : h;
  return [
    {
      years: d(gn(n)),
      months: d(gn(o)),
      weeks: d(gn(r)),
      days: d(gn(i)),
      hours: d(gn(s)),
      minutes: d(gn(a)),
      seconds: d(gn(l), l === "-0"),
      milliseconds: d(Nu(c), f)
    }
  ];
}
const L_ = {
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function Lu(e, t, n, o, r, i, s) {
  const a = {
    year: t.length === 2 ? oc(tn(t)) : tn(t),
    month: fg.indexOf(n) + 1,
    day: tn(o),
    hour: tn(r),
    minute: tn(i)
  };
  return s && (a.second = tn(s)), e && (a.weekday = e.length > 3 ? hg.indexOf(e) + 1 : mg.indexOf(e) + 1), a;
}
const R_ = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|(?:([+-]\d\d)(\d\d)))$/;
function __(e) {
  const [
    ,
    t,
    n,
    o,
    r,
    i,
    s,
    a,
    l,
    c,
    u,
    f
  ] = e, d = Lu(t, r, o, n, i, s, a);
  let h;
  return l ? h = L_[l] : c ? h = 0 : h = Ka(u, f), [d, new Oe(h)];
}
function P_(e) {
  return e.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").trim();
}
const B_ = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (\d\d) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{4}) (\d\d):(\d\d):(\d\d) GMT$/, j_ = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday), (\d\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d\d) (\d\d):(\d\d):(\d\d) GMT$/, U_ = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ( \d|\d\d) (\d\d):(\d\d):(\d\d) (\d{4})$/;
function Sd(e) {
  const [, t, n, o, r, i, s, a] = e;
  return [Lu(t, r, o, n, i, s, a), Oe.utcInstance];
}
function W_(e) {
  const [, t, n, o, r, i, s, a] = e;
  return [Lu(t, a, n, o, r, i, s), Oe.utcInstance];
}
const F_ = nr(C_, Du), q_ = nr(O_, Du), H_ = nr(E_, Du), V_ = nr(Tg), Cg = or(
  N_,
  ir,
  Bi,
  ji
), Z_ = or(
  S_,
  ir,
  Bi,
  ji
), z_ = or(
  x_,
  ir,
  Bi,
  ji
), G_ = or(
  ir,
  Bi,
  ji
);
function Y_(e) {
  return rr(
    e,
    [F_, Cg],
    [q_, Z_],
    [H_, z_],
    [V_, G_]
  );
}
function K_(e) {
  return rr(P_(e), [R_, __]);
}
function Q_(e) {
  return rr(
    e,
    [B_, Sd],
    [j_, Sd],
    [U_, W_]
  );
}
function J_(e) {
  return rr(e, [k_, D_]);
}
const X_ = or(ir);
function eP(e) {
  return rr(e, [M_, X_]);
}
const tP = nr(A_, $_), nP = nr(wg), oP = or(
  ir,
  Bi,
  ji
);
function rP(e) {
  return rr(
    e,
    [tP, Cg],
    [nP, oP]
  );
}
const xd = "Invalid Duration", Og = {
  weeks: {
    days: 7,
    hours: 7 * 24,
    minutes: 7 * 24 * 60,
    seconds: 7 * 24 * 60 * 60,
    milliseconds: 7 * 24 * 60 * 60 * 1e3
  },
  days: {
    hours: 24,
    minutes: 24 * 60,
    seconds: 24 * 60 * 60,
    milliseconds: 24 * 60 * 60 * 1e3
  },
  hours: { minutes: 60, seconds: 60 * 60, milliseconds: 60 * 60 * 1e3 },
  minutes: { seconds: 60, milliseconds: 60 * 1e3 },
  seconds: { milliseconds: 1e3 }
}, iP = {
  years: {
    quarters: 4,
    months: 12,
    weeks: 52,
    days: 365,
    hours: 365 * 24,
    minutes: 365 * 24 * 60,
    seconds: 365 * 24 * 60 * 60,
    milliseconds: 365 * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: 13,
    days: 91,
    hours: 91 * 24,
    minutes: 91 * 24 * 60,
    seconds: 91 * 24 * 60 * 60,
    milliseconds: 91 * 24 * 60 * 60 * 1e3
  },
  months: {
    weeks: 4,
    days: 30,
    hours: 30 * 24,
    minutes: 30 * 24 * 60,
    seconds: 30 * 24 * 60 * 60,
    milliseconds: 30 * 24 * 60 * 60 * 1e3
  },
  ...Og
}, Ue = 146097 / 400, no = 146097 / 4800, sP = {
  years: {
    quarters: 4,
    months: 12,
    weeks: Ue / 7,
    days: Ue,
    hours: Ue * 24,
    minutes: Ue * 24 * 60,
    seconds: Ue * 24 * 60 * 60,
    milliseconds: Ue * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: Ue / 28,
    days: Ue / 4,
    hours: Ue * 24 / 4,
    minutes: Ue * 24 * 60 / 4,
    seconds: Ue * 24 * 60 * 60 / 4,
    milliseconds: Ue * 24 * 60 * 60 * 1e3 / 4
  },
  months: {
    weeks: no / 7,
    days: no,
    hours: no * 24,
    minutes: no * 24 * 60,
    seconds: no * 24 * 60 * 60,
    milliseconds: no * 24 * 60 * 60 * 1e3
  },
  ...Og
}, Nn = [
  "years",
  "quarters",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds"
], aP = Nn.slice(0).reverse();
function Gt(e, t, n = !1) {
  const o = {
    values: n ? t.values : { ...e.values, ...t.values || {} },
    loc: e.loc.clone(t.loc),
    conversionAccuracy: t.conversionAccuracy || e.conversionAccuracy,
    matrix: t.matrix || e.matrix
  };
  return new W(o);
}
function Eg(e, t) {
  let n = t.milliseconds ?? 0;
  for (const o of aP.slice(1))
    t[o] && (n += t[o] * e[o].milliseconds);
  return n;
}
function Ad(e, t) {
  const n = Eg(e, t) < 0 ? -1 : 1;
  Nn.reduceRight((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const i = t[o] * n, s = e[r][o], a = Math.floor(i / s);
      t[r] += a * n, t[o] -= a * s * n;
    }
    return r;
  }, null), Nn.reduce((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const i = t[o] % 1;
      t[o] -= i, t[r] += i * e[o][r];
    }
    return r;
  }, null);
}
function lP(e) {
  const t = {};
  for (const [n, o] of Object.entries(e))
    o !== 0 && (t[n] = o);
  return t;
}
class W {
  /**
   * @private
   */
  constructor(t) {
    const n = t.conversionAccuracy === "longterm" || !1;
    let o = n ? sP : iP;
    t.matrix && (o = t.matrix), this.values = t.values, this.loc = t.loc || V.create(), this.conversionAccuracy = n ? "longterm" : "casual", this.invalid = t.invalid || null, this.matrix = o, this.isLuxonDuration = !0;
  }
  /**
   * Create Duration from a number of milliseconds.
   * @param {number} count of milliseconds
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  static fromMillis(t, n) {
    return W.fromObject({ milliseconds: t }, n);
  }
  /**
   * Create a Duration from a JavaScript object with keys like 'years' and 'hours'.
   * If this object is empty then a zero milliseconds duration is returned.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.years
   * @param {number} obj.quarters
   * @param {number} obj.months
   * @param {number} obj.weeks
   * @param {number} obj.days
   * @param {number} obj.hours
   * @param {number} obj.minutes
   * @param {number} obj.seconds
   * @param {number} obj.milliseconds
   * @param {Object} [opts=[]] - options for creating this Duration
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the custom conversion system to use
   * @return {Duration}
   */
  static fromObject(t, n = {}) {
    if (t == null || typeof t != "object")
      throw new Ae(
        `Duration.fromObject: argument expected to be an object, got ${t === null ? "null" : typeof t}`
      );
    return new W({
      values: ha(t, W.normalizeUnit),
      loc: V.fromObject(n),
      conversionAccuracy: n.conversionAccuracy,
      matrix: n.matrix
    });
  }
  /**
   * Create a Duration from DurationLike.
   *
   * @param {Object | number | Duration} durationLike
   * One of:
   * - object with keys like 'years' and 'hours'.
   * - number representing milliseconds
   * - Duration instance
   * @return {Duration}
   */
  static fromDurationLike(t) {
    if (_n(t))
      return W.fromMillis(t);
    if (W.isDuration(t))
      return t;
    if (typeof t == "object")
      return W.fromObject(t);
    throw new Ae(
      `Unknown duration argument ${t} of type ${typeof t}`
    );
  }
  /**
   * Create a Duration from an ISO 8601 duration string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the preset conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromISO('P3Y6M1W4DT12H30M5S').toObject() //=> { years: 3, months: 6, weeks: 1, days: 4, hours: 12, minutes: 30, seconds: 5 }
   * @example Duration.fromISO('PT23H').toObject() //=> { hours: 23 }
   * @example Duration.fromISO('P5Y3M').toObject() //=> { years: 5, months: 3 }
   * @return {Duration}
   */
  static fromISO(t, n) {
    const [o] = J_(t);
    return o ? W.fromObject(o, n) : W.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create a Duration from an ISO 8601 time string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @example Duration.fromISOTime('11:22:33.444').toObject() //=> { hours: 11, minutes: 22, seconds: 33, milliseconds: 444 }
   * @example Duration.fromISOTime('11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @return {Duration}
   */
  static fromISOTime(t, n) {
    const [o] = eP(t);
    return o ? W.fromObject(o, n) : W.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create an invalid Duration.
   * @param {string} reason - simple string of why this datetime is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Duration}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Ae("need to specify a reason the Duration is invalid");
    const o = t instanceof ct ? t : new ct(t, n);
    if (le.throwOnInvalid)
      throw new RR(o);
    return new W({ invalid: o });
  }
  /**
   * @private
   */
  static normalizeUnit(t) {
    const n = {
      year: "years",
      years: "years",
      quarter: "quarters",
      quarters: "quarters",
      month: "months",
      months: "months",
      week: "weeks",
      weeks: "weeks",
      day: "days",
      days: "days",
      hour: "hours",
      hours: "hours",
      minute: "minutes",
      minutes: "minutes",
      second: "seconds",
      seconds: "seconds",
      millisecond: "milliseconds",
      milliseconds: "milliseconds"
    }[t && t.toLowerCase()];
    if (!n)
      throw new Ry(t);
    return n;
  }
  /**
   * Check if an object is a Duration. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDuration(t) {
    return t && t.isLuxonDuration || !1;
  }
  /**
   * Get  the locale of a Duration, such 'en-GB'
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a Duration, such 'beng'. The numbering system is used when formatting the Duration
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Returns a string representation of this Duration formatted according to the specified format string. You may use these tokens:
   * * `S` for milliseconds
   * * `s` for seconds
   * * `m` for minutes
   * * `h` for hours
   * * `d` for days
   * * `w` for weeks
   * * `M` for months
   * * `y` for years
   * Notes:
   * * Add padding by repeating the token, e.g. "yy" pads the years to two digits, "hhhh" pads the hours out to four digits
   * * Tokens can be escaped by wrapping with single quotes.
   * * The duration will be converted to the set of units in the format string using {@link Duration#shiftTo} and the Durations's conversion accuracy setting.
   * @param {string} fmt - the format string
   * @param {Object} opts - options
   * @param {boolean} [opts.floor=true] - floor numerical values
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("y d s") //=> "1 6 2"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("yy dd sss") //=> "01 06 002"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("M S") //=> "12 518402000"
   * @return {string}
   */
  toFormat(t, n = {}) {
    const o = {
      ...n,
      floor: n.round !== !1 && n.floor !== !1
    };
    return this.isValid ? Ie.create(this.loc, o).formatDurationFromString(this, t) : xd;
  }
  /**
   * Returns a string representation of a Duration with all units included.
   * To modify its behavior, use `listStyle` and any Intl.NumberFormat option, though `unitDisplay` is especially relevant.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @param {Object} opts - Formatting options. Accepts the same keys as the options parameter of the native `Intl.NumberFormat` constructor, as well as `listStyle`.
   * @param {string} [opts.listStyle='narrow'] - How to format the merged list. Corresponds to the `style` property of the options parameter of the native `Intl.ListFormat` constructor.
   * @example
   * ```js
   * var dur = Duration.fromObject({ days: 1, hours: 5, minutes: 6 })
   * dur.toHuman() //=> '1 day, 5 hours, 6 minutes'
   * dur.toHuman({ listStyle: "long" }) //=> '1 day, 5 hours, and 6 minutes'
   * dur.toHuman({ unitDisplay: "short" }) //=> '1 day, 5 hr, 6 min'
   * ```
   */
  toHuman(t = {}) {
    if (!this.isValid)
      return xd;
    const n = Nn.map((o) => {
      const r = this.values[o];
      return N(r) ? null : this.loc.numberFormatter({ style: "unit", unitDisplay: "long", ...t, unit: o.slice(0, -1) }).format(r);
    }).filter((o) => o);
    return this.loc.listFormatter({ type: "conjunction", style: t.listStyle || "narrow", ...t }).format(n);
  }
  /**
   * Returns a JavaScript object with this Duration's values.
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toObject() //=> { years: 1, days: 6, seconds: 2 }
   * @return {Object}
   */
  toObject() {
    return this.isValid ? { ...this.values } : {};
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromObject({ years: 3, seconds: 45 }).toISO() //=> 'P3YT45S'
   * @example Duration.fromObject({ months: 4, seconds: 45 }).toISO() //=> 'P4MT45S'
   * @example Duration.fromObject({ months: 5 }).toISO() //=> 'P5M'
   * @example Duration.fromObject({ minutes: 5 }).toISO() //=> 'PT5M'
   * @example Duration.fromObject({ milliseconds: 6 }).toISO() //=> 'PT0.006S'
   * @return {string}
   */
  toISO() {
    if (!this.isValid)
      return null;
    let t = "P";
    return this.years !== 0 && (t += this.years + "Y"), (this.months !== 0 || this.quarters !== 0) && (t += this.months + this.quarters * 3 + "M"), this.weeks !== 0 && (t += this.weeks + "W"), this.days !== 0 && (t += this.days + "D"), (this.hours !== 0 || this.minutes !== 0 || this.seconds !== 0 || this.milliseconds !== 0) && (t += "T"), this.hours !== 0 && (t += this.hours + "H"), this.minutes !== 0 && (t += this.minutes + "M"), (this.seconds !== 0 || this.milliseconds !== 0) && (t += Mu(this.seconds + this.milliseconds / 1e3, 3) + "S"), t === "P" && (t += "T0S"), t;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration, formatted as a time of day.
   * Note that this will return null if the duration is invalid, negative, or equal to or greater than 24 hours.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example Duration.fromObject({ hours: 11 }).toISOTime() //=> '11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressMilliseconds: true }) //=> '11:00:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressSeconds: true }) //=> '11:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ includePrefix: true }) //=> 'T11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ format: 'basic' }) //=> '110000.000'
   * @return {string}
   */
  toISOTime(t = {}) {
    if (!this.isValid)
      return null;
    const n = this.toMillis();
    return n < 0 || n >= 864e5 ? null : (t = {
      suppressMilliseconds: !1,
      suppressSeconds: !1,
      includePrefix: !1,
      format: "extended",
      ...t,
      includeOffset: !1
    }, D.fromMillis(n, { zone: "UTC" }).toISOTime(t));
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in debugging.
   * @return {string}
   */
  toString() {
    return this.toISO();
  }
  /**
   * Returns a string representation of this Duration appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Duration { values: ${JSON.stringify(this.values)} }` : `Duration { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns an milliseconds value of this Duration.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? Eg(this.matrix, this.values) : NaN;
  }
  /**
   * Returns an milliseconds value of this Duration. Alias of {@link toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Make this Duration longer by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = W.fromDurationLike(t), o = {};
    for (const r of Nn)
      (Uo(n.values, r) || Uo(this.values, r)) && (o[r] = n.get(r) + this.get(r));
    return Gt(this, { values: o }, !0);
  }
  /**
   * Make this Duration shorter by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = W.fromDurationLike(t);
    return this.plus(n.negate());
  }
  /**
   * Scale this Duration by the specified amount. Return a newly-constructed Duration.
   * @param {function} fn - The function to apply to each unit. Arity is 1 or 2: the value of the unit and, optionally, the unit name. Must return a number.
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits(x => x * 2) //=> { hours: 2, minutes: 60 }
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits((x, u) => u === "hours" ? x * 2 : x) //=> { hours: 2, minutes: 30 }
   * @return {Duration}
   */
  mapUnits(t) {
    if (!this.isValid)
      return this;
    const n = {};
    for (const o of Object.keys(this.values))
      n[o] = ug(t(this.values[o], o));
    return Gt(this, { values: n }, !0);
  }
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example Duration.fromObject({years: 2, days: 3}).get('years') //=> 2
   * @example Duration.fromObject({years: 2, days: 3}).get('months') //=> 0
   * @example Duration.fromObject({years: 2, days: 3}).get('days') //=> 3
   * @return {number}
   */
  get(t) {
    return this[W.normalizeUnit(t)];
  }
  /**
   * "Set" the values of specified units. Return a newly-constructed Duration.
   * @param {Object} values - a mapping of units to numbers
   * @example dur.set({ years: 2017 })
   * @example dur.set({ hours: 8, minutes: 30 })
   * @return {Duration}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = { ...this.values, ...ha(t, W.normalizeUnit) };
    return Gt(this, { values: n });
  }
  /**
   * "Set" the locale and/or numberingSystem.  Returns a newly-constructed Duration.
   * @example dur.reconfigure({ locale: 'en-GB' })
   * @return {Duration}
   */
  reconfigure({ locale: t, numberingSystem: n, conversionAccuracy: o, matrix: r } = {}) {
    const s = { loc: this.loc.clone({ locale: t, numberingSystem: n }), matrix: r, conversionAccuracy: o };
    return Gt(this, s);
  }
  /**
   * Return the length of the duration in the specified unit.
   * @param {string} unit - a unit such as 'minutes' or 'days'
   * @example Duration.fromObject({years: 1}).as('days') //=> 365
   * @example Duration.fromObject({years: 1}).as('months') //=> 12
   * @example Duration.fromObject({hours: 60}).as('days') //=> 2.5
   * @return {number}
   */
  as(t) {
    return this.isValid ? this.shiftTo(t).get(t) : NaN;
  }
  /**
   * Reduce this Duration to its canonical representation in its current units.
   * Assuming the overall value of the Duration is positive, this means:
   * - excessive values for lower-order units are converted to higher-order units (if possible, see first and second example)
   * - negative lower-order units are converted to higher order units (there must be such a higher order unit, otherwise
   *   the overall value would be negative, see third example)
   * - fractional values for higher-order units are converted to lower-order units (if possible, see fourth example)
   *
   * If the overall value is negative, the result of this method is equivalent to `this.negate().normalize().negate()`.
   * @example Duration.fromObject({ years: 2, days: 5000 }).normalize().toObject() //=> { years: 15, days: 255 }
   * @example Duration.fromObject({ days: 5000 }).normalize().toObject() //=> { days: 5000 }
   * @example Duration.fromObject({ hours: 12, minutes: -45 }).normalize().toObject() //=> { hours: 11, minutes: 15 }
   * @example Duration.fromObject({ years: 2.5, days: 0, hours: 0 }).normalize().toObject() //=> { years: 2, days: 182, hours: 12 }
   * @return {Duration}
   */
  normalize() {
    if (!this.isValid)
      return this;
    const t = this.toObject();
    return Ad(this.matrix, t), Gt(this, { values: t }, !0);
  }
  /**
   * Rescale units to its largest representation
   * @example Duration.fromObject({ milliseconds: 90000 }).rescale().toObject() //=> { minutes: 1, seconds: 30 }
   * @return {Duration}
   */
  rescale() {
    if (!this.isValid)
      return this;
    const t = lP(this.normalize().shiftToAll().toObject());
    return Gt(this, { values: t }, !0);
  }
  /**
   * Convert this Duration into its representation in a different set of units.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).shiftTo('minutes', 'milliseconds').toObject() //=> { minutes: 60, milliseconds: 30000 }
   * @return {Duration}
   */
  shiftTo(...t) {
    if (!this.isValid)
      return this;
    if (t.length === 0)
      return this;
    t = t.map((s) => W.normalizeUnit(s));
    const n = {}, o = {}, r = this.toObject();
    let i;
    for (const s of Nn)
      if (t.indexOf(s) >= 0) {
        i = s;
        let a = 0;
        for (const c in o)
          a += this.matrix[c][s] * o[c], o[c] = 0;
        _n(r[s]) && (a += r[s]);
        const l = Math.trunc(a);
        n[s] = l, o[s] = (a * 1e3 - l * 1e3) / 1e3;
      } else
        _n(r[s]) && (o[s] = r[s]);
    for (const s in o)
      o[s] !== 0 && (n[i] += s === i ? o[s] : o[s] / this.matrix[i][s]);
    return Ad(this.matrix, n), Gt(this, { values: n }, !0);
  }
  /**
   * Shift this Duration to all available units.
   * Same as shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds")
   * @return {Duration}
   */
  shiftToAll() {
    return this.isValid ? this.shiftTo(
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    ) : this;
  }
  /**
   * Return the negative of this Duration.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).negate().toObject() //=> { hours: -1, seconds: -30 }
   * @return {Duration}
   */
  negate() {
    if (!this.isValid)
      return this;
    const t = {};
    for (const n of Object.keys(this.values))
      t[n] = this.values[n] === 0 ? 0 : -this.values[n];
    return Gt(this, { values: t }, !0);
  }
  /**
   * Get the years.
   * @type {number}
   */
  get years() {
    return this.isValid ? this.values.years || 0 : NaN;
  }
  /**
   * Get the quarters.
   * @type {number}
   */
  get quarters() {
    return this.isValid ? this.values.quarters || 0 : NaN;
  }
  /**
   * Get the months.
   * @type {number}
   */
  get months() {
    return this.isValid ? this.values.months || 0 : NaN;
  }
  /**
   * Get the weeks
   * @type {number}
   */
  get weeks() {
    return this.isValid ? this.values.weeks || 0 : NaN;
  }
  /**
   * Get the days.
   * @type {number}
   */
  get days() {
    return this.isValid ? this.values.days || 0 : NaN;
  }
  /**
   * Get the hours.
   * @type {number}
   */
  get hours() {
    return this.isValid ? this.values.hours || 0 : NaN;
  }
  /**
   * Get the minutes.
   * @type {number}
   */
  get minutes() {
    return this.isValid ? this.values.minutes || 0 : NaN;
  }
  /**
   * Get the seconds.
   * @return {number}
   */
  get seconds() {
    return this.isValid ? this.values.seconds || 0 : NaN;
  }
  /**
   * Get the milliseconds.
   * @return {number}
   */
  get milliseconds() {
    return this.isValid ? this.values.milliseconds || 0 : NaN;
  }
  /**
   * Returns whether the Duration is invalid. Invalid durations are returned by diff operations
   * on invalid DateTimes or Intervals.
   * @return {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this Duration became invalid, or null if the Duration is valid
   * @return {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Duration became invalid, or null if the Duration is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Equality check
   * Two Durations are equal iff they have the same units and the same values for each unit.
   * @param {Duration} other
   * @return {boolean}
   */
  equals(t) {
    if (!this.isValid || !t.isValid || !this.loc.equals(t.loc))
      return !1;
    function n(o, r) {
      return o === void 0 || o === 0 ? r === void 0 || r === 0 : o === r;
    }
    for (const o of Nn)
      if (!n(this.values[o], t.values[o]))
        return !1;
    return !0;
  }
}
const oo = "Invalid Interval";
function cP(e, t) {
  return !e || !e.isValid ? se.invalid("missing or invalid start") : !t || !t.isValid ? se.invalid("missing or invalid end") : t < e ? se.invalid(
    "end before start",
    `The end of an interval must be after its start, but you had start=${e.toISO()} and end=${t.toISO()}`
  ) : null;
}
class se {
  /**
   * @private
   */
  constructor(t) {
    this.s = t.start, this.e = t.end, this.invalid = t.invalid || null, this.isLuxonInterval = !0;
  }
  /**
   * Create an invalid Interval.
   * @param {string} reason - simple string of why this Interval is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Interval}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Ae("need to specify a reason the Interval is invalid");
    const o = t instanceof ct ? t : new ct(t, n);
    if (le.throwOnInvalid)
      throw new LR(o);
    return new se({ invalid: o });
  }
  /**
   * Create an Interval from a start DateTime and an end DateTime. Inclusive of the start but not the end.
   * @param {DateTime|Date|Object} start
   * @param {DateTime|Date|Object} end
   * @return {Interval}
   */
  static fromDateTimes(t, n) {
    const o = yr(t), r = yr(n), i = cP(o, r);
    return i ?? new se({
      start: o,
      end: r
    });
  }
  /**
   * Create an Interval from a start DateTime and a Duration to extend to.
   * @param {DateTime|Date|Object} start
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static after(t, n) {
    const o = W.fromDurationLike(n), r = yr(t);
    return se.fromDateTimes(r, r.plus(o));
  }
  /**
   * Create an Interval from an end DateTime and a Duration to extend backwards to.
   * @param {DateTime|Date|Object} end
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static before(t, n) {
    const o = W.fromDurationLike(n), r = yr(t);
    return se.fromDateTimes(r.minus(o), r);
  }
  /**
   * Create an Interval from an ISO 8601 string.
   * Accepts `<start>/<end>`, `<start>/<duration>`, and `<duration>/<end>` formats.
   * @param {string} text - the ISO string to parse
   * @param {Object} [opts] - options to pass {@link DateTime#fromISO} and optionally {@link Duration#fromISO}
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {Interval}
   */
  static fromISO(t, n) {
    const [o, r] = (t || "").split("/", 2);
    if (o && r) {
      let i, s;
      try {
        i = D.fromISO(o, n), s = i.isValid;
      } catch {
        s = !1;
      }
      let a, l;
      try {
        a = D.fromISO(r, n), l = a.isValid;
      } catch {
        l = !1;
      }
      if (s && l)
        return se.fromDateTimes(i, a);
      if (s) {
        const c = W.fromISO(r, n);
        if (c.isValid)
          return se.after(i, c);
      } else if (l) {
        const c = W.fromISO(o, n);
        if (c.isValid)
          return se.before(a, c);
      }
    }
    return se.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Check if an object is an Interval. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isInterval(t) {
    return t && t.isLuxonInterval || !1;
  }
  /**
   * Returns the start of the Interval
   * @type {DateTime}
   */
  get start() {
    return this.isValid ? this.s : null;
  }
  /**
   * Returns the end of the Interval
   * @type {DateTime}
   */
  get end() {
    return this.isValid ? this.e : null;
  }
  /**
   * Returns whether this Interval's end is at least its start, meaning that the Interval isn't 'backwards'.
   * @type {boolean}
   */
  get isValid() {
    return this.invalidReason === null;
  }
  /**
   * Returns an error code if this Interval is invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Interval became invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Returns the length of the Interval in the specified unit.
   * @param {string} unit - the unit (such as 'hours' or 'days') to return the length in.
   * @return {number}
   */
  length(t = "milliseconds") {
    return this.isValid ? this.toDuration(t).get(t) : NaN;
  }
  /**
   * Returns the count of minutes, hours, days, months, or years included in the Interval, even in part.
   * Unlike {@link Interval#length} this counts sections of the calendar, not periods of time, e.g. specifying 'day'
   * asks 'what dates are included in this interval?', not 'how many days long is this interval?'
   * @param {string} [unit='milliseconds'] - the unit of time to count.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; this operation will always use the locale of the start DateTime
   * @return {number}
   */
  count(t = "milliseconds", n) {
    if (!this.isValid)
      return NaN;
    const o = this.start.startOf(t, n);
    let r;
    return n != null && n.useLocaleWeeks ? r = this.end.reconfigure({ locale: o.locale }) : r = this.end, r = r.startOf(t, n), Math.floor(r.diff(o, t).get(t)) + (r.valueOf() !== this.end.valueOf());
  }
  /**
   * Returns whether this Interval's start and end are both in the same unit of time
   * @param {string} unit - the unit of time to check sameness on
   * @return {boolean}
   */
  hasSame(t) {
    return this.isValid ? this.isEmpty() || this.e.minus(1).hasSame(this.s, t) : !1;
  }
  /**
   * Return whether this Interval has the same start and end DateTimes.
   * @return {boolean}
   */
  isEmpty() {
    return this.s.valueOf() === this.e.valueOf();
  }
  /**
   * Return whether this Interval's start is after the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isAfter(t) {
    return this.isValid ? this.s > t : !1;
  }
  /**
   * Return whether this Interval's end is before the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isBefore(t) {
    return this.isValid ? this.e <= t : !1;
  }
  /**
   * Return whether this Interval contains the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  contains(t) {
    return this.isValid ? this.s <= t && this.e > t : !1;
  }
  /**
   * "Sets" the start and/or end dates. Returns a newly-constructed Interval.
   * @param {Object} values - the values to set
   * @param {DateTime} values.start - the starting DateTime
   * @param {DateTime} values.end - the ending DateTime
   * @return {Interval}
   */
  set({ start: t, end: n } = {}) {
    return this.isValid ? se.fromDateTimes(t || this.s, n || this.e) : this;
  }
  /**
   * Split this Interval at each of the specified DateTimes
   * @param {...DateTime} dateTimes - the unit of time to count.
   * @return {Array}
   */
  splitAt(...t) {
    if (!this.isValid)
      return [];
    const n = t.map(yr).filter((s) => this.contains(s)).sort((s, a) => s.toMillis() - a.toMillis()), o = [];
    let { s: r } = this, i = 0;
    for (; r < this.e; ) {
      const s = n[i] || this.e, a = +s > +this.e ? this.e : s;
      o.push(se.fromDateTimes(r, a)), r = a, i += 1;
    }
    return o;
  }
  /**
   * Split this Interval into smaller Intervals, each of the specified length.
   * Left over time is grouped into a smaller interval
   * @param {Duration|Object|number} duration - The length of each resulting interval.
   * @return {Array}
   */
  splitBy(t) {
    const n = W.fromDurationLike(t);
    if (!this.isValid || !n.isValid || n.as("milliseconds") === 0)
      return [];
    let { s: o } = this, r = 1, i;
    const s = [];
    for (; o < this.e; ) {
      const a = this.start.plus(n.mapUnits((l) => l * r));
      i = +a > +this.e ? this.e : a, s.push(se.fromDateTimes(o, i)), o = i, r += 1;
    }
    return s;
  }
  /**
   * Split this Interval into the specified number of smaller intervals.
   * @param {number} numberOfParts - The number of Intervals to divide the Interval into.
   * @return {Array}
   */
  divideEqually(t) {
    return this.isValid ? this.splitBy(this.length() / t).slice(0, t) : [];
  }
  /**
   * Return whether this Interval overlaps with the specified Interval
   * @param {Interval} other
   * @return {boolean}
   */
  overlaps(t) {
    return this.e > t.s && this.s < t.e;
  }
  /**
   * Return whether this Interval's end is adjacent to the specified Interval's start.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsStart(t) {
    return this.isValid ? +this.e == +t.s : !1;
  }
  /**
   * Return whether this Interval's start is adjacent to the specified Interval's end.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsEnd(t) {
    return this.isValid ? +t.e == +this.s : !1;
  }
  /**
   * Return whether this Interval engulfs the start and end of the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  engulfs(t) {
    return this.isValid ? this.s <= t.s && this.e >= t.e : !1;
  }
  /**
   * Return whether this Interval has the same start and end as the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  equals(t) {
    return !this.isValid || !t.isValid ? !1 : this.s.equals(t.s) && this.e.equals(t.e);
  }
  /**
   * Return an Interval representing the intersection of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the maximum start time and the minimum end time of the two Intervals.
   * Returns null if the intersection is empty, meaning, the intervals don't intersect.
   * @param {Interval} other
   * @return {Interval}
   */
  intersection(t) {
    if (!this.isValid)
      return this;
    const n = this.s > t.s ? this.s : t.s, o = this.e < t.e ? this.e : t.e;
    return n >= o ? null : se.fromDateTimes(n, o);
  }
  /**
   * Return an Interval representing the union of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the minimum start time and the maximum end time of the two Intervals.
   * @param {Interval} other
   * @return {Interval}
   */
  union(t) {
    if (!this.isValid)
      return this;
    const n = this.s < t.s ? this.s : t.s, o = this.e > t.e ? this.e : t.e;
    return se.fromDateTimes(n, o);
  }
  /**
   * Merge an array of Intervals into a equivalent minimal set of Intervals.
   * Combines overlapping and adjacent Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static merge(t) {
    const [n, o] = t.sort((r, i) => r.s - i.s).reduce(
      ([r, i], s) => i ? i.overlaps(s) || i.abutsStart(s) ? [r, i.union(s)] : [r.concat([i]), s] : [r, s],
      [[], null]
    );
    return o && n.push(o), n;
  }
  /**
   * Return an array of Intervals representing the spans of time that only appear in one of the specified Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static xor(t) {
    let n = null, o = 0;
    const r = [], i = t.map((l) => [
      { time: l.s, type: "s" },
      { time: l.e, type: "e" }
    ]), s = Array.prototype.concat(...i), a = s.sort((l, c) => l.time - c.time);
    for (const l of a)
      o += l.type === "s" ? 1 : -1, o === 1 ? n = l.time : (n && +n != +l.time && r.push(se.fromDateTimes(n, l.time)), n = null);
    return se.merge(r);
  }
  /**
   * Return an Interval representing the span of time in this Interval that doesn't overlap with any of the specified Intervals.
   * @param {...Interval} intervals
   * @return {Array}
   */
  difference(...t) {
    return se.xor([this].concat(t)).map((n) => this.intersection(n)).filter((n) => n && !n.isEmpty());
  }
  /**
   * Returns a string representation of this Interval appropriate for debugging.
   * @return {string}
   */
  toString() {
    return this.isValid ? `[${this.s.toISO()} – ${this.e.toISO()})` : oo;
  }
  /**
   * Returns a string representation of this Interval appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Interval { start: ${this.s.toISO()}, end: ${this.e.toISO()} }` : `Interval { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns a localized string representing this Interval. Accepts the same options as the
   * Intl.DateTimeFormat constructor and any presets defined by Luxon, such as
   * {@link DateTime.DATE_FULL} or {@link DateTime.TIME_SIMPLE}. The exact behavior of this method
   * is browser-specific, but in general it will return an appropriate representation of the
   * Interval in the assigned locale. Defaults to the system's locale if no locale has been
   * specified.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {Object} [formatOpts=DateTime.DATE_SHORT] - Either a DateTime preset or
   * Intl.DateTimeFormat constructor options.
   * @param {Object} opts - Options to override the configuration of the start DateTime.
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(); //=> 11/7/2022 – 11/8/2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL); //=> November 7 – 8, 2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL, { locale: 'fr-FR' }); //=> 7–8 novembre 2022
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString(DateTime.TIME_SIMPLE); //=> 6:00 – 8:00 PM
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> Mon, Nov 07, 6:00 – 8:00 p
   * @return {string}
   */
  toLocaleString(t = ua, n = {}) {
    return this.isValid ? Ie.create(this.s.loc.clone(n), t).formatInterval(this) : oo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Interval.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISO(t) {
    return this.isValid ? `${this.s.toISO(t)}/${this.e.toISO(t)}` : oo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of date of this Interval.
   * The time components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {string}
   */
  toISODate() {
    return this.isValid ? `${this.s.toISODate()}/${this.e.toISODate()}` : oo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of time of this Interval.
   * The date components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISOTime(t) {
    return this.isValid ? `${this.s.toISOTime(t)}/${this.e.toISOTime(t)}` : oo;
  }
  /**
   * Returns a string representation of this Interval formatted according to the specified format
   * string. **You may not want this.** See {@link Interval#toLocaleString} for a more flexible
   * formatting tool.
   * @param {string} dateFormat - The format string. This string formats the start and end time.
   * See {@link DateTime#toFormat} for details.
   * @param {Object} opts - Options.
   * @param {string} [opts.separator =  ' – '] - A separator to place between the start and end
   * representations.
   * @return {string}
   */
  toFormat(t, { separator: n = " – " } = {}) {
    return this.isValid ? `${this.s.toFormat(t)}${n}${this.e.toFormat(t)}` : oo;
  }
  /**
   * Return a Duration representing the time spanned by this interval.
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example Interval.fromDateTimes(dt1, dt2).toDuration().toObject() //=> { milliseconds: 88489257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('days').toObject() //=> { days: 1.0241812152777778 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes']).toObject() //=> { hours: 24, minutes: 34.82095 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes', 'seconds']).toObject() //=> { hours: 24, minutes: 34, seconds: 49.257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('seconds').toObject() //=> { seconds: 88489.257 }
   * @return {Duration}
   */
  toDuration(t, n) {
    return this.isValid ? this.e.diff(this.s, t, n) : W.invalid(this.invalidReason);
  }
  /**
   * Run mapFn on the interval start and end, returning a new Interval from the resulting DateTimes
   * @param {function} mapFn
   * @return {Interval}
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.toUTC())
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.plus({ hours: 2 }))
   */
  mapEndpoints(t) {
    return se.fromDateTimes(t(this.s), t(this.e));
  }
}
class Yi {
  /**
   * Return whether the specified zone contains a DST.
   * @param {string|Zone} [zone='local'] - Zone to check. Defaults to the environment's local zone.
   * @return {boolean}
   */
  static hasDST(t = le.defaultZone) {
    const n = D.now().setZone(t).set({ month: 12 });
    return !t.isUniversal && n.offset !== n.set({ month: 6 }).offset;
  }
  /**
   * Return whether the specified zone is a valid IANA specifier.
   * @param {string} zone - Zone to check
   * @return {boolean}
   */
  static isValidIANAZone(t) {
    return Wt.isValidZone(t);
  }
  /**
   * Converts the input into a {@link Zone} instance.
   *
   * * If `input` is already a Zone instance, it is returned unchanged.
   * * If `input` is a string containing a valid time zone name, a Zone instance
   *   with that name is returned.
   * * If `input` is a string that doesn't refer to a known time zone, a Zone
   *   instance with {@link Zone#isValid} == false is returned.
   * * If `input is a number, a Zone instance with the specified fixed offset
   *   in minutes is returned.
   * * If `input` is `null` or `undefined`, the default zone is returned.
   * @param {string|Zone|number} [input] - the value to be converted
   * @return {Zone}
   */
  static normalizeZone(t) {
    return nn(t, le.defaultZone);
  }
  /**
   * Get the weekday on which the week starts according to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number} the start of the week, 1 for Monday through 7 for Sunday
   */
  static getStartOfWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getStartOfWeek();
  }
  /**
   * Get the minimum number of days necessary in a week before it is considered part of the next year according
   * to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number}
   */
  static getMinimumDaysInFirstWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getMinDaysInFirstWeek();
  }
  /**
   * Get the weekdays, which are considered the weekend according to the given locale
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number[]} an array of weekdays, 1 for Monday through 7 for Sunday
   */
  static getWeekendWeekdays({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getWeekendDays().slice();
  }
  /**
   * Return an array of standalone month names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @example Info.months()[0] //=> 'January'
   * @example Info.months('short')[0] //=> 'Jan'
   * @example Info.months('numeric')[0] //=> '1'
   * @example Info.months('short', { locale: 'fr-CA' } )[0] //=> 'janv.'
   * @example Info.months('numeric', { locale: 'ar' })[0] //=> '١'
   * @example Info.months('long', { outputCalendar: 'islamic' })[0] //=> 'Rabiʻ I'
   * @return {Array}
   */
  static months(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: i = "gregory" } = {}) {
    return (r || V.create(n, o, i)).months(t);
  }
  /**
   * Return an array of format month names.
   * Format months differ from standalone months in that they're meant to appear next to the day of the month. In some languages, that
   * changes the string.
   * See {@link Info#months}
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @return {Array}
   */
  static monthsFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: i = "gregory" } = {}) {
    return (r || V.create(n, o, i)).months(t, !0);
  }
  /**
   * Return an array of standalone week names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the weekday representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @example Info.weekdays()[0] //=> 'Monday'
   * @example Info.weekdays('short')[0] //=> 'Mon'
   * @example Info.weekdays('short', { locale: 'fr-CA' })[0] //=> 'lun.'
   * @example Info.weekdays('short', { locale: 'ar' })[0] //=> 'الاثنين'
   * @return {Array}
   */
  static weekdays(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t);
  }
  /**
   * Return an array of format week names.
   * Format weekdays differ from standalone weekdays in that they're meant to appear next to more date information. In some languages, that
   * changes the string.
   * See {@link Info#weekdays}
   * @param {string} [length='long'] - the length of the month representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale=null] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @return {Array}
   */
  static weekdaysFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t, !0);
  }
  /**
   * Return an array of meridiems.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.meridiems() //=> [ 'AM', 'PM' ]
   * @example Info.meridiems({ locale: 'my' }) //=> [ 'နံနက်', 'ညနေ' ]
   * @return {Array}
   */
  static meridiems({ locale: t = null } = {}) {
    return V.create(t).meridiems();
  }
  /**
   * Return an array of eras, such as ['BC', 'AD']. The locale can be specified, but the calendar system is always Gregorian.
   * @param {string} [length='short'] - the length of the era representation, such as "short" or "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.eras() //=> [ 'BC', 'AD' ]
   * @example Info.eras('long') //=> [ 'Before Christ', 'Anno Domini' ]
   * @example Info.eras('long', { locale: 'fr' }) //=> [ 'avant Jésus-Christ', 'après Jésus-Christ' ]
   * @return {Array}
   */
  static eras(t = "short", { locale: n = null } = {}) {
    return V.create(n, null, "gregory").eras(t);
  }
  /**
   * Return the set of available features in this environment.
   * Some features of Luxon are not available in all environments. For example, on older browsers, relative time formatting support is not available. Use this function to figure out if that's the case.
   * Keys:
   * * `relative`: whether this environment supports relative time formatting
   * * `localeWeek`: whether this environment supports different weekdays for the start of the week based on the locale
   * @example Info.features() //=> { relative: false, localeWeek: true }
   * @return {Object}
   */
  static features() {
    return { relative: ag(), localeWeek: lg() };
  }
}
function $d(e, t) {
  const n = (r) => r.toUTC(0, { keepLocalTime: !0 }).startOf("day").valueOf(), o = n(t) - n(e);
  return Math.floor(W.fromMillis(o).as("days"));
}
function uP(e, t, n) {
  const o = [
    ["years", (l, c) => c.year - l.year],
    ["quarters", (l, c) => c.quarter - l.quarter + (c.year - l.year) * 4],
    ["months", (l, c) => c.month - l.month + (c.year - l.year) * 12],
    [
      "weeks",
      (l, c) => {
        const u = $d(l, c);
        return (u - u % 7) / 7;
      }
    ],
    ["days", $d]
  ], r = {}, i = e;
  let s, a;
  for (const [l, c] of o)
    n.indexOf(l) >= 0 && (s = l, r[l] = c(e, t), a = i.plus(r), a > t ? (r[l]--, e = i.plus(r), e > t && (a = e, r[l]--, e = i.plus(r))) : e = a);
  return [e, r, a, s];
}
function fP(e, t, n, o) {
  let [r, i, s, a] = uP(e, t, n);
  const l = t - r, c = n.filter(
    (f) => ["hours", "minutes", "seconds", "milliseconds"].indexOf(f) >= 0
  );
  c.length === 0 && (s < t && (s = r.plus({ [a]: 1 })), s !== r && (i[a] = (i[a] || 0) + l / (s - r)));
  const u = W.fromObject(i, o);
  return c.length > 0 ? W.fromMillis(l, o).shiftTo(...c).plus(u) : u;
}
const Ru = {
  arab: "[٠-٩]",
  arabext: "[۰-۹]",
  bali: "[᭐-᭙]",
  beng: "[০-৯]",
  deva: "[०-९]",
  fullwide: "[０-９]",
  gujr: "[૦-૯]",
  hanidec: "[〇|一|二|三|四|五|六|七|八|九]",
  khmr: "[០-៩]",
  knda: "[೦-೯]",
  laoo: "[໐-໙]",
  limb: "[᥆-᥏]",
  mlym: "[൦-൯]",
  mong: "[᠐-᠙]",
  mymr: "[၀-၉]",
  orya: "[୦-୯]",
  tamldec: "[௦-௯]",
  telu: "[౦-౯]",
  thai: "[๐-๙]",
  tibt: "[༠-༩]",
  latn: "\\d"
}, Nd = {
  arab: [1632, 1641],
  arabext: [1776, 1785],
  bali: [6992, 7001],
  beng: [2534, 2543],
  deva: [2406, 2415],
  fullwide: [65296, 65303],
  gujr: [2790, 2799],
  khmr: [6112, 6121],
  knda: [3302, 3311],
  laoo: [3792, 3801],
  limb: [6470, 6479],
  mlym: [3430, 3439],
  mong: [6160, 6169],
  mymr: [4160, 4169],
  orya: [2918, 2927],
  tamldec: [3046, 3055],
  telu: [3174, 3183],
  thai: [3664, 3673],
  tibt: [3872, 3881]
}, dP = Ru.hanidec.replace(/[\[|\]]/g, "").split("");
function hP(e) {
  let t = parseInt(e, 10);
  if (isNaN(t)) {
    t = "";
    for (let n = 0; n < e.length; n++) {
      const o = e.charCodeAt(n);
      if (e[n].search(Ru.hanidec) !== -1)
        t += dP.indexOf(e[n]);
      else
        for (const r in Nd) {
          const [i, s] = Nd[r];
          o >= i && o <= s && (t += o - i);
        }
    }
    return parseInt(t, 10);
  } else
    return t;
}
function it({ numberingSystem: e }, t = "") {
  return new RegExp(`${Ru[e || "latn"]}${t}`);
}
const mP = "missing Intl.DateTimeFormat.formatToParts support";
function q(e, t = (n) => n) {
  return { regex: e, deser: ([n]) => t(hP(n)) };
}
const pP = " ", Sg = `[ ${pP}]`, xg = new RegExp(Sg, "g");
function yP(e) {
  return e.replace(/\./g, "\\.?").replace(xg, Sg);
}
function Md(e) {
  return e.replace(/\./g, "").replace(xg, " ").toLowerCase();
}
function st(e, t) {
  return e === null ? null : {
    regex: RegExp(e.map(yP).join("|")),
    deser: ([n]) => e.findIndex((o) => Md(n) === Md(o)) + t
  };
}
function kd(e, t) {
  return { regex: e, deser: ([, n, o]) => Ka(n, o), groups: t };
}
function Ki(e) {
  return { regex: e, deser: ([t]) => t };
}
function gP(e) {
  return e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
function vP(e, t) {
  const n = it(t), o = it(t, "{2}"), r = it(t, "{3}"), i = it(t, "{4}"), s = it(t, "{6}"), a = it(t, "{1,2}"), l = it(t, "{1,3}"), c = it(t, "{1,6}"), u = it(t, "{1,9}"), f = it(t, "{2,4}"), d = it(t, "{4,6}"), h = (v) => ({ regex: RegExp(gP(v.val)), deser: ([T]) => T, literal: !0 }), g = ((v) => {
    if (e.literal)
      return h(v);
    switch (v.val) {
      case "G":
        return st(t.eras("short"), 0);
      case "GG":
        return st(t.eras("long"), 0);
      case "y":
        return q(c);
      case "yy":
        return q(f, oc);
      case "yyyy":
        return q(i);
      case "yyyyy":
        return q(d);
      case "yyyyyy":
        return q(s);
      case "M":
        return q(a);
      case "MM":
        return q(o);
      case "MMM":
        return st(t.months("short", !0), 1);
      case "MMMM":
        return st(t.months("long", !0), 1);
      case "L":
        return q(a);
      case "LL":
        return q(o);
      case "LLL":
        return st(t.months("short", !1), 1);
      case "LLLL":
        return st(t.months("long", !1), 1);
      case "d":
        return q(a);
      case "dd":
        return q(o);
      case "o":
        return q(l);
      case "ooo":
        return q(r);
      case "HH":
        return q(o);
      case "H":
        return q(a);
      case "hh":
        return q(o);
      case "h":
        return q(a);
      case "mm":
        return q(o);
      case "m":
        return q(a);
      case "q":
        return q(a);
      case "qq":
        return q(o);
      case "s":
        return q(a);
      case "ss":
        return q(o);
      case "S":
        return q(l);
      case "SSS":
        return q(r);
      case "u":
        return Ki(u);
      case "uu":
        return Ki(a);
      case "uuu":
        return q(n);
      case "a":
        return st(t.meridiems(), 0);
      case "kkkk":
        return q(i);
      case "kk":
        return q(f, oc);
      case "W":
        return q(a);
      case "WW":
        return q(o);
      case "E":
      case "c":
        return q(n);
      case "EEE":
        return st(t.weekdays("short", !1), 1);
      case "EEEE":
        return st(t.weekdays("long", !1), 1);
      case "ccc":
        return st(t.weekdays("short", !0), 1);
      case "cccc":
        return st(t.weekdays("long", !0), 1);
      case "Z":
      case "ZZ":
        return kd(new RegExp(`([+-]${a.source})(?::(${o.source}))?`), 2);
      case "ZZZ":
        return kd(new RegExp(`([+-]${a.source})(${o.source})?`), 2);
      case "z":
        return Ki(/[a-z_+-/]{1,256}?/i);
      case " ":
        return Ki(/[^\S\n\r]/);
      default:
        return h(v);
    }
  })(e) || {
    invalidReason: mP
  };
  return g.token = e, g;
}
const bP = {
  year: {
    "2-digit": "yy",
    numeric: "yyyyy"
  },
  month: {
    numeric: "M",
    "2-digit": "MM",
    short: "MMM",
    long: "MMMM"
  },
  day: {
    numeric: "d",
    "2-digit": "dd"
  },
  weekday: {
    short: "EEE",
    long: "EEEE"
  },
  dayperiod: "a",
  dayPeriod: "a",
  hour12: {
    numeric: "h",
    "2-digit": "hh"
  },
  hour24: {
    numeric: "H",
    "2-digit": "HH"
  },
  minute: {
    numeric: "m",
    "2-digit": "mm"
  },
  second: {
    numeric: "s",
    "2-digit": "ss"
  },
  timeZoneName: {
    long: "ZZZZZ",
    short: "ZZZ"
  }
};
function IP(e, t, n) {
  const { type: o, value: r } = e;
  if (o === "literal") {
    const l = /^\s+$/.test(r);
    return {
      literal: !l,
      val: l ? " " : r
    };
  }
  const i = t[o];
  let s = o;
  o === "hour" && (t.hour12 != null ? s = t.hour12 ? "hour12" : "hour24" : t.hourCycle != null ? t.hourCycle === "h11" || t.hourCycle === "h12" ? s = "hour12" : s = "hour24" : s = n.hour12 ? "hour12" : "hour24");
  let a = bP[s];
  if (typeof a == "object" && (a = a[i]), a)
    return {
      literal: !1,
      val: a
    };
}
function TP(e) {
  return [`^${e.map((n) => n.regex).reduce((n, o) => `${n}(${o.source})`, "")}$`, e];
}
function wP(e, t, n) {
  const o = e.match(t);
  if (o) {
    const r = {};
    let i = 1;
    for (const s in n)
      if (Uo(n, s)) {
        const a = n[s], l = a.groups ? a.groups + 1 : 1;
        !a.literal && a.token && (r[a.token.val[0]] = a.deser(o.slice(i, i + l))), i += l;
      }
    return [o, r];
  } else
    return [o, {}];
}
function CP(e) {
  const t = (i) => {
    switch (i) {
      case "S":
        return "millisecond";
      case "s":
        return "second";
      case "m":
        return "minute";
      case "h":
      case "H":
        return "hour";
      case "d":
        return "day";
      case "o":
        return "ordinal";
      case "L":
      case "M":
        return "month";
      case "y":
        return "year";
      case "E":
      case "c":
        return "weekday";
      case "W":
        return "weekNumber";
      case "k":
        return "weekYear";
      case "q":
        return "quarter";
      default:
        return null;
    }
  };
  let n = null, o;
  return N(e.z) || (n = Wt.create(e.z)), N(e.Z) || (n || (n = new Oe(e.Z)), o = e.Z), N(e.q) || (e.M = (e.q - 1) * 3 + 1), N(e.h) || (e.h < 12 && e.a === 1 ? e.h += 12 : e.h === 12 && e.a === 0 && (e.h = 0)), e.G === 0 && e.y && (e.y = -e.y), N(e.u) || (e.S = Nu(e.u)), [Object.keys(e).reduce((i, s) => {
    const a = t(s);
    return a && (i[a] = e[s]), i;
  }, {}), n, o];
}
let gl = null;
function OP() {
  return gl || (gl = D.fromMillis(1555555555555)), gl;
}
function EP(e, t) {
  if (e.literal)
    return e;
  const n = Ie.macroTokenToFormatOpts(e.val), o = Ng(n, t);
  return o == null || o.includes(void 0) ? e : o;
}
function Ag(e, t) {
  return Array.prototype.concat(...e.map((n) => EP(n, t)));
}
function $g(e, t, n) {
  const o = Ag(Ie.parseFormat(n), e), r = o.map((s) => vP(s, e)), i = r.find((s) => s.invalidReason);
  if (i)
    return { input: t, tokens: o, invalidReason: i.invalidReason };
  {
    const [s, a] = TP(r), l = RegExp(s, "i"), [c, u] = wP(t, l, a), [f, d, h] = u ? CP(u) : [null, null, void 0];
    if (Uo(u, "a") && Uo(u, "H"))
      throw new bo(
        "Can't include meridiem when specifying 24-hour format"
      );
    return { input: t, tokens: o, regex: l, rawMatches: c, matches: u, result: f, zone: d, specificOffset: h };
  }
}
function SP(e, t, n) {
  const { result: o, zone: r, specificOffset: i, invalidReason: s } = $g(e, t, n);
  return [o, r, i, s];
}
function Ng(e, t) {
  if (!e)
    return null;
  const o = Ie.create(t, e).dtFormatter(OP()), r = o.formatToParts(), i = o.resolvedOptions();
  return r.map((s) => IP(s, e, i));
}
const vl = "Invalid DateTime", Dd = 864e13;
function Qi(e) {
  return new ct("unsupported zone", `the zone "${e.name}" is not supported`);
}
function bl(e) {
  return e.weekData === null && (e.weekData = fa(e.c)), e.weekData;
}
function Il(e) {
  return e.localWeekData === null && (e.localWeekData = fa(
    e.c,
    e.loc.getMinDaysInFirstWeek(),
    e.loc.getStartOfWeek()
  )), e.localWeekData;
}
function vn(e, t) {
  const n = {
    ts: e.ts,
    zone: e.zone,
    c: e.c,
    o: e.o,
    loc: e.loc,
    invalid: e.invalid
  };
  return new D({ ...n, ...t, old: n });
}
function Mg(e, t, n) {
  let o = e - t * 60 * 1e3;
  const r = n.offset(o);
  if (t === r)
    return [o, t];
  o -= (r - t) * 60 * 1e3;
  const i = n.offset(o);
  return r === i ? [o, r] : [e - Math.min(r, i) * 60 * 1e3, Math.max(r, i)];
}
function Ji(e, t) {
  e += t * 60 * 1e3;
  const n = new Date(e);
  return {
    year: n.getUTCFullYear(),
    month: n.getUTCMonth() + 1,
    day: n.getUTCDate(),
    hour: n.getUTCHours(),
    minute: n.getUTCMinutes(),
    second: n.getUTCSeconds(),
    millisecond: n.getUTCMilliseconds()
  };
}
function js(e, t, n) {
  return Mg(Ya(e), t, n);
}
function Ld(e, t) {
  const n = e.o, o = e.c.year + Math.trunc(t.years), r = e.c.month + Math.trunc(t.months) + Math.trunc(t.quarters) * 3, i = {
    ...e.c,
    year: o,
    month: r,
    day: Math.min(e.c.day, da(o, r)) + Math.trunc(t.days) + Math.trunc(t.weeks) * 7
  }, s = W.fromObject({
    years: t.years - Math.trunc(t.years),
    quarters: t.quarters - Math.trunc(t.quarters),
    months: t.months - Math.trunc(t.months),
    weeks: t.weeks - Math.trunc(t.weeks),
    days: t.days - Math.trunc(t.days),
    hours: t.hours,
    minutes: t.minutes,
    seconds: t.seconds,
    milliseconds: t.milliseconds
  }).as("milliseconds"), a = Ya(i);
  let [l, c] = Mg(a, n, e.zone);
  return s !== 0 && (l += s, c = e.zone.offset(l)), { ts: l, o: c };
}
function pr(e, t, n, o, r, i) {
  const { setZone: s, zone: a } = n;
  if (e && Object.keys(e).length !== 0 || t) {
    const l = t || a, c = D.fromObject(e, {
      ...n,
      zone: l,
      specificOffset: i
    });
    return s ? c : c.setZone(a);
  } else
    return D.invalid(
      new ct("unparsable", `the input "${r}" can't be parsed as ${o}`)
    );
}
function Xi(e, t, n = !0) {
  return e.isValid ? Ie.create(V.create("en-US"), {
    allowZ: n,
    forceSimple: !0
  }).formatDateTimeFromString(e, t) : null;
}
function Tl(e, t) {
  const n = e.c.year > 9999 || e.c.year < 0;
  let o = "";
  return n && e.c.year >= 0 && (o += "+"), o += fe(e.c.year, n ? 6 : 4), t ? (o += "-", o += fe(e.c.month), o += "-", o += fe(e.c.day)) : (o += fe(e.c.month), o += fe(e.c.day)), o;
}
function Rd(e, t, n, o, r, i) {
  let s = fe(e.c.hour);
  return t ? (s += ":", s += fe(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (s += ":")) : s += fe(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (s += fe(e.c.second), (e.c.millisecond !== 0 || !o) && (s += ".", s += fe(e.c.millisecond, 3))), r && (e.isOffsetFixed && e.offset === 0 && !i ? s += "Z" : e.o < 0 ? (s += "-", s += fe(Math.trunc(-e.o / 60)), s += ":", s += fe(Math.trunc(-e.o % 60))) : (s += "+", s += fe(Math.trunc(e.o / 60)), s += ":", s += fe(Math.trunc(e.o % 60)))), i && (s += "[" + e.zone.ianaName + "]"), s;
}
const kg = {
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, xP = {
  weekNumber: 1,
  weekday: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, AP = {
  ordinal: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, Dg = ["year", "month", "day", "hour", "minute", "second", "millisecond"], $P = [
  "weekYear",
  "weekNumber",
  "weekday",
  "hour",
  "minute",
  "second",
  "millisecond"
], NP = ["year", "ordinal", "hour", "minute", "second", "millisecond"];
function MP(e) {
  const t = {
    year: "year",
    years: "year",
    month: "month",
    months: "month",
    day: "day",
    days: "day",
    hour: "hour",
    hours: "hour",
    minute: "minute",
    minutes: "minute",
    quarter: "quarter",
    quarters: "quarter",
    second: "second",
    seconds: "second",
    millisecond: "millisecond",
    milliseconds: "millisecond",
    weekday: "weekday",
    weekdays: "weekday",
    weeknumber: "weekNumber",
    weeksnumber: "weekNumber",
    weeknumbers: "weekNumber",
    weekyear: "weekYear",
    weekyears: "weekYear",
    ordinal: "ordinal"
  }[e.toLowerCase()];
  if (!t)
    throw new Ry(e);
  return t;
}
function _d(e) {
  switch (e.toLowerCase()) {
    case "localweekday":
    case "localweekdays":
      return "localWeekday";
    case "localweeknumber":
    case "localweeknumbers":
      return "localWeekNumber";
    case "localweekyear":
    case "localweekyears":
      return "localWeekYear";
    default:
      return MP(e);
  }
}
function Pd(e, t) {
  const n = nn(t.zone, le.defaultZone), o = V.fromObject(t), r = le.now();
  let i, s;
  if (N(e.year))
    i = r;
  else {
    for (const c of Dg)
      N(e[c]) && (e[c] = kg[c]);
    const a = ig(e) || sg(e);
    if (a)
      return D.invalid(a);
    const l = n.offset(r);
    [i, s] = js(e, l, n);
  }
  return new D({ ts: i, zone: n, loc: o, o: s });
}
function Bd(e, t, n) {
  const o = N(n.round) ? !0 : n.round, r = (s, a) => (s = Mu(s, o || n.calendary ? 0 : 2, !0), t.loc.clone(n).relFormatter(n).format(s, a)), i = (s) => n.calendary ? t.hasSame(e, s) ? 0 : t.startOf(s).diff(e.startOf(s), s).get(s) : t.diff(e, s).get(s);
  if (n.unit)
    return r(i(n.unit), n.unit);
  for (const s of n.units) {
    const a = i(s);
    if (Math.abs(a) >= 1)
      return r(a, s);
  }
  return r(e > t ? -0 : 0, n.units[n.units.length - 1]);
}
function jd(e) {
  let t = {}, n;
  return e.length > 0 && typeof e[e.length - 1] == "object" ? (t = e[e.length - 1], n = Array.from(e).slice(0, e.length - 1)) : n = Array.from(e), [t, n];
}
class D {
  /**
   * @access private
   */
  constructor(t) {
    const n = t.zone || le.defaultZone;
    let o = t.invalid || (Number.isNaN(t.ts) ? new ct("invalid input") : null) || (n.isValid ? null : Qi(n));
    this.ts = N(t.ts) ? le.now() : t.ts;
    let r = null, i = null;
    if (!o)
      if (t.old && t.old.ts === this.ts && t.old.zone.equals(n))
        [r, i] = [t.old.c, t.old.o];
      else {
        const a = n.offset(this.ts);
        r = Ji(this.ts, a), o = Number.isNaN(r.year) ? new ct("invalid input") : null, r = o ? null : r, i = o ? null : a;
      }
    this._zone = n, this.loc = t.loc || V.create(), this.invalid = o, this.weekData = null, this.localWeekData = null, this.c = r, this.o = i, this.isLuxonDateTime = !0;
  }
  // CONSTRUCT
  /**
   * Create a DateTime for the current instant, in the system's time zone.
   *
   * Use Settings to override these default values if needed.
   * @example DateTime.now().toISO() //~> now in the ISO format
   * @return {DateTime}
   */
  static now() {
    return new D({});
  }
  /**
   * Create a local DateTime
   * @param {number} [year] - The calendar year. If omitted (as in, call `local()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month, 1-indexed
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @example DateTime.local()                                  //~> now
   * @example DateTime.local({ zone: "America/New_York" })      //~> now, in US east coast time
   * @example DateTime.local(2017)                              //~> 2017-01-01T00:00:00
   * @example DateTime.local(2017, 3)                           //~> 2017-03-01T00:00:00
   * @example DateTime.local(2017, 3, 12, { locale: "fr" })     //~> 2017-03-12T00:00:00, with a French locale
   * @example DateTime.local(2017, 3, 12, 5)                    //~> 2017-03-12T05:00:00
   * @example DateTime.local(2017, 3, 12, 5, { zone: "utc" })   //~> 2017-03-12T05:00:00, in UTC
   * @example DateTime.local(2017, 3, 12, 5, 45)                //~> 2017-03-12T05:45:00
   * @example DateTime.local(2017, 3, 12, 5, 45, 10)            //~> 2017-03-12T05:45:10
   * @example DateTime.local(2017, 3, 12, 5, 45, 10, 765)       //~> 2017-03-12T05:45:10.765
   * @return {DateTime}
   */
  static local() {
    const [t, n] = jd(arguments), [o, r, i, s, a, l, c] = n;
    return Pd({ year: o, month: r, day: i, hour: s, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime in UTC
   * @param {number} [year] - The calendar year. If omitted (as in, call `utc()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @param {Object} options - configuration options for the DateTime
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} [options.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [options.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.utc()                                              //~> now
   * @example DateTime.utc(2017)                                          //~> 2017-01-01T00:00:00Z
   * @example DateTime.utc(2017, 3)                                       //~> 2017-03-01T00:00:00Z
   * @example DateTime.utc(2017, 3, 12)                                   //~> 2017-03-12T00:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5)                                //~> 2017-03-12T05:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45)                            //~> 2017-03-12T05:45:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, { locale: "fr" })          //~> 2017-03-12T05:45:00Z with a French locale
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10)                        //~> 2017-03-12T05:45:10Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10, 765, { locale: "fr" }) //~> 2017-03-12T05:45:10.765Z with a French locale
   * @return {DateTime}
   */
  static utc() {
    const [t, n] = jd(arguments), [o, r, i, s, a, l, c] = n;
    return t.zone = Oe.utcInstance, Pd({ year: o, month: r, day: i, hour: s, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime from a JavaScript Date object. Uses the default zone.
   * @param {Date} date - a JavaScript Date object
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @return {DateTime}
   */
  static fromJSDate(t, n = {}) {
    const o = s_(t) ? t.valueOf() : NaN;
    if (Number.isNaN(o))
      return D.invalid("invalid input");
    const r = nn(n.zone, le.defaultZone);
    return r.isValid ? new D({
      ts: o,
      zone: r,
      loc: V.fromObject(n)
    }) : D.invalid(Qi(r));
  }
  /**
   * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} milliseconds - a number of milliseconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromMillis(t, n = {}) {
    if (_n(t))
      return t < -Dd || t > Dd ? D.invalid("Timestamp out of range") : new D({
        ts: t,
        zone: nn(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Ae(
      `fromMillis requires a numerical input, but received a ${typeof t} with value ${t}`
    );
  }
  /**
   * Create a DateTime from a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} seconds - a number of seconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromSeconds(t, n = {}) {
    if (_n(t))
      return new D({
        ts: t * 1e3,
        zone: nn(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Ae("fromSeconds requires a numerical input");
  }
  /**
   * Create a DateTime from a JavaScript object with keys like 'year' and 'hour' with reasonable defaults.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.year - a year, such as 1987
   * @param {number} obj.month - a month, 1-12
   * @param {number} obj.day - a day of the month, 1-31, depending on the month
   * @param {number} obj.ordinal - day of the year, 1-365 or 366
   * @param {number} obj.weekYear - an ISO week year
   * @param {number} obj.weekNumber - an ISO week number, between 1 and 52 or 53, depending on the year
   * @param {number} obj.weekday - an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
   * @param {number} obj.localWeekYear - a week year, according to the locale
   * @param {number} obj.localWeekNumber - a week number, between 1 and 52 or 53, depending on the year, according to the locale
   * @param {number} obj.localWeekday - a weekday, 1-7, where 1 is the first and 7 is the last day of the week, according to the locale
   * @param {number} obj.hour - hour of the day, 0-23
   * @param {number} obj.minute - minute of the hour, 0-59
   * @param {number} obj.second - second of the minute, 0-59
   * @param {number} obj.millisecond - millisecond of the second, 0-999
   * @param {Object} opts - options for creating this DateTime
   * @param {string|Zone} [opts.zone='local'] - interpret the numbers in the context of a particular zone. Can take any value taken as the first argument to setZone()
   * @param {string} [opts.locale='system\'s locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromObject({ year: 1982, month: 5, day: 25}).toISODate() //=> '1982-05-25'
   * @example DateTime.fromObject({ year: 1982 }).toISODate() //=> '1982-01-01'
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }) //~> today at 10:26:06
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'utc' }),
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'local' })
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'America/New_York' })
   * @example DateTime.fromObject({ weekYear: 2016, weekNumber: 2, weekday: 3 }).toISODate() //=> '2016-01-13'
   * @example DateTime.fromObject({ localWeekYear: 2022, localWeekNumber: 1, localWeekday: 1 }, { locale: "en-US" }).toISODate() //=> '2021-12-26'
   * @return {DateTime}
   */
  static fromObject(t, n = {}) {
    t = t || {};
    const o = nn(n.zone, le.defaultZone);
    if (!o.isValid)
      return D.invalid(Qi(o));
    const r = V.fromObject(n), i = ha(t, _d), { minDaysInFirstWeek: s, startOfWeek: a } = wd(i, r), l = le.now(), c = N(n.specificOffset) ? o.offset(l) : n.specificOffset, u = !N(i.ordinal), f = !N(i.year), d = !N(i.month) || !N(i.day), h = f || d, p = i.weekYear || i.weekNumber;
    if ((h || u) && p)
      throw new bo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (d && u)
      throw new bo("Can't mix ordinal dates with month/day");
    const g = p || i.weekday && !h;
    let v, T, O = Ji(l, c);
    g ? (v = $P, T = xP, O = fa(O, s, a)) : u ? (v = NP, T = AP, O = yl(O)) : (v = Dg, T = kg);
    let k = !1;
    for (const Z of v) {
      const P = i[Z];
      N(P) ? k ? i[Z] = T[Z] : i[Z] = O[Z] : k = !0;
    }
    const L = g ? o_(i, s, a) : u ? r_(i) : ig(i), ae = L || sg(i);
    if (ae)
      return D.invalid(ae);
    const gt = g ? Id(i, s, a) : u ? Td(i) : i, [vt, tt] = js(gt, c, o), je = new D({
      ts: vt,
      zone: o,
      o: tt,
      loc: r
    });
    return i.weekday && h && t.weekday !== je.weekday ? D.invalid(
      "mismatched weekday",
      `you can't specify both a weekday of ${i.weekday} and a date of ${je.toISO()}`
    ) : je;
  }
  /**
   * Create a DateTime from an ISO 8601 string
   * @param {string} text - the ISO string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the time to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} [opts.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [opts.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromISO('2016-05-25T09:08:34.123')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00', {setZone: true})
   * @example DateTime.fromISO('2016-05-25T09:08:34.123', {zone: 'utc'})
   * @example DateTime.fromISO('2016-W05-4')
   * @return {DateTime}
   */
  static fromISO(t, n = {}) {
    const [o, r] = Y_(t);
    return pr(o, r, n, "ISO 8601", t);
  }
  /**
   * Create a DateTime from an RFC 2822 string
   * @param {string} text - the RFC 2822 string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since the offset is always specified in the string itself, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23:12 GMT')
   * @example DateTime.fromRFC2822('Fri, 25 Nov 2016 13:23:12 +0600')
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23 Z')
   * @return {DateTime}
   */
  static fromRFC2822(t, n = {}) {
    const [o, r] = K_(t);
    return pr(o, r, n, "RFC 2822", t);
  }
  /**
   * Create a DateTime from an HTTP header date
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @param {string} text - the HTTP header date
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since HTTP dates are always in UTC, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with the fixed-offset zone specified in the string. For HTTP dates, this is always UTC, so this option is equivalent to setting the `zone` option to 'utc', but this option is included for consistency with similar methods.
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromHTTP('Sun, 06 Nov 1994 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sunday, 06-Nov-94 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sun Nov  6 08:49:37 1994')
   * @return {DateTime}
   */
  static fromHTTP(t, n = {}) {
    const [o, r] = Q_(t);
    return pr(o, r, n, "HTTP", n);
  }
  /**
   * Create a DateTime from an input string and format string.
   * Defaults to en-US if no locale has been specified, regardless of the system's locale. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/parsing?id=table-of-tokens).
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see the link below for the formats)
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromFormat(t, n, o = {}) {
    if (N(t) || N(n))
      throw new Ae("fromFormat requires an input string and a format");
    const { locale: r = null, numberingSystem: i = null } = o, s = V.fromOpts({
      locale: r,
      numberingSystem: i,
      defaultToEN: !0
    }), [a, l, c, u] = SP(s, t, n);
    return u ? D.invalid(u) : pr(a, l, o, `format ${n}`, t, c);
  }
  /**
   * @deprecated use fromFormat instead
   */
  static fromString(t, n, o = {}) {
    return D.fromFormat(t, n, o);
  }
  /**
   * Create a DateTime from a SQL date, time, or datetime
   * Defaults to en-US if no locale has been specified, regardless of the system's locale
   * @param {string} text - the string to parse
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @example DateTime.fromSQL('2017-05-15')
   * @example DateTime.fromSQL('2017-05-15 09:12:34')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342+06:00')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles', { setZone: true })
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342', { zone: 'America/Los_Angeles' })
   * @example DateTime.fromSQL('09:12:34.342')
   * @return {DateTime}
   */
  static fromSQL(t, n = {}) {
    const [o, r] = rP(t);
    return pr(o, r, n, "SQL", t);
  }
  /**
   * Create an invalid DateTime.
   * @param {string} reason - simple string of why this DateTime is invalid. Should not contain parameters or anything else data-dependent.
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {DateTime}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Ae("need to specify a reason the DateTime is invalid");
    const o = t instanceof ct ? t : new ct(t, n);
    if (le.throwOnInvalid)
      throw new DR(o);
    return new D({ invalid: o });
  }
  /**
   * Check if an object is an instance of DateTime. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDateTime(t) {
    return t && t.isLuxonDateTime || !1;
  }
  /**
   * Produce the format string for a set of options
   * @param formatOpts
   * @param localeOpts
   * @returns {string}
   */
  static parseFormatForOpts(t, n = {}) {
    const o = Ng(t, V.fromObject(n));
    return o ? o.map((r) => r ? r.val : null).join("") : null;
  }
  /**
   * Produce the the fully expanded format token for the locale
   * Does NOT quote characters, so quoted tokens will not round trip correctly
   * @param fmt
   * @param localeOpts
   * @returns {string}
   */
  static expandFormat(t, n = {}) {
    return Ag(Ie.parseFormat(t), V.fromObject(n)).map((r) => r.val).join("");
  }
  // INFO
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example DateTime.local(2017, 7, 4).get('month'); //=> 7
   * @example DateTime.local(2017, 7, 4).get('day'); //=> 4
   * @return {number}
   */
  get(t) {
    return this[t];
  }
  /**
   * Returns whether the DateTime is valid. Invalid DateTimes occur when:
   * * The DateTime was created from invalid calendar information, such as the 13th month or February 30
   * * The DateTime was created by an operation on another invalid date
   * @type {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this DateTime is invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this DateTime became invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Get the locale of a DateTime, such 'en-GB'. The locale is used when formatting the DateTime
   *
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a DateTime, such 'beng'. The numbering system is used when formatting the DateTime
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Get the output calendar of a DateTime, such 'islamic'. The output calendar is used when formatting the DateTime
   *
   * @type {string}
   */
  get outputCalendar() {
    return this.isValid ? this.loc.outputCalendar : null;
  }
  /**
   * Get the time zone associated with this DateTime.
   * @type {Zone}
   */
  get zone() {
    return this._zone;
  }
  /**
   * Get the name of the time zone.
   * @type {string}
   */
  get zoneName() {
    return this.isValid ? this.zone.name : null;
  }
  /**
   * Get the year
   * @example DateTime.local(2017, 5, 25).year //=> 2017
   * @type {number}
   */
  get year() {
    return this.isValid ? this.c.year : NaN;
  }
  /**
   * Get the quarter
   * @example DateTime.local(2017, 5, 25).quarter //=> 2
   * @type {number}
   */
  get quarter() {
    return this.isValid ? Math.ceil(this.c.month / 3) : NaN;
  }
  /**
   * Get the month (1-12).
   * @example DateTime.local(2017, 5, 25).month //=> 5
   * @type {number}
   */
  get month() {
    return this.isValid ? this.c.month : NaN;
  }
  /**
   * Get the day of the month (1-30ish).
   * @example DateTime.local(2017, 5, 25).day //=> 25
   * @type {number}
   */
  get day() {
    return this.isValid ? this.c.day : NaN;
  }
  /**
   * Get the hour of the day (0-23).
   * @example DateTime.local(2017, 5, 25, 9).hour //=> 9
   * @type {number}
   */
  get hour() {
    return this.isValid ? this.c.hour : NaN;
  }
  /**
   * Get the minute of the hour (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
   * @type {number}
   */
  get minute() {
    return this.isValid ? this.c.minute : NaN;
  }
  /**
   * Get the second of the minute (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52).second //=> 52
   * @type {number}
   */
  get second() {
    return this.isValid ? this.c.second : NaN;
  }
  /**
   * Get the millisecond of the second (0-999).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52, 654).millisecond //=> 654
   * @type {number}
   */
  get millisecond() {
    return this.isValid ? this.c.millisecond : NaN;
  }
  /**
   * Get the week year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 12, 31).weekYear //=> 2015
   * @type {number}
   */
  get weekYear() {
    return this.isValid ? bl(this).weekYear : NaN;
  }
  /**
   * Get the week number of the week year (1-52ish).
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2017, 5, 25).weekNumber //=> 21
   * @type {number}
   */
  get weekNumber() {
    return this.isValid ? bl(this).weekNumber : NaN;
  }
  /**
   * Get the day of the week.
   * 1 is Monday and 7 is Sunday
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 11, 31).weekday //=> 4
   * @type {number}
   */
  get weekday() {
    return this.isValid ? bl(this).weekday : NaN;
  }
  /**
   * Returns true if this date is on a weekend according to the locale, false otherwise
   * @returns {boolean}
   */
  get isWeekend() {
    return this.isValid && this.loc.getWeekendDays().includes(this.weekday);
  }
  /**
   * Get the day of the week according to the locale.
   * 1 is the first day of the week and 7 is the last day of the week.
   * If the locale assigns Sunday as the first day of the week, then a date which is a Sunday will return 1,
   * @returns {number}
   */
  get localWeekday() {
    return this.isValid ? Il(this).weekday : NaN;
  }
  /**
   * Get the week number of the week year according to the locale. Different locales assign week numbers differently,
   * because the week can start on different days of the week (see localWeekday) and because a different number of days
   * is required for a week to count as the first week of a year.
   * @returns {number}
   */
  get localWeekNumber() {
    return this.isValid ? Il(this).weekNumber : NaN;
  }
  /**
   * Get the week year according to the locale. Different locales assign week numbers (and therefor week years)
   * differently, see localWeekNumber.
   * @returns {number}
   */
  get localWeekYear() {
    return this.isValid ? Il(this).weekYear : NaN;
  }
  /**
   * Get the ordinal (meaning the day of the year)
   * @example DateTime.local(2017, 5, 25).ordinal //=> 145
   * @type {number|DateTime}
   */
  get ordinal() {
    return this.isValid ? yl(this.c).ordinal : NaN;
  }
  /**
   * Get the human readable short month name, such as 'Oct'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthShort //=> Oct
   * @type {string}
   */
  get monthShort() {
    return this.isValid ? Yi.months("short", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable long month name, such as 'October'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthLong //=> October
   * @type {string}
   */
  get monthLong() {
    return this.isValid ? Yi.months("long", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable short weekday, such as 'Mon'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayShort //=> Mon
   * @type {string}
   */
  get weekdayShort() {
    return this.isValid ? Yi.weekdays("short", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the human readable long weekday, such as 'Monday'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayLong //=> Monday
   * @type {string}
   */
  get weekdayLong() {
    return this.isValid ? Yi.weekdays("long", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the UTC offset of this DateTime in minutes
   * @example DateTime.now().offset //=> -240
   * @example DateTime.utc().offset //=> 0
   * @type {number}
   */
  get offset() {
    return this.isValid ? +this.o : NaN;
  }
  /**
   * Get the short human name for the zone's current offset, for example "EST" or "EDT".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameShort() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "short",
      locale: this.locale
    }) : null;
  }
  /**
   * Get the long human name for the zone's current offset, for example "Eastern Standard Time" or "Eastern Daylight Time".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameLong() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "long",
      locale: this.locale
    }) : null;
  }
  /**
   * Get whether this zone's offset ever changes, as in a DST.
   * @type {boolean}
   */
  get isOffsetFixed() {
    return this.isValid ? this.zone.isUniversal : null;
  }
  /**
   * Get whether the DateTime is in a DST.
   * @type {boolean}
   */
  get isInDST() {
    return this.isOffsetFixed ? !1 : this.offset > this.set({ month: 1, day: 1 }).offset || this.offset > this.set({ month: 5 }).offset;
  }
  /**
   * Get those DateTimes which have the same local time as this DateTime, but a different offset from UTC
   * in this DateTime's zone. During DST changes local time can be ambiguous, for example
   * `2023-10-29T02:30:00` in `Europe/Berlin` can have offset `+01:00` or `+02:00`.
   * This method will return both possible DateTimes if this DateTime's local time is ambiguous.
   * @returns {DateTime[]}
   */
  getPossibleOffsets() {
    if (!this.isValid || this.isOffsetFixed)
      return [this];
    const t = 864e5, n = 6e4, o = Ya(this.c), r = this.zone.offset(o - t), i = this.zone.offset(o + t), s = this.zone.offset(o - r * n), a = this.zone.offset(o - i * n);
    if (s === a)
      return [this];
    const l = o - s * n, c = o - a * n, u = Ji(l, s), f = Ji(c, a);
    return u.hour === f.hour && u.minute === f.minute && u.second === f.second && u.millisecond === f.millisecond ? [vn(this, { ts: l }), vn(this, { ts: c })] : [this];
  }
  /**
   * Returns true if this DateTime is in a leap year, false otherwise
   * @example DateTime.local(2016).isInLeapYear //=> true
   * @example DateTime.local(2013).isInLeapYear //=> false
   * @type {boolean}
   */
  get isInLeapYear() {
    return Pi(this.year);
  }
  /**
   * Returns the number of days in this DateTime's month
   * @example DateTime.local(2016, 2).daysInMonth //=> 29
   * @example DateTime.local(2016, 3).daysInMonth //=> 31
   * @type {number}
   */
  get daysInMonth() {
    return da(this.year, this.month);
  }
  /**
   * Returns the number of days in this DateTime's year
   * @example DateTime.local(2016).daysInYear //=> 366
   * @example DateTime.local(2013).daysInYear //=> 365
   * @type {number}
   */
  get daysInYear() {
    return this.isValid ? No(this.year) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2004).weeksInWeekYear //=> 53
   * @example DateTime.local(2013).weeksInWeekYear //=> 52
   * @type {number}
   */
  get weeksInWeekYear() {
    return this.isValid ? ai(this.weekYear) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's local week year
   * @example DateTime.local(2020, 6, {locale: 'en-US'}).weeksInLocalWeekYear //=> 52
   * @example DateTime.local(2020, 6, {locale: 'de-DE'}).weeksInLocalWeekYear //=> 53
   * @type {number}
   */
  get weeksInLocalWeekYear() {
    return this.isValid ? ai(
      this.localWeekYear,
      this.loc.getMinDaysInFirstWeek(),
      this.loc.getStartOfWeek()
    ) : NaN;
  }
  /**
   * Returns the resolved Intl options for this DateTime.
   * This is useful in understanding the behavior of formatting methods
   * @param {Object} opts - the same options as toLocaleString
   * @return {Object}
   */
  resolvedLocaleOptions(t = {}) {
    const { locale: n, numberingSystem: o, calendar: r } = Ie.create(
      this.loc.clone(t),
      t
    ).resolvedOptions(this);
    return { locale: n, numberingSystem: o, outputCalendar: r };
  }
  // TRANSFORM
  /**
   * "Set" the DateTime's zone to UTC. Returns a newly-constructed DateTime.
   *
   * Equivalent to {@link DateTime#setZone}('utc')
   * @param {number} [offset=0] - optionally, an offset from UTC in minutes
   * @param {Object} [opts={}] - options to pass to `setZone()`
   * @return {DateTime}
   */
  toUTC(t = 0, n = {}) {
    return this.setZone(Oe.instance(t), n);
  }
  /**
   * "Set" the DateTime's zone to the host's local zone. Returns a newly-constructed DateTime.
   *
   * Equivalent to `setZone('local')`
   * @return {DateTime}
   */
  toLocal() {
    return this.setZone(le.defaultZone);
  }
  /**
   * "Set" the DateTime's zone to specified zone. Returns a newly-constructed DateTime.
   *
   * By default, the setter keeps the underlying time the same (as in, the same timestamp), but the new instance will report different local times and consider DSTs when making computations, as with {@link DateTime#plus}. You may wish to use {@link DateTime#toLocal} and {@link DateTime#toUTC} which provide simple convenience wrappers for commonly used zones.
   * @param {string|Zone} [zone='local'] - a zone identifier. As a string, that can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. You may also supply an instance of a {@link DateTime#Zone} class.
   * @param {Object} opts - options
   * @param {boolean} [opts.keepLocalTime=false] - If true, adjust the underlying time so that the local time stays the same, but in the target zone. You should rarely need this.
   * @return {DateTime}
   */
  setZone(t, { keepLocalTime: n = !1, keepCalendarTime: o = !1 } = {}) {
    if (t = nn(t, le.defaultZone), t.equals(this.zone))
      return this;
    if (t.isValid) {
      let r = this.ts;
      if (n || o) {
        const i = t.offset(this.ts), s = this.toObject();
        [r] = js(s, i, t);
      }
      return vn(this, { ts: r, zone: t });
    } else
      return D.invalid(Qi(t));
  }
  /**
   * "Set" the locale, numberingSystem, or outputCalendar. Returns a newly-constructed DateTime.
   * @param {Object} properties - the properties to set
   * @example DateTime.local(2017, 5, 25).reconfigure({ locale: 'en-GB' })
   * @return {DateTime}
   */
  reconfigure({ locale: t, numberingSystem: n, outputCalendar: o } = {}) {
    const r = this.loc.clone({ locale: t, numberingSystem: n, outputCalendar: o });
    return vn(this, { loc: r });
  }
  /**
   * "Set" the locale. Returns a newly-constructed DateTime.
   * Just a convenient alias for reconfigure({ locale })
   * @example DateTime.local(2017, 5, 25).setLocale('en-GB')
   * @return {DateTime}
   */
  setLocale(t) {
    return this.reconfigure({ locale: t });
  }
  /**
   * "Set" the values of specified units. Returns a newly-constructed DateTime.
   * You can only set units with this method; for "setting" metadata, see {@link DateTime#reconfigure} and {@link DateTime#setZone}.
   *
   * This method also supports setting locale-based week units, i.e. `localWeekday`, `localWeekNumber` and `localWeekYear`.
   * They cannot be mixed with ISO-week units like `weekday`.
   * @param {Object} values - a mapping of units to numbers
   * @example dt.set({ year: 2017 })
   * @example dt.set({ hour: 8, minute: 30 })
   * @example dt.set({ weekday: 5 })
   * @example dt.set({ year: 2005, ordinal: 234 })
   * @return {DateTime}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = ha(t, _d), { minDaysInFirstWeek: o, startOfWeek: r } = wd(n, this.loc), i = !N(n.weekYear) || !N(n.weekNumber) || !N(n.weekday), s = !N(n.ordinal), a = !N(n.year), l = !N(n.month) || !N(n.day), c = a || l, u = n.weekYear || n.weekNumber;
    if ((c || s) && u)
      throw new bo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (l && s)
      throw new bo("Can't mix ordinal dates with month/day");
    let f;
    i ? f = Id(
      { ...fa(this.c, o, r), ...n },
      o,
      r
    ) : N(n.ordinal) ? (f = { ...this.toObject(), ...n }, N(n.day) && (f.day = Math.min(da(f.year, f.month), f.day))) : f = Td({ ...yl(this.c), ...n });
    const [d, h] = js(f, this.o, this.zone);
    return vn(this, { ts: d, o: h });
  }
  /**
   * Add a period of time to this DateTime and return the resulting DateTime
   *
   * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds. Adding days, months, or years shifts the calendar, accounting for DSTs and leap years along the way. Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @example DateTime.now().plus(123) //~> in 123 milliseconds
   * @example DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
   * @example DateTime.now().plus({ days: 1 }) //~> this time tomorrow
   * @example DateTime.now().plus({ days: -1 }) //~> this time yesterday
   * @example DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
   * @example DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
   * @return {DateTime}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = W.fromDurationLike(t);
    return vn(this, Ld(this, n));
  }
  /**
   * Subtract a period of time to this DateTime and return the resulting DateTime
   * See {@link DateTime#plus}
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   @return {DateTime}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = W.fromDurationLike(t).negate();
    return vn(this, Ld(this, n));
  }
  /**
   * "Set" this DateTime to the beginning of a unit of time.
   * @param {string} unit - The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
   * @example DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
   * @example DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   * @return {DateTime}
   */
  startOf(t, { useLocaleWeeks: n = !1 } = {}) {
    if (!this.isValid)
      return this;
    const o = {}, r = W.normalizeUnit(t);
    switch (r) {
      case "years":
        o.month = 1;
      case "quarters":
      case "months":
        o.day = 1;
      case "weeks":
      case "days":
        o.hour = 0;
      case "hours":
        o.minute = 0;
      case "minutes":
        o.second = 0;
      case "seconds":
        o.millisecond = 0;
        break;
    }
    if (r === "weeks")
      if (n) {
        const i = this.loc.getStartOfWeek(), { weekday: s } = this;
        s < i && (o.weekNumber = this.weekNumber - 1), o.weekday = i;
      } else
        o.weekday = 1;
    if (r === "quarters") {
      const i = Math.ceil(this.month / 3);
      o.month = (i - 1) * 3 + 1;
    }
    return this.set(o);
  }
  /**
   * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
   * @param {string} unit - The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   * @return {DateTime}
   */
  endOf(t, n) {
    return this.isValid ? this.plus({ [t]: 1 }).startOf(t, n).minus(1) : this;
  }
  // OUTPUT
  /**
   * Returns a string representation of this DateTime formatted according to the specified format string.
   * **You may not want this.** See {@link DateTime#toLocaleString} for a more flexible formatting tool. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens).
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   * @param {string} fmt - the format string
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * @example DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * @example DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * @example DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   * @return {string}
   */
  toFormat(t, n = {}) {
    return this.isValid ? Ie.create(this.loc.redefaultToEN(n)).formatDateTimeFromString(this, t) : vl;
  }
  /**
   * Returns a localized string representing this date. Accepts the same options as the Intl.DateTimeFormat constructor and any presets defined by Luxon, such as `DateTime.DATE_FULL` or `DateTime.TIME_SIMPLE`.
   * The exact behavior of this method is browser-specific, but in general it will return an appropriate representation
   * of the DateTime in the assigned locale.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param formatOpts {Object} - Intl.DateTimeFormat constructor options and configuration options
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toLocaleString(); //=> 4/20/2017
   * @example DateTime.now().setLocale('en-gb').toLocaleString(); //=> '20/04/2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL); //=> 'April 20, 2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL, { locale: 'fr' }); //=> '28 août 2022'
   * @example DateTime.now().toLocaleString(DateTime.TIME_SIMPLE); //=> '11:32 AM'
   * @example DateTime.now().toLocaleString(DateTime.DATETIME_SHORT); //=> '4/20/2017, 11:32 AM'
   * @example DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
   * @example DateTime.now().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> 'Thu, Apr 20, 11:27 AM'
   * @example DateTime.now().toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); //=> '11:32'
   * @return {string}
   */
  toLocaleString(t = ua, n = {}) {
    return this.isValid ? Ie.create(this.loc.clone(n), t).formatDateTime(this) : vl;
  }
  /**
   * Returns an array of format "parts", meaning individual tokens along with metadata. This is allows callers to post-process individual sections of the formatted output.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
   * @param opts {Object} - Intl.DateTimeFormat constructor options, same as `toLocaleString`.
   * @example DateTime.now().toLocaleParts(); //=> [
   *                                   //=>   { type: 'day', value: '25' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'month', value: '05' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'year', value: '1982' }
   *                                   //=> ]
   */
  toLocaleParts(t = {}) {
    return this.isValid ? Ie.create(this.loc.clone(t), t).formatDateTimeParts(this) : [];
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=false] - add the time zone format extension
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1983, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * @example DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * @example DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * @example DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   * @return {string}
   */
  toISO({
    format: t = "extended",
    suppressSeconds: n = !1,
    suppressMilliseconds: o = !1,
    includeOffset: r = !0,
    extendedZone: i = !1
  } = {}) {
    if (!this.isValid)
      return null;
    const s = t === "extended";
    let a = Tl(this, s);
    return a += "T", a += Rd(this, s, n, o, r, i), a;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's date component
   * @param {Object} opts - options
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1982, 5, 25).toISODate() //=> '1982-05-25'
   * @example DateTime.utc(1982, 5, 25).toISODate({ format: 'basic' }) //=> '19820525'
   * @return {string}
   */
  toISODate({ format: t = "extended" } = {}) {
    return this.isValid ? Tl(this, t === "extended") : null;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's week date
   * @example DateTime.utc(1982, 5, 25).toISOWeekDate() //=> '1982-W21-2'
   * @return {string}
   */
  toISOWeekDate() {
    return Xi(this, "kkkk-'W'WW-c");
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's time component
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=true] - add the time zone format extension
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime() //=> '07:34:19.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34, seconds: 0, milliseconds: 0 }).toISOTime({ suppressSeconds: true }) //=> '07:34Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ format: 'basic' }) //=> '073419.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ includePrefix: true }) //=> 'T07:34:19.361Z'
   * @return {string}
   */
  toISOTime({
    suppressMilliseconds: t = !1,
    suppressSeconds: n = !1,
    includeOffset: o = !0,
    includePrefix: r = !1,
    extendedZone: i = !1,
    format: s = "extended"
  } = {}) {
    return this.isValid ? (r ? "T" : "") + Rd(
      this,
      s === "extended",
      n,
      t,
      o,
      i
    ) : null;
  }
  /**
   * Returns an RFC 2822-compatible string representation of this DateTime
   * @example DateTime.utc(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 +0000'
   * @example DateTime.local(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 -0400'
   * @return {string}
   */
  toRFC2822() {
    return Xi(this, "EEE, dd LLL yyyy HH:mm:ss ZZZ", !1);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in HTTP headers. The output is always expressed in GMT.
   * Specifically, the string conforms to RFC 1123.
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @example DateTime.utc(2014, 7, 13).toHTTP() //=> 'Sun, 13 Jul 2014 00:00:00 GMT'
   * @example DateTime.utc(2014, 7, 13, 19).toHTTP() //=> 'Sun, 13 Jul 2014 19:00:00 GMT'
   * @return {string}
   */
  toHTTP() {
    return Xi(this.toUTC(), "EEE, dd LLL yyyy HH:mm:ss 'GMT'");
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date
   * @example DateTime.utc(2014, 7, 13).toSQLDate() //=> '2014-07-13'
   * @return {string}
   */
  toSQLDate() {
    return this.isValid ? Tl(this, !0) : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Time
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc().toSQL() //=> '05:15:16.345'
   * @example DateTime.now().toSQL() //=> '05:15:16.345 -04:00'
   * @example DateTime.now().toSQL({ includeOffset: false }) //=> '05:15:16.345'
   * @example DateTime.now().toSQL({ includeZone: false }) //=> '05:15:16.345 America/New_York'
   * @return {string}
   */
  toSQLTime({ includeOffset: t = !0, includeZone: n = !1, includeOffsetSpace: o = !0 } = {}) {
    let r = "HH:mm:ss.SSS";
    return (n || t) && (o && (r += " "), n ? r += "z" : t && (r += "ZZ")), Xi(this, r, !0);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 Z'
   * @example DateTime.local(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 -04:00'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeOffset: false }) //=> '2014-07-13 00:00:00.000'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeZone: true }) //=> '2014-07-13 00:00:00.000 America/New_York'
   * @return {string}
   */
  toSQL(t = {}) {
    return this.isValid ? `${this.toSQLDate()} ${this.toSQLTime(t)}` : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for debugging
   * @return {string}
   */
  toString() {
    return this.isValid ? this.toISO() : vl;
  }
  /**
   * Returns a string representation of this DateTime appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `DateTime { ts: ${this.toISO()}, zone: ${this.zone.name}, locale: ${this.locale} }` : `DateTime { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns the epoch milliseconds of this DateTime. Alias of {@link DateTime#toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Returns the epoch milliseconds of this DateTime.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? this.ts : NaN;
  }
  /**
   * Returns the epoch seconds of this DateTime.
   * @return {number}
   */
  toSeconds() {
    return this.isValid ? this.ts / 1e3 : NaN;
  }
  /**
   * Returns the epoch seconds (as a whole number) of this DateTime.
   * @return {number}
   */
  toUnixInteger() {
    return this.isValid ? Math.floor(this.ts / 1e3) : NaN;
  }
  /**
   * Returns an ISO 8601 representation of this DateTime appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns a BSON serializable equivalent to this DateTime.
   * @return {Date}
   */
  toBSON() {
    return this.toJSDate();
  }
  /**
   * Returns a JavaScript object with this DateTime's year, month, day, and so on.
   * @param opts - options for generating the object
   * @param {boolean} [opts.includeConfig=false] - include configuration attributes in the output
   * @example DateTime.now().toObject() //=> { year: 2017, month: 4, day: 22, hour: 20, minute: 49, second: 42, millisecond: 268 }
   * @return {Object}
   */
  toObject(t = {}) {
    if (!this.isValid)
      return {};
    const n = { ...this.c };
    return t.includeConfig && (n.outputCalendar = this.outputCalendar, n.numberingSystem = this.loc.numberingSystem, n.locale = this.loc.locale), n;
  }
  /**
   * Returns a JavaScript Date equivalent to this DateTime.
   * @return {Date}
   */
  toJSDate() {
    return new Date(this.isValid ? this.ts : NaN);
  }
  // COMPARE
  /**
   * Return the difference between two DateTimes as a Duration.
   * @param {DateTime} otherDateTime - the DateTime to compare this one to
   * @param {string|string[]} [unit=['milliseconds']] - the unit or array of units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example
   * var i1 = DateTime.fromISO('1982-05-25T09:45'),
   *     i2 = DateTime.fromISO('1983-10-14T10:30');
   * i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }
   * i2.diff(i1, 'hours').toObject() //=> { hours: 12168.75 }
   * i2.diff(i1, ['months', 'days']).toObject() //=> { months: 16, days: 19.03125 }
   * i2.diff(i1, ['months', 'days', 'hours']).toObject() //=> { months: 16, days: 19, hours: 0.75 }
   * @return {Duration}
   */
  diff(t, n = "milliseconds", o = {}) {
    if (!this.isValid || !t.isValid)
      return W.invalid("created by diffing an invalid DateTime");
    const r = { locale: this.locale, numberingSystem: this.numberingSystem, ...o }, i = a_(n).map(W.normalizeUnit), s = t.valueOf() > this.valueOf(), a = s ? this : t, l = s ? t : this, c = fP(a, l, i, r);
    return s ? c.negate() : c;
  }
  /**
   * Return the difference between this DateTime and right now.
   * See {@link DateTime#diff}
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units units (such as 'hours' or 'days') to include in the duration
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  diffNow(t = "milliseconds", n = {}) {
    return this.diff(D.now(), t, n);
  }
  /**
   * Return an Interval spanning between this DateTime and another DateTime
   * @param {DateTime} otherDateTime - the other end point of the Interval
   * @return {Interval}
   */
  until(t) {
    return this.isValid ? se.fromDateTimes(this, t) : this;
  }
  /**
   * Return whether this DateTime is in the same unit of time as another DateTime.
   * Higher-order units must also be identical for this function to return `true`.
   * Note that time zones are **ignored** in this comparison, which compares the **local** calendar time. Use {@link DateTime#setZone} to convert one of the dates if needed.
   * @param {DateTime} otherDateTime - the other DateTime
   * @param {string} unit - the unit of time to check sameness on
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; only the locale of this DateTime is used
   * @example DateTime.now().hasSame(otherDT, 'day'); //~> true if otherDT is in the same current calendar day
   * @return {boolean}
   */
  hasSame(t, n, o) {
    if (!this.isValid)
      return !1;
    const r = t.valueOf(), i = this.setZone(t.zone, { keepLocalTime: !0 });
    return i.startOf(n, o) <= r && r <= i.endOf(n, o);
  }
  /**
   * Equality check
   * Two DateTimes are equal if and only if they represent the same millisecond, have the same zone and location, and are both valid.
   * To compare just the millisecond values, use `+dt1 === +dt2`.
   * @param {DateTime} other - the other DateTime
   * @return {boolean}
   */
  equals(t) {
    return this.isValid && t.isValid && this.valueOf() === t.valueOf() && this.zone.equals(t.zone) && this.loc.equals(t.loc);
  }
  /**
   * Returns a string representation of a this time relative to now, such as "in two days". Can only internationalize if your
   * platform supports Intl.RelativeTimeFormat. Rounds down by default.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} [options.style="long"] - the style of units, must be "long", "short", or "narrow"
   * @param {string|string[]} options.unit - use a specific unit or array of units; if omitted, or an array, the method will pick the best unit. Use an array or one of "years", "quarters", "months", "weeks", "days", "hours", "minutes", or "seconds"
   * @param {boolean} [options.round=true] - whether to round the numbers in the output.
   * @param {number} [options.padding=0] - padding in milliseconds. This allows you to round up the result if it fits inside the threshold. Don't use in combination with {round: false} because the decimal output will include the padding.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelative() //=> "in 1 day"
   * @example DateTime.now().setLocale("es").toRelative({ days: 1 }) //=> "dentro de 1 día"
   * @example DateTime.now().plus({ days: 1 }).toRelative({ locale: "fr" }) //=> "dans 23 heures"
   * @example DateTime.now().minus({ days: 2 }).toRelative() //=> "2 days ago"
   * @example DateTime.now().minus({ days: 2 }).toRelative({ unit: "hours" }) //=> "48 hours ago"
   * @example DateTime.now().minus({ hours: 36 }).toRelative({ round: false }) //=> "1.5 days ago"
   */
  toRelative(t = {}) {
    if (!this.isValid)
      return null;
    const n = t.base || D.fromObject({}, { zone: this.zone }), o = t.padding ? this < n ? -t.padding : t.padding : 0;
    let r = ["years", "months", "days", "hours", "minutes", "seconds"], i = t.unit;
    return Array.isArray(t.unit) && (r = t.unit, i = void 0), Bd(n, this.plus(o), {
      ...t,
      numeric: "always",
      units: r,
      unit: i
    });
  }
  /**
   * Returns a string representation of this date relative to today, such as "yesterday" or "next month".
   * Only internationalizes on platforms that supports Intl.RelativeTimeFormat.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.unit - use a specific unit; if omitted, the method will pick the unit. Use one of "years", "quarters", "months", "weeks", or "days"
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar() //=> "tomorrow"
   * @example DateTime.now().setLocale("es").plus({ days: 1 }).toRelative() //=> ""mañana"
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar({ locale: "fr" }) //=> "demain"
   * @example DateTime.now().minus({ days: 2 }).toRelativeCalendar() //=> "2 days ago"
   */
  toRelativeCalendar(t = {}) {
    return this.isValid ? Bd(t.base || D.fromObject({}, { zone: this.zone }), this, {
      ...t,
      numeric: "auto",
      units: ["years", "months", "days"],
      calendary: !0
    }) : null;
  }
  /**
   * Return the min of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the minimum
   * @return {DateTime} the min DateTime, or undefined if called with no argument
   */
  static min(...t) {
    if (!t.every(D.isDateTime))
      throw new Ae("min requires all arguments be DateTimes");
    return Cd(t, (n) => n.valueOf(), Math.min);
  }
  /**
   * Return the max of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the maximum
   * @return {DateTime} the max DateTime, or undefined if called with no argument
   */
  static max(...t) {
    if (!t.every(D.isDateTime))
      throw new Ae("max requires all arguments be DateTimes");
    return Cd(t, (n) => n.valueOf(), Math.max);
  }
  // MISC
  /**
   * Explain how a string would be parsed by fromFormat()
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see description)
   * @param {Object} options - options taken by fromFormat()
   * @return {Object}
   */
  static fromFormatExplain(t, n, o = {}) {
    const { locale: r = null, numberingSystem: i = null } = o, s = V.fromOpts({
      locale: r,
      numberingSystem: i,
      defaultToEN: !0
    });
    return $g(s, t, n);
  }
  /**
   * @deprecated use fromFormatExplain instead
   */
  static fromStringExplain(t, n, o = {}) {
    return D.fromFormatExplain(t, n, o);
  }
  // FORMAT PRESETS
  /**
   * {@link DateTime#toLocaleString} format like 10/14/1983
   * @type {Object}
   */
  static get DATE_SHORT() {
    return ua;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED() {
    return _y;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED_WITH_WEEKDAY() {
    return _R;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983'
   * @type {Object}
   */
  static get DATE_FULL() {
    return Py;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Tuesday, October 14, 1983'
   * @type {Object}
   */
  static get DATE_HUGE() {
    return By;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_SIMPLE() {
    return jy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SECONDS() {
    return Uy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SHORT_OFFSET() {
    return Wy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_LONG_OFFSET() {
    return Fy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_SIMPLE() {
    return qy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SECONDS() {
    return Hy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 EDT', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SHORT_OFFSET() {
    return Vy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 Eastern Daylight Time', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_LONG_OFFSET() {
    return Zy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT() {
    return zy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT_WITH_SECONDS() {
    return Gy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED() {
    return Yy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_SECONDS() {
    return Ky;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, 14 Oct 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_WEEKDAY() {
    return PR;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL() {
    return Qy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30:33 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL_WITH_SECONDS() {
    return Jy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE() {
    return Xy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30:33 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE_WITH_SECONDS() {
    return eg;
  }
}
function yr(e) {
  if (D.isDateTime(e))
    return e;
  if (e && e.valueOf && _n(e.valueOf()))
    return D.fromJSDate(e);
  if (e && typeof e == "object")
    return D.fromObject(e);
  throw new Ae(
    `Unknown datetime argument: ${e}, of type ${typeof e}`
  );
}
function kP({ collections: e, lww: t }, { tableIds: n, tableColumnIds: o, relationshipIds: r, indexIds: i, indexColumnIds: s, memoIds: a }) {
  y(e).collection("tableEntities").removeMany(n), y(e).collection("tableColumnEntities").removeMany(o), y(e).collection("relationshipEntities").removeMany(r), y(e).collection("indexEntities").removeMany(i), y(e).collection("indexColumnEntities").removeMany(s), y(e).collection("memoEntities").removeMany(a), [
    ...n,
    ...o,
    ...r,
    ...i,
    ...s,
    ...a
  ].forEach((l) => Reflect.deleteProperty(t, l));
}
const DP = 3, LP = M([
  "tableEntities",
  "tableColumnEntities",
  "relationshipEntities",
  "indexEntities",
  "indexColumnEntities",
  "memoEntities"
]);
class WB {
  async run(t) {
    const n = JSON.parse(t), o = qn(n), { doc: { tableIds: r, memoIds: i, indexIds: s, relationshipIds: a }, collections: l, lww: c } = o, u = M(r), f = M(i), d = M(s), h = M(a), p = RP(D.now()), g = y(l).collection("tableEntities"), v = y(l).collection("tableColumnEntities"), T = y(l).collection("indexEntities"), O = y(l).collection("indexColumnEntities"), k = y(l).collection("relationshipEntities"), L = y(l).collection("memoEntities"), ae = new Set(g.selectAll().filter(p(u)).map(({ id: S }) => S)), gt = /* @__PURE__ */ new Set(), vt = new Set(k.selectAll().filter(p(h)).map(({ id: S }) => S)), tt = new Set(T.selectAll().filter(p(d)).map(({ id: S }) => S)), je = /* @__PURE__ */ new Set(), Z = new Set(L.selectAll().filter(p(f)).map(({ id: S }) => S));
    v.selectAll().filter(({ tableId: S }) => ae.has(S)).forEach(({ id: S }) => gt.add(S)), k.selectAll().filter(({ id: S, start: bt, end: sr }) => !vt.has(S) && (ae.has(bt.tableId) || ae.has(sr.tableId))).forEach(({ id: S }) => vt.add(S)), T.selectAll().filter(({ id: S, tableId: bt }) => !tt.has(S) && ae.has(bt)).forEach(({ id: S }) => tt.add(S)), O.selectAll().filter(({ indexId: S }) => tt.has(S)).forEach(({ id: S }) => je.add(S)), kP(o, {
      tableIds: [...ae],
      tableColumnIds: [...gt],
      relationshipIds: [...vt],
      indexIds: [...tt],
      indexColumnIds: [...je],
      memoIds: [...Z]
    });
    const P = M(g.selectAll().map(({ id: S }) => S)), nt = M(v.selectAll().map(({ id: S }) => S)), Ht = M(T.selectAll().map(({ id: S }) => S)), At = M(O.selectAll().map(({ id: S }) => S)), Lg = M(k.selectAll().map(({ id: S }) => S)), Rg = M(L.selectAll().map(({ id: S }) => S));
    return v.selectAll().filter(({ tableId: S, id: bt, meta: sr }) => !P(S) && p(() => !1)({ id: bt, meta: sr })).forEach(({ id: S }) => gt.add(S)), O.selectAll().filter(({ indexId: S, id: bt, meta: sr }) => !Ht(S) && p(() => !1)({ id: bt, meta: sr })).forEach(({ id: S }) => je.add(S)), Object.entries(c).forEach(([S, [bt]]) => {
      if (LP(bt))
        switch (bt) {
          case "tableEntities":
            !P(S) && ae.add(S);
            break;
          case "tableColumnEntities":
            !nt(S) && gt.add(S);
            break;
          case "relationshipEntities":
            !Lg(S) && vt.add(S);
            break;
          case "indexEntities":
            !Ht(S) && tt.add(S);
            break;
          case "indexColumnEntities":
            !At(S) && je.add(S);
            break;
          case "memoEntities":
            !Rg(S) && Z.add(S);
            break;
        }
    }), {
      tableIds: [...ae],
      tableColumnIds: [...gt],
      relationshipIds: [...vt],
      indexIds: [...tt],
      indexColumnIds: [...je],
      memoIds: [...Z]
    };
  }
}
const RP = (e) => (t) => ({ id: n, meta: o }) => {
  if (t(n))
    return !1;
  const r = Math.floor(e.diff(D.fromMillis(o.updateAt), "days").toObject().days ?? 0);
  return DP < r;
};
export {
  SB as $,
  $ as A,
  M as B,
  xB as C,
  DB as D,
  UB as E,
  LN as F,
  MB as G,
  NB as H,
  sd as I,
  Cy as J,
  Cw as K,
  OB as L,
  YL as M,
  QL as N,
  Xe as O,
  KL as P,
  Su as Q,
  zo as R,
  bi as S,
  Yn as T,
  um as U,
  tB as V,
  I as W,
  eB as X,
  xw as Y,
  hi as Z,
  Ys as _,
  Gs as a,
  _ as a$,
  qP as a0,
  qp as a1,
  BP as a2,
  wt as a3,
  RB as a4,
  _B as a5,
  BB as a6,
  PB as a7,
  jB as a8,
  wp as a9,
  yu as aA,
  bp as aB,
  S1 as aC,
  Jp as aD,
  mN as aE,
  pN as aF,
  ra as aG,
  aN as aH,
  LD as aI,
  RD as aJ,
  ID as aK,
  sy as aL,
  Qp as aM,
  FP as aN,
  j as aO,
  SI as aP,
  ZP as aQ,
  VP as aR,
  to as aS,
  Ep as aT,
  fy as aU,
  Re as aV,
  $p as aW,
  U as aX,
  hN as aY,
  dN as aZ,
  uy as a_,
  QP as aa,
  Hl as ab,
  XP as ac,
  y as ad,
  YP as ae,
  aM as af,
  xI as ag,
  nB as ah,
  yR as ai,
  ld as aj,
  Ql as ak,
  re as al,
  jP as am,
  iM as an,
  na as ao,
  Sp as ap,
  cN as aq,
  uB as ar,
  uN as as,
  lN as at,
  kn as au,
  Dp as av,
  Tw as aw,
  ED as ax,
  SL as ay,
  pu as az,
  Ii as b,
  ii as b$,
  z as b0,
  Mp as b1,
  Np as b2,
  ln as b3,
  AD as b4,
  ND as b5,
  KN as b6,
  JN as b7,
  MD as b8,
  Sw as b9,
  qa as bA,
  Eo as bB,
  aB as bC,
  mB as bD,
  tN as bE,
  nN as bF,
  oN as bG,
  AL as bH,
  Cp as bI,
  fI as bJ,
  me as bK,
  Xr as bL,
  Iy as bM,
  kL as bN,
  qk as bO,
  NL as bP,
  D as bQ,
  ty as bR,
  mu as bS,
  OD as bT,
  bD as bU,
  jo as bV,
  kD as bW,
  Yf as bX,
  vu as bY,
  Kp as bZ,
  cB as b_,
  WP as ba,
  cy as bb,
  ay as bc,
  Ua as bd,
  Iu as be,
  bu as bf,
  DL as bg,
  dM as bh,
  LL as bi,
  zP as bj,
  IB as bk,
  Zr as bl,
  fB as bm,
  dB as bn,
  hu as bo,
  Ly as bp,
  HP as bq,
  UN as br,
  EL as bs,
  Ic as bt,
  du as bu,
  ht as bv,
  cM as bw,
  jn as bx,
  Q$ as by,
  ZL as bz,
  Et as c,
  J$ as c$,
  PL as c0,
  Gp as c1,
  jL as c2,
  UL as c3,
  WL as c4,
  FL as c5,
  _L as c6,
  Qk as c7,
  iB as c8,
  zk as c9,
  mM as cA,
  Vk as cB,
  yB as cC,
  lB as cD,
  t2 as cE,
  O2 as cF,
  oy as cG,
  Ve as cH,
  Lp as cI,
  l2 as cJ,
  sB as cK,
  i2 as cL,
  hE as cM,
  xm as cN,
  UP as cO,
  hB as cP,
  gB as cQ,
  Rk as cR,
  Zl as cS,
  ny as cT,
  _a as cU,
  VL as cV,
  WB as cW,
  $B as cX,
  AB as cY,
  kB as cZ,
  X$ as c_,
  rB as ca,
  Yk as cb,
  GP as cc,
  wB as cd,
  jS as ce,
  LB as cf,
  Xk as cg,
  Zi as ch,
  CB as ci,
  CM as cj,
  CD as ck,
  wD as cl,
  xt as cm,
  EB as cn,
  Tp as co,
  lt as cp,
  TB as cq,
  Hp as cr,
  $L as cs,
  _N as ct,
  xL as cu,
  ke as cv,
  eM as cw,
  ou as cx,
  SD as cy,
  ML as cz,
  ge as d,
  Ra as d0,
  tu as d1,
  bB as d2,
  oB as d3,
  vB as d4,
  pB as d5,
  JP as d6,
  kP as d7,
  yM as d8,
  N$ as d9,
  gw as e,
  aw as f,
  ow as g,
  tw as h,
  ew as i,
  nw as j,
  rw as k,
  lw as l,
  uw as m,
  iw as n,
  Vn as o,
  Qs as p,
  Tm as q,
  cw as r,
  q1 as s,
  _l as t,
  bm as u,
  Rl as v,
  BS as w,
  M$ as x,
  ww as y,
  Oy as z
};
