import { D as A, E as w, u as x, O as E, G as d, K as G, p as I, bt as l, J as p, Y as O, d2 as T, bs as V, aB as $, d7 as j, d8 as k, V as J, d9 as R, cW as z } from "./schemaGCService-B0HS3Am6.js";
/*!
 * @dineug/erd-editor
 * @version 3.2.4 | Sat Sep 28 2024
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
const b = {
  change: "change"
};
function D(u) {
  const o = /* @__PURE__ */ new Set(), t = A(u, !1), m = w(t), c = new x(), S = new E((e) => t.subscribe((a) => e.next(a))).pipe(d(p), G(200)), n = /* @__PURE__ */ new Set(), f = new z(), g = (e) => (n.has(e) || n.add(e), () => {
    n.delete(e);
  }), i = (e, a) => {
    n.forEach((s) => {
      const h = Reflect.get(s, e);
      O(h, a);
    });
  }, y = (e) => {
    const a = T(e);
    t.dispatchSync(V($(a) ? "{}" : a)), f.run(l(t.state)).then((s) => {
      (s.tableIds.length || s.tableColumnIds.length || s.relationshipIds.length || s.indexIds.length || s.indexColumnIds.length || s.memoIds.length) && (j(t.state, s), t.dispatchSync(k()), i(b.change, void 0));
    });
  }, r = (e) => {
    c.next([e].flat());
  }, v = (e) => {
    J(() => r(e));
  }, C = () => {
    Array.from(o).forEach((e) => e.unsubscribe()), o.clear(), n.clear(), t.destroy(), m.destroy();
  };
  return o.add(S.subscribe(() => i(b.change, void 0))).add(c.pipe(d(p), I((e) => e.map((a) => R(a, "tags")))).subscribe(t.dispatchSync)), Object.freeze({
    get value() {
      return l(t.state);
    },
    on: g,
    setInitialValue: y,
    dispatch: v,
    dispatchSync: r,
    destroy: C
  });
}
export {
  D as createReplicationStore
};
